export const HEADER_HEIGHT = 60
export const SUB_HEADER_HEIGHT = 120
export const LAYOUT_MAX_WIDTH = 2700
export const SIDER_WIDTH = 340
export const SIDER_COLLAPSED_WIDTH = 48
export const CONTENT_MAX_WIDTH = 1200
export const LOGIN_HEADER_HEIGHT = 290
export const LAYOUT_PADDING = 16
export const GLOBAL_FILTER_HEIGHT = 133
export const COMPARED_RELEASE_UI_HEIGHT = 96
export const SEARCH_UI_HEIGHT = 65

export const DOWNLOAD_FILES_OPTIONS = {
	INCLUDE_SVD_PDF_FILES: 'includeSvdPdfFiles',
	INCLUDE_SVD_XML_FILES: 'includeSvdXmlFiles',
	INCLUDE_INSPECTION_DOCUMENT_XML_FILES: 'includeInspectionDocumentXmlFiles',
	INCLUDE_INSPECTION_DOCUMENT_CSV_FILES: 'includeInspectionDocumentCsvFiles',
	INCLUDE_AKA_PDF: 'includeAkaPdf',
	INCLUDE_BASE_FILE: 'includeBaseFile',
	INCLUDE_HELP_FILES: 'includeHelpFiles',
	INCLUDE_REVISION_HISTORY_FILE: 'includeRevisionHistoryFile',
	INCLUDE_ATTACHMENTS: 'includeAttachments',
	INCLUDE_PCX_FILES: 'includePcxFiles'
}

export const DOWNLOAD_FILES_OPTIONS_VALUES = [
	'includeSvdPdfFiles',
	'includeSvdXmlFiles',
	'includeInspectionDocumentXmlFiles',
	'includeInspectionDocumentCsvFiles',
	'includeBaseFile',
	'includeAkaPdf',
	'includeHelpFiles',
	'includeRevisionHistoryFile',
	'includeAttachments',
	'includePcxFiles'
]

export const ATTACHMENT_OPTIONS = {
	ONLY_CHANGED_AND_NEW: 1,
	ALL_ATTACHMENTS: 2
}

export const CATALOG_TYPES = {
	1: 'VSW',
	2: 'AMM',
	3: 'SVVS'
}

export const RELEASE_STATUS = {
	PENDING: 'pending',
	RELEASED: 'released'
}

export const RELEASE_STATUS_CODES = {
	1: 'in Prüfung',
	2: 'veröffentlicht'
}

export const RELEASE_STATUS_COLORS = {
	1: 'orange',
	2: 'success'
}

export const REQUIREMENT_PRIORITY = {
	REQUIRED: 'required',
	OPTIONAL: 'optional',
	CONDITIONAL: 'conditional'
}

export const REQUIREMENT_PRIORITY_CODES = {
	1: 'Pflicht',
	2: 'Optional',
	3: 'Konditional'
}

export const QUARTERS = {
	FIRST: 1,
	SECOND: 2,
	THIRD: 3,
	FOURTH: 4
}

export const VERSIONS = {
	BETA: 'BETA',
	ONE: '1',
	TWO: '2',
	THREE: '3',
	FOUR: '4',
	FIVE: '5',
	SIX: '6',
	SEVEN: '7',
	EIGHT: '8',
	NINE: '9',
	TEN: '10'
}
export const ALL_CATEGORIES_KEY = 'ALL'
export const CATEGORIES = {
	[ALL_CATEGORIES_KEY]: 'Alle Meldungen',
	AKA: 'AKA',
	HPM: 'HPM',
	APPROVAL: 'Zulassung',
	NEWSLETTER: 'Rundschreiben',
	EVENT: 'Veranstaltung',
	MAINTENANCE: 'Wartung',
	OTHER: 'Sonstiges'
}

export const DIFF = {
	SAME: 0,
	NEW: 1,
	DELETED: 2,
	CHANGED: 3,
	ASSIGNMENT: 4
}

export const CHANGED_REQUIREMENT_STATUS = {
	NEW: 'new',
	CHANGED: 'changed',
	DELETED: 'deleted'
}

export const ATTACHMENTS_LOADING_LIST_THRESHOLD = 1000

const ascend = 'ascend'
export const ATTACHMENT_TYPE_KEY = 'attachment.type'
export const ATTACHMENT_OBJECT_TYPE_KEY = 'attachmentObject.type'
export const CONTRACT_ID_KEY = 'contract.contractId'
export const ATTACHMENT_SPU_ID_KEY = 'attachment.spuId'
export const ORDERBY_FIELDS = {
	[ATTACHMENT_TYPE_KEY]: 'attachment__type',
	[ATTACHMENT_SPU_ID_KEY]: 'attachment__spu_id',
	[ATTACHMENT_OBJECT_TYPE_KEY]: 'attachment_object__type',
	[CONTRACT_ID_KEY]: 'contract__contract_id'
}

export const ATTACHMENTS_INIT_ORDERBY = [
	{ order: ascend, field: ORDERBY_FIELDS[ATTACHMENT_TYPE_KEY] },
	{ order: ascend, field: ORDERBY_FIELDS[ATTACHMENT_OBJECT_TYPE_KEY] },
	{ order: ascend, field: ORDERBY_FIELDS[CONTRACT_ID_KEY] },
	{ order: ascend, field: ORDERBY_FIELDS[ATTACHMENT_SPU_ID_KEY] }
]

export const ATTACHMENTS_INIT_FILTERS = {
	[ATTACHMENT_TYPE_KEY]: undefined,
	[ATTACHMENT_OBJECT_TYPE_KEY]: undefined,
	[CONTRACT_ID_KEY]: undefined
}

export const CHANGES_CHAPTER_IDS = {
	REQUIREMENT: 'REQUIREMENT',
	CHANGED_REQUIREMENT: 'CHANGED_REQUIREMENT',
	NEW_REQUIREMENT: 'NEW_REQUIREMENT',
	DELETED_REQUIREMENT: 'DELETED_REQUIREMENT',
	CHANGED_ATTACHMENTS: 'CHANGED_ATTACHMENTS',
	SELECTIVE_CONTRACT_DEFINITIONS: 'SELECTIVE_CONTRACT_DEFINITIONS'
}

export const FILTER_OPTIONS = {
	CONTRACT: 'contract',
	ATTACHMENT_TYPE: 'attachmentType'
}

export const CONTRACT_TYPS = {
	A_1: 'HausarztzentrierteVersorgung',
	A_2: 'FachaerztlicheVersorgung',
	A_3: 'KvVertrag'
}

export const DRUG_MODULE_NAME_OPTIONS = [
	{ value: 'ABDAmed (Rohdaten)', label: 'ABDAmed (Rohdaten)' },
	{ value: 'IFAP (Rohdaten)', label: 'IFAP (Rohdaten)' },
	{ value: 'ifap praxisCENTER', label: 'ifap praxisCENTER' },
	{ value: 'medatixx', label: 'medatixx' },
	{ value: 'MMI PHARMINDEX', label: 'MMI PHARMINDEX (Rohdaten)' },
	{ value: 'MMI PHARMINDEX (Rohdaten)', label: 'MMI PHARMINDEX (Rohdaten)' },
	{ value: 'MMI PHARMINDEX PLUS', label: 'MMI PHARMINDEX PLUS' },
	{ value: 'Scholz', label: 'Scholz' },
	{ value: 'Sonstige', label: 'Sonstige' }
]
