export default {
	tabs: {
		requirements: 'Anforderungen',
		attachments: 'Anlagen',
		changes: 'Änderungsverzeichnis'
	},
	filters: {
		attachmentType: 'Anlagetyp',
		contract: 'Vertrag',
		contracts: 'Verträge',
		details: 'Details',
		filtered: '(gefiltert)',
		selectAll: 'Alle Verträge auswählen',
		attachmentsByContract: 'Vertrag',
		attachmentsByType: 'Anlagetyp',
		filterByContract: 'Nach Vertrag',
		filterByAttachment: 'Nur Anlagen'
	},
	attachments: {
		noResult: 'Dieser Katalog oder Filter verfügt über keine Anlagen.',
		noMoreResult: 'Keine weiteren Anlagen'
	},
	changes: {
		overview: {
			version: {
				current: 'Vorliegende Version',
				compared: 'Vergleichsversion'
			},
			year: 'Jahr',
			quarter: 'Quartal',
			release: 'Version'
		},
		requirements: {
			new: 'Neue Anforderungen',
			changed: 'Geänderte Anforderungen',
			deleted: 'Gelöschte Anforderungen',
			contracts: 'Geänderte Zuordnungen'
		},
		attachments: {
			new: 'Neue Anlagen',
			changed: 'Geänderte Anlagen',
			deleted: 'Gelöschte Anlagen'
		},
		noChanges: 'Keine {{type}} vorhanden'
	},
	openSendEmailDialog: 'Anforderungskatalog Download',
	downloadDialog: {
		title: 'Wählen Sie Ihre gewünschten Dateien aus',
		sendButton: 'Download anfordern',
		errorMessage: 'Bitte wählen Sie mindestens eine Option aus.',
		options: {
			attachments: {
				label: 'Anlagedateien',
				options: {
					only_changed_and_new: 'Nur Geänderte und Neue',
					without: 'Ohne',
					all: 'Alle Dateien',
					includePcxFiles: 'PCX-Dateien einschließen'
				},
				notExistHint:
					'Zu dem Release {{releaseName}} stehen keine Anlagedateien zur Verfügung'
			},
			pdf: 'PDF-Anforderungsdokument',
			pdfExtra: 'Anforderungskatalog Vertragssoftware',
			baseFile: 'XML-Basisdatei',
			helpFiles: 'Verschiedene Hilfsdateien',
			revisionHistory: 'PDF-Änderungsverzeichnis',
			inspectionDocumentXml: 'SVD-Dateien für Kassen',
			inspectionDocumentCsv: 'CSV-Leistungsübersichten',
			inspectionDocumentExtra: 'Weitere Prüfdokumente',
			svd: {
				xml: 'SVD-Dateien',
				pdf: 'PDF-Prüfdokumente',
				extra: 'Selektivvertragsdefinition',
				base: 'AKA-Daten strukturiert als XML',
				help: 'Hilfsdateien',
				history: 'AKA-Katalog (nur Änderungen)',
				attachment: 'AKA-Anlagen'
			}
		}
	},
	message: {
		successSendingEmail:
			'Ihr Download wird erzeugt. Sie erhalten in Kürze einen Download-Link per E-Mail. Dies kann je nach Umfang einige Minuten dauern.',
		errorSendingEmail: 'Die Email konnte nicht versendet werden.'
	},
	showRequirements: 'Zugeordnete Verträge/Regionen {{show}}',
	comparingSubheader: {
		loadingReleaseList: 'Loading releases list'
	},
	compareRelease: 'vergleichen mit: ',
	navigations: {
		requirements: 'ANFORDERUNGEN',
		attachments: 'ANLAGEN',
		revisionHistory: 'ÄNDERUNGSVERZEICHNIS'
	}
}
