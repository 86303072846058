import React, { useState, useEffect } from 'react'
import AttachmentsComponent from '../components/Attachments'
import { useQuery } from '@apollo/react-hooks'
import { ATTACHMENTS } from 'modules/core/queries'
import { ErrorHandler } from 'components'
import * as PropTypes from 'prop-types'
import {
	ATTACHMENT_TYPE_KEY,
	ATTACHMENT_OBJECT_TYPE_KEY,
	CONTRACT_ID_KEY,
	ATTACHMENTS_INIT_FILTERS
} from 'modules/core/constants'

const AttachmentList = ({
	filters,
	releaseId,
	comparedReleaseId,
	search,
	...other
}) => {
	const [attachments, setAttachments] = useState([])
	const [inProgress, setInProgress] = useState(false)
	const {
		loading,
		called,
		error,
		data: { releaseAttachments: { pageInfo = undefined, edges = [] } = [] } = {}
	} = useQuery(ATTACHMENTS, {
		fetchPolicy: 'network-only',
		variables: {
			releaseId,
			comparedReleaseId,
			query: search,
			attachment_Type: filters[ATTACHMENT_TYPE_KEY],
			attachmentObject_Type: filters[ATTACHMENT_OBJECT_TYPE_KEY],
			contract_ContractId: filters[CONTRACT_ID_KEY]
		}
	})

	useEffect(() => {
		if (called && !loading && pageInfo) {
			setAttachments(edges.map(edge => edge.node))
		}
	}, [loading, called, edges, pageInfo])

	useEffect(() => {
		setInProgress(false)
	}, [attachments])

	return (
		<ErrorHandler error={error} loading={loading}>
			<AttachmentsComponent
				attachments={attachments}
				inProgress={inProgress}
				loading={loading}
				releaseId={releaseId}
				{...other}
			/>
		</ErrorHandler>
	)
}
AttachmentList.propTypes = {
	filters: PropTypes.shape({}),
	setFilters: PropTypes.func,
	releaseId: PropTypes.string.isRequired,
	comparedReleaseId: PropTypes.string,
	attachmentsTypes: PropTypes.array,
	attachmentObjectTypes: PropTypes.array,
	attachmentContracts: PropTypes.array
}

AttachmentList.defaultProps = {
	filters: ATTACHMENTS_INIT_FILTERS,
	setFilters: () => {},
	comparedReleaseId: undefined,
	attachmentsTypes: [],
	attachmentObjectTypes: [],
	attachmentContracts: []
}

export default AttachmentList
