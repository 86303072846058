import { ButtonWrapper } from './styles'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

type AkaButton = {
	children: React.ReactNode
}

const AkaButton = ({ children, ...props }: AkaButton & ButtonProps) => (
	<ButtonWrapper>
		<Button {...props}>{children}</Button>
	</ButtonWrapper>
)

export default AkaButton
