import { createContext } from 'react'
import { JWT } from 'configs/JWT'

export const defaultUserData = {
	user: {
		firstName: '',
		lastName: '',
		email: '',
		hasSelectedContracts: false,
		isKasseUser: false,
		isVswUser: false,
		isSuperuser: false,
		helpFile: undefined,
		contractsFilter: []
	},
	isAuthenticated: !!JWT.get(),
	error: undefined,
	loadingAuth: false,
	loadingVerify: false,
	hasToken: false,
	login: () => {},
	logout: () => {},
	refetchMe: () => {}
}

export default createContext(defaultUserData)
