/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/react-hooks'
import PageLayout from 'modules/layout/containers/PageLayout'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { ErrorHandler } from 'components'
import { Layout, message, Tag, Upload } from 'antd'
import pdfDownloadIcon from 'img/pdf-download.svg'
import { CONTENT_CHECK, SOFTWARE_SYSTEM_DETAILS } from 'modules/core/queries'
import ContentCheckSider from './ContentCheckSider'
import { AkaButton, AkaTypography, LoadingSpinner } from 'components'
import { InboxOutlined } from '@ant-design/icons'
import type { UploadProps, UploadFile } from 'antd'
import { UPLOAD_CONTENT_CHECK_PROOF } from 'modules/core/mutations'
import { ContentCheckInstructionHintWrapper } from './styles'
import { ROUTE_FUNCTIONS } from '../../navigation'
import { StylesDictionary } from 'components/typography/styles'
import { useUser } from 'providers'
import { isContentCheckProofSubmitted } from 'utils/helper.utils'
import {
	ContentCheckProofUploadMutation,
	ContentCheckProofUploadMutationVariables,
	ContentCheckQuery,
	ContentCheckQueryVariables
} from 'generated/types'
import { useAntdMessageApi } from 'providers'

const { Content } = Layout
const { Dragger } = Upload

const styles: StylesDictionary = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column'
	},
	submitWrapper: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end'
	}
}
const ContentCheckDetailsPage = () => {
	const {
		id: contentCheckId,
		systemId,
		quarter
	} = useParams<{
		id: string
		quarter: string
		systemId: string
	}>()
	const [t] = useTranslation()
	const history = useHistory()
	const messageApi = useAntdMessageApi()

	const {
		user: { isVswUser }
	} = useUser()

	const [fileList, setFileList] = useState<UploadFile[]>([])

	const { data, loading, error } = useQuery<
		ContentCheckQuery,
		ContentCheckQueryVariables
	>(CONTENT_CHECK, {
		variables: { id: contentCheckId, systemId }
	})
	const {
		data: softwareSystemData,
		loading: softwareSystemLoading,
		error: softwareSystemError
	} = useQuery(SOFTWARE_SYSTEM_DETAILS, {
		variables: { id: systemId }
	})
	const [
		uploadProof,
		{ loading: uploadProofLoading, error: uploadProofError }
	] = useMutation<
		ContentCheckProofUploadMutation,
		ContentCheckProofUploadMutationVariables
	>(UPLOAD_CONTENT_CHECK_PROOF, {
		refetchQueries: [
			{ query: CONTENT_CHECK, variables: { id: contentCheckId, systemId } }
		]
	})

	const currentContentCheck = data?.contentCheck
	const approvalRun = currentContentCheck?.approvalRun
	const contractId = currentContentCheck?.contract.contractId
	const contractName = currentContentCheck?.contract.name
	const softwareSystem = softwareSystemData?.softwareSystem

	const isSubmitted = isContentCheckProofSubmitted(
		currentContentCheck?.proof?.status
	)
	const isReadOnly = !isVswUser || isSubmitted

	const alreadyUploadedFiles = currentContentCheck?.proof?.files || []

	const uploadProps: UploadProps = {
		name: 'file',
		multiple: true,
		accept:
			'image/jpeg, image/jpg, image/png, image/mpg, image/mpeg, application/zip, application/pdf',
		disabled: isReadOnly || !currentContentCheck?.instructionFileUrl,
		customRequest({ onSuccess }) {
			// to get rid of the default antd Upload submit
			setTimeout(() => onSuccess && onSuccess('ok'), 0)
		},
		onRemove(file) {
			setFileList(fileList.filter(f => f.uid !== file.uid))
		},
		onChange({ fileList, file }) {
			const { status } = file

			if (status === 'done') {
				setFileList(fileList)
			} else if (status === 'error') {
				message.error(`${file.name} Fehler aufgetreten.`)
			}
		},
		showUploadList: {
			showRemoveIcon: true
		}
	}

	const sideBarContent = !!approvalRun && !!contractId && (
		<ContentCheckSider
			quarter={approvalRun?.quarter}
			year={approvalRun?.year}
			tenant={approvalRun?.tenant?.name}
			system={softwareSystem?.systemName}
			contract={contractId}
		/>
	)

	const mainContent = (
		<Content style={styles.contentWrapper}>
			<ErrorHandler
				loading={loading || softwareSystemLoading}
				error={error || softwareSystemError || uploadProofError}
			>
				<div style={styles.wrapper}>
					<AkaTypography variant="h2">
						<Trans i18nKey={'approval.contentCheck.heading'}>
							Ergebnisprüfung für den Vertrag{' '}
							<strong>{{ contractName } as any}</strong> für das System{' '}
							<strong>
								{{ systemName: softwareSystem?.systemName } as any}
							</strong>
						</Trans>
					</AkaTypography>
					<ContentCheckInstructionHintWrapper>
						{currentContentCheck?.instructionFileUrl && (
							<a
								href={currentContentCheck?.instructionFileUrl}
								target={'_blank'}
								rel="noreferrer"
								download
							>
								<img src={pdfDownloadIcon} width={50} alt="PDF download icon" />
							</a>
						)}
						<span style={{ marginLeft: 12 }}>
							<AkaTypography variant="body2">
								<Trans i18nKey={'approval.contentCheck.instructionHin'}>
									In diesem Quartal findet für den Vertrag{' '}
									<strong>{{ contractName } as any}</strong> eine
									Ergebnisprüfung statt.
								</Trans>
							</AkaTypography>
							{currentContentCheck?.instructionFileUrl ? (
								<AkaTypography variant={'body2'}>
									{t('approval.contentCheck.pleaseUploadProof')}
								</AkaTypography>
							) : (
								<AkaTypography variant={'body2'} color={'error'}>
									{t('approval.contentCheck.noInstructionFile')}
								</AkaTypography>
							)}
						</span>
					</ContentCheckInstructionHintWrapper>
					{alreadyUploadedFiles.length > 0 && (
						<div style={{ marginTop: 16 }}>
							<AkaTypography variant={'h3'}>
								{t('approval.contentCheck.alreadyUploadedFiles')}
							</AkaTypography>
							<ul style={{ listStyleType: 'none' }}>
								{alreadyUploadedFiles.map((file, index) => {
									const path = file.url
									const fileName = file.name
									const fileExtension = fileName
										?.split('.')
										.pop()
										?.toLowerCase()
									return (
										<li key={index}>
											<a
												href={path}
												target={'_blank'}
												rel={'noreferrer'}
												download
											>
												{fileExtension && (
													<Tag color={'blue'}>{fileExtension}</Tag>
												)}
												{fileName}
											</a>
										</li>
									)
								})}
							</ul>
						</div>
					)}
					<Dragger {...uploadProps} style={{ marginTop: 16 }}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							{t('approval.contentCheck.uploadText')}
						</p>
						<p className="ant-upload-hint">
							{t('approval.contentCheck.uploadHint')}
						</p>
					</Dragger>
					<div style={styles.submitWrapper}>
						<AkaButton
							htmlType="submit"
							type="primary"
							disabled={!fileList.length || isReadOnly}
							loading={uploadProofLoading}
							onClick={() => {
								uploadProof({
									variables: {
										systemId: systemId,
										contentCheckId: contentCheckId,
										proofFiles: fileList.map(file => file.originFileObj)
									}
								}).then(_ => {
									history.push(ROUTE_FUNCTIONS.getApprovalPath(quarter))
									messageApi?.open({
										type: 'success',
										content: t('approval.contentCheck.uploadSuccessMessage')
									})
								})
							}}
						>
							{t('approval.contentCheck.proofSubmit')}
						</AkaButton>
						{isSubmitted && (
							<AkaTypography variant={'tiny'} style={{ marginTop: 16 }}>
								{t('approval.contentCheck.alreadyProofSubmitted')}
							</AkaTypography>
						)}
					</div>
				</div>
			</ErrorHandler>
		</Content>
	)
	if (uploadProofLoading || loading || softwareSystemLoading) {
		return <LoadingSpinner />
	}
	return (
		<PageLayout
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default ContentCheckDetailsPage
