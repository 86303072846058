import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	Date: string
	DateTime: string
	GenericScalar: any
	JSONString: any
	Upload: any
}

export type ApprovalLogItemType = {
	__typename?: 'ApprovalLogItemType'
	actionMessage: Scalars['String']
	approvalRun: ApprovalRunType
	createdAt: Scalars['DateTime']
	createdByText: Scalars['String']
	id: Scalars['ID']
	isUserMessage: Scalars['Boolean']
	tenant: TenantType
	userMessage?: Maybe<Scalars['String']>
}

export type ApprovalReleaseChanges = {
	__typename?: 'ApprovalReleaseChanges'
	changedRequirementIds: Array<Scalars['ID']>
	newRequirementIds: Array<Scalars['ID']>
}

export type ApprovalRunType = {
	__typename?: 'ApprovalRunType'
	id: Scalars['ID']
	quarter: Scalars['Int']
	release?: Maybe<ReleaseType>
	submissionDeadline: Scalars['Date']
	tenant: TenantType
	title: Scalars['String']
	year: Scalars['Int']
}

export type AttachmentContractReleaseNode = Node & {
	__typename?: 'AttachmentContractReleaseNode'
	attachment: AttachmentType
	attachmentObject: AttachmentObjectType
	attachmentObjectSpuId: Scalars['Int']
	attachmentSpuId: Scalars['Int']
	changes?: Maybe<Scalars['String']>
	contract: ContractType
	diff?: Maybe<Scalars['Int']>
	id: Scalars['ID']
	release: ReleaseType
}

export type AttachmentContractReleaseNodeConnection = {
	__typename?: 'AttachmentContractReleaseNodeConnection'
	edges: Array<Maybe<AttachmentContractReleaseNodeEdge>>
	pageInfo: PageInfo
}

export type AttachmentContractReleaseNodeEdge = {
	__typename?: 'AttachmentContractReleaseNodeEdge'
	cursor: Scalars['String']
	node?: Maybe<AttachmentContractReleaseNode>
}

export type AttachmentObjectType = {
	__typename?: 'AttachmentObjectType'
	attachmentContractReleases: AttachmentContractReleaseNodeConnection
	file?: Maybe<Scalars['String']>
	filePath?: Maybe<Scalars['String']>
	fileVersion?: Maybe<Scalars['String']>
	id: Scalars['ID']
	link?: Maybe<Scalars['String']>
	page?: Maybe<Scalars['String']>
	release: ReleaseType
	releaseType?: Maybe<Scalars['String']>
	spuId: Scalars['Int']
	text?: Maybe<Scalars['String']>
	title: Scalars['String']
	type?: Maybe<Scalars['String']>
}

export type AttachmentObjectTypeAttachmentContractReleasesArgs = {
	after?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
}

export type AttachmentType = {
	__typename?: 'AttachmentType'
	attachmentContractReleases: AttachmentContractReleaseNodeConnection
	id: Scalars['ID']
	printTypes?: Maybe<Scalars['String']>
	release: ReleaseType
	spuId: Scalars['Int']
	subtype?: Maybe<Scalars['String']>
	title: Scalars['String']
	type: Scalars['String']
}

export type AttachmentTypeAttachmentContractReleasesArgs = {
	after?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
}

export type ChapterType = {
	__typename?: 'ChapterType'
	chapterIndex?: Maybe<Scalars['String']>
	chapters: Array<ChapterType>
	comparedReleaseId?: Maybe<Scalars['Int']>
	description?: Maybe<Scalars['String']>
	diff?: Maybe<Scalars['Int']>
	fullTitle?: Maybe<Scalars['String']>
	id: Scalars['ID']
	matrixRequirements?: Maybe<Array<Maybe<RequirementType>>>
	parent?: Maybe<ChapterType>
	parentUid: Scalars['String']
	pathFromRoot?: Maybe<Array<Maybe<Scalars['String']>>>
	pos: Scalars['Int']
	release: ReleaseType
	requirements: Array<RequirementAssignmentType>
	title: Scalars['String']
	uid: Scalars['String']
}

export type ChapterTypeMatrixRequirementsArgs = {
	reportId: Scalars['ID']
}

export type ContactPersonInputType = {
	email: Scalars['String']
	firstname: Scalars['String']
	lastname: Scalars['String']
	phoneNumber: Scalars['String']
	title?: InputMaybe<Scalars['String']>
}

export type ContactPersonType = {
	__typename?: 'ContactPersonType'
	email: Scalars['String']
	firstname: Scalars['String']
	id: Scalars['ID']
	isMainContact: Scalars['Boolean']
	lastname: Scalars['String']
	phoneNumber: Scalars['String']
	title: Scalars['String']
}

export enum ContentCheckProofStatus {
	APPROVED = 'APPROVED',
	CORRECTION_REQUESTED = 'CORRECTION_REQUESTED',
	IN_REVIEW = 'IN_REVIEW',
	OPEN = 'OPEN'
}

export type ContentCheckProofType = {
	__typename?: 'ContentCheckProofType'
	files: Array<FileType>
	id: Scalars['ID']
	status: ContentCheckProofStatus
	system: SoftwareSystemType
}

export type ContentCheckProofUpload = {
	__typename?: 'ContentCheckProofUpload'
	success?: Maybe<Scalars['Boolean']>
}

export type ContentCheckType = {
	__typename?: 'ContentCheckType'
	approvalRun: ApprovalRunType
	contract: ContractType
	id: Scalars['ID']
	instructionFileUrl?: Maybe<Scalars['String']>
	proof?: Maybe<ContentCheckProofType>
	title: Scalars['String']
}

export type ContentCheckTypeProofArgs = {
	systemId: Scalars['ID']
}

export type ContractRegionGroupType = {
	__typename?: 'ContractRegionGroupType'
	contract: ContractType
	id: Scalars['ID']
	regions: Array<RegionType>
}

export type ContractRegionGroupsPerRequirement = {
	contractRegionGroupIds: Array<Scalars['Int']>
	requirementId: Scalars['Int']
}

export enum ContractTyp {
	A_1 = 'A_1',
	A_2 = 'A_2',
	A_3 = 'A_3'
}

export type ContractType = {
	__typename?: 'ContractType'
	attachmentContractReleases: AttachmentContractReleaseNodeConnection
	contentChecks: Array<ContentCheckType>
	contractGroups: Array<ContractRegionGroupType>
	contractId: Scalars['String']
	contractsFilter: Array<UserType>
	id: Scalars['ID']
	identification: Scalars['String']
	isApprovalRelevant: Scalars['Boolean']
	name: Scalars['String']
	regions: Array<RegionType>
	releaseContracts: ReleaseContractNodeConnection
	requirementContractRegions: Array<RequirementContractRegionType>
	selectedContracts: Array<UserType>
	softwareSystems: Array<SoftwareSystemType>
	systemApprovals: Array<SystemApprovalType>
	tenant?: Maybe<TenantType>
	typ: ContractTyp
}

export type ContractTypeAttachmentContractReleasesArgs = {
	after?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
}

export type ContractTypeReleaseContractsArgs = {
	after?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
}

export type CustomJsonWebTokenMutation = {
	__typename?: 'CustomJSONWebTokenMutation'
	payload: Scalars['GenericScalar']
	refreshExpiresIn: Scalars['Int']
	token: Scalars['String']
	user?: Maybe<UserType>
}

export type DjangoDebug = {
	__typename?: 'DjangoDebug'
	sql?: Maybe<Array<Maybe<DjangoDebugSql>>>
}

export type DjangoDebugSql = {
	__typename?: 'DjangoDebugSQL'
	alias: Scalars['String']
	duration: Scalars['Float']
	encoding?: Maybe<Scalars['String']>
	isSelect: Scalars['Boolean']
	isSlow: Scalars['Boolean']
	isoLevel?: Maybe<Scalars['String']>
	params: Scalars['String']
	rawSql: Scalars['String']
	sql?: Maybe<Scalars['String']>
	startTime: Scalars['Float']
	stopTime: Scalars['Float']
	transId?: Maybe<Scalars['String']>
	transStatus?: Maybe<Scalars['String']>
	vendor: Scalars['String']
}

export type DrugModuleType = {
	__typename?: 'DrugModuleType'
	date?: Maybe<Scalars['Date']>
	name: Scalars['String']
	version: Scalars['String']
}

export type FileType = {
	__typename?: 'FileType'
	name: Scalars['String']
	url: Scalars['String']
}

export type ImageType = {
	__typename?: 'ImageType'
	height: Scalars['Int']
	url: Scalars['String']
	width: Scalars['Int']
}

export type JustificationInputType = {
	requirementIds: Array<Scalars['Int']>
	text: Scalars['String']
}

export type JustificationType = {
	__typename?: 'JustificationType'
	id: Scalars['ID']
	requirements: Array<RequirementAssignmentType>
	text: Scalars['String']
}

export type JustificationsMutation = {
	__typename?: 'JustificationsMutation'
	justificationsCount: Scalars['Int']
}

export type Mutation = {
	__typename?: 'Mutation'
	contentCheckProofUpload?: Maybe<ContentCheckProofUpload>
	justifyUnsatisfiedRequirements?: Maybe<JustificationsMutation>
	refreshToken?: Maybe<Refresh>
	selectContractRegionGroupRequirements?: Maybe<SelectContractRegionGroupRequirements>
	selectContractRegionGroups?: Maybe<Scalars['Boolean']>
	sendReleasePacketEmail?: Maybe<SendReleasePacketEmailMutationPayload>
	sendUserMessageForApproval?: Maybe<UserMessageForApproval>
	setUserContractsFilter?: Maybe<SetUserContractsFilterPayload>
	tokenAuth?: Maybe<CustomJsonWebTokenMutation>
	updateReportSoftwareSystemInfo?: Maybe<UpdateReportSoftwareSystemInfo>
	updateSoftwareSystem?: Maybe<UpdateSoftwareSystem>
	updateSoftwareSystemCosts?: Maybe<UpdateSoftwareSystemCosts>
	verifyToken?: Maybe<VerifyToken>
}

export type MutationContentCheckProofUploadArgs = {
	contentCheckId: Scalars['ID']
	proofFiles: Array<Scalars['Upload']>
	systemId: Scalars['ID']
}

export type MutationJustifyUnsatisfiedRequirementsArgs = {
	items: Array<JustificationInputType>
	reportId: Scalars['ID']
}

export type MutationRefreshTokenArgs = {
	token?: InputMaybe<Scalars['String']>
}

export type MutationSelectContractRegionGroupRequirementsArgs = {
	reportId: Scalars['ID']
	selection: Array<ContractRegionGroupsPerRequirement>
}

export type MutationSelectContractRegionGroupsArgs = {
	reportId: Scalars['ID']
	selectedGroupIds: Array<InputMaybe<Scalars['ID']>>
}

export type MutationSendReleasePacketEmailArgs = {
	input: SendReleasePacketEmailMutationInput
}

export type MutationSendUserMessageForApprovalArgs = {
	contentCheckId?: InputMaybe<Scalars['ID']>
	softwareSystemId: Scalars['ID']
	systemReportId?: InputMaybe<Scalars['ID']>
	userMessage: Scalars['String']
}

export type MutationSetUserContractsFilterArgs = {
	input: SetUserContractsFilterInput
}

export type MutationTokenAuthArgs = {
	email: Scalars['String']
	password: Scalars['String']
}

export type MutationUpdateReportSoftwareSystemInfoArgs = {
	additionalContactInput?: InputMaybe<ContactPersonInputType>
	mainContactInput: ContactPersonInputType
	reportInput: ReportInputType
	reportSystemInput: ReportSystemInputType
}

export type MutationUpdateSoftwareSystemArgs = {
	slogan?: InputMaybe<Scalars['String']>
	systemId: Scalars['ID']
	website?: InputMaybe<Scalars['String']>
}

export type MutationUpdateSoftwareSystemCostsArgs = {
	systemCosts: Array<SystemCostType>
	systemId: Scalars['ID']
}

export type MutationVerifyTokenArgs = {
	token?: InputMaybe<Scalars['String']>
}

export type Node = {
	id: Scalars['ID']
}

export enum NotificationCategory {
	AKA = 'AKA',
	APPROVAL = 'APPROVAL',
	EVENT = 'EVENT',
	HPM = 'HPM',
	MAINTENANCE = 'MAINTENANCE',
	NEWSLETTER = 'NEWSLETTER',
	NONE = 'NONE',
	OTHER = 'OTHER'
}

export type NotificationContentObjectUnion = ApprovalRunType | ReleaseType

export type NotificationObjectType = {
	__typename?: 'NotificationObjectType'
	category?: Maybe<NotificationCategory>
	contentObject?: Maybe<NotificationContentObjectUnion>
	contentObjectModel?: Maybe<Scalars['String']>
	createdAt: Scalars['DateTime']
	createdBy?: Maybe<UserType>
	emailsLastSendAt?: Maybe<Scalars['DateTime']>
	id: Scalars['ID']
	lastmodifiedAt: Scalars['DateTime']
	lastmodifiedBy?: Maybe<UserType>
	message: Scalars['String']
	objectId?: Maybe<Scalars['Int']>
	releasedAt?: Maybe<Scalars['DateTime']>
	status: NotificationStatus
	title: Scalars['String']
	type: NotificationType
}

export enum NotificationStatus {
	A_10 = 'A_10',
	A_20 = 'A_20'
}

export enum NotificationType {
	A_10 = 'A_10',
	A_20 = 'A_20',
	A_30 = 'A_30'
}

export type PageInfo = {
	__typename?: 'PageInfo'
	endCursor?: Maybe<Scalars['String']>
	hasNextPage: Scalars['Boolean']
	hasPreviousPage: Scalars['Boolean']
	startCursor?: Maybe<Scalars['String']>
}

export type Query = {
	__typename?: 'Query'
	_debug?: Maybe<DjangoDebug>
	approvalFeed: Array<Maybe<ApprovalLogItemType>>
	attachmentContracts?: Maybe<Array<Maybe<Scalars['String']>>>
	attachmentObjectTypes?: Maybe<Array<Maybe<Scalars['String']>>>
	attachmentsObjectFilePath?: Maybe<Scalars['String']>
	attachmentsTypes?: Maybe<Array<Maybe<Scalars['String']>>>
	chapter?: Maybe<ChapterType>
	chapterDiff?: Maybe<ChapterType>
	chapters?: Maybe<Array<Maybe<ChapterType>>>
	contentCheck?: Maybe<ContentCheckType>
	contentCheckList?: Maybe<Array<Maybe<ContentCheckType>>>
	contractRegionGroupList?: Maybe<Array<Maybe<ContractRegionGroupType>>>
	me?: Maybe<UserType>
	notifications?: Maybe<Array<Maybe<NotificationObjectType>>>
	release?: Maybe<ReleaseType>
	releaseAttachmentChanges?: Maybe<ReleaseAttachmentContractsChangesDirectoryType>
	releaseAttachmentObjectContracts?: Maybe<Array<Maybe<Scalars['String']>>>
	releaseAttachments?: Maybe<AttachmentContractReleaseNodeConnection>
	releaseContractChanges?: Maybe<ReleaseContractsChangesDirectoryType>
	releaseContracts?: Maybe<Array<Maybe<ContractType>>>
	releaseRequirementsChanges?: Maybe<ReleaseRequirementsChangesDirectoryType>
	releaseRequirementsSearch?: Maybe<Array<Maybe<RequirementType>>>
	releases?: Maybe<Array<Maybe<ReleaseType>>>
	releasesToCompare?: Maybe<Array<Maybe<ReleaseType>>>
	requirementsDiff?: Maybe<Array<Maybe<RequirementType>>>
	softwareSystem?: Maybe<SoftwareSystemType>
	softwareSystemReport?: Maybe<SoftwareSystemReportType>
	softwareSystemReportList?: Maybe<Array<Maybe<SoftwareSystemReportType>>>
	softwareSystems?: Maybe<Array<Maybe<SoftwareSystemType>>>
	userContracts?: Maybe<Array<Maybe<ContractType>>>
	users?: Maybe<Array<Maybe<UserType>>>
}

export type QueryApprovalFeedArgs = {
	quarter: Scalars['Int']
	systemId: Scalars['ID']
	year: Scalars['Int']
}

export type QueryAttachmentContractsArgs = {
	releaseId: Scalars['ID']
}

export type QueryAttachmentObjectTypesArgs = {
	releaseId: Scalars['ID']
}

export type QueryAttachmentsObjectFilePathArgs = {
	attachmentObjectId: Scalars['ID']
}

export type QueryAttachmentsTypesArgs = {
	releaseId: Scalars['ID']
}

export type QueryChapterArgs = {
	id: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryChapterDiffArgs = {
	comparedReleaseId: Scalars['ID']
	id: Scalars['ID']
}

export type QueryChaptersArgs = {
	releaseId: Scalars['ID']
}

export type QueryContentCheckArgs = {
	id: Scalars['ID']
	systemId: Scalars['ID']
}

export type QueryContentCheckListArgs = {
	quarter: Scalars['Int']
	systemId: Scalars['ID']
	year: Scalars['Int']
}

export type QueryContractRegionGroupListArgs = {
	reportId: Scalars['ID']
}

export type QueryReleaseArgs = {
	id: Scalars['ID']
}

export type QueryReleaseAttachmentChangesArgs = {
	comparedReleaseId: Scalars['ID']
	currentReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryReleaseAttachmentObjectContractsArgs = {
	attachmentObjectSpuID: Scalars['Int']
	attachmentSpuID: Scalars['Int']
	releaseId: Scalars['ID']
}

export type QueryReleaseAttachmentsArgs = {
	after?: InputMaybe<Scalars['String']>
	attachmentObject_Type?: InputMaybe<Scalars['String']>
	attachment_Type?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	comparedReleaseId?: InputMaybe<Scalars['ID']>
	contract_ContractId?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
	query?: InputMaybe<Scalars['String']>
	releaseId: Scalars['ID']
}

export type QueryReleaseContractChangesArgs = {
	comparedReleaseId: Scalars['ID']
	currentReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryReleaseContractsArgs = {
	releaseId: Scalars['ID']
}

export type QueryReleaseRequirementsChangesArgs = {
	comparedReleaseId: Scalars['ID']
	currentReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryReleaseRequirementsSearchArgs = {
	query: Scalars['String']
	releaseId: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryReleasesToCompareArgs = {
	currentVersionId: Scalars['ID']
}

export type QueryRequirementsDiffArgs = {
	chapterId: Scalars['ID']
	comparedReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QuerySoftwareSystemArgs = {
	id: Scalars['ID']
}

export type QuerySoftwareSystemReportArgs = {
	id: Scalars['ID']
}

export type QuerySoftwareSystemReportListArgs = {
	quarter: Scalars['Int']
	systemId: Scalars['ID']
	year: Scalars['Int']
}

export type Refresh = {
	__typename?: 'Refresh'
	payload: Scalars['GenericScalar']
	refreshExpiresIn: Scalars['Int']
	token: Scalars['String']
}

export type RegionType = {
	__typename?: 'RegionType'
	comparedReleaseId?: Maybe<Scalars['Int']>
	comparedRequirementId?: Maybe<Scalars['Int']>
	contractGroups: Array<ContractRegionGroupType>
	contracts?: Maybe<Scalars['String']>
	contractsDiff?: Maybe<Scalars['String']>
	diff?: Maybe<Scalars['Int']>
	name: Scalars['String']
	okv: Scalars['String']
	requirementContractRegions: Array<RequirementContractRegionType>
	requirements: Array<RequirementAssignmentType>
	shortName?: Maybe<Scalars['String']>
}

export type ReleaseAttachmentContractsChangesDirectoryType = {
	__typename?: 'ReleaseAttachmentContractsChangesDirectoryType'
	changedAttachments?: Maybe<Array<Maybe<AttachmentContractReleaseNode>>>
	comparedReleaseId?: Maybe<Scalars['Int']>
	currentReleaseId?: Maybe<Scalars['Int']>
	deletedAttachments?: Maybe<Array<Maybe<AttachmentContractReleaseNode>>>
	newAttachments?: Maybe<Array<Maybe<AttachmentContractReleaseNode>>>
}

export type ReleaseContractNode = Node & {
	__typename?: 'ReleaseContractNode'
	changes?: Maybe<Scalars['String']>
	contract: ContractType
	diff?: Maybe<Scalars['Int']>
	id: Scalars['ID']
	oldVersion?: Maybe<Scalars['String']>
	release: ReleaseType
	version: Scalars['String']
}

export type ReleaseContractNodeConnection = {
	__typename?: 'ReleaseContractNodeConnection'
	edges: Array<Maybe<ReleaseContractNodeEdge>>
	pageInfo: PageInfo
}

export type ReleaseContractNodeEdge = {
	__typename?: 'ReleaseContractNodeEdge'
	cursor: Scalars['String']
	node?: Maybe<ReleaseContractNode>
}

export type ReleaseContractsChangesDirectoryType = {
	__typename?: 'ReleaseContractsChangesDirectoryType'
	comparedReleaseId?: Maybe<Scalars['Int']>
	currentReleaseId?: Maybe<Scalars['Int']>
	releaseContractsDiff?: Maybe<Array<Maybe<ReleaseContractNode>>>
}

export type ReleaseRequirementsChangesDirectoryType = {
	__typename?: 'ReleaseRequirementsChangesDirectoryType'
	changedAssignmentRequirements?: Maybe<Array<Maybe<RequirementAssignmentType>>>
	changedRequirements?: Maybe<Array<Maybe<RequirementType>>>
	comparedReleaseId?: Maybe<Scalars['Int']>
	currentReleaseId?: Maybe<Scalars['Int']>
	deletedRequirements?: Maybe<Array<Maybe<RequirementType>>>
	newRequirements?: Maybe<Array<Maybe<RequirementType>>>
}

export type ReleaseType = {
	__typename?: 'ReleaseType'
	approvalRuns: Array<ApprovalRunType>
	attachment: Array<AttachmentType>
	attachmentContractReleases: AttachmentContractReleaseNodeConnection
	attachmentObjects: Array<AttachmentObjectType>
	catalog: Scalars['Int']
	chapters: Array<ChapterType>
	contractGroups: Array<ContractRegionGroupType>
	createdAt: Scalars['DateTime']
	createdBy: UserType
	id: Scalars['ID']
	isAttachmentsDirExist?: Maybe<Scalars['Boolean']>
	lastmodifiedAt: Scalars['DateTime']
	lastmodifiedBy?: Maybe<UserType>
	name: Scalars['String']
	quarter: Scalars['String']
	releaseContracts: ReleaseContractNodeConnection
	releaseDate: Scalars['Date']
	requirementContractRegions: Array<RequirementContractRegionType>
	requirements: Array<RequirementAssignmentType>
	requirementsCatalogRootChapter?: Maybe<ChapterType>
	revisionHistoryRootChapter?: Maybe<ChapterType>
	rootChapter?: Maybe<ChapterType>
	status: Scalars['Int']
	version: Scalars['String']
	year: Scalars['Int']
}

export type ReleaseTypeAttachmentContractReleasesArgs = {
	after?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
}

export type ReleaseTypeReleaseContractsArgs = {
	after?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	offset?: InputMaybe<Scalars['Int']>
}

export type ReportInputType = {
	reportId: Scalars['ID']
	rolloutDate: Scalars['Date']
	systemVersion: Scalars['String']
}

export enum ReportStatus {
	APPROVED = 'APPROVED',
	CORRECTION_REQUESTED = 'CORRECTION_REQUESTED',
	EDITING = 'EDITING',
	IN_REVIEW = 'IN_REVIEW',
	OPEN = 'OPEN'
}

export type ReportSystemInputType = {
	city: Scalars['String']
	drugModuleDate: Scalars['Date']
	drugModuleName: Scalars['String']
	drugModuleVersion: Scalars['String']
	orderAddress: Scalars['String']
	postalCode: Scalars['String']
	streetAndNumber: Scalars['String']
	systemId: Scalars['ID']
	website?: InputMaybe<Scalars['String']>
}

export type RequirementAssignmentType = {
	__typename?: 'RequirementAssignmentType'
	caliberId: Scalars['Int']
	catalog: Scalars['String']
	chapter?: Maybe<ChapterType>
	comparedReleaseId?: Maybe<Scalars['Int']>
	contractRegionGroupIds: Array<Scalars['ID']>
	contractRegionGroups: Array<ContractRegionGroupType>
	deletedRegionContractAssignments?: Maybe<Array<Maybe<Scalars['String']>>>
	description?: Maybe<Scalars['String']>
	diff?: Maybe<Scalars['Int']>
	id: Scalars['ID']
	newRegionContractAssignments?: Maybe<Array<Maybe<Scalars['String']>>>
	note?: Maybe<Scalars['String']>
	objectId: Scalars['Int']
	objectVersionId: Scalars['Int']
	pos: Scalars['Int']
	prefix?: Maybe<Scalars['String']>
	prefixedCaliberId?: Maybe<Scalars['String']>
	priority: Scalars['Int']
	priorityText?: Maybe<Scalars['String']>
	regions?: Maybe<Array<Maybe<RegionType>>>
	regionsDiff?: Maybe<Array<Maybe<RegionType>>>
	release: ReleaseType
	requirementContractRegions: Array<RequirementContractRegionType>
	selectedContractRegionGroupIds: Array<Scalars['ID']>
	strippedDescription?: Maybe<Scalars['String']>
	strippedNote?: Maybe<Scalars['String']>
	title: Scalars['String']
}

export type RequirementContractRegionType = {
	__typename?: 'RequirementContractRegionType'
	contract: ContractType
	id: Scalars['ID']
	region: RegionType
	release: ReleaseType
	requirement: RequirementAssignmentType
}

export type RequirementType = {
	__typename?: 'RequirementType'
	caliberId: Scalars['Int']
	catalog: Scalars['String']
	chapter?: Maybe<ChapterType>
	comparedReleaseId?: Maybe<Scalars['Int']>
	contractRegionGroupIds: Array<Scalars['ID']>
	contractRegionGroups: Array<ContractRegionGroupType>
	description?: Maybe<Scalars['String']>
	diff?: Maybe<Scalars['Int']>
	id: Scalars['ID']
	note?: Maybe<Scalars['String']>
	objectId: Scalars['Int']
	objectVersionId: Scalars['Int']
	pos: Scalars['Int']
	prefix?: Maybe<Scalars['String']>
	prefixedCaliberId?: Maybe<Scalars['String']>
	priority: Scalars['Int']
	priorityText?: Maybe<Scalars['String']>
	regions?: Maybe<Array<Maybe<RegionType>>>
	regionsDiff?: Maybe<Array<Maybe<RegionType>>>
	release: ReleaseType
	requirementContractRegions: Array<RequirementContractRegionType>
	selectedContractRegionGroupIds: Array<Scalars['ID']>
	strippedDescription?: Maybe<Scalars['String']>
	strippedNote?: Maybe<Scalars['String']>
	title: Scalars['String']
}

export type SelectContractRegionGroupRequirements = {
	__typename?: 'SelectContractRegionGroupRequirements'
	createdSelections: Scalars['Int']
	deletedSelections: Scalars['Int']
	unchangedSelections: Scalars['Int']
}

export type SendReleasePacketEmailMutationInput = {
	attachmentOption?: InputMaybe<Scalars['Int']>
	clientMutationId?: InputMaybe<Scalars['String']>
	comparedReleaseId?: InputMaybe<Scalars['ID']>
	includeAkaPdf?: InputMaybe<Scalars['Boolean']>
	includeBaseFile?: InputMaybe<Scalars['Boolean']>
	includeHelpFiles?: InputMaybe<Scalars['Boolean']>
	includeInspectionDocumentCsvFiles?: InputMaybe<Scalars['Boolean']>
	includeInspectionDocumentXmlFiles?: InputMaybe<Scalars['Boolean']>
	includePcxFiles?: InputMaybe<Scalars['Boolean']>
	includeRevisionHistoryFile?: InputMaybe<Scalars['Boolean']>
	includeSvdPdfFiles?: InputMaybe<Scalars['Boolean']>
	includeSvdXmlFiles?: InputMaybe<Scalars['Boolean']>
	isFiltered?: InputMaybe<Scalars['Boolean']>
	releaseId: Scalars['ID']
	selectedContracts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SendReleasePacketEmailMutationPayload = {
	__typename?: 'SendReleasePacketEmailMutationPayload'
	clientMutationId?: Maybe<Scalars['String']>
	inProgress?: Maybe<Scalars['Boolean']>
}

export type SetUserContractsFilterInput = {
	clientMutationId?: InputMaybe<Scalars['String']>
	contractList: Array<Scalars['ID']>
}

export type SetUserContractsFilterPayload = {
	__typename?: 'SetUserContractsFilterPayload'
	clientMutationId?: Maybe<Scalars['String']>
	user?: Maybe<UserType>
}

export type SoftwareSystemReportType = {
	__typename?: 'SoftwareSystemReportType'
	approvalRun: ApprovalRunType
	changes: ApprovalReleaseChanges
	id: Scalars['ID']
	isPrefilled: Scalars['Boolean']
	justifications: Array<JustificationType>
	rolloutDate?: Maybe<Scalars['Date']>
	selectedContractRegionGroups: Array<ContractRegionGroupType>
	status: ReportStatus
	system: SoftwareSystemType
	systemVersion: Scalars['String']
	unsatisfiedRequirements: Array<RequirementType>
}

export type SoftwareSystemType = {
	__typename?: 'SoftwareSystemType'
	additionalContactPerson?: Maybe<ContactPersonType>
	approvals: Array<Maybe<SystemApprovalType>>
	city: Scalars['String']
	drugModule?: Maybe<DrugModuleType>
	id: Scalars['ID']
	isParticipating: Scalars['Boolean']
	mainContactPerson?: Maybe<ContactPersonType>
	manufacturerName: Scalars['String']
	orderAddress: Scalars['String']
	postalCode: Scalars['String']
	slogan: Scalars['String']
	streetAndNumber: Scalars['String']
	systemCosts: Scalars['JSONString']
	systemName: Scalars['String']
	website: Scalars['String']
}

export type SoftwareSystemTypeApprovalsArgs = {
	quarter: Scalars['Int']
	year: Scalars['Int']
}

export enum SystemApprovalStatus {
	APPROVED = 'APPROVED',
	APPROVED_WITH_RESERVATION = 'APPROVED_WITH_RESERVATION',
	REJECTED = 'REJECTED'
}

export type SystemApprovalType = {
	__typename?: 'SystemApprovalType'
	status?: Maybe<SystemApprovalStatus>
	tenant: TenantType
}

export type SystemCostType = {
	accounting: Scalars['String']
	amount: Scalars['String']
	key: Scalars['Int']
	module: Scalars['String']
	note: Scalars['String']
	type: Scalars['String']
}

export type TenantType = {
	__typename?: 'TenantType'
	catalogName: Scalars['String']
	hexColor: Scalars['String']
	id: Scalars['ID']
	logo?: Maybe<ImageType>
	name: Scalars['String']
}

export type UpdateReportSoftwareSystemInfo = {
	__typename?: 'UpdateReportSoftwareSystemInfo'
	success?: Maybe<Scalars['Boolean']>
}

export type UpdateSoftwareSystem = {
	__typename?: 'UpdateSoftwareSystem'
	softwareSystem?: Maybe<SoftwareSystemType>
}

export type UpdateSoftwareSystemCosts = {
	__typename?: 'UpdateSoftwareSystemCosts'
	success?: Maybe<Scalars['Boolean']>
}

export type UserMessageForApproval = {
	__typename?: 'UserMessageForApproval'
	success?: Maybe<Scalars['Boolean']>
}

export type UserType = {
	__typename?: 'UserType'
	contractsFilter: Array<ContractType>
	email: Scalars['String']
	firstName: Scalars['String']
	fullName?: Maybe<Scalars['String']>
	hasSelectedContracts?: Maybe<Scalars['Boolean']>
	helpFile?: Maybe<Scalars['String']>
	id: Scalars['ID']
	isKasseUser?: Maybe<Scalars['Boolean']>
	isSuperuser: Scalars['Boolean']
	isVswUser?: Maybe<Scalars['Boolean']>
	lastName: Scalars['String']
}

export type VerifyToken = {
	__typename?: 'VerifyToken'
	payload: Scalars['GenericScalar']
	user?: Maybe<UserType>
}

export type UserFragment = {
	__typename?: 'UserType'
	id: string
	email: string
	firstName: string
	lastName: string
	isSuperuser: boolean
	hasSelectedContracts?: boolean | null
	isKasseUser?: boolean | null
	isVswUser?: boolean | null
	helpFile?: string | null
	contractsFilter: Array<{
		__typename?: 'ContractType'
		id: string
		contractId: string
	}>
}

export type TokenAuthMutationVariables = Exact<{
	email: Scalars['String']
	password: Scalars['String']
}>

export type TokenAuthMutation = {
	__typename?: 'Mutation'
	tokenAuth?: {
		__typename?: 'CustomJSONWebTokenMutation'
		token: string
		user?: {
			__typename?: 'UserType'
			id: string
			email: string
			firstName: string
			lastName: string
			isSuperuser: boolean
			hasSelectedContracts?: boolean | null
			isKasseUser?: boolean | null
			isVswUser?: boolean | null
			helpFile?: string | null
			contractsFilter: Array<{
				__typename?: 'ContractType'
				id: string
				contractId: string
			}>
		} | null
	} | null
}

export type VerifyTokenMutationVariables = Exact<{
	token: Scalars['String']
}>

export type VerifyTokenMutation = {
	__typename?: 'Mutation'
	verifyToken?: {
		__typename?: 'VerifyToken'
		payload: any
		user?: {
			__typename?: 'UserType'
			id: string
			email: string
			firstName: string
			lastName: string
			isSuperuser: boolean
			hasSelectedContracts?: boolean | null
			isKasseUser?: boolean | null
			isVswUser?: boolean | null
			helpFile?: string | null
			contractsFilter: Array<{
				__typename?: 'ContractType'
				id: string
				contractId: string
			}>
		} | null
	} | null
}

export type SetUserContractsFilterMutationVariables = Exact<{
	contractList: Array<Scalars['ID']> | Scalars['ID']
}>

export type SetUserContractsFilterMutation = {
	__typename?: 'Mutation'
	setUserContractsFilter?: {
		__typename?: 'SetUserContractsFilterPayload'
		user?: {
			__typename?: 'UserType'
			id: string
			email: string
			firstName: string
			lastName: string
			isSuperuser: boolean
			hasSelectedContracts?: boolean | null
			isKasseUser?: boolean | null
			isVswUser?: boolean | null
			helpFile?: string | null
			contractsFilter: Array<{
				__typename?: 'ContractType'
				id: string
				contractId: string
			}>
		} | null
	} | null
}

export type SendReleasePacketEmailMutationVariables = Exact<{
	releaseId: Scalars['ID']
	comparedReleaseId?: InputMaybe<Scalars['ID']>
	includeSvdPdfFiles?: InputMaybe<Scalars['Boolean']>
	includeSvdXmlFiles?: InputMaybe<Scalars['Boolean']>
	includeInspectionDocumentXmlFiles?: InputMaybe<Scalars['Boolean']>
	includeInspectionDocumentCsvFiles?: InputMaybe<Scalars['Boolean']>
	includeAkaPdf?: InputMaybe<Scalars['Boolean']>
	includeBaseFile?: InputMaybe<Scalars['Boolean']>
	includeHelpFiles?: InputMaybe<Scalars['Boolean']>
	includePcxFiles?: InputMaybe<Scalars['Boolean']>
	includeRevisionHistoryFile?: InputMaybe<Scalars['Boolean']>
	attachmentOption?: InputMaybe<Scalars['Int']>
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
	isFiltered?: InputMaybe<Scalars['Boolean']>
}>

export type SendReleasePacketEmailMutation = {
	__typename?: 'Mutation'
	sendReleasePacketEmail?: {
		__typename?: 'SendReleasePacketEmailMutationPayload'
		inProgress?: boolean | null
	} | null
}

export type SelectContractRegionGroupsMutationVariables = Exact<{
	reportId: Scalars['ID']
	selectedGroupIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
}>

export type SelectContractRegionGroupsMutation = {
	__typename?: 'Mutation'
	selectContractRegionGroups?: boolean | null
}

export type SubmitJustifyUnsatisfiedRequirementsMutationVariables = Exact<{
	items: Array<JustificationInputType> | JustificationInputType
	reportId: Scalars['ID']
}>

export type SubmitJustifyUnsatisfiedRequirementsMutation = {
	__typename?: 'Mutation'
	justifyUnsatisfiedRequirements?: {
		__typename?: 'JustificationsMutation'
		justificationsCount: number
	} | null
}

export type SelectContractRegionGroupRequirementsMutationVariables = Exact<{
	reportId: Scalars['ID']
	selection:
		| Array<ContractRegionGroupsPerRequirement>
		| ContractRegionGroupsPerRequirement
}>

export type SelectContractRegionGroupRequirementsMutation = {
	__typename?: 'Mutation'
	selectContractRegionGroupRequirements?: {
		__typename?: 'SelectContractRegionGroupRequirements'
		unchangedSelections: number
		deletedSelections: number
		createdSelections: number
	} | null
}

export type UpdateSoftwareSystemMutationVariables = Exact<{
	systemId: Scalars['ID']
	slogan: Scalars['String']
	website: Scalars['String']
}>

export type UpdateSoftwareSystemMutation = {
	__typename?: 'Mutation'
	updateSoftwareSystem?: {
		__typename?: 'UpdateSoftwareSystem'
		softwareSystem?: {
			__typename?: 'SoftwareSystemType'
			id: string
			slogan: string
			website: string
			manufacturerName: string
		} | null
	} | null
}

export type UpdateReportSoftwareSystemInfoMutationVariables = Exact<{
	reportInput: ReportInputType
	reportSystemInput: ReportSystemInputType
	mainContactInput: ContactPersonInputType
	additionalContactInput?: InputMaybe<ContactPersonInputType>
}>

export type UpdateReportSoftwareSystemInfoMutation = {
	__typename?: 'Mutation'
	updateReportSoftwareSystemInfo?: {
		__typename?: 'UpdateReportSoftwareSystemInfo'
		success?: boolean | null
	} | null
}

export type UpdateSoftwareSystemCostsMutationVariables = Exact<{
	systemId: Scalars['ID']
	systemCosts: Array<SystemCostType> | SystemCostType
}>

export type UpdateSoftwareSystemCostsMutation = {
	__typename?: 'Mutation'
	updateSoftwareSystemCosts?: {
		__typename?: 'UpdateSoftwareSystemCosts'
		success?: boolean | null
	} | null
}

export type ContentCheckProofUploadMutationVariables = Exact<{
	systemId: Scalars['ID']
	contentCheckId: Scalars['ID']
	proofFiles: Array<Scalars['Upload']> | Scalars['Upload']
}>

export type ContentCheckProofUploadMutation = {
	__typename?: 'Mutation'
	contentCheckProofUpload?: {
		__typename?: 'ContentCheckProofUpload'
		success?: boolean | null
	} | null
}

export type SendUserMessageForApprovalMutationVariables = Exact<{
	contentCheckId?: InputMaybe<Scalars['ID']>
	systemReportId?: InputMaybe<Scalars['ID']>
	softwareSystemId: Scalars['ID']
	userMessage: Scalars['String']
}>

export type SendUserMessageForApprovalMutation = {
	__typename?: 'Mutation'
	sendUserMessageForApproval?: {
		__typename?: 'UserMessageForApproval'
		success?: boolean | null
	} | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
	__typename?: 'Query'
	me?: {
		__typename?: 'UserType'
		id: string
		email: string
		firstName: string
		lastName: string
		isSuperuser: boolean
		hasSelectedContracts?: boolean | null
		isKasseUser?: boolean | null
		isVswUser?: boolean | null
		helpFile?: string | null
		contractsFilter: Array<{
			__typename?: 'ContractType'
			id: string
			contractId: string
		}>
	} | null
}

export type ReleasesQueryVariables = Exact<{ [key: string]: never }>

export type ReleasesQuery = {
	__typename?: 'Query'
	releases?: Array<{
		__typename?: 'ReleaseType'
		id: string
		createdAt: string
		name: string
		status: number
	} | null> | null
}

export type RevisionHistoryChaptersFragment = {
	__typename?: 'ChapterType'
	id: string
	title: string
	pos: number
	fullTitle?: string | null
	description?: string | null
	chapters: Array<{ __typename?: 'ChapterType'; id: string }>
}

export type RevisionReleaseQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type RevisionReleaseQuery = {
	__typename?: 'Query'
	release?: {
		__typename?: 'ReleaseType'
		id: string
		revisionHistoryRootChapter?: {
			__typename?: 'ChapterType'
			chapters: Array<{
				__typename?: 'ChapterType'
				id: string
				title: string
				pos: number
				fullTitle?: string | null
				description?: string | null
				chapters: Array<{ __typename?: 'ChapterType'; id: string }>
			}>
		} | null
	} | null
}

export type ReleaseQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type ReleaseQuery = {
	__typename?: 'Query'
	release?: {
		__typename?: 'ReleaseType'
		id: string
		name: string
		year: number
		quarter: string
		version: string
		isAttachmentsDirExist?: boolean | null
		rootChapter?: {
			__typename?: 'ChapterType'
			id: string
			title: string
			pos: number
			fullTitle?: string | null
			parent?: { __typename?: 'ChapterType'; id: string; pos: number } | null
			chapters: Array<{
				__typename?: 'ChapterType'
				id: string
				title: string
				pos: number
				fullTitle?: string | null
				chapters: Array<{
					__typename?: 'ChapterType'
					id: string
					title: string
					pos: number
					fullTitle?: string | null
					chapters: Array<{
						__typename?: 'ChapterType'
						id: string
						title: string
						pos: number
						fullTitle?: string | null
						chapters: Array<{
							__typename?: 'ChapterType'
							id: string
							title: string
							pos: number
							fullTitle?: string | null
							chapters: Array<{
								__typename?: 'ChapterType'
								id: string
								title: string
								pos: number
								fullTitle?: string | null
							}>
						}>
					}>
				}>
			}>
		} | null
	} | null
}

export type RequirementsDiffQueryVariables = Exact<{
	chapterId: Scalars['ID']
	comparedReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
}>

export type RequirementsDiffQuery = {
	__typename?: 'Query'
	requirementsDiff?: Array<{
		__typename?: 'RequirementType'
		id: string
		comparedReleaseId?: number | null
		prefixedCaliberId?: string | null
		priority: number
		priorityText?: string | null
		catalog: string
		title: string
		description?: string | null
		note?: string | null
		diff?: number | null
		regionsDiff?: Array<{
			__typename?: 'RegionType'
			diff?: number | null
			comparedReleaseId?: number | null
			comparedRequirementId?: number | null
			okv: string
			name: string
			contractsDiff?: string | null
		} | null> | null
	} | null> | null
}

export type ChapterDiffQueryVariables = Exact<{
	id: Scalars['ID']
	comparedReleaseId: Scalars['ID']
}>

export type ChapterDiffQuery = {
	__typename?: 'Query'
	chapterDiff?: {
		__typename?: 'ChapterType'
		id: string
		pos: number
		title: string
		fullTitle?: string | null
		description?: string | null
		comparedReleaseId?: number | null
		parent?: {
			__typename?: 'ChapterType'
			id: string
			title: string
			pos: number
			fullTitle?: string | null
		} | null
		chapters: Array<{
			__typename?: 'ChapterType'
			id: string
			pos: number
			title: string
			fullTitle?: string | null
		}>
	} | null
}

export type ChapterQueryVariables = Exact<{
	id: Scalars['ID']
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
}>

export type ChapterQuery = {
	__typename?: 'Query'
	chapter?: {
		__typename?: 'ChapterType'
		id: string
		pos: number
		title: string
		fullTitle?: string | null
		description?: string | null
		parent?: {
			__typename?: 'ChapterType'
			id: string
			title: string
			pos: number
			fullTitle?: string | null
		} | null
		chapters: Array<{
			__typename?: 'ChapterType'
			id: string
			pos: number
			title: string
			fullTitle?: string | null
		}>
		requirements: Array<{
			__typename?: 'RequirementAssignmentType'
			id: string
			prefixedCaliberId?: string | null
			priority: number
			catalog: string
			title: string
			description?: string | null
			note?: string | null
			regions?: Array<{
				__typename?: 'RegionType'
				okv: string
				name: string
				contracts?: string | null
			} | null> | null
		}>
	} | null
}

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>

export type NotificationsQuery = {
	__typename?: 'Query'
	notifications?: Array<{
		__typename?: 'NotificationObjectType'
		id: string
		title: string
		message: string
		createdAt: string
		objectId?: number | null
		contentObjectModel?: string | null
		category?: NotificationCategory | null
		contentObject?:
			| {
					__typename?: 'ApprovalRunType'
					id: string
					title: string
					submissionDeadline: string
					year: number
					approvalQuarter: number
					tenant: {
						__typename?: 'TenantType'
						id: string
						name: string
						logo?: { __typename?: 'ImageType'; url: string } | null
					}
			  }
			| {
					__typename?: 'ReleaseType'
					id: string
					name: string
					createdAt: string
					version: string
					year: number
					quarter: string
					releaseDate: string
					catalog: number
			  }
			| null
	} | null> | null
}

export type SoftwareSystemReportListQueryVariables = Exact<{
	systemId: Scalars['ID']
	quarter: Scalars['Int']
	year: Scalars['Int']
}>

export type SoftwareSystemReportListQuery = {
	__typename?: 'Query'
	softwareSystemReportList?: Array<{
		__typename?: 'SoftwareSystemReportType'
		id: string
		status: ReportStatus
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			submissionDeadline: string
			year: number
			quarter: number
			tenant: {
				__typename?: 'TenantType'
				id: string
				name: string
				logo?: { __typename?: 'ImageType'; url: string } | null
			}
		}
	} | null> | null
}

export type SelectSoftwareSystemReportRequirementsQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type SelectSoftwareSystemReportRequirementsQuery = {
	__typename?: 'Query'
	softwareSystemReport?: {
		__typename?: 'SoftwareSystemReportType'
		id: string
		status: ReportStatus
		isPrefilled: boolean
		changes: {
			__typename?: 'ApprovalReleaseChanges'
			changedRequirementIds: Array<string>
			newRequirementIds: Array<string>
		}
		system: {
			__typename?: 'SoftwareSystemType'
			id: string
			systemName: string
		}
		selectedContractRegionGroups: Array<{
			__typename?: 'ContractRegionGroupType'
			id: string
			contract: { __typename?: 'ContractType'; id: string; contractId: string }
			regions: Array<{ __typename?: 'RegionType'; okv: string; name: string }>
		}>
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			quarter: number
			year: number
			tenant: { __typename?: 'TenantType'; id: string; name: string }
			release?: {
				__typename?: 'ReleaseType'
				id: string
				rootChapter?: {
					__typename?: 'ChapterType'
					id: string
					fullTitle?: string | null
					chapters: Array<{
						__typename?: 'ChapterType'
						id: string
						title: string
						pos: number
						fullTitle?: string | null
						matrixRequirements?: Array<{
							__typename?: 'RequirementType'
							id: string
							caliberId: number
							title: string
							prefixedCaliberId?: string | null
							contractRegionGroupIds: Array<string>
							selectedContractRegionGroupIds: Array<string>
							priority: number
						} | null> | null
						chapters: Array<{
							__typename?: 'ChapterType'
							id: string
							title: string
							pos: number
							fullTitle?: string | null
							matrixRequirements?: Array<{
								__typename?: 'RequirementType'
								id: string
								caliberId: number
								title: string
								prefixedCaliberId?: string | null
								contractRegionGroupIds: Array<string>
								selectedContractRegionGroupIds: Array<string>
								priority: number
							} | null> | null
							chapters: Array<{
								__typename?: 'ChapterType'
								id: string
								title: string
								pos: number
								fullTitle?: string | null
								matrixRequirements?: Array<{
									__typename?: 'RequirementType'
									id: string
									caliberId: number
									title: string
									prefixedCaliberId?: string | null
									contractRegionGroupIds: Array<string>
									selectedContractRegionGroupIds: Array<string>
									priority: number
								} | null> | null
								chapters: Array<{
									__typename?: 'ChapterType'
									id: string
									title: string
									pos: number
									fullTitle?: string | null
									matrixRequirements?: Array<{
										__typename?: 'RequirementType'
										id: string
										caliberId: number
										title: string
										prefixedCaliberId?: string | null
										contractRegionGroupIds: Array<string>
										selectedContractRegionGroupIds: Array<string>
									} | null> | null
									chapters: Array<{
										__typename?: 'ChapterType'
										id: string
										title: string
										pos: number
										fullTitle?: string | null
										matrixRequirements?: Array<{
											__typename?: 'RequirementType'
											id: string
											caliberId: number
											title: string
											prefixedCaliberId?: string | null
											contractRegionGroupIds: Array<string>
											selectedContractRegionGroupIds: Array<string>
											priority: number
										} | null> | null
									}>
								}>
							}>
						}>
					}>
				} | null
			} | null
		}
	} | null
}

export type SoftwareSystemReportJustificationsQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type SoftwareSystemReportJustificationsQuery = {
	__typename?: 'Query'
	softwareSystemReport?: {
		__typename?: 'SoftwareSystemReportType'
		id: string
		status: ReportStatus
		system: {
			__typename?: 'SoftwareSystemType'
			id: string
			systemName: string
		}
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			quarter: number
			year: number
			tenant: { __typename?: 'TenantType'; id: string; name: string }
		}
		justifications: Array<{
			__typename?: 'JustificationType'
			id: string
			text: string
			requirements: Array<{
				__typename?: 'RequirementAssignmentType'
				id: string
				prefixedCaliberId?: string | null
				title: string
			}>
		}>
	} | null
}

export type SoftwareSystemReportSystemInfoQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type SoftwareSystemReportSystemInfoQuery = {
	__typename?: 'Query'
	softwareSystemReport?: {
		__typename?: 'SoftwareSystemReportType'
		id: string
		status: ReportStatus
		systemVersion: string
		rolloutDate?: string | null
		system: {
			__typename?: 'SoftwareSystemType'
			id: string
			manufacturerName: string
			systemName: string
			streetAndNumber: string
			postalCode: string
			city: string
			website: string
			orderAddress: string
			drugModule?: {
				__typename?: 'DrugModuleType'
				name: string
				date?: string | null
				version: string
			} | null
			mainContactPerson?: {
				__typename?: 'ContactPersonType'
				id: string
				title: string
				firstname: string
				lastname: string
				phoneNumber: string
				email: string
				isMainContact: boolean
			} | null
			additionalContactPerson?: {
				__typename?: 'ContactPersonType'
				id: string
				title: string
				firstname: string
				lastname: string
				phoneNumber: string
				email: string
				isMainContact: boolean
			} | null
		}
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			quarter: number
			year: number
			tenant: { __typename?: 'TenantType'; id: string; name: string }
		}
	} | null
}

export type SofwareSystemReportSystemCostsQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type SofwareSystemReportSystemCostsQuery = {
	__typename?: 'Query'
	softwareSystemReport?: {
		__typename?: 'SoftwareSystemReportType'
		id: string
		status: ReportStatus
		systemVersion: string
		rolloutDate?: string | null
		system: {
			__typename?: 'SoftwareSystemType'
			id: string
			systemName: string
			systemCosts: any
		}
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			quarter: number
			year: number
			tenant: { __typename?: 'TenantType'; id: string; name: string }
		}
	} | null
}

export type SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type SoftwareSystemReportUnsatiesfiedRequirementsQuery = {
	__typename?: 'Query'
	softwareSystemReport?: {
		__typename?: 'SoftwareSystemReportType'
		id: string
		unsatisfiedRequirements: Array<{
			__typename?: 'RequirementType'
			id: string
			title: string
			prefixedCaliberId?: string | null
		}>
	} | null
}

export type ContentCheckListQueryVariables = Exact<{
	systemId: Scalars['ID']
	quarter: Scalars['Int']
	year: Scalars['Int']
}>

export type ContentCheckListQuery = {
	__typename?: 'Query'
	contentCheckList?: Array<{
		__typename?: 'ContentCheckType'
		id: string
		title: string
		approvalRun: {
			__typename?: 'ApprovalRunType'
			submissionDeadline: string
			tenant: {
				__typename?: 'TenantType'
				id: string
				name: string
				logo?: { __typename?: 'ImageType'; url: string } | null
			}
		}
		proof?: {
			__typename?: 'ContentCheckProofType'
			status: ContentCheckProofStatus
		} | null
		contract: { __typename?: 'ContractType'; contractId: string }
	} | null> | null
}

export type ContentCheckQueryVariables = Exact<{
	id: Scalars['ID']
	systemId: Scalars['ID']
}>

export type ContentCheckQuery = {
	__typename?: 'Query'
	contentCheck?: {
		__typename?: 'ContentCheckType'
		id: string
		title: string
		instructionFileUrl?: string | null
		contract: {
			__typename?: 'ContractType'
			id: string
			contractId: string
			name: string
		}
		approvalRun: {
			__typename?: 'ApprovalRunType'
			quarter: number
			year: number
			tenant: { __typename?: 'TenantType'; id: string; name: string }
		}
		proof?: {
			__typename?: 'ContentCheckProofType'
			status: ContentCheckProofStatus
			files: Array<{ __typename?: 'FileType'; url: string; name: string }>
			system: {
				__typename?: 'SoftwareSystemType'
				id: string
				systemName: string
			}
		} | null
	} | null
}

export type ContractRegionGroupsQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type ContractRegionGroupsQuery = {
	__typename?: 'Query'
	contractRegionGroupList?: Array<{
		__typename?: 'ContractRegionGroupType'
		id: string
		contract: { __typename?: 'ContractType'; id: string; contractId: string }
		regions: Array<{
			__typename?: 'RegionType'
			okv: string
			shortName?: string | null
			name: string
		}>
	} | null> | null
	softwareSystemReport?: {
		__typename?: 'SoftwareSystemReportType'
		id: string
		status: ReportStatus
		isPrefilled: boolean
		selectedContractRegionGroups: Array<{
			__typename?: 'ContractRegionGroupType'
			id: string
		}>
		system: {
			__typename?: 'SoftwareSystemType'
			id: string
			systemName: string
		}
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			quarter: number
			year: number
			tenant: { __typename?: 'TenantType'; id: string; name: string }
		}
	} | null
}

export type ReleasesToCompareQueryVariables = Exact<{
	currentVersionId: Scalars['ID']
}>

export type ReleasesToCompareQuery = {
	__typename?: 'Query'
	releasesToCompare?: Array<{
		__typename?: 'ReleaseType'
		id: string
		name: string
		year: number
		quarter: string
		version: string
	} | null> | null
}

export type AttachmentNodeFragmentFragment = {
	__typename?: 'AttachmentContractReleaseNode'
	id: string
	diff?: number | null
	changes?: string | null
	attachmentSpuId: number
	attachmentObjectSpuId: number
	attachment: {
		__typename?: 'AttachmentType'
		id: string
		title: string
		spuId: number
		printTypes?: string | null
		type: string
	}
	attachmentObject: {
		__typename?: 'AttachmentObjectType'
		id: string
		title: string
		type?: string | null
		file?: string | null
		text?: string | null
		link?: string | null
		releaseType?: string | null
		fileVersion?: string | null
		page?: string | null
	}
}

export type ReleaseAttachmentsQueryVariables = Exact<{
	releaseId: Scalars['ID']
	comparedReleaseId?: InputMaybe<Scalars['ID']>
	query?: InputMaybe<Scalars['String']>
	before?: InputMaybe<Scalars['String']>
	after?: InputMaybe<Scalars['String']>
	first?: InputMaybe<Scalars['Int']>
	last?: InputMaybe<Scalars['Int']>
	attachment_Type?: InputMaybe<Scalars['String']>
	attachmentObject_Type?: InputMaybe<Scalars['String']>
	contract_ContractId?: InputMaybe<Scalars['String']>
}>

export type ReleaseAttachmentsQuery = {
	__typename?: 'Query'
	releaseAttachments?: {
		__typename?: 'AttachmentContractReleaseNodeConnection'
		pageInfo: {
			__typename?: 'PageInfo'
			hasNextPage: boolean
			endCursor?: string | null
		}
		edges: Array<{
			__typename?: 'AttachmentContractReleaseNodeEdge'
			node?: {
				__typename?: 'AttachmentContractReleaseNode'
				id: string
				diff?: number | null
				changes?: string | null
				attachmentSpuId: number
				attachmentObjectSpuId: number
				attachment: {
					__typename?: 'AttachmentType'
					id: string
					title: string
					spuId: number
					printTypes?: string | null
					type: string
				}
				attachmentObject: {
					__typename?: 'AttachmentObjectType'
					id: string
					title: string
					type?: string | null
					file?: string | null
					text?: string | null
					link?: string | null
					releaseType?: string | null
					fileVersion?: string | null
					page?: string | null
				}
			} | null
		} | null>
	} | null
}

export type AttachmentsTypesQueryVariables = Exact<{
	releaseId: Scalars['ID']
}>

export type AttachmentsTypesQuery = {
	__typename?: 'Query'
	attachmentsTypes?: Array<string | null> | null
}

export type AttachmentObjectTypesQueryVariables = Exact<{
	releaseId: Scalars['ID']
}>

export type AttachmentObjectTypesQuery = {
	__typename?: 'Query'
	attachmentObjectTypes?: Array<string | null> | null
}

export type AttachmentContractsQueryVariables = Exact<{
	releaseId: Scalars['ID']
}>

export type AttachmentContractsQuery = {
	__typename?: 'Query'
	attachmentContracts?: Array<string | null> | null
}

export type ReleaseContractsQueryVariables = Exact<{
	releaseId: Scalars['ID']
}>

export type ReleaseContractsQuery = {
	__typename?: 'Query'
	releaseContracts?: Array<{
		__typename?: 'ContractType'
		contractId: string
		identification: string
	} | null> | null
}

export type RequirementFragmentFragment = {
	__typename?: 'RequirementType'
	id: string
	prefixedCaliberId?: string | null
	title: string
	priorityText?: string | null
	description?: string | null
	note?: string | null
	diff?: number | null
	chapter?: {
		__typename?: 'ChapterType'
		pathFromRoot?: Array<string | null> | null
	} | null
	regionsDiff?: Array<{
		__typename?: 'RegionType'
		diff?: number | null
		comparedReleaseId?: number | null
		comparedRequirementId?: number | null
		okv: string
		name: string
		contractsDiff?: string | null
	} | null> | null
}

export type UserContractsQueryVariables = Exact<{ [key: string]: never }>

export type UserContractsQuery = {
	__typename?: 'Query'
	userContracts?: Array<{
		__typename?: 'ContractType'
		contractId: string
		identification: string
		typ: ContractTyp
		key: string
	} | null> | null
}

export type ReleaseRequirementsChangesQueryVariables = Exact<{
	currentReleaseId: Scalars['ID']
	comparedReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
}>

export type ReleaseRequirementsChangesQuery = {
	__typename?: 'Query'
	releaseRequirementsChanges?: {
		__typename?: 'ReleaseRequirementsChangesDirectoryType'
		currentReleaseId?: number | null
		comparedReleaseId?: number | null
		newRequirements?: Array<{
			__typename?: 'RequirementType'
			id: string
			prefixedCaliberId?: string | null
			title: string
			priorityText?: string | null
			description?: string | null
			note?: string | null
			diff?: number | null
			chapter?: {
				__typename?: 'ChapterType'
				pathFromRoot?: Array<string | null> | null
			} | null
			regionsDiff?: Array<{
				__typename?: 'RegionType'
				diff?: number | null
				comparedReleaseId?: number | null
				comparedRequirementId?: number | null
				okv: string
				name: string
				contractsDiff?: string | null
			} | null> | null
		} | null> | null
		changedRequirements?: Array<{
			__typename?: 'RequirementType'
			id: string
			prefixedCaliberId?: string | null
			title: string
			priorityText?: string | null
			description?: string | null
			note?: string | null
			diff?: number | null
			chapter?: {
				__typename?: 'ChapterType'
				pathFromRoot?: Array<string | null> | null
			} | null
			regionsDiff?: Array<{
				__typename?: 'RegionType'
				diff?: number | null
				comparedReleaseId?: number | null
				comparedRequirementId?: number | null
				okv: string
				name: string
				contractsDiff?: string | null
			} | null> | null
		} | null> | null
		deletedRequirements?: Array<{
			__typename?: 'RequirementType'
			id: string
			prefixedCaliberId?: string | null
			title: string
			priorityText?: string | null
			description?: string | null
			note?: string | null
			diff?: number | null
			chapter?: {
				__typename?: 'ChapterType'
				pathFromRoot?: Array<string | null> | null
			} | null
			regionsDiff?: Array<{
				__typename?: 'RegionType'
				diff?: number | null
				comparedReleaseId?: number | null
				comparedRequirementId?: number | null
				okv: string
				name: string
				contractsDiff?: string | null
			} | null> | null
		} | null> | null
		changedAssignmentRequirements?: Array<{
			__typename?: 'RequirementAssignmentType'
			id: string
			prefixedCaliberId?: string | null
			title: string
			newRegionContractAssignments?: Array<string | null> | null
			deletedRegionContractAssignments?: Array<string | null> | null
		} | null> | null
	} | null
}

export type AttachmentContractReleaseFragmentFragment = {
	__typename?: 'AttachmentContractReleaseNode'
	id: string
	changes?: string | null
	diff?: number | null
	contract: { __typename?: 'ContractType'; contractId: string }
	attachment: {
		__typename?: 'AttachmentType'
		spuId: number
		title: string
		type: string
	}
	attachmentObject: {
		__typename?: 'AttachmentObjectType'
		title: string
		file?: string | null
		type?: string | null
	}
}

export type AttachmentReleaseChangesQueryVariables = Exact<{
	currentReleaseId: Scalars['ID']
	comparedReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
}>

export type AttachmentReleaseChangesQuery = {
	__typename?: 'Query'
	releaseAttachmentChanges?: {
		__typename?: 'ReleaseAttachmentContractsChangesDirectoryType'
		comparedReleaseId?: number | null
		currentReleaseId?: number | null
		changedAttachments?: Array<{
			__typename?: 'AttachmentContractReleaseNode'
			id: string
			changes?: string | null
			diff?: number | null
			contract: { __typename?: 'ContractType'; contractId: string }
			attachment: {
				__typename?: 'AttachmentType'
				spuId: number
				title: string
				type: string
			}
			attachmentObject: {
				__typename?: 'AttachmentObjectType'
				title: string
				file?: string | null
				type?: string | null
			}
		} | null> | null
		deletedAttachments?: Array<{
			__typename?: 'AttachmentContractReleaseNode'
			id: string
			changes?: string | null
			diff?: number | null
			contract: { __typename?: 'ContractType'; contractId: string }
			attachment: {
				__typename?: 'AttachmentType'
				spuId: number
				title: string
				type: string
			}
			attachmentObject: {
				__typename?: 'AttachmentObjectType'
				title: string
				file?: string | null
				type?: string | null
			}
		} | null> | null
		newAttachments?: Array<{
			__typename?: 'AttachmentContractReleaseNode'
			id: string
			changes?: string | null
			diff?: number | null
			contract: { __typename?: 'ContractType'; contractId: string }
			attachment: {
				__typename?: 'AttachmentType'
				spuId: number
				title: string
				type: string
			}
			attachmentObject: {
				__typename?: 'AttachmentObjectType'
				title: string
				file?: string | null
				type?: string | null
			}
		} | null> | null
	} | null
}

export type SearchReleaseRequirementsQueryVariables = Exact<{
	releaseId: Scalars['ID']
	query: Scalars['String']
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
}>

export type SearchReleaseRequirementsQuery = {
	__typename?: 'Query'
	releaseRequirementsSearch?: Array<{
		__typename?: 'RequirementType'
		id: string
		prefixedCaliberId?: string | null
		title: string
		priority: number
		chapter?: { __typename?: 'ChapterType'; id: string } | null
	} | null> | null
}

export type ReleaseAttachmentObjectContractsQueryVariables = Exact<{
	releaseId: Scalars['ID']
	attachmentSpuID: Scalars['Int']
	attachmentObjectSpuID: Scalars['Int']
}>

export type ReleaseAttachmentObjectContractsQuery = {
	__typename?: 'Query'
	releaseAttachmentObjectContracts?: Array<string | null> | null
}

export type AttachmentsObjectFilePathQueryVariables = Exact<{
	attachmentObjectId: Scalars['ID']
}>

export type AttachmentsObjectFilePathQuery = {
	__typename?: 'Query'
	attachmentsObjectFilePath?: string | null
}

export type ReleaseContractChangesQueryVariables = Exact<{
	currentReleaseId: Scalars['ID']
	comparedReleaseId: Scalars['ID']
	selectedContracts?: InputMaybe<
		Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
	>
}>

export type ReleaseContractChangesQuery = {
	__typename?: 'Query'
	releaseContractChanges?: {
		__typename?: 'ReleaseContractsChangesDirectoryType'
		currentReleaseId?: number | null
		comparedReleaseId?: number | null
		releaseContractsDiff?: Array<{
			__typename?: 'ReleaseContractNode'
			id: string
			version: string
			diff?: number | null
			oldVersion?: string | null
			contract: {
				__typename?: 'ContractType'
				contractId: string
				identification: string
			}
		} | null> | null
	} | null
}

export type SoftwareSystemsQueryVariables = Exact<{ [key: string]: never }>

export type SoftwareSystemsQuery = {
	__typename?: 'Query'
	softwareSystems?: Array<{
		__typename?: 'SoftwareSystemType'
		id: string
		systemName: string
		slogan: string
		website: string
		manufacturerName: string
		isParticipating: boolean
	} | null> | null
}

export type SoftwareSystemApprovalsQueryVariables = Exact<{
	systemId: Scalars['ID']
	year: Scalars['Int']
	quarter: Scalars['Int']
}>

export type SoftwareSystemApprovalsQuery = {
	__typename?: 'Query'
	softwareSystem?: {
		__typename?: 'SoftwareSystemType'
		id: string
		approvals: Array<{
			__typename?: 'SystemApprovalType'
			status?: SystemApprovalStatus | null
			tenant: {
				__typename?: 'TenantType'
				id: string
				name: string
				hexColor: string
			}
		} | null>
	} | null
}

export type SoftwareSystemQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type SoftwareSystemQuery = {
	__typename?: 'Query'
	softwareSystem?: {
		__typename?: 'SoftwareSystemType'
		id: string
		systemName: string
	} | null
}

export type ApprovalFeedQueryVariables = Exact<{
	systemId: Scalars['ID']
	quarter: Scalars['Int']
	year: Scalars['Int']
}>

export type ApprovalFeedQuery = {
	__typename?: 'Query'
	approvalFeed: Array<{
		__typename?: 'ApprovalLogItemType'
		id: string
		createdAt: string
		createdByText: string
		isUserMessage: boolean
		actionMessage: string
		userMessage?: string | null
		approvalRun: {
			__typename?: 'ApprovalRunType'
			id: string
			tenant: {
				__typename?: 'TenantType'
				id: string
				catalogName: string
				hexColor: string
			}
		}
	} | null>
}

export const UserFragmentDoc = gql`
	fragment User on UserType {
		id
		email
		firstName
		lastName
		isSuperuser
		hasSelectedContracts
		isKasseUser
		isVswUser
		helpFile
		contractsFilter {
			id
			contractId
		}
	}
`
export const RevisionHistoryChaptersFragmentDoc = gql`
	fragment revisionHistoryChapters on ChapterType {
		id
		title
		pos
		fullTitle
		description
		chapters {
			id
		}
	}
`
export const AttachmentNodeFragmentFragmentDoc = gql`
	fragment attachmentNodeFragment on AttachmentContractReleaseNode {
		id
		diff
		changes
		attachmentSpuId
		attachmentObjectSpuId
		attachment {
			id
			title
			spuId
			printTypes
			type
		}
		attachmentObject {
			id
			title
			type
			file
			text
			link
			releaseType
			fileVersion
			page
		}
	}
`
export const RequirementFragmentFragmentDoc = gql`
	fragment requirementFragment on RequirementType {
		id
		prefixedCaliberId
		title
		priorityText
		description
		note
		diff
		chapter {
			pathFromRoot
		}
		regionsDiff {
			diff
			comparedReleaseId
			comparedRequirementId
			okv
			name
			contractsDiff
		}
	}
`
export const AttachmentContractReleaseFragmentFragmentDoc = gql`
	fragment attachmentContractReleaseFragment on AttachmentContractReleaseNode {
		id
		changes
		diff
		contract {
			contractId
		}
		attachment {
			spuId
			title
			type
		}
		attachmentObject {
			title
			file
			type
		}
	}
`
export const TokenAuthDocument = gql`
	mutation tokenAuth($email: String!, $password: String!) {
		tokenAuth(email: $email, password: $password) {
			token
			user {
				...User
			}
		}
	}
	${UserFragmentDoc}
`
export type TokenAuthMutationFn = Apollo.MutationFunction<
	TokenAuthMutation,
	TokenAuthMutationVariables
>

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
	baseOptions?: Apollo.MutationHookOptions<
		TokenAuthMutation,
		TokenAuthMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(
		TokenAuthDocument,
		options
	)
}
export type TokenAuthMutationHookResult = ReturnType<
	typeof useTokenAuthMutation
>
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
	TokenAuthMutation,
	TokenAuthMutationVariables
>
export const VerifyTokenDocument = gql`
	mutation verifyToken($token: String!) {
		verifyToken(token: $token) {
			payload
			user {
				...User
			}
		}
	}
	${UserFragmentDoc}
`
export type VerifyTokenMutationFn = Apollo.MutationFunction<
	VerifyTokenMutation,
	VerifyTokenMutationVariables
>

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(
	baseOptions?: Apollo.MutationHookOptions<
		VerifyTokenMutation,
		VerifyTokenMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(
		VerifyTokenDocument,
		options
	)
}
export type VerifyTokenMutationHookResult = ReturnType<
	typeof useVerifyTokenMutation
>
export type VerifyTokenMutationResult =
	Apollo.MutationResult<VerifyTokenMutation>
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<
	VerifyTokenMutation,
	VerifyTokenMutationVariables
>
export const SetUserContractsFilterDocument = gql`
	mutation setUserContractsFilter($contractList: [ID!]!) {
		setUserContractsFilter(input: { contractList: $contractList }) {
			user {
				...User
			}
		}
	}
	${UserFragmentDoc}
`
export type SetUserContractsFilterMutationFn = Apollo.MutationFunction<
	SetUserContractsFilterMutation,
	SetUserContractsFilterMutationVariables
>

/**
 * __useSetUserContractsFilterMutation__
 *
 * To run a mutation, you first call `useSetUserContractsFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserContractsFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserContractsFilterMutation, { data, loading, error }] = useSetUserContractsFilterMutation({
 *   variables: {
 *      contractList: // value for 'contractList'
 *   },
 * });
 */
export function useSetUserContractsFilterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SetUserContractsFilterMutation,
		SetUserContractsFilterMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SetUserContractsFilterMutation,
		SetUserContractsFilterMutationVariables
	>(SetUserContractsFilterDocument, options)
}
export type SetUserContractsFilterMutationHookResult = ReturnType<
	typeof useSetUserContractsFilterMutation
>
export type SetUserContractsFilterMutationResult =
	Apollo.MutationResult<SetUserContractsFilterMutation>
export type SetUserContractsFilterMutationOptions = Apollo.BaseMutationOptions<
	SetUserContractsFilterMutation,
	SetUserContractsFilterMutationVariables
>
export const SendReleasePacketEmailDocument = gql`
	mutation sendReleasePacketEmail(
		$releaseId: ID!
		$comparedReleaseId: ID
		$includeSvdPdfFiles: Boolean
		$includeSvdXmlFiles: Boolean
		$includeInspectionDocumentXmlFiles: Boolean
		$includeInspectionDocumentCsvFiles: Boolean
		$includeAkaPdf: Boolean
		$includeBaseFile: Boolean
		$includeHelpFiles: Boolean
		$includePcxFiles: Boolean
		$includeRevisionHistoryFile: Boolean
		$attachmentOption: Int
		$selectedContracts: [String]
		$isFiltered: Boolean
	) {
		sendReleasePacketEmail(
			input: {
				releaseId: $releaseId
				comparedReleaseId: $comparedReleaseId
				includeSvdPdfFiles: $includeSvdPdfFiles
				includeSvdXmlFiles: $includeSvdXmlFiles
				includeInspectionDocumentXmlFiles: $includeInspectionDocumentXmlFiles
				includeInspectionDocumentCsvFiles: $includeInspectionDocumentCsvFiles
				includeAkaPdf: $includeAkaPdf
				includeBaseFile: $includeBaseFile
				includeHelpFiles: $includeHelpFiles
				includePcxFiles: $includePcxFiles
				includeRevisionHistoryFile: $includeRevisionHistoryFile
				attachmentOption: $attachmentOption
				selectedContracts: $selectedContracts
				isFiltered: $isFiltered
			}
		) {
			inProgress
		}
	}
`
export type SendReleasePacketEmailMutationFn = Apollo.MutationFunction<
	SendReleasePacketEmailMutation,
	SendReleasePacketEmailMutationVariables
>

/**
 * __useSendReleasePacketEmailMutation__
 *
 * To run a mutation, you first call `useSendReleasePacketEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReleasePacketEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReleasePacketEmailMutation, { data, loading, error }] = useSendReleasePacketEmailMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *      includeSvdPdfFiles: // value for 'includeSvdPdfFiles'
 *      includeSvdXmlFiles: // value for 'includeSvdXmlFiles'
 *      includeInspectionDocumentXmlFiles: // value for 'includeInspectionDocumentXmlFiles'
 *      includeInspectionDocumentCsvFiles: // value for 'includeInspectionDocumentCsvFiles'
 *      includeAkaPdf: // value for 'includeAkaPdf'
 *      includeBaseFile: // value for 'includeBaseFile'
 *      includeHelpFiles: // value for 'includeHelpFiles'
 *      includePcxFiles: // value for 'includePcxFiles'
 *      includeRevisionHistoryFile: // value for 'includeRevisionHistoryFile'
 *      attachmentOption: // value for 'attachmentOption'
 *      selectedContracts: // value for 'selectedContracts'
 *      isFiltered: // value for 'isFiltered'
 *   },
 * });
 */
export function useSendReleasePacketEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendReleasePacketEmailMutation,
		SendReleasePacketEmailMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SendReleasePacketEmailMutation,
		SendReleasePacketEmailMutationVariables
	>(SendReleasePacketEmailDocument, options)
}
export type SendReleasePacketEmailMutationHookResult = ReturnType<
	typeof useSendReleasePacketEmailMutation
>
export type SendReleasePacketEmailMutationResult =
	Apollo.MutationResult<SendReleasePacketEmailMutation>
export type SendReleasePacketEmailMutationOptions = Apollo.BaseMutationOptions<
	SendReleasePacketEmailMutation,
	SendReleasePacketEmailMutationVariables
>
export const SelectContractRegionGroupsDocument = gql`
	mutation selectContractRegionGroups(
		$reportId: ID!
		$selectedGroupIds: [ID]!
	) {
		selectContractRegionGroups(
			reportId: $reportId
			selectedGroupIds: $selectedGroupIds
		)
	}
`
export type SelectContractRegionGroupsMutationFn = Apollo.MutationFunction<
	SelectContractRegionGroupsMutation,
	SelectContractRegionGroupsMutationVariables
>

/**
 * __useSelectContractRegionGroupsMutation__
 *
 * To run a mutation, you first call `useSelectContractRegionGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectContractRegionGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectContractRegionGroupsMutation, { data, loading, error }] = useSelectContractRegionGroupsMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      selectedGroupIds: // value for 'selectedGroupIds'
 *   },
 * });
 */
export function useSelectContractRegionGroupsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SelectContractRegionGroupsMutation,
		SelectContractRegionGroupsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SelectContractRegionGroupsMutation,
		SelectContractRegionGroupsMutationVariables
	>(SelectContractRegionGroupsDocument, options)
}
export type SelectContractRegionGroupsMutationHookResult = ReturnType<
	typeof useSelectContractRegionGroupsMutation
>
export type SelectContractRegionGroupsMutationResult =
	Apollo.MutationResult<SelectContractRegionGroupsMutation>
export type SelectContractRegionGroupsMutationOptions =
	Apollo.BaseMutationOptions<
		SelectContractRegionGroupsMutation,
		SelectContractRegionGroupsMutationVariables
	>
export const SubmitJustifyUnsatisfiedRequirementsDocument = gql`
	mutation submitJustifyUnsatisfiedRequirements(
		$items: [JustificationInputType!]!
		$reportId: ID!
	) {
		justifyUnsatisfiedRequirements(items: $items, reportId: $reportId) {
			justificationsCount
		}
	}
`
export type SubmitJustifyUnsatisfiedRequirementsMutationFn =
	Apollo.MutationFunction<
		SubmitJustifyUnsatisfiedRequirementsMutation,
		SubmitJustifyUnsatisfiedRequirementsMutationVariables
	>

/**
 * __useSubmitJustifyUnsatisfiedRequirementsMutation__
 *
 * To run a mutation, you first call `useSubmitJustifyUnsatisfiedRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitJustifyUnsatisfiedRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitJustifyUnsatisfiedRequirementsMutation, { data, loading, error }] = useSubmitJustifyUnsatisfiedRequirementsMutation({
 *   variables: {
 *      items: // value for 'items'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useSubmitJustifyUnsatisfiedRequirementsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SubmitJustifyUnsatisfiedRequirementsMutation,
		SubmitJustifyUnsatisfiedRequirementsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SubmitJustifyUnsatisfiedRequirementsMutation,
		SubmitJustifyUnsatisfiedRequirementsMutationVariables
	>(SubmitJustifyUnsatisfiedRequirementsDocument, options)
}
export type SubmitJustifyUnsatisfiedRequirementsMutationHookResult = ReturnType<
	typeof useSubmitJustifyUnsatisfiedRequirementsMutation
>
export type SubmitJustifyUnsatisfiedRequirementsMutationResult =
	Apollo.MutationResult<SubmitJustifyUnsatisfiedRequirementsMutation>
export type SubmitJustifyUnsatisfiedRequirementsMutationOptions =
	Apollo.BaseMutationOptions<
		SubmitJustifyUnsatisfiedRequirementsMutation,
		SubmitJustifyUnsatisfiedRequirementsMutationVariables
	>
export const SelectContractRegionGroupRequirementsDocument = gql`
	mutation selectContractRegionGroupRequirements(
		$reportId: ID!
		$selection: [ContractRegionGroupsPerRequirement!]!
	) {
		selectContractRegionGroupRequirements(
			reportId: $reportId
			selection: $selection
		) {
			unchangedSelections
			deletedSelections
			createdSelections
		}
	}
`
export type SelectContractRegionGroupRequirementsMutationFn =
	Apollo.MutationFunction<
		SelectContractRegionGroupRequirementsMutation,
		SelectContractRegionGroupRequirementsMutationVariables
	>

/**
 * __useSelectContractRegionGroupRequirementsMutation__
 *
 * To run a mutation, you first call `useSelectContractRegionGroupRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectContractRegionGroupRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectContractRegionGroupRequirementsMutation, { data, loading, error }] = useSelectContractRegionGroupRequirementsMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useSelectContractRegionGroupRequirementsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SelectContractRegionGroupRequirementsMutation,
		SelectContractRegionGroupRequirementsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SelectContractRegionGroupRequirementsMutation,
		SelectContractRegionGroupRequirementsMutationVariables
	>(SelectContractRegionGroupRequirementsDocument, options)
}
export type SelectContractRegionGroupRequirementsMutationHookResult =
	ReturnType<typeof useSelectContractRegionGroupRequirementsMutation>
export type SelectContractRegionGroupRequirementsMutationResult =
	Apollo.MutationResult<SelectContractRegionGroupRequirementsMutation>
export type SelectContractRegionGroupRequirementsMutationOptions =
	Apollo.BaseMutationOptions<
		SelectContractRegionGroupRequirementsMutation,
		SelectContractRegionGroupRequirementsMutationVariables
	>
export const UpdateSoftwareSystemDocument = gql`
	mutation updateSoftwareSystem(
		$systemId: ID!
		$slogan: String!
		$website: String!
	) {
		updateSoftwareSystem(
			systemId: $systemId
			slogan: $slogan
			website: $website
		) {
			softwareSystem {
				id
				slogan
				website
				manufacturerName
			}
		}
	}
`
export type UpdateSoftwareSystemMutationFn = Apollo.MutationFunction<
	UpdateSoftwareSystemMutation,
	UpdateSoftwareSystemMutationVariables
>

/**
 * __useUpdateSoftwareSystemMutation__
 *
 * To run a mutation, you first call `useUpdateSoftwareSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoftwareSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoftwareSystemMutation, { data, loading, error }] = useUpdateSoftwareSystemMutation({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      slogan: // value for 'slogan'
 *      website: // value for 'website'
 *   },
 * });
 */
export function useUpdateSoftwareSystemMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateSoftwareSystemMutation,
		UpdateSoftwareSystemMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateSoftwareSystemMutation,
		UpdateSoftwareSystemMutationVariables
	>(UpdateSoftwareSystemDocument, options)
}
export type UpdateSoftwareSystemMutationHookResult = ReturnType<
	typeof useUpdateSoftwareSystemMutation
>
export type UpdateSoftwareSystemMutationResult =
	Apollo.MutationResult<UpdateSoftwareSystemMutation>
export type UpdateSoftwareSystemMutationOptions = Apollo.BaseMutationOptions<
	UpdateSoftwareSystemMutation,
	UpdateSoftwareSystemMutationVariables
>
export const UpdateReportSoftwareSystemInfoDocument = gql`
	mutation updateReportSoftwareSystemInfo(
		$reportInput: ReportInputType!
		$reportSystemInput: ReportSystemInputType!
		$mainContactInput: ContactPersonInputType!
		$additionalContactInput: ContactPersonInputType
	) {
		updateReportSoftwareSystemInfo(
			reportSystemInput: $reportSystemInput
			mainContactInput: $mainContactInput
			reportInput: $reportInput
			additionalContactInput: $additionalContactInput
		) {
			success
		}
	}
`
export type UpdateReportSoftwareSystemInfoMutationFn = Apollo.MutationFunction<
	UpdateReportSoftwareSystemInfoMutation,
	UpdateReportSoftwareSystemInfoMutationVariables
>

/**
 * __useUpdateReportSoftwareSystemInfoMutation__
 *
 * To run a mutation, you first call `useUpdateReportSoftwareSystemInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportSoftwareSystemInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportSoftwareSystemInfoMutation, { data, loading, error }] = useUpdateReportSoftwareSystemInfoMutation({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *      reportSystemInput: // value for 'reportSystemInput'
 *      mainContactInput: // value for 'mainContactInput'
 *      additionalContactInput: // value for 'additionalContactInput'
 *   },
 * });
 */
export function useUpdateReportSoftwareSystemInfoMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateReportSoftwareSystemInfoMutation,
		UpdateReportSoftwareSystemInfoMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateReportSoftwareSystemInfoMutation,
		UpdateReportSoftwareSystemInfoMutationVariables
	>(UpdateReportSoftwareSystemInfoDocument, options)
}
export type UpdateReportSoftwareSystemInfoMutationHookResult = ReturnType<
	typeof useUpdateReportSoftwareSystemInfoMutation
>
export type UpdateReportSoftwareSystemInfoMutationResult =
	Apollo.MutationResult<UpdateReportSoftwareSystemInfoMutation>
export type UpdateReportSoftwareSystemInfoMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateReportSoftwareSystemInfoMutation,
		UpdateReportSoftwareSystemInfoMutationVariables
	>
export const UpdateSoftwareSystemCostsDocument = gql`
	mutation updateSoftwareSystemCosts(
		$systemId: ID!
		$systemCosts: [SystemCostType!]!
	) {
		updateSoftwareSystemCosts(systemId: $systemId, systemCosts: $systemCosts) {
			success
		}
	}
`
export type UpdateSoftwareSystemCostsMutationFn = Apollo.MutationFunction<
	UpdateSoftwareSystemCostsMutation,
	UpdateSoftwareSystemCostsMutationVariables
>

/**
 * __useUpdateSoftwareSystemCostsMutation__
 *
 * To run a mutation, you first call `useUpdateSoftwareSystemCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoftwareSystemCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoftwareSystemCostsMutation, { data, loading, error }] = useUpdateSoftwareSystemCostsMutation({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      systemCosts: // value for 'systemCosts'
 *   },
 * });
 */
export function useUpdateSoftwareSystemCostsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateSoftwareSystemCostsMutation,
		UpdateSoftwareSystemCostsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateSoftwareSystemCostsMutation,
		UpdateSoftwareSystemCostsMutationVariables
	>(UpdateSoftwareSystemCostsDocument, options)
}
export type UpdateSoftwareSystemCostsMutationHookResult = ReturnType<
	typeof useUpdateSoftwareSystemCostsMutation
>
export type UpdateSoftwareSystemCostsMutationResult =
	Apollo.MutationResult<UpdateSoftwareSystemCostsMutation>
export type UpdateSoftwareSystemCostsMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateSoftwareSystemCostsMutation,
		UpdateSoftwareSystemCostsMutationVariables
	>
export const ContentCheckProofUploadDocument = gql`
	mutation contentCheckProofUpload(
		$systemId: ID!
		$contentCheckId: ID!
		$proofFiles: [Upload!]!
	) {
		contentCheckProofUpload(
			systemId: $systemId
			contentCheckId: $contentCheckId
			proofFiles: $proofFiles
		) {
			success
		}
	}
`
export type ContentCheckProofUploadMutationFn = Apollo.MutationFunction<
	ContentCheckProofUploadMutation,
	ContentCheckProofUploadMutationVariables
>

/**
 * __useContentCheckProofUploadMutation__
 *
 * To run a mutation, you first call `useContentCheckProofUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCheckProofUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCheckProofUploadMutation, { data, loading, error }] = useContentCheckProofUploadMutation({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      contentCheckId: // value for 'contentCheckId'
 *      proofFiles: // value for 'proofFiles'
 *   },
 * });
 */
export function useContentCheckProofUploadMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ContentCheckProofUploadMutation,
		ContentCheckProofUploadMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		ContentCheckProofUploadMutation,
		ContentCheckProofUploadMutationVariables
	>(ContentCheckProofUploadDocument, options)
}
export type ContentCheckProofUploadMutationHookResult = ReturnType<
	typeof useContentCheckProofUploadMutation
>
export type ContentCheckProofUploadMutationResult =
	Apollo.MutationResult<ContentCheckProofUploadMutation>
export type ContentCheckProofUploadMutationOptions = Apollo.BaseMutationOptions<
	ContentCheckProofUploadMutation,
	ContentCheckProofUploadMutationVariables
>
export const SendUserMessageForApprovalDocument = gql`
	mutation sendUserMessageForApproval(
		$contentCheckId: ID
		$systemReportId: ID
		$softwareSystemId: ID!
		$userMessage: String!
	) {
		sendUserMessageForApproval(
			contentCheckId: $contentCheckId
			systemReportId: $systemReportId
			softwareSystemId: $softwareSystemId
			userMessage: $userMessage
		) {
			success
		}
	}
`
export type SendUserMessageForApprovalMutationFn = Apollo.MutationFunction<
	SendUserMessageForApprovalMutation,
	SendUserMessageForApprovalMutationVariables
>

/**
 * __useSendUserMessageForApprovalMutation__
 *
 * To run a mutation, you first call `useSendUserMessageForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserMessageForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserMessageForApprovalMutation, { data, loading, error }] = useSendUserMessageForApprovalMutation({
 *   variables: {
 *      contentCheckId: // value for 'contentCheckId'
 *      systemReportId: // value for 'systemReportId'
 *      softwareSystemId: // value for 'softwareSystemId'
 *      userMessage: // value for 'userMessage'
 *   },
 * });
 */
export function useSendUserMessageForApprovalMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendUserMessageForApprovalMutation,
		SendUserMessageForApprovalMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SendUserMessageForApprovalMutation,
		SendUserMessageForApprovalMutationVariables
	>(SendUserMessageForApprovalDocument, options)
}
export type SendUserMessageForApprovalMutationHookResult = ReturnType<
	typeof useSendUserMessageForApprovalMutation
>
export type SendUserMessageForApprovalMutationResult =
	Apollo.MutationResult<SendUserMessageForApprovalMutation>
export type SendUserMessageForApprovalMutationOptions =
	Apollo.BaseMutationOptions<
		SendUserMessageForApprovalMutation,
		SendUserMessageForApprovalMutationVariables
	>
export const MeDocument = gql`
	query me {
		me {
			...User
		}
	}
	${UserFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
	baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const ReleasesDocument = gql`
	query releases {
		releases {
			id
			createdAt
			name
			status
		}
	}
`

/**
 * __useReleasesQuery__
 *
 * To run a query within a React component, call `useReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReleasesQuery(
	baseOptions?: Apollo.QueryHookOptions<ReleasesQuery, ReleasesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReleasesQuery, ReleasesQueryVariables>(
		ReleasesDocument,
		options
	)
}
export function useReleasesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleasesQuery,
		ReleasesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReleasesQuery, ReleasesQueryVariables>(
		ReleasesDocument,
		options
	)
}
export type ReleasesQueryHookResult = ReturnType<typeof useReleasesQuery>
export type ReleasesLazyQueryHookResult = ReturnType<
	typeof useReleasesLazyQuery
>
export type ReleasesQueryResult = Apollo.QueryResult<
	ReleasesQuery,
	ReleasesQueryVariables
>
export const RevisionReleaseDocument = gql`
	query revisionRelease($id: ID!) {
		release(id: $id) {
			id
			revisionHistoryRootChapter {
				chapters {
					...revisionHistoryChapters
				}
			}
		}
	}
	${RevisionHistoryChaptersFragmentDoc}
`

/**
 * __useRevisionReleaseQuery__
 *
 * To run a query within a React component, call `useRevisionReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevisionReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevisionReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevisionReleaseQuery(
	baseOptions: Apollo.QueryHookOptions<
		RevisionReleaseQuery,
		RevisionReleaseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<RevisionReleaseQuery, RevisionReleaseQueryVariables>(
		RevisionReleaseDocument,
		options
	)
}
export function useRevisionReleaseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		RevisionReleaseQuery,
		RevisionReleaseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		RevisionReleaseQuery,
		RevisionReleaseQueryVariables
	>(RevisionReleaseDocument, options)
}
export type RevisionReleaseQueryHookResult = ReturnType<
	typeof useRevisionReleaseQuery
>
export type RevisionReleaseLazyQueryHookResult = ReturnType<
	typeof useRevisionReleaseLazyQuery
>
export type RevisionReleaseQueryResult = Apollo.QueryResult<
	RevisionReleaseQuery,
	RevisionReleaseQueryVariables
>
export const ReleaseDocument = gql`
	query release($id: ID!) {
		release(id: $id) {
			id
			name
			year
			quarter
			version
			isAttachmentsDirExist
			rootChapter {
				id
				title
				pos
				fullTitle
				parent {
					id
					pos
				}
				chapters {
					id
					title
					pos
					fullTitle
					chapters {
						id
						title
						pos
						fullTitle
						chapters {
							id
							title
							pos
							fullTitle
							chapters {
								id
								title
								pos
								fullTitle
								chapters {
									id
									title
									pos
									fullTitle
								}
							}
						}
					}
				}
			}
		}
	}
`

/**
 * __useReleaseQuery__
 *
 * To run a query within a React component, call `useReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseQuery(
	baseOptions: Apollo.QueryHookOptions<ReleaseQuery, ReleaseQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReleaseQuery, ReleaseQueryVariables>(
		ReleaseDocument,
		options
	)
}
export function useReleaseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReleaseQuery, ReleaseQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ReleaseQuery, ReleaseQueryVariables>(
		ReleaseDocument,
		options
	)
}
export type ReleaseQueryHookResult = ReturnType<typeof useReleaseQuery>
export type ReleaseLazyQueryHookResult = ReturnType<typeof useReleaseLazyQuery>
export type ReleaseQueryResult = Apollo.QueryResult<
	ReleaseQuery,
	ReleaseQueryVariables
>
export const RequirementsDiffDocument = gql`
	query requirementsDiff(
		$chapterId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		requirementsDiff(
			chapterId: $chapterId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			id
			comparedReleaseId
			prefixedCaliberId
			priority
			priorityText
			catalog
			title
			description
			note
			diff
			regionsDiff {
				diff
				comparedReleaseId
				comparedRequirementId
				okv
				name
				contractsDiff
			}
		}
	}
`

/**
 * __useRequirementsDiffQuery__
 *
 * To run a query within a React component, call `useRequirementsDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequirementsDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequirementsDiffQuery({
 *   variables: {
 *      chapterId: // value for 'chapterId'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *      selectedContracts: // value for 'selectedContracts'
 *   },
 * });
 */
export function useRequirementsDiffQuery(
	baseOptions: Apollo.QueryHookOptions<
		RequirementsDiffQuery,
		RequirementsDiffQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<RequirementsDiffQuery, RequirementsDiffQueryVariables>(
		RequirementsDiffDocument,
		options
	)
}
export function useRequirementsDiffLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		RequirementsDiffQuery,
		RequirementsDiffQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		RequirementsDiffQuery,
		RequirementsDiffQueryVariables
	>(RequirementsDiffDocument, options)
}
export type RequirementsDiffQueryHookResult = ReturnType<
	typeof useRequirementsDiffQuery
>
export type RequirementsDiffLazyQueryHookResult = ReturnType<
	typeof useRequirementsDiffLazyQuery
>
export type RequirementsDiffQueryResult = Apollo.QueryResult<
	RequirementsDiffQuery,
	RequirementsDiffQueryVariables
>
export const ChapterDiffDocument = gql`
	query chapterDiff($id: ID!, $comparedReleaseId: ID!) {
		chapterDiff(id: $id, comparedReleaseId: $comparedReleaseId) {
			parent {
				id
				title
				pos
				fullTitle
			}
			chapters {
				id
				pos
				title
				fullTitle
			}
			id
			pos
			title
			fullTitle
			description
			comparedReleaseId
		}
	}
`

/**
 * __useChapterDiffQuery__
 *
 * To run a query within a React component, call `useChapterDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterDiffQuery({
 *   variables: {
 *      id: // value for 'id'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *   },
 * });
 */
export function useChapterDiffQuery(
	baseOptions: Apollo.QueryHookOptions<
		ChapterDiffQuery,
		ChapterDiffQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ChapterDiffQuery, ChapterDiffQueryVariables>(
		ChapterDiffDocument,
		options
	)
}
export function useChapterDiffLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ChapterDiffQuery,
		ChapterDiffQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ChapterDiffQuery, ChapterDiffQueryVariables>(
		ChapterDiffDocument,
		options
	)
}
export type ChapterDiffQueryHookResult = ReturnType<typeof useChapterDiffQuery>
export type ChapterDiffLazyQueryHookResult = ReturnType<
	typeof useChapterDiffLazyQuery
>
export type ChapterDiffQueryResult = Apollo.QueryResult<
	ChapterDiffQuery,
	ChapterDiffQueryVariables
>
export const ChapterDocument = gql`
	query chapter($id: ID!, $selectedContracts: [String]) {
		chapter(id: $id, selectedContracts: $selectedContracts) {
			parent {
				id
				title
				pos
				fullTitle
			}
			chapters {
				id
				pos
				title
				fullTitle
			}
			id
			pos
			title
			fullTitle
			description
			requirements {
				id
				prefixedCaliberId
				priority
				catalog
				title
				description
				note
				regions {
					okv
					name
					contracts
				}
			}
		}
	}
`

/**
 * __useChapterQuery__
 *
 * To run a query within a React component, call `useChapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      selectedContracts: // value for 'selectedContracts'
 *   },
 * });
 */
export function useChapterQuery(
	baseOptions: Apollo.QueryHookOptions<ChapterQuery, ChapterQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ChapterQuery, ChapterQueryVariables>(
		ChapterDocument,
		options
	)
}
export function useChapterLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ChapterQuery, ChapterQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ChapterQuery, ChapterQueryVariables>(
		ChapterDocument,
		options
	)
}
export type ChapterQueryHookResult = ReturnType<typeof useChapterQuery>
export type ChapterLazyQueryHookResult = ReturnType<typeof useChapterLazyQuery>
export type ChapterQueryResult = Apollo.QueryResult<
	ChapterQuery,
	ChapterQueryVariables
>
export const NotificationsDocument = gql`
	query notifications {
		notifications {
			id
			title
			message
			createdAt
			objectId
			contentObjectModel
			category
			contentObject {
				... on ReleaseType {
					id
					name
					createdAt
					version
					year
					quarter
					releaseDate
					catalog
				}
				... on ApprovalRunType {
					id
					title
					submissionDeadline
					year
					approvalQuarter: quarter
					tenant {
						id
						name
						logo {
							url
						}
					}
				}
			}
		}
	}
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		NotificationsQuery,
		NotificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
		NotificationsDocument,
		options
	)
}
export function useNotificationsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		NotificationsQuery,
		NotificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
		NotificationsDocument,
		options
	)
}
export type NotificationsQueryHookResult = ReturnType<
	typeof useNotificationsQuery
>
export type NotificationsLazyQueryHookResult = ReturnType<
	typeof useNotificationsLazyQuery
>
export type NotificationsQueryResult = Apollo.QueryResult<
	NotificationsQuery,
	NotificationsQueryVariables
>
export const SoftwareSystemReportListDocument = gql`
	query softwareSystemReportList($systemId: ID!, $quarter: Int!, $year: Int!) {
		softwareSystemReportList(
			systemId: $systemId
			quarter: $quarter
			year: $year
		) {
			id
			status
			approvalRun {
				id
				submissionDeadline
				year
				quarter
				tenant {
					id
					name
					logo {
						url
					}
				}
			}
		}
	}
`

/**
 * __useSoftwareSystemReportListQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemReportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemReportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemReportListQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      quarter: // value for 'quarter'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useSoftwareSystemReportListQuery(
	baseOptions: Apollo.QueryHookOptions<
		SoftwareSystemReportListQuery,
		SoftwareSystemReportListQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SoftwareSystemReportListQuery,
		SoftwareSystemReportListQueryVariables
	>(SoftwareSystemReportListDocument, options)
}
export function useSoftwareSystemReportListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemReportListQuery,
		SoftwareSystemReportListQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SoftwareSystemReportListQuery,
		SoftwareSystemReportListQueryVariables
	>(SoftwareSystemReportListDocument, options)
}
export type SoftwareSystemReportListQueryHookResult = ReturnType<
	typeof useSoftwareSystemReportListQuery
>
export type SoftwareSystemReportListLazyQueryHookResult = ReturnType<
	typeof useSoftwareSystemReportListLazyQuery
>
export type SoftwareSystemReportListQueryResult = Apollo.QueryResult<
	SoftwareSystemReportListQuery,
	SoftwareSystemReportListQueryVariables
>
export const SelectSoftwareSystemReportRequirementsDocument = gql`
	query selectSoftwareSystemReportRequirements($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			isPrefilled
			changes {
				changedRequirementIds
				newRequirementIds
			}
			system {
				id
				systemName
			}
			selectedContractRegionGroups {
				id
				contract {
					id
					contractId
				}
				regions {
					okv
					name
				}
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
				release {
					id
					rootChapter {
						id
						fullTitle
						chapters {
							id
							title
							pos
							fullTitle
							matrixRequirements(reportId: $id) {
								id
								caliberId
								title
								prefixedCaliberId
								contractRegionGroupIds
								selectedContractRegionGroupIds
								priority
							}
							chapters {
								id
								title
								pos
								fullTitle
								matrixRequirements(reportId: $id) {
									id
									caliberId
									title
									prefixedCaliberId
									contractRegionGroupIds
									selectedContractRegionGroupIds
									priority
								}
								chapters {
									id
									title
									pos
									fullTitle
									matrixRequirements(reportId: $id) {
										id
										caliberId
										title
										prefixedCaliberId
										contractRegionGroupIds
										selectedContractRegionGroupIds
										priority
									}
									chapters {
										id
										title
										pos
										fullTitle
										matrixRequirements(reportId: $id) {
											id
											caliberId
											title
											prefixedCaliberId
											contractRegionGroupIds
											selectedContractRegionGroupIds
										}
										chapters {
											id
											title
											pos
											fullTitle
											matrixRequirements(reportId: $id) {
												id
												caliberId
												title
												prefixedCaliberId
												contractRegionGroupIds
												selectedContractRegionGroupIds
												priority
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

/**
 * __useSelectSoftwareSystemReportRequirementsQuery__
 *
 * To run a query within a React component, call `useSelectSoftwareSystemReportRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectSoftwareSystemReportRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectSoftwareSystemReportRequirementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectSoftwareSystemReportRequirementsQuery(
	baseOptions: Apollo.QueryHookOptions<
		SelectSoftwareSystemReportRequirementsQuery,
		SelectSoftwareSystemReportRequirementsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SelectSoftwareSystemReportRequirementsQuery,
		SelectSoftwareSystemReportRequirementsQueryVariables
	>(SelectSoftwareSystemReportRequirementsDocument, options)
}
export function useSelectSoftwareSystemReportRequirementsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SelectSoftwareSystemReportRequirementsQuery,
		SelectSoftwareSystemReportRequirementsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SelectSoftwareSystemReportRequirementsQuery,
		SelectSoftwareSystemReportRequirementsQueryVariables
	>(SelectSoftwareSystemReportRequirementsDocument, options)
}
export type SelectSoftwareSystemReportRequirementsQueryHookResult = ReturnType<
	typeof useSelectSoftwareSystemReportRequirementsQuery
>
export type SelectSoftwareSystemReportRequirementsLazyQueryHookResult =
	ReturnType<typeof useSelectSoftwareSystemReportRequirementsLazyQuery>
export type SelectSoftwareSystemReportRequirementsQueryResult =
	Apollo.QueryResult<
		SelectSoftwareSystemReportRequirementsQuery,
		SelectSoftwareSystemReportRequirementsQueryVariables
	>
export const SoftwareSystemReportJustificationsDocument = gql`
	query softwareSystemReportJustifications($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			system {
				id
				systemName
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
			justifications {
				id
				requirements {
					id
					prefixedCaliberId
					title
				}
				text
			}
		}
	}
`

/**
 * __useSoftwareSystemReportJustificationsQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemReportJustificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemReportJustificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemReportJustificationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftwareSystemReportJustificationsQuery(
	baseOptions: Apollo.QueryHookOptions<
		SoftwareSystemReportJustificationsQuery,
		SoftwareSystemReportJustificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SoftwareSystemReportJustificationsQuery,
		SoftwareSystemReportJustificationsQueryVariables
	>(SoftwareSystemReportJustificationsDocument, options)
}
export function useSoftwareSystemReportJustificationsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemReportJustificationsQuery,
		SoftwareSystemReportJustificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SoftwareSystemReportJustificationsQuery,
		SoftwareSystemReportJustificationsQueryVariables
	>(SoftwareSystemReportJustificationsDocument, options)
}
export type SoftwareSystemReportJustificationsQueryHookResult = ReturnType<
	typeof useSoftwareSystemReportJustificationsQuery
>
export type SoftwareSystemReportJustificationsLazyQueryHookResult = ReturnType<
	typeof useSoftwareSystemReportJustificationsLazyQuery
>
export type SoftwareSystemReportJustificationsQueryResult = Apollo.QueryResult<
	SoftwareSystemReportJustificationsQuery,
	SoftwareSystemReportJustificationsQueryVariables
>
export const SoftwareSystemReportSystemInfoDocument = gql`
	query softwareSystemReportSystemInfo($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			systemVersion
			rolloutDate
			system {
				id
				manufacturerName
				systemName
				streetAndNumber
				postalCode
				city
				website
				drugModule {
					name
					date
					version
				}
				orderAddress
				mainContactPerson {
					id
					title
					firstname
					lastname
					phoneNumber
					email
					isMainContact
				}
				additionalContactPerson {
					id
					title
					firstname
					lastname
					phoneNumber
					email
					isMainContact
				}
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
		}
	}
`

/**
 * __useSoftwareSystemReportSystemInfoQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemReportSystemInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemReportSystemInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemReportSystemInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftwareSystemReportSystemInfoQuery(
	baseOptions: Apollo.QueryHookOptions<
		SoftwareSystemReportSystemInfoQuery,
		SoftwareSystemReportSystemInfoQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SoftwareSystemReportSystemInfoQuery,
		SoftwareSystemReportSystemInfoQueryVariables
	>(SoftwareSystemReportSystemInfoDocument, options)
}
export function useSoftwareSystemReportSystemInfoLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemReportSystemInfoQuery,
		SoftwareSystemReportSystemInfoQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SoftwareSystemReportSystemInfoQuery,
		SoftwareSystemReportSystemInfoQueryVariables
	>(SoftwareSystemReportSystemInfoDocument, options)
}
export type SoftwareSystemReportSystemInfoQueryHookResult = ReturnType<
	typeof useSoftwareSystemReportSystemInfoQuery
>
export type SoftwareSystemReportSystemInfoLazyQueryHookResult = ReturnType<
	typeof useSoftwareSystemReportSystemInfoLazyQuery
>
export type SoftwareSystemReportSystemInfoQueryResult = Apollo.QueryResult<
	SoftwareSystemReportSystemInfoQuery,
	SoftwareSystemReportSystemInfoQueryVariables
>
export const SofwareSystemReportSystemCostsDocument = gql`
	query sofwareSystemReportSystemCosts($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			systemVersion
			rolloutDate
			system {
				id
				systemName
				systemCosts
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
		}
	}
`

/**
 * __useSofwareSystemReportSystemCostsQuery__
 *
 * To run a query within a React component, call `useSofwareSystemReportSystemCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSofwareSystemReportSystemCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSofwareSystemReportSystemCostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSofwareSystemReportSystemCostsQuery(
	baseOptions: Apollo.QueryHookOptions<
		SofwareSystemReportSystemCostsQuery,
		SofwareSystemReportSystemCostsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SofwareSystemReportSystemCostsQuery,
		SofwareSystemReportSystemCostsQueryVariables
	>(SofwareSystemReportSystemCostsDocument, options)
}
export function useSofwareSystemReportSystemCostsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SofwareSystemReportSystemCostsQuery,
		SofwareSystemReportSystemCostsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SofwareSystemReportSystemCostsQuery,
		SofwareSystemReportSystemCostsQueryVariables
	>(SofwareSystemReportSystemCostsDocument, options)
}
export type SofwareSystemReportSystemCostsQueryHookResult = ReturnType<
	typeof useSofwareSystemReportSystemCostsQuery
>
export type SofwareSystemReportSystemCostsLazyQueryHookResult = ReturnType<
	typeof useSofwareSystemReportSystemCostsLazyQuery
>
export type SofwareSystemReportSystemCostsQueryResult = Apollo.QueryResult<
	SofwareSystemReportSystemCostsQuery,
	SofwareSystemReportSystemCostsQueryVariables
>
export const SoftwareSystemReportUnsatiesfiedRequirementsDocument = gql`
	query softwareSystemReportUnsatiesfiedRequirements($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			unsatisfiedRequirements {
				id
				title
				prefixedCaliberId
			}
		}
	}
`

/**
 * __useSoftwareSystemReportUnsatiesfiedRequirementsQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemReportUnsatiesfiedRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemReportUnsatiesfiedRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemReportUnsatiesfiedRequirementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftwareSystemReportUnsatiesfiedRequirementsQuery(
	baseOptions: Apollo.QueryHookOptions<
		SoftwareSystemReportUnsatiesfiedRequirementsQuery,
		SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SoftwareSystemReportUnsatiesfiedRequirementsQuery,
		SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
	>(SoftwareSystemReportUnsatiesfiedRequirementsDocument, options)
}
export function useSoftwareSystemReportUnsatiesfiedRequirementsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemReportUnsatiesfiedRequirementsQuery,
		SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SoftwareSystemReportUnsatiesfiedRequirementsQuery,
		SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
	>(SoftwareSystemReportUnsatiesfiedRequirementsDocument, options)
}
export type SoftwareSystemReportUnsatiesfiedRequirementsQueryHookResult =
	ReturnType<typeof useSoftwareSystemReportUnsatiesfiedRequirementsQuery>
export type SoftwareSystemReportUnsatiesfiedRequirementsLazyQueryHookResult =
	ReturnType<typeof useSoftwareSystemReportUnsatiesfiedRequirementsLazyQuery>
export type SoftwareSystemReportUnsatiesfiedRequirementsQueryResult =
	Apollo.QueryResult<
		SoftwareSystemReportUnsatiesfiedRequirementsQuery,
		SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
	>
export const ContentCheckListDocument = gql`
	query contentCheckList($systemId: ID!, $quarter: Int!, $year: Int!) {
		contentCheckList(systemId: $systemId, quarter: $quarter, year: $year) {
			id
			title
			approvalRun {
				submissionDeadline
				tenant {
					id
					name
					logo {
						url
					}
				}
			}
			proof(systemId: $systemId) {
				status
			}
			contract {
				contractId
			}
		}
	}
`

/**
 * __useContentCheckListQuery__
 *
 * To run a query within a React component, call `useContentCheckListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCheckListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCheckListQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      quarter: // value for 'quarter'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useContentCheckListQuery(
	baseOptions: Apollo.QueryHookOptions<
		ContentCheckListQuery,
		ContentCheckListQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ContentCheckListQuery, ContentCheckListQueryVariables>(
		ContentCheckListDocument,
		options
	)
}
export function useContentCheckListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ContentCheckListQuery,
		ContentCheckListQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ContentCheckListQuery,
		ContentCheckListQueryVariables
	>(ContentCheckListDocument, options)
}
export type ContentCheckListQueryHookResult = ReturnType<
	typeof useContentCheckListQuery
>
export type ContentCheckListLazyQueryHookResult = ReturnType<
	typeof useContentCheckListLazyQuery
>
export type ContentCheckListQueryResult = Apollo.QueryResult<
	ContentCheckListQuery,
	ContentCheckListQueryVariables
>
export const ContentCheckDocument = gql`
	query contentCheck($id: ID!, $systemId: ID!) {
		contentCheck(id: $id, systemId: $systemId) {
			id
			title
			instructionFileUrl
			contract {
				id
				contractId
				name
			}
			approvalRun {
				quarter
				year
				tenant {
					id
					name
				}
			}
			proof(systemId: $systemId) {
				files {
					url
					name
				}
				status
				system {
					id
					systemName
				}
			}
		}
	}
`

/**
 * __useContentCheckQuery__
 *
 * To run a query within a React component, call `useContentCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCheckQuery({
 *   variables: {
 *      id: // value for 'id'
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useContentCheckQuery(
	baseOptions: Apollo.QueryHookOptions<
		ContentCheckQuery,
		ContentCheckQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ContentCheckQuery, ContentCheckQueryVariables>(
		ContentCheckDocument,
		options
	)
}
export function useContentCheckLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ContentCheckQuery,
		ContentCheckQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ContentCheckQuery, ContentCheckQueryVariables>(
		ContentCheckDocument,
		options
	)
}
export type ContentCheckQueryHookResult = ReturnType<
	typeof useContentCheckQuery
>
export type ContentCheckLazyQueryHookResult = ReturnType<
	typeof useContentCheckLazyQuery
>
export type ContentCheckQueryResult = Apollo.QueryResult<
	ContentCheckQuery,
	ContentCheckQueryVariables
>
export const ContractRegionGroupsDocument = gql`
	query contractRegionGroups($id: ID!) {
		contractRegionGroupList(reportId: $id) {
			id
			contract {
				id
				contractId
			}
			regions {
				okv
				shortName
				name
			}
		}
		softwareSystemReport(id: $id) {
			id
			status
			isPrefilled
			selectedContractRegionGroups {
				id
			}
			system {
				id
				systemName
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
		}
	}
`

/**
 * __useContractRegionGroupsQuery__
 *
 * To run a query within a React component, call `useContractRegionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractRegionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractRegionGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractRegionGroupsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ContractRegionGroupsQuery,
		ContractRegionGroupsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ContractRegionGroupsQuery,
		ContractRegionGroupsQueryVariables
	>(ContractRegionGroupsDocument, options)
}
export function useContractRegionGroupsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ContractRegionGroupsQuery,
		ContractRegionGroupsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ContractRegionGroupsQuery,
		ContractRegionGroupsQueryVariables
	>(ContractRegionGroupsDocument, options)
}
export type ContractRegionGroupsQueryHookResult = ReturnType<
	typeof useContractRegionGroupsQuery
>
export type ContractRegionGroupsLazyQueryHookResult = ReturnType<
	typeof useContractRegionGroupsLazyQuery
>
export type ContractRegionGroupsQueryResult = Apollo.QueryResult<
	ContractRegionGroupsQuery,
	ContractRegionGroupsQueryVariables
>
export const ReleasesToCompareDocument = gql`
	query releasesToCompare($currentVersionId: ID!) {
		releasesToCompare(currentVersionId: $currentVersionId) {
			id
			name
			year
			quarter
			version
		}
	}
`

/**
 * __useReleasesToCompareQuery__
 *
 * To run a query within a React component, call `useReleasesToCompareQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesToCompareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesToCompareQuery({
 *   variables: {
 *      currentVersionId: // value for 'currentVersionId'
 *   },
 * });
 */
export function useReleasesToCompareQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReleasesToCompareQuery,
		ReleasesToCompareQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ReleasesToCompareQuery,
		ReleasesToCompareQueryVariables
	>(ReleasesToCompareDocument, options)
}
export function useReleasesToCompareLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleasesToCompareQuery,
		ReleasesToCompareQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReleasesToCompareQuery,
		ReleasesToCompareQueryVariables
	>(ReleasesToCompareDocument, options)
}
export type ReleasesToCompareQueryHookResult = ReturnType<
	typeof useReleasesToCompareQuery
>
export type ReleasesToCompareLazyQueryHookResult = ReturnType<
	typeof useReleasesToCompareLazyQuery
>
export type ReleasesToCompareQueryResult = Apollo.QueryResult<
	ReleasesToCompareQuery,
	ReleasesToCompareQueryVariables
>
export const ReleaseAttachmentsDocument = gql`
	query releaseAttachments(
		$releaseId: ID!
		$comparedReleaseId: ID
		$query: String
		$before: String
		$after: String
		$first: Int
		$last: Int
		$attachment_Type: String
		$attachmentObject_Type: String
		$contract_ContractId: String
	) {
		releaseAttachments(
			releaseId: $releaseId
			comparedReleaseId: $comparedReleaseId
			query: $query
			before: $before
			after: $after
			first: $first
			last: $last
			attachment_Type: $attachment_Type
			attachmentObject_Type: $attachmentObject_Type
			contract_ContractId: $contract_ContractId
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					...attachmentNodeFragment
				}
			}
		}
	}
	${AttachmentNodeFragmentFragmentDoc}
`

/**
 * __useReleaseAttachmentsQuery__
 *
 * To run a query within a React component, call `useReleaseAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseAttachmentsQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *      query: // value for 'query'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      attachment_Type: // value for 'attachment_Type'
 *      attachmentObject_Type: // value for 'attachmentObject_Type'
 *      contract_ContractId: // value for 'contract_ContractId'
 *   },
 * });
 */
export function useReleaseAttachmentsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReleaseAttachmentsQuery,
		ReleaseAttachmentsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ReleaseAttachmentsQuery,
		ReleaseAttachmentsQueryVariables
	>(ReleaseAttachmentsDocument, options)
}
export function useReleaseAttachmentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleaseAttachmentsQuery,
		ReleaseAttachmentsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReleaseAttachmentsQuery,
		ReleaseAttachmentsQueryVariables
	>(ReleaseAttachmentsDocument, options)
}
export type ReleaseAttachmentsQueryHookResult = ReturnType<
	typeof useReleaseAttachmentsQuery
>
export type ReleaseAttachmentsLazyQueryHookResult = ReturnType<
	typeof useReleaseAttachmentsLazyQuery
>
export type ReleaseAttachmentsQueryResult = Apollo.QueryResult<
	ReleaseAttachmentsQuery,
	ReleaseAttachmentsQueryVariables
>
export const AttachmentsTypesDocument = gql`
	query attachmentsTypes($releaseId: ID!) {
		attachmentsTypes(releaseId: $releaseId)
	}
`

/**
 * __useAttachmentsTypesQuery__
 *
 * To run a query within a React component, call `useAttachmentsTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsTypesQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useAttachmentsTypesQuery(
	baseOptions: Apollo.QueryHookOptions<
		AttachmentsTypesQuery,
		AttachmentsTypesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<AttachmentsTypesQuery, AttachmentsTypesQueryVariables>(
		AttachmentsTypesDocument,
		options
	)
}
export function useAttachmentsTypesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AttachmentsTypesQuery,
		AttachmentsTypesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		AttachmentsTypesQuery,
		AttachmentsTypesQueryVariables
	>(AttachmentsTypesDocument, options)
}
export type AttachmentsTypesQueryHookResult = ReturnType<
	typeof useAttachmentsTypesQuery
>
export type AttachmentsTypesLazyQueryHookResult = ReturnType<
	typeof useAttachmentsTypesLazyQuery
>
export type AttachmentsTypesQueryResult = Apollo.QueryResult<
	AttachmentsTypesQuery,
	AttachmentsTypesQueryVariables
>
export const AttachmentObjectTypesDocument = gql`
	query attachmentObjectTypes($releaseId: ID!) {
		attachmentObjectTypes(releaseId: $releaseId)
	}
`

/**
 * __useAttachmentObjectTypesQuery__
 *
 * To run a query within a React component, call `useAttachmentObjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentObjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentObjectTypesQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useAttachmentObjectTypesQuery(
	baseOptions: Apollo.QueryHookOptions<
		AttachmentObjectTypesQuery,
		AttachmentObjectTypesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		AttachmentObjectTypesQuery,
		AttachmentObjectTypesQueryVariables
	>(AttachmentObjectTypesDocument, options)
}
export function useAttachmentObjectTypesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AttachmentObjectTypesQuery,
		AttachmentObjectTypesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		AttachmentObjectTypesQuery,
		AttachmentObjectTypesQueryVariables
	>(AttachmentObjectTypesDocument, options)
}
export type AttachmentObjectTypesQueryHookResult = ReturnType<
	typeof useAttachmentObjectTypesQuery
>
export type AttachmentObjectTypesLazyQueryHookResult = ReturnType<
	typeof useAttachmentObjectTypesLazyQuery
>
export type AttachmentObjectTypesQueryResult = Apollo.QueryResult<
	AttachmentObjectTypesQuery,
	AttachmentObjectTypesQueryVariables
>
export const AttachmentContractsDocument = gql`
	query attachmentContracts($releaseId: ID!) {
		attachmentContracts(releaseId: $releaseId)
	}
`

/**
 * __useAttachmentContractsQuery__
 *
 * To run a query within a React component, call `useAttachmentContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentContractsQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useAttachmentContractsQuery(
	baseOptions: Apollo.QueryHookOptions<
		AttachmentContractsQuery,
		AttachmentContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		AttachmentContractsQuery,
		AttachmentContractsQueryVariables
	>(AttachmentContractsDocument, options)
}
export function useAttachmentContractsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AttachmentContractsQuery,
		AttachmentContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		AttachmentContractsQuery,
		AttachmentContractsQueryVariables
	>(AttachmentContractsDocument, options)
}
export type AttachmentContractsQueryHookResult = ReturnType<
	typeof useAttachmentContractsQuery
>
export type AttachmentContractsLazyQueryHookResult = ReturnType<
	typeof useAttachmentContractsLazyQuery
>
export type AttachmentContractsQueryResult = Apollo.QueryResult<
	AttachmentContractsQuery,
	AttachmentContractsQueryVariables
>
export const ReleaseContractsDocument = gql`
	query releaseContracts($releaseId: ID!) {
		releaseContracts(releaseId: $releaseId) {
			contractId
			identification
		}
	}
`

/**
 * __useReleaseContractsQuery__
 *
 * To run a query within a React component, call `useReleaseContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseContractsQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useReleaseContractsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReleaseContractsQuery,
		ReleaseContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ReleaseContractsQuery, ReleaseContractsQueryVariables>(
		ReleaseContractsDocument,
		options
	)
}
export function useReleaseContractsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleaseContractsQuery,
		ReleaseContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReleaseContractsQuery,
		ReleaseContractsQueryVariables
	>(ReleaseContractsDocument, options)
}
export type ReleaseContractsQueryHookResult = ReturnType<
	typeof useReleaseContractsQuery
>
export type ReleaseContractsLazyQueryHookResult = ReturnType<
	typeof useReleaseContractsLazyQuery
>
export type ReleaseContractsQueryResult = Apollo.QueryResult<
	ReleaseContractsQuery,
	ReleaseContractsQueryVariables
>
export const UserContractsDocument = gql`
	query userContracts {
		userContracts {
			key: id
			contractId
			identification
			typ
		}
	}
`

/**
 * __useUserContractsQuery__
 *
 * To run a query within a React component, call `useUserContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContractsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		UserContractsQuery,
		UserContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<UserContractsQuery, UserContractsQueryVariables>(
		UserContractsDocument,
		options
	)
}
export function useUserContractsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserContractsQuery,
		UserContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<UserContractsQuery, UserContractsQueryVariables>(
		UserContractsDocument,
		options
	)
}
export type UserContractsQueryHookResult = ReturnType<
	typeof useUserContractsQuery
>
export type UserContractsLazyQueryHookResult = ReturnType<
	typeof useUserContractsLazyQuery
>
export type UserContractsQueryResult = Apollo.QueryResult<
	UserContractsQuery,
	UserContractsQueryVariables
>
export const ReleaseRequirementsChangesDocument = gql`
	query releaseRequirementsChanges(
		$currentReleaseId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		releaseRequirementsChanges(
			currentReleaseId: $currentReleaseId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			currentReleaseId
			comparedReleaseId
			newRequirements {
				...requirementFragment
			}
			changedRequirements {
				...requirementFragment
			}
			deletedRequirements {
				...requirementFragment
			}
			changedAssignmentRequirements {
				id
				prefixedCaliberId
				title
				newRegionContractAssignments
				deletedRegionContractAssignments
			}
		}
	}
	${RequirementFragmentFragmentDoc}
`

/**
 * __useReleaseRequirementsChangesQuery__
 *
 * To run a query within a React component, call `useReleaseRequirementsChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseRequirementsChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseRequirementsChangesQuery({
 *   variables: {
 *      currentReleaseId: // value for 'currentReleaseId'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *      selectedContracts: // value for 'selectedContracts'
 *   },
 * });
 */
export function useReleaseRequirementsChangesQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReleaseRequirementsChangesQuery,
		ReleaseRequirementsChangesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ReleaseRequirementsChangesQuery,
		ReleaseRequirementsChangesQueryVariables
	>(ReleaseRequirementsChangesDocument, options)
}
export function useReleaseRequirementsChangesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleaseRequirementsChangesQuery,
		ReleaseRequirementsChangesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReleaseRequirementsChangesQuery,
		ReleaseRequirementsChangesQueryVariables
	>(ReleaseRequirementsChangesDocument, options)
}
export type ReleaseRequirementsChangesQueryHookResult = ReturnType<
	typeof useReleaseRequirementsChangesQuery
>
export type ReleaseRequirementsChangesLazyQueryHookResult = ReturnType<
	typeof useReleaseRequirementsChangesLazyQuery
>
export type ReleaseRequirementsChangesQueryResult = Apollo.QueryResult<
	ReleaseRequirementsChangesQuery,
	ReleaseRequirementsChangesQueryVariables
>
export const AttachmentReleaseChangesDocument = gql`
	query attachmentReleaseChanges(
		$currentReleaseId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		releaseAttachmentChanges(
			currentReleaseId: $currentReleaseId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			comparedReleaseId
			currentReleaseId
			changedAttachments {
				...attachmentContractReleaseFragment
			}
			deletedAttachments {
				...attachmentContractReleaseFragment
			}
			newAttachments {
				...attachmentContractReleaseFragment
			}
		}
	}
	${AttachmentContractReleaseFragmentFragmentDoc}
`

/**
 * __useAttachmentReleaseChangesQuery__
 *
 * To run a query within a React component, call `useAttachmentReleaseChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentReleaseChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentReleaseChangesQuery({
 *   variables: {
 *      currentReleaseId: // value for 'currentReleaseId'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *      selectedContracts: // value for 'selectedContracts'
 *   },
 * });
 */
export function useAttachmentReleaseChangesQuery(
	baseOptions: Apollo.QueryHookOptions<
		AttachmentReleaseChangesQuery,
		AttachmentReleaseChangesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		AttachmentReleaseChangesQuery,
		AttachmentReleaseChangesQueryVariables
	>(AttachmentReleaseChangesDocument, options)
}
export function useAttachmentReleaseChangesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AttachmentReleaseChangesQuery,
		AttachmentReleaseChangesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		AttachmentReleaseChangesQuery,
		AttachmentReleaseChangesQueryVariables
	>(AttachmentReleaseChangesDocument, options)
}
export type AttachmentReleaseChangesQueryHookResult = ReturnType<
	typeof useAttachmentReleaseChangesQuery
>
export type AttachmentReleaseChangesLazyQueryHookResult = ReturnType<
	typeof useAttachmentReleaseChangesLazyQuery
>
export type AttachmentReleaseChangesQueryResult = Apollo.QueryResult<
	AttachmentReleaseChangesQuery,
	AttachmentReleaseChangesQueryVariables
>
export const SearchReleaseRequirementsDocument = gql`
	query searchReleaseRequirements(
		$releaseId: ID!
		$query: String!
		$selectedContracts: [String]
	) {
		releaseRequirementsSearch(
			releaseId: $releaseId
			query: $query
			selectedContracts: $selectedContracts
		) {
			id
			prefixedCaliberId
			title
			priority
			chapter {
				id
			}
		}
	}
`

/**
 * __useSearchReleaseRequirementsQuery__
 *
 * To run a query within a React component, call `useSearchReleaseRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchReleaseRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchReleaseRequirementsQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      query: // value for 'query'
 *      selectedContracts: // value for 'selectedContracts'
 *   },
 * });
 */
export function useSearchReleaseRequirementsQuery(
	baseOptions: Apollo.QueryHookOptions<
		SearchReleaseRequirementsQuery,
		SearchReleaseRequirementsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SearchReleaseRequirementsQuery,
		SearchReleaseRequirementsQueryVariables
	>(SearchReleaseRequirementsDocument, options)
}
export function useSearchReleaseRequirementsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SearchReleaseRequirementsQuery,
		SearchReleaseRequirementsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SearchReleaseRequirementsQuery,
		SearchReleaseRequirementsQueryVariables
	>(SearchReleaseRequirementsDocument, options)
}
export type SearchReleaseRequirementsQueryHookResult = ReturnType<
	typeof useSearchReleaseRequirementsQuery
>
export type SearchReleaseRequirementsLazyQueryHookResult = ReturnType<
	typeof useSearchReleaseRequirementsLazyQuery
>
export type SearchReleaseRequirementsQueryResult = Apollo.QueryResult<
	SearchReleaseRequirementsQuery,
	SearchReleaseRequirementsQueryVariables
>
export const ReleaseAttachmentObjectContractsDocument = gql`
	query releaseAttachmentObjectContracts(
		$releaseId: ID!
		$attachmentSpuID: Int!
		$attachmentObjectSpuID: Int!
	) {
		releaseAttachmentObjectContracts(
			releaseId: $releaseId
			attachmentSpuID: $attachmentSpuID
			attachmentObjectSpuID: $attachmentObjectSpuID
		)
	}
`

/**
 * __useReleaseAttachmentObjectContractsQuery__
 *
 * To run a query within a React component, call `useReleaseAttachmentObjectContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseAttachmentObjectContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseAttachmentObjectContractsQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      attachmentSpuID: // value for 'attachmentSpuID'
 *      attachmentObjectSpuID: // value for 'attachmentObjectSpuID'
 *   },
 * });
 */
export function useReleaseAttachmentObjectContractsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReleaseAttachmentObjectContractsQuery,
		ReleaseAttachmentObjectContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ReleaseAttachmentObjectContractsQuery,
		ReleaseAttachmentObjectContractsQueryVariables
	>(ReleaseAttachmentObjectContractsDocument, options)
}
export function useReleaseAttachmentObjectContractsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleaseAttachmentObjectContractsQuery,
		ReleaseAttachmentObjectContractsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReleaseAttachmentObjectContractsQuery,
		ReleaseAttachmentObjectContractsQueryVariables
	>(ReleaseAttachmentObjectContractsDocument, options)
}
export type ReleaseAttachmentObjectContractsQueryHookResult = ReturnType<
	typeof useReleaseAttachmentObjectContractsQuery
>
export type ReleaseAttachmentObjectContractsLazyQueryHookResult = ReturnType<
	typeof useReleaseAttachmentObjectContractsLazyQuery
>
export type ReleaseAttachmentObjectContractsQueryResult = Apollo.QueryResult<
	ReleaseAttachmentObjectContractsQuery,
	ReleaseAttachmentObjectContractsQueryVariables
>
export const AttachmentsObjectFilePathDocument = gql`
	query attachmentsObjectFilePath($attachmentObjectId: ID!) {
		attachmentsObjectFilePath(attachmentObjectId: $attachmentObjectId)
	}
`

/**
 * __useAttachmentsObjectFilePathQuery__
 *
 * To run a query within a React component, call `useAttachmentsObjectFilePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsObjectFilePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsObjectFilePathQuery({
 *   variables: {
 *      attachmentObjectId: // value for 'attachmentObjectId'
 *   },
 * });
 */
export function useAttachmentsObjectFilePathQuery(
	baseOptions: Apollo.QueryHookOptions<
		AttachmentsObjectFilePathQuery,
		AttachmentsObjectFilePathQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		AttachmentsObjectFilePathQuery,
		AttachmentsObjectFilePathQueryVariables
	>(AttachmentsObjectFilePathDocument, options)
}
export function useAttachmentsObjectFilePathLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AttachmentsObjectFilePathQuery,
		AttachmentsObjectFilePathQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		AttachmentsObjectFilePathQuery,
		AttachmentsObjectFilePathQueryVariables
	>(AttachmentsObjectFilePathDocument, options)
}
export type AttachmentsObjectFilePathQueryHookResult = ReturnType<
	typeof useAttachmentsObjectFilePathQuery
>
export type AttachmentsObjectFilePathLazyQueryHookResult = ReturnType<
	typeof useAttachmentsObjectFilePathLazyQuery
>
export type AttachmentsObjectFilePathQueryResult = Apollo.QueryResult<
	AttachmentsObjectFilePathQuery,
	AttachmentsObjectFilePathQueryVariables
>
export const ReleaseContractChangesDocument = gql`
	query releaseContractChanges(
		$currentReleaseId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		releaseContractChanges(
			currentReleaseId: $currentReleaseId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			currentReleaseId
			comparedReleaseId
			releaseContractsDiff {
				id
				version
				diff
				oldVersion
				contract {
					contractId
					identification
				}
			}
		}
	}
`

/**
 * __useReleaseContractChangesQuery__
 *
 * To run a query within a React component, call `useReleaseContractChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseContractChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseContractChangesQuery({
 *   variables: {
 *      currentReleaseId: // value for 'currentReleaseId'
 *      comparedReleaseId: // value for 'comparedReleaseId'
 *      selectedContracts: // value for 'selectedContracts'
 *   },
 * });
 */
export function useReleaseContractChangesQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReleaseContractChangesQuery,
		ReleaseContractChangesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		ReleaseContractChangesQuery,
		ReleaseContractChangesQueryVariables
	>(ReleaseContractChangesDocument, options)
}
export function useReleaseContractChangesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReleaseContractChangesQuery,
		ReleaseContractChangesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		ReleaseContractChangesQuery,
		ReleaseContractChangesQueryVariables
	>(ReleaseContractChangesDocument, options)
}
export type ReleaseContractChangesQueryHookResult = ReturnType<
	typeof useReleaseContractChangesQuery
>
export type ReleaseContractChangesLazyQueryHookResult = ReturnType<
	typeof useReleaseContractChangesLazyQuery
>
export type ReleaseContractChangesQueryResult = Apollo.QueryResult<
	ReleaseContractChangesQuery,
	ReleaseContractChangesQueryVariables
>
export const SoftwareSystemsDocument = gql`
	query softwareSystems {
		softwareSystems {
			id
			systemName
			slogan
			website
			manufacturerName
			isParticipating
		}
	}
`

/**
 * __useSoftwareSystemsQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareSystemsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		SoftwareSystemsQuery,
		SoftwareSystemsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<SoftwareSystemsQuery, SoftwareSystemsQueryVariables>(
		SoftwareSystemsDocument,
		options
	)
}
export function useSoftwareSystemsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemsQuery,
		SoftwareSystemsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SoftwareSystemsQuery,
		SoftwareSystemsQueryVariables
	>(SoftwareSystemsDocument, options)
}
export type SoftwareSystemsQueryHookResult = ReturnType<
	typeof useSoftwareSystemsQuery
>
export type SoftwareSystemsLazyQueryHookResult = ReturnType<
	typeof useSoftwareSystemsLazyQuery
>
export type SoftwareSystemsQueryResult = Apollo.QueryResult<
	SoftwareSystemsQuery,
	SoftwareSystemsQueryVariables
>
export const SoftwareSystemApprovalsDocument = gql`
	query softwareSystemApprovals($systemId: ID!, $year: Int!, $quarter: Int!) {
		softwareSystem(id: $systemId) {
			id
			approvals(year: $year, quarter: $quarter) {
				status
				tenant {
					id
					name
					hexColor
				}
			}
		}
	}
`

/**
 * __useSoftwareSystemApprovalsQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemApprovalsQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      year: // value for 'year'
 *      quarter: // value for 'quarter'
 *   },
 * });
 */
export function useSoftwareSystemApprovalsQuery(
	baseOptions: Apollo.QueryHookOptions<
		SoftwareSystemApprovalsQuery,
		SoftwareSystemApprovalsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		SoftwareSystemApprovalsQuery,
		SoftwareSystemApprovalsQueryVariables
	>(SoftwareSystemApprovalsDocument, options)
}
export function useSoftwareSystemApprovalsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemApprovalsQuery,
		SoftwareSystemApprovalsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		SoftwareSystemApprovalsQuery,
		SoftwareSystemApprovalsQueryVariables
	>(SoftwareSystemApprovalsDocument, options)
}
export type SoftwareSystemApprovalsQueryHookResult = ReturnType<
	typeof useSoftwareSystemApprovalsQuery
>
export type SoftwareSystemApprovalsLazyQueryHookResult = ReturnType<
	typeof useSoftwareSystemApprovalsLazyQuery
>
export type SoftwareSystemApprovalsQueryResult = Apollo.QueryResult<
	SoftwareSystemApprovalsQuery,
	SoftwareSystemApprovalsQueryVariables
>
export const SoftwareSystemDocument = gql`
	query softwareSystem($id: ID!) {
		softwareSystem(id: $id) {
			id
			systemName
		}
	}
`

/**
 * __useSoftwareSystemQuery__
 *
 * To run a query within a React component, call `useSoftwareSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSystemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftwareSystemQuery(
	baseOptions: Apollo.QueryHookOptions<
		SoftwareSystemQuery,
		SoftwareSystemQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<SoftwareSystemQuery, SoftwareSystemQueryVariables>(
		SoftwareSystemDocument,
		options
	)
}
export function useSoftwareSystemLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SoftwareSystemQuery,
		SoftwareSystemQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<SoftwareSystemQuery, SoftwareSystemQueryVariables>(
		SoftwareSystemDocument,
		options
	)
}
export type SoftwareSystemQueryHookResult = ReturnType<
	typeof useSoftwareSystemQuery
>
export type SoftwareSystemLazyQueryHookResult = ReturnType<
	typeof useSoftwareSystemLazyQuery
>
export type SoftwareSystemQueryResult = Apollo.QueryResult<
	SoftwareSystemQuery,
	SoftwareSystemQueryVariables
>
export const ApprovalFeedDocument = gql`
	query approvalFeed($systemId: ID!, $quarter: Int!, $year: Int!) {
		approvalFeed(systemId: $systemId, quarter: $quarter, year: $year) {
			id
			createdAt
			createdByText
			isUserMessage
			actionMessage
			userMessage
			approvalRun {
				id
				tenant {
					id
					catalogName
					hexColor
				}
			}
		}
	}
`

/**
 * __useApprovalFeedQuery__
 *
 * To run a query within a React component, call `useApprovalFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalFeedQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      quarter: // value for 'quarter'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useApprovalFeedQuery(
	baseOptions: Apollo.QueryHookOptions<
		ApprovalFeedQuery,
		ApprovalFeedQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<ApprovalFeedQuery, ApprovalFeedQueryVariables>(
		ApprovalFeedDocument,
		options
	)
}
export function useApprovalFeedLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ApprovalFeedQuery,
		ApprovalFeedQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<ApprovalFeedQuery, ApprovalFeedQueryVariables>(
		ApprovalFeedDocument,
		options
	)
}
export type ApprovalFeedQueryHookResult = ReturnType<
	typeof useApprovalFeedQuery
>
export type ApprovalFeedLazyQueryHookResult = ReturnType<
	typeof useApprovalFeedLazyQuery
>
export type ApprovalFeedQueryResult = Apollo.QueryResult<
	ApprovalFeedQuery,
	ApprovalFeedQueryVariables
>
