import { Button, Layout, Menu } from 'antd'
import styled from 'styled-components'

const { Header } = Layout

export const MenuWrapper = styled.div`
	display: flex;
	align-items: center;
	.ant-menu-horizontal {
		border: none;
		.ant-menu-item-selected {
			border: none !important;
		}
	}
	.ant-typography {
		margin-bottom: unset;
	}
`
export const MenuButton = styled(Button)`
	box-shadow: unset;
	border: unset;
	padding: 0;
	:hover {
		background-color: transparent;
		border: unset;
		padding: 0;
	}
	.ant-button {
		:hover {
			background-color: red;
			background: red;
		}
	}
`

export const HorizontalMenu = styled(Menu)`
	color: ${({ theme }) => theme.white};
	background: ${({ theme }) => theme.primary};
	border-bottom: unset;
	justify-content: center;
	flex: 1;
	.ant-menu-horizontal,
	.ant-menu-item::after,
	.ant-menu-submenu::after {
		border: none !important;
	}
	.ant-menu-item {
		font-weight: 700;
		font-family: SegoeUI, sans-serif;
		&:hover {
			background-color: ${({ theme }) => theme.white};
		}
		&.ant-menu-item-selected {
			background-color: ${({ theme }) => theme.white};
		}
	}
`

export const CustomHeader = styled(Header)`
	display: flex;
	align-items: center;
	position: fixed;
	z-index: 2;
	width: 100%;
	padding: 0 16px;
`
