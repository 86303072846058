export default {
	searchPlaceholder: 'Meldungen durchsuchen',
	showCatalogue: 'Release öffnen',
	showApproval: 'Zulassung öffnen',
	noResults: 'keine Meldungen vorhanden',
	allNotifications: 'Alle Meldungen',
	menu: {
		showAll: 'Allgemein'
	},
	notificationsItem: {
		logoAlt: 'Firmenlogo von {{tenant}}'
	}
}
