import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { SELECTIVE_CONTRACT_DEFINITION_CHANGES } from 'modules/core/queries'
import { LoadingSpinner } from 'components'
import SelectiveContractDefinitionsComponent from '../components/SelectiveContractsDefinitions'
import { ContractsFilterContext } from './CatalogPage'
import * as PropTypes from 'prop-types'

const SelectiveContractDefinitions = ({ comparedRelease, currentRelease }) => {
	const { selectedContractIdsList } = useContext(ContractsFilterContext)
	const { data, loading } = useQuery(SELECTIVE_CONTRACT_DEFINITION_CHANGES, {
		variables: {
			currentReleaseId: currentRelease && currentRelease.id,
			comparedReleaseId: comparedRelease && comparedRelease.id,
			selectedContracts: selectedContractIdsList
		},
		fetchPolicy: 'cache-and-network'
	})

	if (loading) return <LoadingSpinner />
	return (
		<>
			<SelectiveContractDefinitionsComponent
				selectiveContractDefinitionChanges={
					data.releaseContractChanges.releaseContractsDiff
				}
			/>
		</>
	)
}
SelectiveContractDefinitions.propTypes = {
	comparedRelease: PropTypes.shape({}).isRequired,
	currentRelease: PropTypes.shape({}).isRequired
}

export default SelectiveContractDefinitions
