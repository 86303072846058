import React from 'react'
import { AkaTypography, LoadingSpinner } from '../../../components'
import { DIFF } from '../../core/constants'
import { Col, Row } from 'antd'
import ReactHtmlParser from 'react-html-parser'
import { ChapterWrapper as StylingWrapper } from '../components/styles'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

const styles = {
	col: {
		marginTop: 20,
		marginBottom: 4
	},
	title: {
		marginTop: 32
	}
}

const getColor = type => {
	switch (type) {
		case DIFF.CHANGED:
			return 'orange'
		case DIFF.NEW:
			return 'insContent'
		case DIFF.DELETED:
			return 'delContent'
		default:
			return 'default'
	}
}

const ChangesList = ({ list, loading, title, type }) => {
	const [t] = useTranslation()
	return (
		<StylingWrapper>
			<AkaTypography
				style={styles.title}
				data-testid={`title`}
				variant="h2"
				color={getColor(type)}
			>
				{title}
			</AkaTypography>
			{loading && <LoadingSpinner />}
			{!loading && list.length === 0 && (
				<AkaTypography variant={'body3'}>
					{t('catalog.changes.noChanges', { type: 'Änderungen' })}
				</AkaTypography>
			)}
			{!loading &&
				list.map(
					({
						prefixedCaliberId,
						title,
						newRegionContractAssignments = [],
						deletedRegionContractAssignments = []
					}) => (
						<div key={prefixedCaliberId}>
							<div>
								<Row>
									<Col span={4}>
										<AkaTypography
											data-testid={`caliber-${prefixedCaliberId}`}
											variant={'body2'}
											style={styles.col}
										>
											{prefixedCaliberId}
										</AkaTypography>
									</Col>
									<Col span={18}>
										<AkaTypography
											data-testid={`title-${prefixedCaliberId}`}
											variant={'body2'}
											style={styles.col}
										>
											{ReactHtmlParser(title)}
										</AkaTypography>
									</Col>
								</Row>
							</div>
							{newRegionContractAssignments.length > 0 && (
								<Row>
									{newRegionContractAssignments.map(item => (
										<Col key={item} span={10}>
											<ins>{item}</ins>
										</Col>
									))}
								</Row>
							)}
							{deletedRegionContractAssignments.length > 0 && (
								<Row>
									{deletedRegionContractAssignments.map(item => (
										<Col key={item} span={10}>
											<del>{item}</del>
										</Col>
									))}
								</Row>
							)}
						</div>
					)
				)}
		</StylingWrapper>
	)
}

ChangesList.propTypes = {
	list: PropTypes.array,
	loading: PropTypes.bool,
	title: PropTypes.string,
	type: PropTypes.number.isRequired
}

ChangesList.defaultProps = {
	list: [],
	loading: false,
	title: ''
}

export default ChangesList
