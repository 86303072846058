import React, { useCallback } from 'react'
import * as PropTypes from 'prop-types'
import { AkaTypography } from '..'
import { useTranslation } from 'react-i18next'
const styles = {
	Wrapper: { textAlign: 'center', margin: '100px auto' }
}
const Errors = {
	401: 'Sie sind nicht authentifiziert',
	403: 'Sie sind nicht berechtigt',
	406: 'Einige erforderliche Informationen nicht bereitgestellt'
}
const ErrorHandler = ({
	children,
	error,
	errorMessage,
	loading,
	readableErrorMessage
}) => {
	const [t] = useTranslation()
	const getErrorMessage = useCallback(() => {
		if (errorMessage) return errorMessage
		if (readableErrorMessage && error) return readableErrorMessage
		else if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
			const regex = /.*matching query does not exist\.?$/gi
			if (regex.test(error.graphQLErrors[0].message)) {
				return t('core.notFoundError')
			} else if (
				error.graphQLErrors[0].status &&
				Errors[error.graphQLErrors[0].status]
			) {
				return Errors[error.graphQLErrors[0].status]
			} else
				return t(
					`core.${error.graphQLErrors[0].message}`,
					t('core.generalError')
				)
		} else return t('core.generalError')
	}, [errorMessage, error, readableErrorMessage, t])

	if ((error || errorMessage) && !loading)
		return (
			<div style={styles.Wrapper}>
				<AkaTypography variant="h3" color="error" data-testid="error-message">
					{getErrorMessage()}
				</AkaTypography>
			</div>
		)
	return <>{children}</>
}

ErrorHandler.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	error: PropTypes.shape({}),
	errorMessage: PropTypes.string,
	readableErrorMessage: PropTypes.string,
	loading: PropTypes.bool
}

ErrorHandler.defaultProps = {
	error: undefined,
	errorMessage: '',
	readableErrorMessage: '',
	loading: true
}

export default ErrorHandler
