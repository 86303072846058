import React, { useCallback, useMemo, useState } from 'react'
import { Checkbox, Col, Modal, Row, Form } from 'antd'
import { AkaButton, AkaTypography } from 'components'
import { useTranslation } from 'react-i18next'
import { useUser } from 'providers'
import {
	ATTACHMENT_OPTIONS,
	DOWNLOAD_FILES_OPTIONS,
	DOWNLOAD_FILES_OPTIONS_VALUES
} from 'modules/core/constants'
import * as PropTypes from 'prop-types'

const styles = {
	checkboxGroup: {
		width: '100%'
	},
	formItem: {
		marginBottom: 0,
		fontFamily: 'SegoeUI, sans-serif',
		fontWeight: 400
	},
	attachmentOptions: { padding: '0 24px' },
	noMargin: { margin: 0 }
}

const DownloadDialog = ({
	handleSubmit,
	handleClose,
	sending,
	open,
	release
}) => {
	const [t] = useTranslation()
	const { user } = useUser()

	const cleanedDownloadFileOptions = useMemo(
		() =>
			DOWNLOAD_FILES_OPTIONS_VALUES.reduce((accum, current) => {
				if (current === DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES) {
					return accum
				}
				if (
					!release.isAttachmentsDirExist &&
					current === DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
				) {
					return accum
				}
				if (!user.isSuperuser) {
					if (
						user.hasSelectedContracts &&
						(current === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
							current === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE)
					) {
						return accum
					}
					if (
						user.isKasseUser &&
						(current === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE ||
							current === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
							current === DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES)
					) {
						return accum
					} else if (
						user.isVswUser &&
						(current ===
							DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES ||
							current ===
								DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES)
					) {
						return accum
					}
				}
				return [...accum, current]
			}, []),
		[release, user]
	)

	const [checkedList, setCheckedList] = useState({
		checked: [...cleanedDownloadFileOptions]
	})

	const [canSelectedAttachmentOption, setCanSelectedAttachmentOption] =
		useState(true)

	const validateCheckedList = checkedList => {
		if (checkedList.length === 0) {
			return {
				validateStatus: 'error',
				errorMsg: t('catalog.downloadDialog.errorMessage')
			}
		}
		return {
			validateStatus: 'success',
			errorMsg: null
		}
	}

	const options = [
		{
			label: t('catalog.downloadDialog.options.pdf'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_AKA_PDF
		},
		{
			label: t('catalog.downloadDialog.options.revisionHistory'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_REVISION_HISTORY_FILE
		},
		{
			label: t('catalog.downloadDialog.options.baseFile'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE
		},
		{
			label: t('catalog.downloadDialog.options.svd.xml'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES
		},
		{
			label: t('catalog.downloadDialog.options.attachments.label'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
		},
		{
			label: t('catalog.downloadDialog.options.helpFiles'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES
		},
		{
			label: t('catalog.downloadDialog.options.svd.pdf'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_PDF_FILES
		},
		{
			label: t('catalog.downloadDialog.options.inspectionDocumentXml'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES
		},
		{
			label: t('catalog.downloadDialog.options.inspectionDocumentCsv'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES
		}
	]

	const onChange = checkedList => {
		const attachmentSelected = !!checkedList.find(
			item => item === DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
		)
		setCanSelectedAttachmentOption(attachmentSelected)
		setCheckedList({
			...validateCheckedList(checkedList),
			checked: checkedList
		})
		setCanSelectedAttachmentOption(attachmentSelected)
	}

	const renderAttachmentOptions = useMemo(
		() => (
			<div style={styles.attachmentOptions} data-testid="attachment-options">
				{release && release.isAttachmentsDirExist && (
					<Checkbox
						value={DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES}
						data-testid={DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES}
					>
						{t(
							'catalog.downloadDialog.options.attachments.options.includePcxFiles'
						)}
					</Checkbox>
				)}
			</div>
		),
		[release, t] // eslint-disable-line
	)

	const onSubmit = () => {
		return handleSubmit(checkedList.checked, ATTACHMENT_OPTIONS.ALL_ATTACHMENTS)
	}

	const hideOption = useCallback(
		value =>
			(release &&
				!release.isAttachmentsDirExist &&
				value === DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS) ||
			(!user.isSuperuser &&
				((user.isKasseUser &&
					(value === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
						value === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE ||
						value === DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES)) ||
					(user.isVswUser &&
						(value ===
							DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES ||
							value ===
								DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES)) ||
					(user.hasSelectedContracts &&
						(value === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
							value === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE)))),
		[user, release]
	)

	return (
		<Modal
			title={
				<AkaTypography
					gutterbottom={0}
					variant={'bodySegoe2'}
					style={styles.noMargin}
				>
					{t('catalog.downloadDialog.title')}
				</AkaTypography>
			}
			open={open}
			onCancel={handleClose}
			width={600}
			footer={[
				<AkaButton
					key="submit"
					loading={sending}
					onClick={onSubmit}
					type="primary"
					shape="round"
					data-testid="download-button"
					disabled={checkedList.errorMsg}
				>
					{t('catalog.downloadDialog.sendButton')}
				</AkaButton>
			]}
		>
			<Form data-testid="download-modal">
				<Form.Item
					validateStatus={checkedList.validateStatus}
					help={checkedList.errorMsg}
					style={styles.formItem}
				>
					<Checkbox.Group
						value={checkedList.checked}
						onChange={onChange}
						style={styles.checkboxGroup}
					>
						{options.map(
							option =>
								!hideOption(option.value) && (
									<Row key={option.value}>
										<Col span={option.extraInfo ? 10 : null}>
											<Checkbox value={option.value} data-testid={option.value}>
												{option.label}
												{option.value ===
													DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS &&
													canSelectedAttachmentOption &&
													renderAttachmentOptions}
											</Checkbox>
										</Col>
									</Row>
								)
						)}
					</Checkbox.Group>
				</Form.Item>
			</Form>
		</Modal>
	)
}

DownloadDialog.propTypes = {
	release: PropTypes.shape({}).isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	open: PropTypes.bool,
	sending: PropTypes.bool
}

DownloadDialog.defaultProps = {
	open: false,
	sending: false
}
export default DownloadDialog
