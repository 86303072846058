import React from 'react'
import * as PropTypes from 'prop-types'
import { ErrorHandler } from 'components'
import AttachmentList from './AttachmentsList'
import { Layout } from 'antd'
import { ATTACHMENTS_INIT_FILTERS } from 'modules/core/constants'

const styles = {
	wrapper: {
		padding: '19px 0 90px',
		overflowX: 'auto'
	},
	filtersWrapper: {
		display: 'flex',
		justifyContent: 'left',
		marginBottom: 16,
		padding: 8
	},
	selectWrapper: { display: 'flex', alignItems: 'baseLine', width: '33%' },
	select: { minWidth: 200 }
}

const Attachments = ({
	releaseId,
	comparedReleaseId,
	attachmentObjectTypes,
	searchQuery,
	filters,
	done,
	setFilters,
	error,
	...other
}) => {
	return (
		<ErrorHandler
			error={error.attachmentObjectTypesError || error.attachmentTypesError}
		>
			<Layout style={styles.wrapper}>
				<>
					{done && (
						<AttachmentList
							search={searchQuery}
							filters={filters}
							setFilters={setFilters}
							releaseId={releaseId}
							comparedReleaseId={comparedReleaseId}
							attachmentObjectTypes={attachmentObjectTypes}
							{...other}
						/>
					)}
				</>
			</Layout>
		</ErrorHandler>
	)
}

Attachments.propTypes = {
	releaseId: PropTypes.string.isRequired,
	comparedReleaseId: PropTypes.string,
	attachmentObjectTypes: PropTypes.array,
	searchQuery: PropTypes.string,
	filters: PropTypes.shape({}),
	done: PropTypes.bool,
	setFilters: PropTypes.func,
	error: PropTypes.shape({})
}

Attachments.defaultProps = {
	comparedReleaseId: undefined,
	attachmentObjectTypes: [],
	searchQuery: undefined,
	filters: ATTACHMENTS_INIT_FILTERS,
	done: true,
	setFilters: () => {},
	error: {
		attachmentTypesError: undefined,
		attachmentObjectTypesError: undefined
	}
}

export default Attachments
