import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Login from '../components/Login'
import { useUser } from 'providers'
import { AkaTypography } from 'components'
import { Layout } from 'antd'
import logo from 'img/AKA_portal_logo.svg'
import { LOGIN_HEADER_HEIGHT, HEADER_HEIGHT } from 'modules/core/constants'

const { Header, Content } = Layout

const validationSchema = t =>
	Yup.object().shape({
		email: Yup.string()
			.email(t('auth.validations.email'))
			.required(t('auth.emailRequired')),
		password: Yup.string().required(t('auth.passwordRequired'))
	})

const styles = {
	header: {
		width: '100%',
		paddingBottom: 32,
		height: LOGIN_HEADER_HEIGHT,
		minHeight: 120,
		display: 'flex',
		justifyContent: 'center',
		logo: {
			alignSelf: 'flex-end'
		}
	},
	content: {
		flexDirection: 'column',
		display: 'flex',
		maxWidth: 300,
		margin: 'auto',
		alignItems: 'center',
		accountLink: {
			textDecoration: 'underline'
		}
	},
	marginTop: {
		marginTop: HEADER_HEIGHT
	}
}

const LoginPage = () => {
	const [t] = useTranslation()
	const {
		user,
		login,
		error,
		loadingAuth: loading,
		loadingVerify,
		isAuthenticated,
		redirect
	} = useUser()

	useEffect(() => {
		if (user && isAuthenticated && !loadingVerify && !loading) {
			redirect()
		}
	}, [user, redirect, loadingVerify, loading, isAuthenticated])

	const handleSubmit = variables => {
		login(variables)
	}

	if (user && isAuthenticated && !loadingVerify && !loading)
		return (
			<AkaTypography variant="bodySegoe2">
				You are already logged in
			</AkaTypography>
		)

	return (
		<Layout>
			<Header style={styles.header}>
				<img style={styles.header.logo} src={logo} width={300} alt="logo" />
			</Header>
			<Content style={styles.content}>
				<Login
					validationSchema={validationSchema(t)}
					onSubmit={handleSubmit}
					loading={loading}
					error={error}
				/>
				<a
					style={styles.content.accountLink}
					href="mailto:aka-portal@hzv.de?subject=Neuer%20Benutzer"
				>
					<AkaTypography variant={'bodySegoe'} color={'primary'}>
						{t('auth.requestAccount')}
					</AkaTypography>
				</a>
				<a
					style={styles.content.accountLink}
					href="mailto:aka-portal@hzv.de?subject=Passwort%20vergessen"
				>
					<AkaTypography
						gutterbottom={0}
						variant={'bodySegoe'}
						color={'primary'}
					>
						{t('auth.passwordForget')}
					</AkaTypography>
				</a>
				<AkaTypography
					gutterbottom={0}
					style={styles.marginTop}
					variant={'bodySegoe'}
				>
					{t('auth.footer.company')}
				</AkaTypography>
				<a
					style={styles.content.accountLink}
					href="https://www.haevg-rz.de/impressum/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<AkaTypography variant={'bodySegoe'} color={'primary'}>
						{t('auth.footer.impressum')}
					</AkaTypography>
				</a>
			</Content>
		</Layout>
	)
}

export default LoginPage
