import { TFunction, useTranslation } from 'react-i18next'
import moment from 'moment'
import React, { useState } from 'react'
import {
	Card,
	CardSubSection,
	AkaButton,
	HeadSection,
	AkaTypography
} from 'components'
import theme from 'components/themeProvider/theme'
import { useFormik } from 'formik'
import { Input, Typography, Form } from 'antd'
import * as Yup from 'yup'
import { ApolloError } from 'apollo-client'
import { StylesDictionary } from 'components/typography/styles'
import { ExecutionResult } from 'graphql'
import { SoftwareSystemType } from 'generated/types'

moment.locale('de')

const FormItem = Form.Item
const { Paragraph } = Typography

const styles: StylesDictionary = {
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 16
	},
	infoRow: {
		display: 'flex',
		justifyContent: 'start'
	},
	infoItem: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 200
	},
	topBorder: {
		borderTop: `1px solid ${theme.gray}`
	},
	title: {
		fontWeight: 'bold'
	},
	formItem: {
		maxWidth: 300
	},
	buttonSection: { display: 'flex', justifyContent: 'space-between' }
}

const columns = [
	{
		title: 'UNTERNEHMEN',
		dataIndex: 'manufacturerName',
		key: 'manufacturerName'
	},
	{
		title: 'TEILNAHME ZULASSUNG',
		dataIndex: 'isParticipating',
		key: 'isParticipating'
	}
]

const validationSchema = (t: TFunction) =>
	Yup.object().shape({
		slogan: Yup.string().max(
			255,
			t('account.systemList.editForm.validation.maxLength')
		),
		website: Yup.string()
			.url(t('account.systemList.editForm.validation.website'))
			.max(255, t('account.systemList.editForm.validation.maxLength'))
	})

const DisplaySystemInfo = ({
	header,
	data
}: {
	header: { title: string; dataIndex: string; key: string }[]
	data: string[]
}) => {
	return (
		<div style={styles.infoRow}>
			{data.map((dataPoint, i) => {
				return (
					<div style={styles.infoItem} key={i}>
						<AkaTypography style={styles.title} variant="body2">
							{header[i].title}
						</AkaTypography>
						<AkaTypography variant="body2">{dataPoint}</AkaTypography>
					</div>
				)
			})}
		</div>
	)
}

export interface SystemCard {
	item: SoftwareSystemType
	onSubmit: (
		systemId: string,
		slogan: string,
		website: string
	) => Promise<ExecutionResult>
	loading: boolean
	error: ApolloError | undefined
}

export const SystemCard = ({ item, onSubmit, loading, error }: SystemCard) => {
	const [t] = useTranslation()
	const [isEdit, setIsEdit] = useState(false)

	const {
		handleSubmit,
		handleChange,
		handleBlur,
		values,
		errors,
		touched,
		isValid
	} = useFormik({
		initialValues: {
			slogan: item.slogan,
			website: item.website
		},
		onSubmit: ({ slogan, website }) => {
			onSubmit(item.id, slogan, website).then(() => {
				!error && setIsEdit(false)
			})
		},
		validationSchema: validationSchema(t)
	})

	const formContent = (
		<>
			<CardSubSection>
				<HeadSection id={item.id} title={item.systemName} tags={[]} />
				{isEdit ? (
					<FormItem
						style={styles.formItem}
						hasFeedback={true}
						validateStatus={touched.slogan && errors.slogan ? 'error' : ''}
						help={touched.slogan && errors.slogan ? errors.slogan : ''}
					>
						<Input
							id="slogan"
							name="slogan"
							type="text"
							placeholder={t('account.systemList.editForm.placeholders.slogan')}
							onChange={handleChange}
							value={values.slogan}
							onBlur={handleBlur}
						/>
					</FormItem>
				) : (
					<AkaTypography
						style={{ marginBottom: 24, height: 32 }}
						variant="body2"
					>
						{item.slogan}
					</AkaTypography>
				)}
			</CardSubSection>

			<CardSubSection gray={true}>
				<DisplaySystemInfo
					header={columns}
					data={[item.manufacturerName, item.isParticipating ? 'Ja' : 'Nein']}
				/>
				<div style={styles.infoRow}>
					<div style={styles.infoItem}>
						<AkaTypography style={styles.title} variant="body2">
							{t('account.systemList.labels.website')}
						</AkaTypography>
						{isEdit ? (
							<FormItem
								style={styles.formItem}
								hasFeedback={true}
								validateStatus={
									touched.website && errors.website ? 'error' : ''
								}
								help={touched.website && errors.website}
							>
								<Input
									id="website"
									name="website"
									type="text"
									placeholder={t(
										'account.systemList.editForm.placeholders.website'
									)}
									onChange={handleChange}
									value={values.website}
									onBlur={handleBlur}
								/>
							</FormItem>
						) : (
							<a href={item.website} target="_blank" rel="noopener noreferrer">
								<AkaTypography
									style={{ marginBottom: 24, height: 32 }}
									variant={'body2'}
									color={'primary'}
								>
									{item.website}
								</AkaTypography>
							</a>
						)}
					</div>
				</div>
			</CardSubSection>
			<CardSubSection gray={true} style={error && styles.buttonSection}>
				{error && <Paragraph type="danger">{t('core.generalError')}</Paragraph>}
				<div style={styles.buttonWrapper}>
					{!isEdit ? (
						<AkaButton type="primary" onClick={() => setIsEdit(true)}>
							{t('account.systemList.editForm.edit')}
						</AkaButton>
					) : (
						<AkaButton
							key="submit"
							type="primary"
							htmlType="submit"
							disabled={!isValid}
							loading={loading}
							onSubmit={handleSubmit}
						>
							{t('account.systemList.editForm.save')}
						</AkaButton>
					)}
				</div>
			</CardSubSection>
		</>
	)
	return (
		<Card style={styles.topBorder}>
			{isEdit ? (
				<form onSubmit={handleSubmit}>{formContent}</form>
			) : (
				formContent
			)}
		</Card>
	)
}
