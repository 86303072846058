import React, { memo } from 'react'
import { CardSubSectionWrapper } from './styles'
import { CSSProperties } from 'styled-components'

export interface CardSubSection {
	children: React.ReactNode
	id?: string
	gray?: boolean
	style?: CSSProperties
	testId?: string
}

const CardSubSection = memo(
	({ children, gray, style, testId, id }: CardSubSection) => {
		return (
			<CardSubSectionWrapper
				id={id}
				gray={gray}
				style={style}
				data-testid={testId}
			>
				{children}
			</CardSubSectionWrapper>
		)
	}
)

export default CardSubSection
