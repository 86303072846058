import React from 'react'
import { Col, Row } from 'antd'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { AkaTypography } from 'components'
import { VERSIONS, QUARTERS } from 'modules/core/constants'

const DisplayRelease = (title, release) => {
	const [t] = useTranslation()
	return (
		<Col span={12}>
			<Row>
				<AkaTypography data-testid={`title-${release.id}`} variant={'h4'}>
					{title}
				</AkaTypography>
			</Row>
			<Row>
				<AkaTypography data-testid={`release-${release.id}`} variant={'body'}>
					{release.name}
				</AkaTypography>
			</Row>
			<Row>
				<AkaTypography
					data-testid={`year-${release.id}`}
					variant={'body'}
				>{`${t('catalog.changes.overview.year')}: ${
					release.year
				}`}</AkaTypography>
			</Row>
			<Row>
				<AkaTypography variant={'body'}>{`${t(
					'catalog.changes.overview.quarter'
				)}: ${QUARTERS[release.quarter]}`}</AkaTypography>
			</Row>
			<Row>
				<AkaTypography variant={'body'}>{`${t(
					'catalog.changes.overview.release'
				)}: ${VERSIONS[release.version]}`}</AkaTypography>
			</Row>
		</Col>
	)
}

const ReleaseInfo = ({ current, compared }) => {
	const [t] = useTranslation()

	return (
		<Row type="flex" justify="space-between" align="bottom">
			{current &&
				DisplayRelease(t('catalog.changes.overview.version.current'), current)}
			{compared.id &&
				DisplayRelease(
					t('catalog.changes.overview.version.compared'),
					compared
				)}
		</Row>
	)
}

DisplayRelease.propTypes = {
	current: PropTypes.shape({}).isRequired,
	compared: PropTypes.shape({}).isRequired
}

export default ReleaseInfo
