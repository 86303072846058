import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoginWrapper, Headline } from './styles'
import { Formik } from 'formik'
import { Input, Typography, Form } from 'antd'
import { AkaTypography, AkaButton } from 'components'

const FormItem = Form.Item
const { Paragraph } = Typography

const styles = {
	loginButton: { width: '100%' },
	loginError: { textAlign: 'center' },
	gutterbottom: { marginBottom: 16 }
}
const Login = memo(({ error, loading, onSubmit, validationSchema }) => {
	const [t] = useTranslation()

	return (
		<LoginWrapper>
			<Headline>
				<AkaTypography variant="bodySegoe">
					{t('auth.loginHeadline')}
				</AkaTypography>
			</Headline>
			<Formik
				validationSchema={validationSchema}
				initialValues={{ email: '', password: '' }}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit
				}) => (
					<Form
						layout="vertical"
						data-testid="login-form"
						style={{ fontFamily: 'SegoeUI, sans-serif' }}
					>
						<FormItem
							style={styles.inputEmail}
							hasFeedback={true}
							validateStatus={touched.email && errors.email && 'error'}
							help={touched.email && errors.email}
						>
							<Input
								name="email"
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								data-testid="login-email"
								placeholder={t('auth.email')}
							/>
						</FormItem>
						<FormItem
							hasFeedback={true}
							validateStatus={touched.password && errors.password && 'error'}
							help={touched.password && errors.password}
						>
							<Input.Password
								name="password"
								value={values.password}
								onChange={handleChange}
								onBlur={handleBlur}
								data-testid="login-password"
								placeholder={t('auth.password')}
							/>
						</FormItem>
						<FormItem
							data-testid="login-test-button"
							style={styles.gutterbottom}
						>
							<AkaButton
								data-testid="login-submit-button"
								loading={loading}
								style={styles.loginButton}
								type="primary"
								htmlType="submit"
								onClick={handleSubmit}
							>
								{t('auth.login')}
							</AkaButton>
						</FormItem>
						{error && (
							<Paragraph
								data-testid="login-response-error"
								type="danger"
								style={styles.loginError}
							>
								{t('auth.invalidCredentials')}
							</Paragraph>
						)}
					</Form>
				)}
			</Formik>
		</LoginWrapper>
	)
})

Login.propTypes = {
	error: PropTypes.shape({}),
	loading: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	validationSchema: PropTypes.shape({})
}

Login.defaultProps = {
	error: undefined,
	loading: false,
	validationSchema: undefined
}

export default Login
