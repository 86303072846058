import React, { memo } from 'react'
import { Table as AntdTable } from 'antd'

const Table = memo(({ columns, dataSource, rowSelection, ...other }) => {
	return (
		<AntdTable
			columns={columns}
			dataSource={dataSource}
			rowSelection={rowSelection}
			{...other}
			scroll={{ x: 'max-content' }}
		/>
	)
})

export default Table
