import React from 'react'
import { AkaTypography } from 'components'
import * as PropTypes from 'prop-types'
import theme from '../themeProvider/theme'

const styles = {
	headlineBar: {
		height: 40,
		background: theme.white,
		position: 'absolute',
		width: 880
	}
}
const HeadlineBar = ({ headline, testId, height }) => (
	<div style={{ height: height }}>
		<div style={styles.headlineBar}>
			<AkaTypography data-testid={testId} variant="h1">
				{headline}
			</AkaTypography>
		</div>
	</div>
)
HeadlineBar.propTypes = {
	headline: PropTypes.string.isRequired,
	testId: PropTypes.string,
	height: PropTypes.number
}

HeadlineBar.defaultProps = {
	testId: 'headline-text',
	height: 90
}
export default HeadlineBar
