import React from 'react'
import { CustomSubHeader } from './styles'
import { SUB_HEADER_HEIGHT } from 'modules/core/constants'
import deprecated from '../deprecated'

const styles = {
	wrapper: { height: SUB_HEADER_HEIGHT }
}

const SubHeader = ({ children, zindex = 2, style }) => (
	<div style={styles.wrapper}>
		<CustomSubHeader zindex={zindex} style={style}>
			{children}
		</CustomSubHeader>
	</div>
)

export default deprecated(SubHeader, 'deprecated component')
