export const COLORS = {
	BLACK: 'black',
	DEFAULT: 'default',
	ERROR: 'error',
	SUCCESS: 'success',
	INHERIT: 'inherit',
	PRIMARY: 'primary',
	PRIMARYLIGHT: 'primaryLight',
	SECONDARY: 'secondary',
	ORANGE: 'orange',
	WHITE: 'white',
	GREEN: 'green',
	RED: 'red',
	YELLOW: 'yellow',
	GRAY: 'gray',
	DELCONTENT: 'delContent',
	INSCONTENT: 'insContent'
}
