import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import de from 'antd/es/locale/de_DE'
import i18n from './configs/i18next' // eslint-disable-line
import { ConfigProvider } from 'antd'
import * as Sentry from '@sentry/react'
import './App.css'
import AppRouter from 'modules/navigation/AppRouter'
import { ThemeProvider, ScrollToTop } from './components'
import { UserProvider } from 'providers'
import { apolloClient } from 'configs/apolloClient'
import './configs/antd.config'
import { createGlobalStyle } from 'styled-components'
import { MainFallback } from './components/errorHandler/MainFallback'
import { AntdMessageProvider } from 'providers/antdMessageProvider'

const GlobalStyles = createGlobalStyle`
	@font-face {
			font-family: 'SegoeUI';
			src:
				local("Segoe UI"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
			font-weight: 400;
	}
	@font-face {
    font-family: SegoeUI Bold;
    src:
        local("Segoe UI Bold"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
    font-weight: 700;
}
`

function App() {
	return (
		<BrowserRouter>
			<ApolloProvider client={apolloClient}>
				<ConfigProvider locale={de}>
					<ThemeProvider>
						<Sentry.ErrorBoundary fallback={<MainFallback />}>
							<UserProvider>
								<ScrollToTop />
								<GlobalStyles />
								<AntdMessageProvider>
									<AppRouter />
								</AntdMessageProvider>
							</UserProvider>
						</Sentry.ErrorBoundary>
					</ThemeProvider>
				</ConfigProvider>
			</ApolloProvider>
		</BrowserRouter>
	)
}

export default App
