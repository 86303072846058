import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import Account from './Account'
import UserContracts from './UserContracts'
import PageLayout from 'modules/layout/containers/PageLayout'
import { ROUTES } from 'modules/navigation'
import { SoftwareSystemList } from './SoftwareSystemList/SoftwareSystemList'

const { Content } = Layout

const styles = {
	menuStyle: {
		borderRight: 0,
		paddingTop: 24,
		fontFamily: 'SegoeUI, Sans',
		fontWeight: 'bold'
	},
	contentWrapper: {
		padding: '24px 24px 24px 48px'
	}
}

const AccountPage = () => {
	const history = useHistory()
	const [t] = useTranslation()
	const [selectedMenu, setSelectedMenu] = useState('account')

	const navigations = [
		{ name: t('navigation.mainHeaderMenu.notifications'), key: ROUTES.home }
	]

	const navigationChangeHandler = e => {
		if (e.key !== ROUTES.account) {
			history.push(e.key)
		}
	}

	const menuItems = [
		{
			key: 'account',
			label: t('account.profile')
		},
		{
			key: 'myContracts',
			label: t('account.myContracts')
		},
		{
			key: 'systems',
			label: t('account.systems')
		}
	]

	const sideBarContent = (
		<Menu
			mode="inline"
			style={styles.menuStyle}
			defaultSelectedKeys={[selectedMenu]}
			onSelect={e => setSelectedMenu(e.key)}
			items={menuItems}
		/>
	)

	const mainContent = {
		account: (
			<Content style={styles.contentWrapper}>
				<Account />
			</Content>
		),
		myContracts: (
			<Content style={styles.contentWrapper}>
				<UserContracts />
			</Content>
		),
		systems: (
			<Content style={styles.contentWrapper}>
				<SoftwareSystemList />
			</Content>
		)
	}
	return (
		<PageLayout
			navigationItems={navigations}
			onNavigationChange={navigationChangeHandler}
			renderMainContent={mainContent[selectedMenu]}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default AccountPage
