import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import BurgerMenu from './Menu'
import logo from 'img/aka_portal_logo_white.svg'
import { Link } from 'react-router-dom'
import { HorizontalMenu, CustomHeader } from './styles'

const MainHeader = memo(
	({ navigationItems, navigationDefaultKey, handleSelect }) => {
		return (
			<CustomHeader>
				<Link to="/">
					<img src={logo} width={200} height={60} alt="logo" />
				</Link>
				<HorizontalMenu
					mode="horizontal"
					defaultSelectedKeys={[navigationDefaultKey]}
					onSelect={handleSelect}
					items={navigationItems.map(item => ({
						label: item.name,
						key: item.key
					}))}
				/>
				<BurgerMenu />
			</CustomHeader>
		)
	}
)

MainHeader.propTypes = {
	navigationItems: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, key: PropTypes.string })
	),
	navigationDefaultKey: PropTypes.string,
	handleSelect: PropTypes.func
}

MainHeader.defaultProps = {
	navigationItems: [],
	navigationDefaultKey: undefined
}

export default MainHeader
