/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'
import { Typography } from 'antd'
import { COLORS } from '../constants'
import { TitleProps } from 'antd/lib/typography/Title'
import { ParagraphProps } from 'antd/lib/typography/Paragraph'
import { CSSProperties } from 'react'

const { Title, Paragraph } = Typography

const getColor = (theme: any, color: any) => {
	switch (color) {
		case COLORS.SUCCESS:
			return theme.green
		case COLORS.ERROR:
			return theme.error
		case COLORS.INHERIT:
			return 'inherit'
		case COLORS.PRIMARY:
			return theme.primary
		case COLORS.SECONDARY:
			return theme.secondary
		case COLORS.WHITE:
			return theme.white
		case COLORS.GRAY:
			return theme.gray
		case COLORS.RED:
			return theme.red
		case COLORS.ORANGE:
			return theme.orange
		case COLORS.DELCONTENT:
			return theme.delContent
		case COLORS.INSCONTENT:
			return theme.insContent
		default:
			return theme.black
	}
}

export interface StylesDictionary {
	[Key: string]: CSSProperties
}

interface CustomTypographyProps {
	variant: string
	color: any
	gutterbottom: number | null
}

export const StyledTitle = styled(Title)<TitleProps & CustomTypographyProps>`
	${({ theme, variant }) => theme.typography[variant]};
	color: ${({ theme, color }) => getColor(theme, color)} !important;
	${({ gutterbottom }) =>
		gutterbottom !== null && `margin-bottom: ${gutterbottom}px !important`}
`

export const StyledParagraph = styled(Paragraph)<
	ParagraphProps & CustomTypographyProps
>`
	${({ theme, variant }) => theme.typography[variant]};
	color: ${({ theme, color }) => getColor(theme, color)} !important;
	${({ gutterbottom }: { gutterbottom: any }) =>
		gutterbottom !== null && `margin-bottom: ${gutterbottom}px !important`}
`
