import { SystemCostType } from 'generated/types'

export const initialTabelData: Array<SystemCostType> = [
	{
		key: 0,
		type: 'Setup',
		module: 'AIS-HZV/FAV-Modul',
		amount: '0',
		accounting: 'BSNR',
		note: ''
	},
	{
		key: 1,
		type: 'Setup',
		module: '1. HZV/FAV- Vertrag',
		amount: '0',
		accounting: 'BSNR',
		note: ''
	},
	{
		key: 2,
		type: 'Setup',
		module: 'Weiterer HZV/FAV- Vertrag',
		amount: '0',
		accounting: 'BSNR',
		note: ''
	},
	{
		key: 3,
		type: 'Pflege',
		module: '1. HZV/FAV- Vertrag',
		amount: '0',
		accounting: 'BSNR',
		note: ''
	},
	{
		key: 4,
		type: 'Pflege',
		module: 'Weiterer HZV/FAV- Vertrag',
		amount: '0',
		accounting: 'BSNR',
		note: ''
	},
	{
		key: 5,
		type: 'Pflege',
		module: 'HÄVG Prüfmodul',
		amount: '0',
		accounting: 'BSNR',
		note: ''
	}
]
