import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import { Select, Layout } from 'antd'
import { AkaTypography, LoadingSpinner, AkaButton } from 'components'
import { useQuery } from '@apollo/react-hooks'
import { COMPARE_RELEASES_LIST } from 'modules/core/queries'
import { useTranslation } from 'react-i18next'
import theme from 'components/themeProvider/theme'
import { ContractsFilterContext } from './CatalogPage'
import { useUser } from 'providers'
import { debounce } from 'hooks/helpers'

const { Option } = Select
const styles = {
	select: { width: '100%', fontFamily: 'SegoeUI, sans-serif' },
	wrapper: {
		background: theme.mainGray,
		display: 'flex',
		flexDirection: 'column',
		flex: '0 0 auto'
	},
	releaseSection: {
		padding: 16,
		titleWrapper: { display: 'flex' },
		compareRelease: { marginLeft: 8 }
	},
	contractsSection: {
		borderBottom: '1px solid lightgray',
		borderTop: '1px solid lightgray',
		padding: 16
	},
	options: { fontFamily: 'SegoeUI, sans-serif' }
}

const GlobalFilters = ({
	id,
	releaseName,
	onReleaseToCompareSelected,
	contractChoiceList,
	loadingContracts
}) => {
	const [t] = useTranslation()
	const {
		user: { hasSelectedContracts }
	} = useUser()
	const { selectedContractIdsList, updateContractList } = useContext(
		ContractsFilterContext
	)

	const { loading, data: { releasesToCompare = [] } = {} } = useQuery(
		COMPARE_RELEASES_LIST,
		{
			fetchPolicy: 'network-only',
			variables: { currentVersionId: id },
			onCompleted: ({ releasesToCompare }) => {
				if (releasesToCompare.length > 0)
					onReleaseToCompareSelected(releasesToCompare[0])
			}
		}
	)

	const handleSelected = id => {
		const release = releasesToCompare.reduce(
			(accum, current) => (current.id === id ? current : accum),
			undefined
		)
		onReleaseToCompareSelected(release)
	}

	const handleContractsUpdate = debounce(
		values => updateContractList(values),
		500
	)

	return (
		<Layout data-testid="global-filters-wrapper" style={styles.wrapper}>
			{loading && releasesToCompare && releasesToCompare.length === 0 && (
				<LoadingSpinner testId="loading-compare-releases" />
			)}
			{!loading &&
				selectedContractIdsList &&
				releasesToCompare &&
				releasesToCompare.length > 0 && (
					<div style={styles.releaseSection}>
						<div style={styles.releaseSection.titleWrapper}>
							<AkaTypography
								data-testid="release-name"
								variant="bodySegoe2"
								gutterbottom={6}
							>
								{releaseName}
							</AkaTypography>
							<AkaTypography
								variant="bodySegoe"
								style={styles.releaseSection.compareRelease}
								gutterbottom={6}
							>
								{t('catalog.compareRelease')}
							</AkaTypography>
						</div>
						<Select
							data-testid="release-select"
							dropdownMatchSelectWidth={false}
							defaultValue={releasesToCompare[0].id}
							style={styles.select}
							onChange={handleSelected}
						>
							{releasesToCompare.map(release => {
								return (
									<Option
										style={styles.options}
										key={release.id}
										value={release.id}
									>
										{release.name}
									</Option>
								)
							})}
						</Select>
					</div>
				)}
			{loadingContracts && <LoadingSpinner />}
			{contractChoiceList && !loadingContracts && (
				<div style={styles.contractsSection}>
					<span style={{ display: 'flex' }}>
						<AkaTypography variant="bodySegoe2" gutterbottom={5}>
							{t('catalog.filters.contracts')}
						</AkaTypography>
						{selectedContractIdsList.length !== contractChoiceList.length && (
							<AkaTypography
								variant="bodySegoe2"
								color={'primary'}
								gutterbottom={5}
								style={{ marginLeft: 2 }}
							>
								{t('catalog.filters.filtered')}
							</AkaTypography>
						)}
						<AkaTypography variant="bodySegoe2" gutterbottom={5}>
							:
						</AkaTypography>
					</span>

					<span>
						<Select
							data-testid="contract-select"
							showSearch={true}
							allowClear={true}
							maxTagCount={1}
							disabled={hasSelectedContracts || !contractChoiceList}
							defaultValue={contractChoiceList.map(item => item.contractId)}
							onChange={handleContractsUpdate}
							mode="multiple"
							style={{ marginBottom: 6, ...styles.select }}
							value={selectedContractIdsList}
						>
							{contractChoiceList.map(contract => (
								<Option
									style={styles.options}
									key={contract.contractId}
									value={contract.contractId}
								>
									{`${contract.identification}: ${contract.contractId}`}
								</Option>
							))}
						</Select>
					</span>
					<AkaButton
						data-testid="contract-select-all"
						disabled={hasSelectedContracts || !contractChoiceList}
						onClick={() =>
							handleContractsUpdate(
								contractChoiceList.map(item => item.contractId)
							)
						}
					>
						{t('catalog.filters.selectAll')}
					</AkaButton>
				</div>
			)}
		</Layout>
	)
}

GlobalFilters.propTypes = {
	releaseName: PropTypes.string,
	id: PropTypes.string.isRequired,
	onReleaseToCompareSelected: PropTypes.func,
	contractChoiceList: PropTypes.array,
	loadingContracts: PropTypes.bool
}

GlobalFilters.defaultProps = {
	releaseName: 'Release',
	onReleaseToCompareSelected: () => {},
	contractChoiceList: [],
	loadingContracts: true
}

export default GlobalFilters
