import React from 'react'
import { SideWrapper, CustomSearchBar as Search } from './styles'
import { Select, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { AkaTypography } from 'components'
import { ATTACHMENT_TYPE_KEY, CONTRACT_ID_KEY } from 'modules/core/constants'
import * as PropTypes from 'prop-types'
import { FILTER_OPTIONS } from 'modules/core/constants'

const styles = {
	typography: {
		padding: 0,
		margin: '8px 0px'
	},
	options: { fontFamily: 'SegoeUI, sans-serif' },
	radioButtons: {
		textAlign: 'center',
		fontFamily: 'SegoeUI Bold, sans-serif'
	}
}

const { Option } = Select

const AttachmentSider = ({
	attachmentsTypes,
	filters,
	done,
	handleSearch,
	handleContractChange,
	handleAttachmentTypeChange,
	selectedContractIdsList,
	releaseContracts,
	handleSelectFilter,
	activeFilterKey
}) => {
	const [t] = useTranslation()

	const filterrdReleaseContracts = releaseContracts.filter(contract =>
		selectedContractIdsList.includes(contract.contractId)
	)

	return (
		<>
			<SideWrapper gray>
				<Search
					placeholder="Suchen"
					onSearch={handleSearch}
					disabled={!done}
					allowClear={true}
				/>
			</SideWrapper>
			<SideWrapper>
				<Radio.Group
					defaultValue={activeFilterKey}
					buttonStyle="solid"
					onChange={handleSelectFilter}
					name="filter"
					style={styles.radioButtons}
				>
					<Radio.Button
						value={FILTER_OPTIONS.CONTRACT}
						style={{ width: '50%' }}
					>
						{t('catalog.filters.filterByContract')}
					</Radio.Button>
					<Radio.Button
						value={FILTER_OPTIONS.ATTACHMENT_TYPE}
						style={{ width: '50%' }}
					>
						{t('catalog.filters.filterByAttachment')}
					</Radio.Button>
				</Radio.Group>
				{activeFilterKey === FILTER_OPTIONS.CONTRACT && (
					<>
						<AkaTypography variant="bodySegoe2" style={styles.typography}>
							{`${t('catalog.filters.attachmentsByContract')}: `}
						</AkaTypography>
						<Select
							disabled={!done}
							value={filters[CONTRACT_ID_KEY]}
							onChange={handleContractChange}
						>
							{filterrdReleaseContracts.map(contract => (
								<Option
									key={contract.contractId}
									value={contract.contractId}
									style={styles.options}
								>
									{`${contract.identification}: ${contract.contractId}`}
								</Option>
							))}
						</Select>
					</>
				)}
				{activeFilterKey === FILTER_OPTIONS.ATTACHMENT_TYPE && (
					<>
						<AkaTypography variant="bodySegoe2" style={styles.typography}>
							{`${t('catalog.filters.attachmentsByType')}: `}
						</AkaTypography>
						<Select
							disabled={!done}
							value={filters[ATTACHMENT_TYPE_KEY]}
							onChange={handleAttachmentTypeChange}
						>
							{attachmentsTypes.map(attachmentsType => (
								<Option
									key={attachmentsType}
									value={attachmentsType}
									style={styles.options}
								>
									{attachmentsType}
								</Option>
							))}
						</Select>
					</>
				)}
			</SideWrapper>
		</>
	)
}

AttachmentSider.propTypes = {
	filters: PropTypes.shape({}),
	done: PropTypes.bool,
	selectedContractIdsList: PropTypes.array,
	handleSearch: PropTypes.func,
	attachmentsTypes: PropTypes.array,
	handleContractChange: PropTypes.func,
	handleAttachmentTypeChange: PropTypes.func
}

AttachmentSider.defaultProps = {
	filters: {},
	done: true,
	selectedContractIdsList: [],
	handleSearch: () => {},
	attachmentsTypes: [],
	handleContractChange: () => {},
	handleAttachmentTypeChange: () => {}
}

export default AttachmentSider
