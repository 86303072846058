import styled from 'styled-components'
import { LAYOUT_PADDING } from 'modules/core/constants'
import { CloudDownloadOutlined, CloseOutlined } from '@ant-design/icons'
import { Tree, Input } from 'antd'

const { Search } = Input

export const CustomCloudDownloadOutlined = styled(CloudDownloadOutlined)`
	color: ${({ theme }) => theme.white};
	font-size: 50px;
	background: ${({ theme }) => theme.primary};
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	border-radius: 50%;
	position: fixed;
	bottom: 0;
	margin-bottom: 30px;
`

export const CustomCloseOutlined = styled(CloseOutlined)`
	color: ${({ theme }) => theme.primary};
	font-size: 25px;
	background: ${({ theme }) => theme.grayLighter};
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	border-radius: 50%;
	position: fixed;
	bottom: 0;
	margin-bottom: 140px;
`

export const SideWrapper = styled.div`
	display: flex;
	align-items: left;
	flex-direction: column;
	background: ${({ theme, gray }) => gray && theme.mainGray};
	${({ gray }) => gray && 'border-bottom: 1px solid lightgray'};
	padding: ${LAYOUT_PADDING}px;
`

export const StyledTree = styled(Tree)`
	font-size: 14px;
	font-family: Arial, sans-serif;
	font-weight: 400;
	.ant-tree-treenode {
		padding: 4px 0;
		&:first-child {
			padding-top: 7px;
		}
		&:last-child {
			padding-bottom: 7px;
		}
	}

	.ant-tree-title {
		white-space: break-spaces;
	}
`

export const CustomSearchBar = styled(Search)`
	.ant-input-suffix {
		display: none;
	}
`
