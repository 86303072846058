import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { RELEASE_ATTACHMENT_CHANGES } from 'modules/core/queries'
import { LoadingSpinner } from 'components'
import AttachmentChangesComponent from '../components/AttachmentChanges'
import { ContractsFilterContext } from './CatalogPage'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useAntdMessageApi } from 'providers'

const AttachmentChanges = ({ comparedRelease, currentRelease }) => {
	const { selectedContractIdsList } = useContext(ContractsFilterContext)
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()
	const {
		data: {
			releaseAttachmentChanges: {
				deletedAttachments = [],
				newAttachments = [],
				changedAttachments = []
			} = {}
		} = {},
		loading
	} = useQuery(RELEASE_ATTACHMENT_CHANGES, {
		variables: {
			currentReleaseId: currentRelease && currentRelease.id,
			comparedReleaseId: comparedRelease && comparedRelease.id,
			selectedContracts: selectedContractIdsList
		}
	})

	useEffect(() => {
		if (loading) {
			messageApi?.open({
				type: 'success',
				content: t('core.downloadReleaseRequirementChanges')
			})
		}
	}, [loading, t, messageApi])

	if (loading) return <LoadingSpinner />
	return (
		<>
			<AttachmentChangesComponent
				changedAttachments={changedAttachments}
				deletedAttachments={deletedAttachments}
				newAttachments={newAttachments}
			/>
		</>
	)
}

AttachmentChanges.propTypes = {
	comparedRelease: PropTypes.shape({}).isRequired,
	currentRelease: PropTypes.shape({}).isRequired
}

export default AttachmentChanges
