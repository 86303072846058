import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { ErrorHandler, HeadlineBar, LoadingSpinner } from '../../../components'
import ChapterDetailsComponent from './ChapterDetails'

const Chapter = ({
	loading,
	error,
	chapter,
	releaseLoading,
	comparedReleaseId,
	isFirstChapter
}) => {
	const [headline, setHeadline] = useState('loading')

	const getChapterHeadline = chapter => {
		if (chapter.parent) {
			setHeadline(chapter.parent.fullTitle)
		} else {
			setHeadline(chapter.fullTitle)
		}
	}
	useEffect(() => {
		if (chapter) getChapterHeadline(chapter)
	}, [chapter])

	useEffect(() => {
		if (chapter) {
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		}
	}, [chapter])
	if (loading || releaseLoading || !chapter)
		return <LoadingSpinner fullPage testId="chapter-details-loading" />

	return (
		<ErrorHandler loading={loading} error={error}>
			{!isFirstChapter && (
				<HeadlineBar testId="chapter-headline" headline={headline} />
			)}
			<ChapterDetailsComponent
				isFirstChapter={isFirstChapter}
				chapter={chapter}
				comparedReleaseId={comparedReleaseId}
			/>
		</ErrorHandler>
	)
}

Chapter.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.shape({}),
	chapter: PropTypes.shape({}),
	releaseLoading: PropTypes.bool,
	comparedReleaseId: PropTypes.string
}

Chapter.defaultProps = {
	loading: true,
	error: undefined,
	chapter: undefined,
	releaseLoading: true,
	comparedReleaseId: undefined
}

export default Chapter
