import { useQuery } from '@apollo/react-hooks'
import { APPROVAL_FEED_LIST } from '../../core/queries'
import { ErrorHandler } from 'components'

import { CONTENT_MAX_WIDTH } from '../../core/constants'
import { ApprovalFeedItem } from '../components/ApprovalFeedItem'
import { ApprovalLogItemType } from '../../../generated/types'

const styles = {
	contentWrapper: {
		padding: '24px 20px',
		maxWidth: CONTENT_MAX_WIDTH,
		overflow: 'auto',
		minHeight: '50%'
	}
}
export interface ApprovalFeedList {
	systemId: string
	quarter: number
	year: number
}
export const ApprovalFeedList = ({
	systemId,
	quarter,
	year
}: ApprovalFeedList) => {
	const { data, loading, error } = useQuery(APPROVAL_FEED_LIST, {
		variables: {
			systemId,
			quarter,
			year
		},
		skip: !systemId
	})
	const feedList: Array<ApprovalLogItemType> = data?.approvalFeed ?? []
	return (
		<div style={styles.contentWrapper}>
			<ErrorHandler loading={loading} error={error}>
				{feedList.map(feed => (
					<ApprovalFeedItem key={feed.id} {...feed} />
				))}
			</ErrorHandler>
		</div>
	)
}
