import React, { useCallback, useEffect, useState, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { AkaTypography } from 'components'
import { CustomCollapse as Collapse } from './styles'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import { DIFF } from '../../core/constants'
import { Col, Divider, Row } from 'antd'

const { Panel } = Collapse

const styles = {
	boldStyle: {
		fontWeight: 'bold'
	}
}

const RegionDisplay = ({
	name,
	contracts,
	contractsDiff,
	okv,
	heading = false
}) => {
	return (
		<>
			<Col style={{ padding: 16, ...(heading && styles.boldStyle) }} span={7}>
				<AkaTypography variant="body3" style={{ margin: 0, maxWidth: 400 }}>
					{name}
					{!heading && ':'}
				</AkaTypography>
			</Col>
			{contracts && (
				<Col
					style={{ padding: 16, ...(heading && styles.boldStyle) }}
					span={17}
				>
					<AkaTypography
						variant="body3"
						style={{ margin: 0, maxWidth: 400 }}
						data-testid={`contract-${okv}`}
					>
						{contracts}
					</AkaTypography>
				</Col>
			)}
			{contractsDiff && (
				<Col style={{ padding: 16 }} span={17}>
					<AkaTypography
						variant="body3"
						style={{ margin: 0, maxWidth: 400 }}
						data-testid={`contract-${okv}`}
					>
						{ReactHtmlParser(contractsDiff)}
					</AkaTypography>
				</Col>
			)}
		</>
	)
}

const Regions = ({ regions, isDiff }) => {
	const [show, setShow] = useState(false)
	const [activeKey, setActiveKey] = useState([])
	const handleChange = useCallback(() => setShow(!show), [show])

	useEffect(() => {
		if (isDiff) {
			let open = regions.reduce((accum, current) => {
				if (current.diff !== DIFF.SAME) return true
				return accum
			}, false)
			if (open) {
				setShow(open)
			}
		}
	}, [regions, isDiff])
	useEffect(() => setActiveKey(show ? ['panel'] : []), [show])
	const [t] = useTranslation()
	return (
		<>
			<Collapse
				bordered={false}
				defaultActiveKey={[]}
				activeKey={activeKey}
				onChange={handleChange}
				data-testid="testtest"
			>
				<Panel
					data-testid="testtest1"
					key={'panel'}
					header={
						<AkaTypography variant="body" gutterbottom={0}>
							{t('catalog.showRequirements', {
								show: show ? 'ausblenden' : 'anzeigen'
							})}
						</AkaTypography>
					}
				>
					<Row>
						<RegionDisplay
							name={'Region'}
							contracts={'Verträge'}
							okv={'head'}
							style={styles.boldStyle}
							heading
						/>
						{regions
							.filter(region => region.contracts || region.contractsDiff)
							.map(region => (
								<Fragment key={region.okv}>
									<Divider style={{ margin: 0 }} />
									<RegionDisplay {...region} />
								</Fragment>
							))}
					</Row>
				</Panel>
			</Collapse>
		</>
	)
}

Regions.propTypes = {
	regions: PropTypes.array.isRequired,
	isDiff: PropTypes.bool
}
Regions.defaultProps = {
	isDiff: false
}
export default Regions
