import React, { useMemo, memo, useState } from 'react'
import * as PropTypes from 'prop-types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Button } from 'antd'
import { ReadMore, LoadingSpinner } from 'components'
import theme from 'components/themeProvider/theme'
import { useTranslation } from 'react-i18next'
import { DIFF } from 'modules/core/constants'
import AttachmentInfo from './AttachmentInfo'
import { useLazyQuery } from '@apollo/react-hooks'
import { ATTACHMENT_OBJECT_FILE_PATH } from 'modules/core/queries'
import { Link } from 'react-router-dom'
import { TableWrapper, AttachmentTable as Table } from './styles'
import { compareByAlph } from 'modules/core/helpers'

const { confirm } = Modal
const EMPTY = 'Leer'

const TABLE_ROW_STYLES = {
	[DIFF.SAME]: '',
	[DIFF.NEW]: 'table-row-new',
	[DIFF.DELETED]: 'table-row-deleted',
	[DIFF.CHANGED]: 'table-row-changed'
}

const DIFF_VALUES = {
	[DIFF.SAME]: '',
	[DIFF.NEW]: 'NEU',
	[DIFF.DELETED]: 'GELÖSCHT',
	[DIFF.CHANGED]: 'ÄNDERUNG'
}

const copyToClipboard = text => {
	let $body = document.getElementsByTagName('body')[0]
	let $tempInput = document.createElement('INPUT')
	$body.appendChild($tempInput)
	$tempInput.setAttribute('value', text)
	$tempInput.select()
	document.execCommand('copy')
	$body.removeChild($tempInput)
}

const styles = {
	change: { fontSize: 10, marginBottom: 0, textAlign: 'center' },
	noMoreResult: { margin: 'auto' },
	icon: { color: theme.primary },
	flexColumn: { display: 'flex', flexDirection: 'column' }
}

const info = (text, title) => {
	confirm({
		title,
		content: (
			<div>
				<p>{text}</p>
			</div>
		),
		icon: <InfoCircleOutlined style={styles.icon} />,
		okText: 'Kopieren',
		cancelText: 'zurück',
		onOk() {
			copyToClipboard(text)
		},
		onCancel() {}
	})
}
const downloadFile = blob => {
	let a = document.createElement('a')
	a.href = blob
	a.download = blob
	a.target = '_blank'
	a.rel = 'noopener noreferrer'
	a.click()
}
const renderContent = (id, getFilePath, file, text, title, link) =>
	file ? (
		<Link
			to={'#'}
			onClick={() => getFilePath({ variables: { attachmentObjectId: id } })}
		>
			{file}
		</Link>
	) : text ? (
		<ReadMore text={text} onReadMoreClick={() => info(text, title)} />
	) : link ? (
		<a href={link} target="_blank" rel="noopener noreferrer">
			{link}
		</a>
	) : (
		''
	)

const Attachments = memo(
	({ releaseId, loading, inProgress, attachments, attachmentObjectTypes }) => {
		const [t] = useTranslation()
		const [drawerState, setDrawerState] = useState({
			visible: false,
			record: undefined
		})
		const contractsModalColumn = {
			title: '',
			dataIndex: '',
			key: 'x',
			width: 50,
			render: (text, record) => (
				<Button
					type="link"
					style={{ padding: 0 }}
					onClick={() => setDrawerState({ visible: true, record: record })}
				>
					{t('catalog.filters.details')}
				</Button>
			)
		}
		const [getFilePath] = useLazyQuery(ATTACHMENT_OBJECT_FILE_PATH, {
			onCompleted: data => {
				downloadFile(data.attachmentsObjectFilePath)
			}
		})

		const attachmentTypes = useMemo(
			() =>
				attachments.reduce((acc, curr) => {
					if (!acc.includes(curr.attachment.type)) {
						return [curr.attachment.type, ...acc]
					} else {
						return acc
					}
				}, []),
			[attachments]
		)

		const columns = useMemo(
			() => [
				{
					title: 'Anlage',
					dataIndex: 'attachment',
					children: [
						{
							title: 'ID',
							dataIndex: ['attachment', 'spuId'],
							defaultSortOrder: 'ascend',
							width: 50,
							sorter: (a, b) => a.attachment.spuId - b.attachment.spuId
						},
						{
							title: 'Titel',
							width: 200,
							dataIndex: ['attachment', 'title'],
							sorter: (a, b) =>
								compareByAlph(a.attachment.title, b.attachment.title)
						},
						{
							title: 'Anlagetyp',
							width: 100,
							dataIndex: ['attachment', 'type'],
							filters: attachmentTypes.map(item => ({
								text: item,
								value: item
							})),
							defaultSortOrder: 'ascend',

							onFilter: (value, record) => {
								value = value === EMPTY ? null : value
								return record.attachment.type === value
							},
							filterMultiple: true,
							sorter: (a, b) =>
								compareByAlph(a.attachment.type, b.attachment.type)
						}
					]
				},
				{
					title: 'Anlageobjekt',
					dataIndex: 'attachmentObject',
					children: [
						{
							title: 'Typ',
							width: 100,
							dataIndex: ['attachmentObject', 'type'],
							defaultSortOrder: 'ascend',
							sorter: (a, b) =>
								compareByAlph(a.attachmentObject.type, b.attachmentObject.type),
							filters: attachmentObjectTypes.map(type => ({
								text: type ? type : EMPTY,
								value: type ? type : EMPTY
							})),
							onFilter: (value, record) => {
								value = value === EMPTY ? null : value
								return record.attachmentObject.type === value
							},
							filterMultiple: true
						},
						{
							title: 'Inhalt',
							width: 200,
							dataIndex: ['attachmentObject', 'file'],
							render: (file, { attachmentObject: { id, text, link, title } }) =>
								renderContent(id, getFilePath, file, text, title, link)
						},
						{
							title: 'Seite',
							width: 75,
							dataIndex: ['attachmentObject', 'page']
						},
						{
							title: 'Änderung',
							width: 100,
							dataIndex: 'diff',
							render: diff => <p style={styles.change}>{DIFF_VALUES[diff]}</p>,
							defaultSortOrder: 'ascend',
							sorter: (a, b) =>
								compareByAlph(DIFF_VALUES[a.diff], DIFF_VALUES[b.diff]),
							filters: [
								{ text: DIFF_VALUES[DIFF.NEW], value: DIFF.NEW },
								{ text: DIFF_VALUES[DIFF.CHANGED], value: DIFF.CHANGED },
								{ text: DIFF_VALUES[DIFF.DELETED], value: DIFF.DELETED }
							],
							onFilter: (value, record) => {
								return record.diff === value
							},
							filterMultiple: true
						},
						{
							title: 'Änderung an',
							width: 100,
							dataIndex: 'changes',
							render: changes => (
								<span style={styles.flexColumn}>
									{changes &&
										changes
											.split(',')
											.map(change => <span key={change}>{change}</span>)}
								</span>
							)
						}
					]
				}
			],
			[attachmentObjectTypes, attachmentTypes, getFilePath]
		)

		return (
			<TableWrapper>
				<Table
					locale={{
						emptyText: t('catalog.attachments.noResult')
					}}
					loading={loading && { indicator: <LoadingSpinner /> }}
					rowKey={({ id }) => id}
					data-testid="attachmenst-table"
					columns={[{ ...contractsModalColumn }, ...columns]}
					rowClassName={({ diff }) => TABLE_ROW_STYLES[diff]}
					dataSource={attachments}
					scroll={{ x: 750, y: 'calc(100vh - 250px)' }}
					pagination={false}
					bordered
				/>
				{inProgress && <LoadingSpinner />}
				<AttachmentInfo
					releaseId={releaseId}
					visible={drawerState.visible}
					attachmentObjectContracts={drawerState.record}
					onClose={() => setDrawerState({ visible: false, record: undefined })}
					getFilePath={getFilePath}
					renderContent={renderContent}
				/>
			</TableWrapper>
		)
	}
)

Attachments.propTypes = {
	releaseId: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	attachmentObjectTypes: PropTypes.array,
	attachments: PropTypes.array
}

Attachments.defaultProps = {
	loading: true,
	attachmentObjectTypes: [],
	attachments: []
}

export default Attachments
