import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorBoundary from './ErrorBoundary'
import { ErrorHandler } from '../index'

const styles = {
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: '4.5rem'
	},
	errorText: {
		color: 'red',
		fontSize: '4vw',
		fontWeight: 700
	}
}
export const MainFallback = ({ title }) => {
	const { t } = useTranslation()

	const criticalFallback = (
		<div style={styles.wrapper}>
			<p style={styles.errorText}>
				{title ?? 'An unknown error has occurred!'}
			</p>
		</div>
	)
	return (
		<ErrorBoundary fallback={criticalFallback}>
			<ErrorHandler
				errorMessage={title ?? t('core.generalError')}
				loading={false}
			/>
		</ErrorBoundary>
	)
}
