import React, { memo } from 'react'
import { CardWrapper } from './styles'
import { CSSProperties } from 'styled-components'

export interface Card {
	children: React.ReactNode
	style: CSSProperties
	testId?: string
}

const Card = memo(({ children, style, testId }: Card) => {
	return (
		<CardWrapper data-testid={testId} style={style}>
			{children}
		</CardWrapper>
	)
})

export default Card
