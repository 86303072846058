import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReportCard from '../components/ReportCard'
import { AkaTypography, LoadingSpinner } from 'components'
import ContentCheckCard from './ContentCheckCard'
import { ContentCheckProofStatus } from 'generated/types'

const CardList = ({
	reportList,
	reportsLoading,
	contentCheckList,
	contentChecksLoading,
	selectedSystemId
}) => {
	const [t] = useTranslation()
	if (reportsLoading || contentChecksLoading) return <LoadingSpinner fullPage />
	return (
		<>
			{reportList.length === 0 && !reportsLoading ? (
				<AkaTypography data-testid="no-results" variant="body">
					{t('approval.approvalList.noResults')}
				</AkaTypography>
			) : (
				reportList.map(item => (
					<ReportCard
						key={item.id}
						{...item}
						selectedSystemId={selectedSystemId}
					/>
				))
			)}
			{contentCheckList.length > 0 &&
				!contentChecksLoading &&
				contentCheckList.map(item => (
					<ContentCheckCard
						key={item.id}
						selectedSystemId={selectedSystemId}
						status={item?.proof?.status ?? ContentCheckProofStatus.OPEN}
						{...item}
					/>
				))}
		</>
	)
}

CardList.propTypes = {
	reportList: PropTypes.array,
	reportsLoading: PropTypes.bool,
	contentCheckList: PropTypes.array,
	contentChecksLoading: PropTypes.bool,
	selectedSystemId: PropTypes.string
}

CardList.defaultProps = {
	reportList: [],
	reportsLoading: true,
	contentCheckList: [],
	contentChecksLoading: true,
	selectedSystemId: undefined
}

export default CardList
