import React, { useEffect } from 'react'
import { Layout } from 'antd'
import {
	CHANGES_CHAPTER_IDS,
	DIFF,
	LAYOUT_MAX_WIDTH
} from 'modules/core/constants'
import RequirementsComponent from '../components/Requirements'
import { ChapterWrapper as StylingWrapper } from '../components/styles'
import ChangesList from '../components/ChangesList'
import ReleaseInfo from '../components/ReleaseInfo'
import AttachmentChanges from './AttachmentChanges'
import { AkaTypography } from 'components'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ChapterDetails from '../components/ChapterDetails'
import SelectiveContractDefinitions from './SelectiveContractDefinitions'
const { Content } = Layout

const styles = {
	layout: { maxWidth: LAYOUT_MAX_WIDTH },
	contentPadding: {
		paddingBottom: 0
	},
	marginLeft: {
		marginLeft: 450
	},
	title: {
		margin: '32px 0px 40px 0px'
	}
}

const Changes = React.memo(
	({
		release,
		comparedRelease,
		selectedChapter,
		rootChapterId,
		loadingChapters,
		loading,
		dynamicChapterIds,
		selectedChapterId,
		newRequirements,
		changedRequirements,
		deletedRequirements,
		changedAssignmentRequirements,
		isFirstChapter
	}) => {
		const [t] = useTranslation()
		useEffect(() => {
			if (selectedChapterId) {
				document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
			}
		}, [selectedChapterId])
		return (
			<StylingWrapper>
				<Layout style={styles.layout}>
					<Content
						style={styles.contentPadding}
						data-testid="left-side-content"
					>
						{selectedChapter && selectedChapter.id === rootChapterId && (
							<>
								<ChapterDetails
									chapter={selectedChapter}
									isFirstChapter={isFirstChapter}
								/>
								<ReleaseInfo compared={comparedRelease} current={release} />
							</>
						)}
						{!loadingChapters &&
							selectedChapter &&
							dynamicChapterIds.includes(selectedChapter.id) && (
								<>
									<ChapterDetails chapter={selectedChapter} />
								</>
							)}
						{selectedChapterId === CHANGES_CHAPTER_IDS.REQUIREMENT && (
							<>
								<ChangesList
									list={changedRequirements}
									title={t('catalog.changes.requirements.changed')}
									loading={loading}
									emptyMessage={t('catalog.changes.noChanges', {
										type: t('catalog.changes.requirements.changed')
									})}
									type={DIFF.CHANGED}
								/>
								<ChangesList
									list={newRequirements}
									title={t('catalog.changes.requirements.new')}
									loading={loading}
									emptyMessage={t('catalog.changes.noChanges', {
										type: t('catalog.changes.requirements.new')
									})}
									type={DIFF.NEW}
								/>
								<ChangesList
									list={deletedRequirements}
									title={t('catalog.changes.requirements.deleted')}
									loading={loading}
									emptyMessage={t('catalog.changes.noChanges', {
										type: t('catalog.changes.requirements.deleted')
									})}
									type={DIFF.DELETED}
								/>
								<ChangesList
									list={changedAssignmentRequirements}
									title={t('catalog.changes.requirements.contracts')}
									loading={loading}
									emptyMessage={t('catalog.changes.noChanges', {
										type: t('catalog.changes.requirements.contracts')
									})}
									type={DIFF.ASSIGNMENT}
								/>
							</>
						)}
						{selectedChapterId === CHANGES_CHAPTER_IDS.CHANGED_REQUIREMENT && (
							<>
								<AkaTypography style={styles.title} variant="h2" color="orange">
									{t('catalog.changes.requirements.changed')}
								</AkaTypography>
								{!loading && changedRequirements.length === 0 ? (
									<AkaTypography variant="body">
										{t('catalog.changes.noChanges', {
											type: 'Anforderungen'
										})}
									</AkaTypography>
								) : (
									<RequirementsComponent
										loading={loading}
										list={changedRequirements}
										isDiff={true}
									/>
								)}
							</>
						)}
						{selectedChapterId === CHANGES_CHAPTER_IDS.NEW_REQUIREMENT && (
							<>
								<AkaTypography
									style={styles.title}
									variant="h2"
									color="insContent"
								>
									{t('catalog.changes.requirements.new')}
								</AkaTypography>
								{!loading && newRequirements.length === 0 ? (
									<AkaTypography variant="body">
										{t('catalog.changes.noChanges', {
											type: 'Anforderungen'
										})}
									</AkaTypography>
								) : (
									<RequirementsComponent
										loading={loading}
										list={newRequirements}
										isDiff={true}
									/>
								)}
							</>
						)}
						{selectedChapterId === CHANGES_CHAPTER_IDS.DELETED_REQUIREMENT && (
							<>
								<AkaTypography
									style={styles.title}
									variant="h2"
									color="delContent"
								>
									{t('catalog.changes.requirements.deleted')}
								</AkaTypography>
								{!loading && deletedRequirements.length === 0 ? (
									<AkaTypography variant="body">
										{t('catalog.changes.noChanges', {
											type: 'Anforderungen'
										})}
									</AkaTypography>
								) : (
									<RequirementsComponent
										loading={loading}
										list={deletedRequirements}
										isDiff={true}
									/>
								)}
							</>
						)}
						{selectedChapterId === CHANGES_CHAPTER_IDS.CHANGED_ATTACHMENTS && (
							<AttachmentChanges
								currentRelease={release}
								comparedRelease={comparedRelease}
							/>
						)}
						{selectedChapterId ===
							CHANGES_CHAPTER_IDS.SELECTIVE_CONTRACT_DEFINITIONS && (
							<SelectiveContractDefinitions
								currentRelease={release}
								comparedRelease={comparedRelease}
							/>
						)}
					</Content>
				</Layout>
			</StylingWrapper>
		)
	}
)

Changes.propTypes = {
	release: PropTypes.shape({}).isRequired,
	comparedRelease: PropTypes.shape({}).isRequired
}

export default Changes
