import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CatalogDownLoad from './CatalogDownload'
import GlobalFilters from './GlobalFilters'
import Requirements from './Requirements'
import { useAttachment, useCatalog, useRevisionHistory } from 'hooks'
import { useQuery } from '@apollo/react-hooks'
import { RELEASE_CONTRACTS } from 'modules/core/queries'
import PageLayout from 'modules/layout/containers/PageLayout'
import ChangesSider from './ChangesSider'
import CatalogSider from './CatalogSider'
import AttachmentSider from './AttachmentSider'
import Attachments from './Attachments'
import Changes from './Changes'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { Layout } from 'antd'

import { useUser } from 'providers'
const { Content } = Layout

export const ContractsFilterContext = React.createContext({})

const styles = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	}
}
const CatalogPage = () => {
	let { id } = useParams()
	const [t] = useTranslation()
	const {
		user: { contractsFilter, hasSelectedContracts }
	} = useUser()

	const { data, loading: loadingContracts } = useQuery(RELEASE_CONTRACTS, {
		variables: { releaseId: id }
	})

	const [navigationDefaultKey, setNavigationDefaultKey] =
		useState('requirements')
	const navigationChangeHandler = e => {
		setNavigationDefaultKey(e.key)
	}
	const [selectedReleaseToCompare, setSelectedReleaseToCompare] =
		useState(undefined)

	const [selectedContractIdsList, setSelectedContractIdsList] = useState([])
	const { release, ...catalogProps } = useCatalog(id)

	const releaseContracts = data && data.releaseContracts

	const { ...attachmentProps } = useAttachment(
		id,
		selectedContractIdsList,
		releaseContracts,
		navigationDefaultKey === 'attachments'
	)

	useEffect(() => {
		if (
			releaseContracts &&
			releaseContracts.length > 0 &&
			hasSelectedContracts
		) {
			setSelectedContractIdsList(releaseContracts.map(item => item.contractId))
		}
		if (
			releaseContracts &&
			releaseContracts.length > 0 &&
			!hasSelectedContracts
		) {
			const userContractIdsFilter = contractsFilter.map(
				({ contractId }) => contractId
			)
			const list = releaseContracts.filter(({ contractId }) =>
				userContractIdsFilter.includes(contractId)
			)
			setSelectedContractIdsList(list.map(({ contractId }) => contractId))
		}
	}, [contractsFilter, releaseContracts, hasSelectedContracts])

	const handleCompare = useCallback(
		selectedReleaseToCompare => {
			setSelectedReleaseToCompare(selectedReleaseToCompare)
		},
		[id] // eslint-disable-line
	)

	const { ...revisionHistoryProps } = useRevisionHistory(
		release,
		selectedReleaseToCompare,
		selectedContractIdsList,
		navigationDefaultKey === 'revisionHistory'
	)

	const [navigations, setNavigations] = useState([
		{ name: t('catalog.navigations.requirements'), key: 'requirements' },
		{ name: t('catalog.navigations.attachments'), key: 'attachments' }
	])

	useEffect(() => {
		if (selectedReleaseToCompare) {
			setNavigations([
				{ name: t('catalog.navigations.requirements'), key: 'requirements' },
				{ name: t('catalog.navigations.attachments'), key: 'attachments' },
				{
					name: t('catalog.navigations.revisionHistory'),
					key: 'revisionHistory'
				}
			])
		}
	}, [selectedReleaseToCompare, t])

	const sideBarContent = {
		requirements: <CatalogSider releaseId={id} {...catalogProps} />,
		attachments: <AttachmentSider {...attachmentProps} />,
		revisionHistory: <ChangesSider {...revisionHistoryProps} />
	}
	const mainContent = {
		requirements: (
			<Content style={styles.contentWrapper} id="requirements-content">
				<Requirements
					releaseId={id}
					selectedReleaseToCompareId={
						selectedReleaseToCompare && selectedReleaseToCompare.id
					}
					{...catalogProps}
				/>
			</Content>
		),
		attachments: (
			<Content
				style={{
					...styles.contentWrapper,
					maxWidth: 'unset',
					position: 'unset'
				}}
				id="attachments-content"
			>
				<Attachments
					releaseId={id}
					comparedReleaseId={
						selectedReleaseToCompare && selectedReleaseToCompare.id
					}
					{...attachmentProps}
					{...catalogProps}
				/>
			</Content>
		),
		revisionHistory: selectedReleaseToCompare && (
			<Content
				style={{ ...styles.contentWrapper, width: '100%' }}
				id="changes-content"
			>
				<Changes
					release={release}
					comparedRelease={selectedReleaseToCompare}
					isFirstChapter={catalogProps && catalogProps.isFirstChapter}
					{...revisionHistoryProps}
				/>
			</Content>
		)
	}
	if (loadingContracts) return <div />
	return (
		<ContractsFilterContext.Provider
			value={{
				releaseContracts: releaseContracts,
				selectedContractIdsList,
				loading: loadingContracts,
				updateContractList: setSelectedContractIdsList
			}}
		>
			<PageLayout
				navigationItems={navigations}
				navigationDefaultKey={navigationDefaultKey}
				renderMainContent={mainContent[navigationDefaultKey]}
				onNavigationChange={navigationChangeHandler}
				renderSidebarContent={
					<>
						<GlobalFilters
							id={id}
							releaseName={release && release.name}
							onReleaseToCompareSelected={handleCompare}
							contractChoiceList={releaseContracts}
							loadingContracts={loadingContracts}
						/>
						{sideBarContent[navigationDefaultKey]}
					</>
				}
				rightSideContent={
					<CatalogDownLoad
						release={release}
						selectedReleaseToCompareId={
							selectedReleaseToCompare && selectedReleaseToCompare.id
						}
					/>
				}
			/>
		</ContractsFilterContext.Provider>
	)
}

export default CatalogPage
