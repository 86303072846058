import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import NotificationsList from './NotificationsList'
import PageLayout from 'modules/layout/containers/PageLayout'
import { Layout, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	ALL_CATEGORIES_KEY,
	CATEGORIES,
	CONTENT_MAX_WIDTH
} from 'modules/core/constants'
import { useQuery } from '@apollo/react-hooks'
import { NOTIFICATIONS } from '../../core/queries'
import { ROUTES } from 'modules/navigation'

const { Content } = Layout

const NotificationsType = ({ handleChangeCategory, categoriesList = [] }) => {
	return (
		<Menu
			mode="inline"
			defaultSelectedKeys={[ALL_CATEGORIES_KEY]}
			style={styles.menuStyle}
			onClick={e => handleChangeCategory(e.key)}
			items={Object.entries(CATEGORIES).map(([key, value]) => {
				return categoriesList.includes(key) && { key: key, label: value }
			})}
		/>
	)
}
const styles = {
	menuStyle: {
		borderRight: 0,
		paddingTop: 24,
		fontFamily: 'SegoeUI, Sans',
		fontWeight: 'bold'
	},
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	}
}

const NotficationsPage = () => {
	const { data, loading, error } = useQuery(NOTIFICATIONS)

	const history = useHistory()
	const [selectedCategory, setSelectedCategory] = useState(ALL_CATEGORIES_KEY)
	const notifications = data && data.notifications

	const categoriesList = useMemo(() => {
		const list = [ALL_CATEGORIES_KEY]
		return notifications
			? [
					ALL_CATEGORIES_KEY,
					...notifications
						.filter(notification => notification.category)
						.map(notification => notification.category)
			  ]
			: list
	}, [notifications])
	const navigationChangeHandler = e => {
		if (e.key !== ROUTES.home) {
			history.push(e.key)
		}
	}
	const filterHandler = e => {
		setSelectedCategory(e.key)
	}
	const handleChangeCategory = category => {
		setSelectedCategory(category)
	}

	const [t] = useTranslation()
	const navigations = [
		{ name: t('navigation.mainHeaderMenu.notifications'), key: ROUTES.home }
	]
	const sideBarContent = (
		<NotificationsType
			handleFilter={filterHandler}
			categoriesList={categoriesList}
			handleChangeCategory={handleChangeCategory}
		/>
	)

	const mainContent = (
		<Content style={styles.contentWrapper} id="notifications-content">
			<NotificationsList
				list={notifications}
				loadingList={loading}
				errorLoadingList={error}
				selectedCategory={selectedCategory}
			/>
		</Content>
	)

	return (
		<PageLayout
			navigationItems={navigations}
			navigationDefaultKey={ROUTES.home}
			renderMainContent={mainContent}
			onNavigationChange={navigationChangeHandler}
			renderSidebarContent={sideBarContent}
		/>
	)
}

NotificationsType.propTypes = {
	categoriesList: PropTypes.array,
	handleChangeCategory: PropTypes.func
}

export default NotficationsPage
