import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PageLayout from 'modules/layout/containers/PageLayout'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { Layout, Radio, Space } from 'antd'
import { ROUTES, ROUTE_FUNCTIONS } from 'modules/navigation'
import ReportSider, { STEPS_CHOICE } from './ReportSider'
import {
	AkaButton,
	AkaTypography,
	ErrorHandler,
	LoadingSpinner
} from 'components'
import { SOFTWARE_SYSTEM_REPORT_SYSTEM_COSTS } from 'modules/core/queries'
import { UPDATE_SOFTWARE_SYSTEM_COSTS } from 'modules/core/mutations'
import SystemCostsTable from 'modules/approval/components/SystemCostsTable'
import { initialTabelData } from 'modules/approval/constants/initialSystemCostsData'
import {
	SofwareSystemReportSystemCostsQuery,
	SofwareSystemReportSystemCostsQueryVariables,
	SystemCostType
} from 'generated/types'
import { useUser, useAntdMessageApi } from 'providers'
import { isReportSubmitted } from 'utils/helper.utils'

const { Content } = Layout

const styles = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	}
}

const SystemCostsPage = () => {
	const { id: currentReportId, quarter } = useParams<{
		quarter: string
		id: string
	}>()
	const history = useHistory()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const {
		user: { isVswUser }
	} = useUser()

	const { data, loading, error } = useQuery<
		SofwareSystemReportSystemCostsQuery,
		SofwareSystemReportSystemCostsQueryVariables
	>(SOFTWARE_SYSTEM_REPORT_SYSTEM_COSTS, {
		variables: { id: currentReportId }
	})

	const [
		updateSoftwareSystemCosts,
		{ loading: updatingSystemCosts, error: updateSystemCostsError }
	] = useMutation(UPDATE_SOFTWARE_SYSTEM_COSTS, {
		refetchQueries: [
			{
				query: SOFTWARE_SYSTEM_REPORT_SYSTEM_COSTS,
				variables: { id: currentReportId }
			}
		]
	})

	const currentReport = data?.softwareSystemReport

	const isSubmitted = isReportSubmitted(currentReport?.status)
	const isReadOnly = !isVswUser || isSubmitted

	const approvalRun = currentReport?.approvalRun
	const system = currentReport?.system
	const systemCosts = (
		system?.systemCosts ? JSON.parse(system.systemCosts) : []
	) as Array<SystemCostType>

	const hasSystemCosts = systemCosts.length > 0
	const [costsAreEnabled, enableCosts] = useState<undefined | boolean>()

	useEffect(() => {
		if (hasSystemCosts && costsAreEnabled === undefined) {
			enableCosts(true)
		}
	}, [hasSystemCosts, costsAreEnabled])

	const headerNavigations = [
		{
			name: t('navigation.mainHeaderMenu.approvalList'),
			key: ROUTES.approval.report.systemInfoSlug
		}
	]

	const sidebarNavigationHandler = (
		routeFunc: (quarter: string, id: string) => string
	) => {
		history.push(routeFunc(quarter, currentReportId))
	}

	const nextStepRoute = ROUTE_FUNCTIONS.getSelectContractRegionsPath(
		quarter,
		currentReportId
	)
	const handleSubmit = useCallback(
		async (systemCosts: Array<SystemCostType>) => {
			if (system) {
				const successful = await updateSoftwareSystemCosts({
					variables: {
						systemId: system.id,
						systemCosts: systemCosts
					}
				})
				if (successful) {
					messageApi?.open({
						type: 'success',
						content: t('approval.report.systemCosts.submitSuccessMessage')
					})
					history.push(nextStepRoute)
				}
			}
		},
		[t, system, updateSoftwareSystemCosts, nextStepRoute, history, messageApi]
	)

	const sideBarContent = approvalRun && system?.systemName && (
		<ReportSider
			currentStep={STEPS_CHOICE.STEP2}
			onChange={sidebarNavigationHandler}
			quarter={approvalRun.quarter}
			year={approvalRun.year}
			tenant={approvalRun.tenant.name}
			system={system.systemName}
		/>
	)

	const mainContent = (
		<Content style={styles.contentWrapper}>
			<ErrorHandler loading={loading} error={error || updateSystemCostsError}>
				{loading ? (
					<LoadingSpinner fullPage />
				) : (
					<>
						<AkaTypography variant="body2">
							{t('approval.report.systemCosts.description')}
						</AkaTypography>
						<Space direction="vertical" size={16}>
							<Radio.Group
								defaultValue={false}
								disabled={isReadOnly}
								onChange={() => {
									enableCosts(!costsAreEnabled)
								}}
								value={costsAreEnabled}
							>
								<Space direction="vertical">
									<Radio value={false}>
										<AkaTypography variant={costsAreEnabled ? 'body3' : 'body'}>
											{t('approval.report.systemCosts.choiceDisagree')}
										</AkaTypography>
									</Radio>
									<Radio value={true}>
										<AkaTypography variant={costsAreEnabled ? 'body' : 'body3'}>
											{t('approval.report.systemCosts.choiceAgree')}
										</AkaTypography>
									</Radio>
								</Space>
							</Radio.Group>
							<div style={{ display: costsAreEnabled ? 'block' : 'none' }}>
								<SystemCostsTable
									isReadOnly={isReadOnly}
									initialData={hasSystemCosts ? systemCosts : initialTabelData}
									onSubmit={handleSubmit}
									isSubmitting={updatingSystemCosts}
								/>
							</div>
							{!costsAreEnabled && !isReadOnly && (
								<AkaButton type="primary" onClick={() => handleSubmit([])}>
									{t('approval.report.systemCosts.submitButton')}
								</AkaButton>
							)}
							{isSubmitted && (
								<AkaTypography variant={'tiny'}>
									{t('approval.report.reportAlreadySubmitted')}
								</AkaTypography>
							)}
						</Space>
					</>
				)}
			</ErrorHandler>
		</Content>
	)

	return (
		<PageLayout
			navigationItems={headerNavigations}
			navigationDefaultKey={ROUTES.approval.report.systemInfoSlug}
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default SystemCostsPage
