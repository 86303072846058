import React, { memo, useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { AkaTypography, LoadingSpinner } from 'components'
import NotificationItem from './NotificationItem'
import { useTranslation } from 'react-i18next'

const Notifications = memo(({ list, loading }) => {
	const [t] = useTranslation()
	const renderNotificationList = useMemo(
		() => list.map(item => <NotificationItem key={item.id} item={item} />),
		[list]
	)

	if (loading) return <LoadingSpinner fullPage />
	if (list.length === 0 && !loading)
		return (
			<AkaTypography data-testid="no-results" variant="body">
				{t('notification.noResults')}
			</AkaTypography>
		)
	return <>{renderNotificationList}</>
})

Notifications.propTypes = {
	list: PropTypes.array,
	loading: PropTypes.bool
}

Notifications.defaultProps = {
	list: [],
	loading: true
}

export default Notifications
