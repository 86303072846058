import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
`

export const Headline = styled.h1`
	text-align: center;
	font-size: 9em;
	line-height: 1.3em;
	margin: 2rem 0 0.5rem;
	padding: 0;
	color: ${({ theme }) => theme.primary}
	text-shadow: 0 0 8px #003b7680;
`

export const SubHeadline = styled.p`
	text-align: center;
	max-width: 480px;
	font-size: 1.5em;
	line-height: 1.1em;
	padding: 0;
	margin: 0 auto;
`
