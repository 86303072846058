import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import { AttachmentChangesTable as Table } from './styles'
import { DIFF } from 'modules/core/constants'
import { CONTENT_MAX_WIDTH } from '../../core/constants'

const changeType = type => {
	switch (type) {
		case DIFF.CHANGED:
			return 'ÄNDERUNG'
		case DIFF.NEW:
			return 'NEU'
		case DIFF.DELETED:
			return 'GELÖSCHT'
		default:
			return ''
	}
}

const TABLE_ROW_STYLES = {
	[DIFF.SAME]: '',
	[DIFF.NEW]: 'table-row-new',
	[DIFF.DELETED]: 'table-row-deleted',
	[DIFF.CHANGED]: 'table-row-changed'
}

const styles = {
	tableLayout: {
		width: CONTENT_MAX_WIDTH,
		tableLayout: 'fixed'
	}
}

const tableHead = {
	columns: {
		identification: 'ID',
		contractId: 'Vertrag',
		version: 'Version',
		changeType: 'Änderung'
	}
}

const columns = item => [
	{
		title: item.identification,
		dataIndex: ['contract', 'identification'],
		render: text => (text ? text : ''),
		width: '20%',
		ellipsis: false,
		sorter: (a, b) => a.contract.identification - b.contract.identification
	},
	{
		title: item.contractId,
		dataIndex: ['contract', 'contractId'],
		width: '30%',
		ellipsis: false,
		sorter: (a, b) => a.contract.contractId.localeCompare(b.contract.contractId)
	},
	{
		title: item.version,
		dataIndex: undefined,
		render: record =>
			record.diff === 3 ? (
				<>
					<del>{record.oldVersion}</del>
					{'  '}
					<ins>{record.version}</ins>
				</>
			) : (
				<>{record.version}</>
			),
		width: '30%',
		ellipsis: false
	},
	{
		title: item.changeType,
		dataIndex: undefined,
		render: record => changeType(record.diff),
		width: '20%',
		ellipsis: false,
		sorter: (a, b) => a.diff - b.diff
	}
]

const SelectiveContractDefinitions = memo(
	({ selectiveContractDefinitionChanges }) => {
		return (
			<>
				<Table
					style={styles.tableLayout}
					rowClassName={({ diff }) => TABLE_ROW_STYLES[diff]}
					columns={columns(tableHead.columns)}
					dataSource={selectiveContractDefinitionChanges}
					pagination={false}
					rowKey={'id'}
				/>
			</>
		)
	}
)

SelectiveContractDefinitions.propTypes = {
	selectiveContractDefinitionChanges: PropTypes.array
}

SelectiveContractDefinitions.defaultProps = {
	selectiveContractDefinitionChanges: []
}

export default SelectiveContractDefinitions
