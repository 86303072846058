import React from 'react'
import { createContext, useContext } from 'react'
import { message } from 'antd'
import { MessageInstance } from 'antd/lib/message'

const AntdMessageContext = createContext<MessageInstance | null>(null)

export const AntdMessageProvider = ({
	children
}: {
	children: React.ReactElement
}) => {
	const [messageApi, contextHolder] = message.useMessage()

	return (
		<AntdMessageContext.Provider value={messageApi}>
			{contextHolder}
			{children}
		</AntdMessageContext.Provider>
	)
}

export const useAntdMessageApi = () => {
	return useContext(AntdMessageContext)
}
