import styled from 'styled-components'

export const Headline = styled.div`
	text-align: center;
	margin-bottom: 30px;
`

export const LoginWrapper = styled.div`
	margin-top: 60px;
	margin-bottom: 54px;
`
