import React from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import { useUser } from '../../../providers'

const Account = () => {
	const [t] = useTranslation()
	const {
		user: { email, firstName, lastName }
	} = useUser()
	return (
		<Descriptions column={1} size={'middle'}>
			<Descriptions.Item label={t('account.profileInfo.email')}>
				{email}
			</Descriptions.Item>
			<Descriptions.Item label={t('account.profileInfo.firstname')}>
				{firstName}
			</Descriptions.Item>
			<Descriptions.Item label={t('account.profileInfo.lastname')}>
				{lastName}
			</Descriptions.Item>
		</Descriptions>
	)
}

Account.propTypes = {}

Account.defaultProps = {}

export default Account
