import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BadgeWrapper, getBadgeColor } from './styles'
import { Tooltip, Badge } from 'antd'
import { CHANGED_REQUIREMENT_STATUS } from 'modules/core/constants'

const ChangedStatusBadge = ({ size, status, showTooltip }) => {
	const color = getBadgeColor(status)
	const [t] = useTranslation()
	const tooltipTitle = t(
		`approval.report.requirementsTable.changedStatus.${status}`
	)
	return (
		<Tooltip placement="right" title={showTooltip && tooltipTitle}>
			<BadgeWrapper circleSize={size}>
				<Badge color={color} />
			</BadgeWrapper>
		</Tooltip>
	)
}

ChangedStatusBadge.propTypes = {
	size: PropTypes.number,
	status: PropTypes.oneOf(Object.values(CHANGED_REQUIREMENT_STATUS)),
	showTooltip: PropTypes.bool
}

ChangedStatusBadge.defaultProps = {
	size: 16,
	status: CHANGED_REQUIREMENT_STATUS.NEW,
	showTooltip: true
}

export default ChangedStatusBadge
