import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import auth from 'modules/auth/translations'
import core from 'modules/core/translations'
import release from 'modules/releasesList/translations'
import notification from 'modules/notifications/translations'
import catalog from 'modules/catalog/translations'
import account from 'modules/account/translations'
import navigation from 'modules/navigation/translations'
import approval from 'modules/approval/translations'

i18n
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			de: {
				translations: {
					auth,
					core,
					release,
					notification,
					catalog,
					account,
					navigation,
					approval
				}
			}
		},
		lng: 'de',
		fallbackLng: 'de',
		debug: false,
		ns: ['translations'], // have a common namespace used around the full app
		defaultNS: 'translations',
		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ','
		},
		react: {
			wait: true
		}
	})

export default i18n
