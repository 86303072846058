import React, { useContext, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import { REQUIREMENTS_DIFF } from 'modules/core/queries'
import { LoadingSpinner, ErrorHandler } from 'components'
import RequirementsComponent from '../components/Requirements'
import { ContractsFilterContext } from './CatalogPage'

const RequirementsDiff = ({ chapterId, comparedReleaseId }) => {
	const { selectedContractIdsList } = useContext(ContractsFilterContext)
	const [getRequirementDiffList, { loading, data, error }] = useLazyQuery(
		REQUIREMENTS_DIFF,
		{
			variables: {
				chapterId,
				comparedReleaseId,
				selectedContracts: selectedContractIdsList
			}
		}
	)

	useEffect(() => {
		if (chapterId) {
			getRequirementDiffList()
		}
	}, [
		chapterId,
		comparedReleaseId,
		getRequirementDiffList,
		selectedContractIdsList
	])

	if (loading && !data)
		return <LoadingSpinner fullPage testId="requirements-diff-loading" />

	return (
		<ErrorHandler loading={loading} error={error}>
			<RequirementsComponent
				list={data && data.requirementsDiff}
				loading={loading}
				isDiff={true}
			/>
		</ErrorHandler>
	)
}

RequirementsDiff.propTypes = {
	chapterId: PropTypes.string.isRequired,
	comparedReleaseId: PropTypes.string
}

RequirementsDiff.defaultProps = {
	comparedReleaseId: undefined
}

export default RequirementsDiff
