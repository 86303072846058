import React, { Component } from 'react'

const deprecated = (InnerComponent, msg = '') =>
	class extends Component {
		UNSAFE_componentWillMount() {
			if (process.env.NODE_ENV === 'development') {
				console.warn(`${InnerComponent.name} is deprecated`, msg)
			}
		}

		render() {
			return <InnerComponent {...this.props} />
		}
	}

export default deprecated
