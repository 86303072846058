import React, { memo, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { AttachmentChangesTable as Table } from './styles'
import { AkaTypography } from 'components'
import { useTranslation } from 'react-i18next'
import { DIFF } from 'modules/core/constants'
import { CONTENT_MAX_WIDTH } from '../../core/constants'

const getColor = type => {
	switch (type) {
		case DIFF.CHANGED:
			return 'orange'
		case DIFF.NEW:
			return 'insContent'
		case DIFF.DELETED:
			return 'delContent'
		default:
			return 'default'
	}
}

const styles = {
	change: { fontSize: 10, marginBottom: 0, textAlign: 'center' },
	noMoreResult: { margin: 'auto' },
	title: {
		margin: '32px 0px 40px 0px'
	},
	tableLayout: {
		width: CONTENT_MAX_WIDTH,
		tableLayout: 'fixed'
	},
	lastTableColumn: {
		paddingRight: 16
	}
}

const tableHead = {
	columns: {
		id: 'Header',
		contractId: 'Vertrag',
		spuId: 'ID',
		title: 'Titel',
		type: 'Anlagetyp'
	},
	row: [
		{
			type: 'Typ',
			file: 'Titel/Inhalt',
			changes: 'Änderung an'
		}
	]
}

const emptyElement = '—'

const columns = item => [
	{
		title: item.diff !== DIFF.CHANGED ? item.contractId : emptyElement,
		dataIndex: 'type',
		render: text => (text ? text : emptyElement),
		width: '20%',
		ellipsis: false
	},
	{
		title: item.type,
		dataIndex: 'file',
		render: file => {
			return {
				children: <span>{file}</span>,
				props: {
					colSpan: 2
				}
			}
		},
		width: '30%',
		ellipsis: false
	},
	{
		title: item.spuId,
		dataIndex: 'changes',
		render: text => (text ? text : emptyElement),
		width: '10%',
		ellipsis: false
	},
	{
		title: (
			<div data-testid={`attachment-${item.id}`} style={styles.lastTableColumn}>
				{item.title}
			</div>
		),
		dataIndex: undefined,
		width: '40%',
		ellipsis: false
	}
]

const uniqueIdContractor = obj => obj.contract.contractId + obj.attachment.spuId

const groupAttachmentContractListBy = (list, uniqueIdContractor) =>
	list.reduce((accum, current) => {
		let existElementIndex = accum.findIndex(
			item => item.id === uniqueIdContractor(current)
		)
		let existElement = accum.find(
			item => item.id === uniqueIdContractor(current)
		)

		const currentAttachmentObject = {
			...current.attachmentObject,
			changes: current.changes,
			file:
				current.attachmentObject.type === 'Hinweistext'
					? current.attachmentObject.title
					: current.attachmentObject.file
		}

		if (existElement) {
			accum[existElementIndex] = {
				...existElement,
				attachmentObjects: [
					...existElement.attachmentObjects,
					currentAttachmentObject
				]
			}
			return [...accum]
		} else {
			return [
				...accum,
				{
					id: uniqueIdContractor(current),
					...current.attachment,
					...current.contract,
					diff: current.diff,
					attachmentObjects: [currentAttachmentObject]
				}
			]
		}
	}, [])

const AttachmentChanges = memo(
	({ newAttachments, changedAttachments, deletedAttachments }) => {
		const [t] = useTranslation()
		const [groupedNewAttachments, setGroupedNewAttachments] = useState([])
		const [groupedDeletedAttachments, setGroupedDeletedAttachments] = useState(
			[]
		)
		const [groupedChangedAttachments, setGroupedChangedAttachments] = useState(
			[]
		)
		useEffect(() => {
			setGroupedNewAttachments(
				groupAttachmentContractListBy(newAttachments, uniqueIdContractor)
			)
			setGroupedDeletedAttachments(
				groupAttachmentContractListBy(deletedAttachments, uniqueIdContractor)
			)
			setGroupedChangedAttachments(
				groupAttachmentContractListBy(
					changedAttachments,
					obj => obj.attachment.spuId
				)
			)
		}, [changedAttachments, deletedAttachments, newAttachments])

		return (
			<>
				<Table
					style={styles.tableLayout}
					columns={columns(tableHead.columns)}
					dataSource={tableHead.row}
					pagination={false}
				/>
				<AkaTypography
					style={styles.title}
					data-testid={`title`}
					variant="h1"
					color={getColor(DIFF.CHANGED)}
				>
					{t('catalog.changes.attachments.changed')}
				</AkaTypography>
				{changedAttachments.length ? (
					groupedChangedAttachments.map((groupedChangedAttachment, index) => (
						<Table
							key={index}
							style={styles.tableLayout}
							columns={columns(groupedChangedAttachment)}
							dataSource={groupedChangedAttachment.attachmentObjects}
							pagination={false}
						/>
					))
				) : (
					<AkaTypography variant="body">
						{t('catalog.changes.noChanges', {
							type: 'Änderungen'
						})}
					</AkaTypography>
				)}
				<AkaTypography
					style={styles.title}
					data-testid={`title`}
					variant="h1"
					color={getColor(DIFF.NEW)}
				>
					{t('catalog.changes.attachments.new')}
				</AkaTypography>
				{newAttachments.length ? (
					groupedNewAttachments.map((groupedNewAttachment, index) => (
						<Table
							key={index}
							style={styles.tableLayout}
							columns={columns(groupedNewAttachment)}
							dataSource={groupedNewAttachment.attachmentObjects}
							pagination={false}
						/>
					))
				) : (
					<AkaTypography variant="body">
						{t('catalog.changes.noChanges', {
							type: 'Änderungen'
						})}
					</AkaTypography>
				)}
				<AkaTypography
					style={styles.title}
					data-testid={`title`}
					variant="h1"
					color={getColor(DIFF.DELETED)}
				>
					{t('catalog.changes.attachments.deleted')}
				</AkaTypography>
				{deletedAttachments.length ? (
					groupedDeletedAttachments.map((groupedDeletedAttachment, index) => (
						<Table
							key={index}
							style={styles.tableLayout}
							columns={columns(groupedDeletedAttachment)}
							dataSource={groupedDeletedAttachment.attachmentObjects}
							pagination={false}
						/>
					))
				) : (
					<AkaTypography variant="body">
						{t('catalog.changes.noChanges', {
							type: 'Änderungen'
						})}
					</AkaTypography>
				)}
			</>
		)
	}
)

AttachmentChanges.propTypes = {
	deletedAttachments: PropTypes.array,
	changedAttachments: PropTypes.array,
	newAttachments: PropTypes.array
}

AttachmentChanges.defaultProps = {
	deletedAttachments: [],
	changedAttachments: [],
	newAttachments: []
}

export default AttachmentChanges
