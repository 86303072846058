import * as React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

/**
 *  A components that catch the errors anywhere in their child component tree,
 *  log those errors, and display a fallback UI instead of
 *  the component error Stack Traces
 */
class ErrorBoundary extends React.Component {
	state = { hasError: false }

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	render() {
		const { children, fallback } = this.props
		if (this.state.hasError) {
			return fallback
		}

		return children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.element,
	t: PropTypes.func
}

export default withTranslation()(ErrorBoundary)
