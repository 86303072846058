import { useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { ATTACHMENT_OBJECT_TYPES, ATTACHMENT_TYPES } from 'modules/core/queries'
import {
	ATTACHMENT_TYPE_KEY,
	CONTRACT_ID_KEY,
	ATTACHMENTS_INIT_FILTERS
} from 'modules/core/constants'
import { FILTER_OPTIONS } from 'modules/core/constants'

const useAttachment = (
	releaseId,
	selectedContractIdsList,
	releaseContracts = [],
	selected
) => {
	const [searchQuery, setSearchQuery] = useState(undefined)
	const [done, setDone] = useState(false)
	const [activeFilterKey, setActiveFilterKey] = useState(
		FILTER_OPTIONS.CONTRACT
	)
	const [filters, setFilters] = useState({
		...ATTACHMENTS_INIT_FILTERS
	})
	const [attachmentObjectTypes, setAttachmentObjectTypes] = useState()
	const [error, setError] = useState({})
	const handleSelectFilter = ({ target: { value: key } }) => {
		setActiveFilterKey(key)
	}
	useEffect(() => {
		if (activeFilterKey === FILTER_OPTIONS.CONTRACT) {
			handleContractChange(selectedContractIdsList[0])
		}
		if (activeFilterKey === FILTER_OPTIONS.ATTACHMENT_TYPE) {
			handleAttachmentTypeChange(attachmentsTypes[0])
		}
	}, [activeFilterKey]) // eslint-disable-line

	const [getAttachmentsObjectTypes, { error: attachmentObjectTypesError }] =
		useLazyQuery(ATTACHMENT_OBJECT_TYPES, {
			variables: { releaseId },
			skip: !selected,
			onCompleted: data => {
				attachmentLoadingDone()
				handleAttachmentObjectTypes(data.attachmentObjectTypes)
			}
		})

	const { error: attachmentTypesError, data: { attachmentsTypes = [] } = {} } =
		useQuery(ATTACHMENT_TYPES, {
			variables: { releaseId },
			skip: !selected,
			onCompleted: () => {
				getAttachmentsObjectTypes()
			}
		})

	const setDefaultContract = () => {
		selectedContractIdsList.length > 0
			? addAttachmentFilter({ [CONTRACT_ID_KEY]: selectedContractIdsList[0] })
			: addAttachmentFilter({ [CONTRACT_ID_KEY]: undefined })
	}

	useEffect(() => {
		handleAttachmentError({
			attachmentTypesError: attachmentTypesError,
			attachmentObjectTypesError: attachmentObjectTypesError
		})
	}, [attachmentObjectTypesError, attachmentTypesError])

	useEffect(() => {
		if (activeFilterKey === FILTER_OPTIONS.CONTRACT) setDefaultContract()
	}, [selectedContractIdsList]) // eslint-disable-line

	const handleSearch = useCallback(search => {
		setSearchQuery(search)
	}, [])

	const attachmentLoadingDone = () => {
		setDone(true)
	}

	const addAttachmentFilter = newFilter => {
		setFilters({ ...filters, ...newFilter })
	}

	const handleAttachmentObjectTypes = attachmentObjectTypes => {
		setAttachmentObjectTypes(attachmentObjectTypes)
	}

	const handleContractChange = value => {
		setFilters({
			...filters,
			[CONTRACT_ID_KEY]: value,
			[ATTACHMENT_TYPE_KEY]: undefined
		})
	}
	const handleAttachmentTypeChange = value => {
		if (value)
			setFilters({
				...filters,
				[ATTACHMENT_TYPE_KEY]: value,
				[CONTRACT_ID_KEY]: undefined
			})
	}

	const handleAttachmentError = errors => {
		setError(errors)
	}

	return {
		filters,
		done,
		handleSearch,
		selectedContractIdsList,
		releaseContracts,
		attachmentsTypes,
		handleContractChange,
		handleSelectFilter,
		handleAttachmentTypeChange,
		activeFilterKey,
		attachmentObjectTypes,
		searchQuery,
		setFilters,
		error
	}
}

export default useAttachment
