import { useCallback, useEffect, useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { RELEASE } from '../../modules/core/queries'
import { useLocation, useHistory } from 'react-router-dom'

export const defaultReleaseData = {
	release: {},
	selectedChapter: {},
	rootChapter: { chapters: [] }
}

const getChapterIdFromURL = search => {
	const selectedChapterIdFromUrl = new URLSearchParams(search).get('chapterId')
	return selectedChapterIdFromUrl && !isNaN(selectedChapterIdFromUrl)
		? selectedChapterIdFromUrl
		: null
}

const useCatalog = id => {
	const [release, setRelease] = useState({})
	const [rootChapter, setRootChapter] = useState({ chapters: [] })
	const [selectedChapter, setSelectedChapterItem] = useState({})
	const [firstChapterId, setFirstChapterId] = useState(undefined)

	const [selectedChapterId, setSelectedChapterId] = useState(undefined)

	const { data, loading, error } = useQuery(RELEASE, {
		variables: { id },
		fetchPolicy: 'network-only'
	})
	const { search } = useLocation()
	const history = useHistory()

	useEffect(() => {
		if (data && data.release) {
			setRelease(data.release)
			setRootChapter(data.release.rootChapter)
			const selectedChapterIdFromURL = getChapterIdFromURL(search)

			if (selectedChapterIdFromURL) {
				setSelectedChapterId(selectedChapterIdFromURL)
			} else {
				setSelectedChapterId(
					data.release.rootChapter.chapters.length > 0
						? data.release.rootChapter.chapters[0].id
						: data.release.rootChapter
				)
			}
			setFirstChapterId(
				data.release.rootChapter.chapters.length > 0
					? data.release.rootChapter.chapters[0].id
					: data.release.rootChapter
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	useEffect(() => {
		if (data && data.release) {
			const selectedChapterIdFromUrl = getChapterIdFromURL(search)
			if (selectedChapterIdFromUrl) {
				setSelectedChapterId(selectedChapterIdFromUrl)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	const setSelectedChapter = useCallback(
		chapter => {
			if (chapter) {
				setSelectedChapterItem(chapter)
			}
		},
		[setSelectedChapterItem] // eslint-disable-line
	)

	const handleSelectChapter = useCallback(
		chapterId => {
			setSelectedChapterId(chapterId || firstChapterId)
			// remove the search parameter from the url when the user changes the chapter by himself.
			history.replace({ search: '' })
		},
		[setSelectedChapterId, firstChapterId, history]
	)

	const isFirstChapter = useMemo(
		() => selectedChapterId === firstChapterId,
		[firstChapterId, selectedChapterId]
	)

	return {
		loadingRelease: loading,
		error,
		handleSelectChapter,
		selectedChapterId,
		setSelectedChapter,
		release,
		isFirstChapter,
		selectedChapter,
		rootChapter
	}
}

useCatalog.propTypes = { id: PropTypes.number.isRequired }

export default useCatalog
