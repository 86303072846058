import React from 'react'
import defaultTheme from './theme'
import { ThemeProvider } from 'styled-components'

const CustomThemeProvider = ({ children, theme = defaultTheme }) => (
	<ThemeProvider key={theme} theme={theme}>
		{children}
	</ThemeProvider>
)
export default CustomThemeProvider
