import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import Header from '../components/Header'
import { RightSide, StyledSiteLayout, Sidebar } from './styles'
import {
	HEADER_HEIGHT,
	SIDER_WIDTH,
	SIDER_COLLAPSED_WIDTH
} from 'modules/core/constants'
import { Layout } from 'antd'

const styles = {
	sideLayout: {
		marginTop: HEADER_HEIGHT
	},
	sidebar: {
		position: 'sticky'
	}
}

const PageLayout = ({
	navigationItems,
	navigationDefaultKey,
	renderSidebarContent,
	renderMainContent,
	onNavigationChange,
	rightSideContent
}) => {
	const [collapsed, setCollapsed] = useState(false)
	return (
		<Layout>
			<Header
				navigationItems={navigationItems}
				navigationDefaultKey={navigationDefaultKey}
				handleSelect={onNavigationChange}
			/>
			{renderSidebarContent && (
				<Sidebar
					onCollapse={() => setCollapsed(!collapsed)}
					collapsible
					width={SIDER_WIDTH}
					collapsed={collapsed}
					style={styles.sidebar}
					collapsedWidth={SIDER_COLLAPSED_WIDTH}
				>
					{renderSidebarContent}
				</Sidebar>
			)}
			<StyledSiteLayout>
				{renderMainContent}
				{rightSideContent && <RightSide>{rightSideContent}</RightSide>}
			</StyledSiteLayout>
		</Layout>
	)
}
PageLayout.propTypes = {
	navigationItems: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, key: PropTypes.string })
	),
	navigationDefaultKey: PropTypes.string,
	renderSidebarContent: PropTypes.node,
	renderMainContent: PropTypes.node,
	mainContentPosition: PropTypes.string,
	onNavigationChange: PropTypes.func,
	rightSideContent: PropTypes.element
}

PageLayout.defaultProps = {
	navigationItems: [],
	navigationDefaultKey: null,
	renderSidebarContent: null,
	renderMainContent: null,
	rightSideContent: null
}
export default PageLayout
