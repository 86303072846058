import { getProcessStatusColor } from './styles'
import { Form, Modal, Input } from 'antd'
import React from 'react'
import { HeadSection, ErrorHandler, AkaTypography } from 'components'
import { TFunction, useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import { SEND_USER_MESSAGE_FOR_APPROVAL } from '../../core/mutations'
import { APPROVAL_FEED_LIST } from '../../core/queries'
import { TenantType } from '../../../generated/types'
import { ProcessStatusType } from './ProcessStatus'
import { ApolloError } from 'apollo-client'
import { ExecutionResult } from 'graphql'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAntdMessageApi } from 'providers'

const FormItem = Form.Item<{ userMessage: string }>
const { TextArea } = Input

interface UserMessageModal {
	open: boolean
	closeMessageModal: () => void
	contentId: string
	status: ProcessStatusType
	tenant: TenantType
	loading: boolean
	error: ApolloError | undefined
	onSubmit: (userMessage: string) => Promise<ExecutionResult>
}

export interface ReportUserMessageModal {
	reportId: string
	systemId: string
	tenant: TenantType
	open: boolean
	closeMessageModal: () => void
	status: ProcessStatusType
}

export interface ContentCheckUserMessageModal
	extends Omit<ReportUserMessageModal, 'reportId'> {
	contentCheckId: string
}

export const ContentCheckUserMessageModal = ({
	contentCheckId,
	systemId,
	...restProps
}: ContentCheckUserMessageModal) => {
	const { quarter } = useParams<{ quarter?: string }>()
	// eslint-disable-next-line no-unsafe-optional-chaining
	const [year, quarterNum] = quarter?.split('-Q') ?? []
	const [sendUserMessage, { error, loading }] = useMutation(
		SEND_USER_MESSAGE_FOR_APPROVAL,
		{
			refetchQueries: [
				{
					query: APPROVAL_FEED_LIST,
					variables: {
						systemId,
						quarter: quarterNum,
						year
					}
				}
			]
		}
	)
	const handleSubmit = async (userMessage: string) => {
		return await sendUserMessage({
			variables: {
				contentCheckId,
				softwareSystemId: systemId,
				userMessage: userMessage
			}
		})
	}
	return (
		<UserMessageModal
			{...restProps}
			contentId={contentCheckId}
			loading={loading}
			error={error}
			onSubmit={handleSubmit}
		/>
	)
}

export const ReportUserMessageModal = ({
	reportId,
	systemId,
	...restProps
}: ReportUserMessageModal) => {
	const { quarter } = useParams<{ quarter?: string }>()
	// eslint-disable-next-line no-unsafe-optional-chaining
	const [year, quarterNum] = quarter?.split('-Q') ?? []
	const [sendUserMessage, { error, loading }] = useMutation(
		SEND_USER_MESSAGE_FOR_APPROVAL,
		{
			refetchQueries: [
				{
					query: APPROVAL_FEED_LIST,
					variables: {
						systemId,
						quarter: quarterNum,
						year
					}
				}
			]
		}
	)
	const handleSubmit = async (userMessage: string) => {
		return await sendUserMessage({
			variables: {
				systemReportId: reportId,
				softwareSystemId: systemId,
				userMessage: userMessage
			}
		})
	}
	return (
		<UserMessageModal
			{...restProps}
			contentId={reportId}
			loading={loading}
			error={error}
			onSubmit={handleSubmit}
		/>
	)
}
const validationSchema = (t: TFunction) =>
	Yup.object().shape({
		userMessage: Yup.string()
			.trim()
			.required(t('approval.approvalList.messageModal.errorNoMessage'))
			.max(500, t('approval.approvalList.messageModal.errorMaxMessage'))
	})

const UserMessageModal = ({
	open,
	closeMessageModal,
	contentId,
	status,
	tenant,
	loading,
	error,
	onSubmit
}: UserMessageModal) => {
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	// Note: this form is a weird mix of formik and antd Form.
	// The validation status is managed by formik, but the values
	// are managed by the antd Form component.
	// Setting <input value={...} /> has no effect while the input is
	// wrapped in a Form.Item.
	const [antdForm] = Form.useForm()

	const {
		handleSubmit,
		handleChange,
		handleBlur,
		values,
		errors,
		touched,
		resetForm
	} = useFormik({
		initialValues: { userMessage: '' },
		onSubmit: async ({ userMessage }) => {
			await onSubmit(userMessage).then(_ => {
				messageApi?.open({
					type: 'success',
					content: t('approval.approvalList.messageModal.success')
				})
				resetForm() // reset validation state
				antdForm.resetFields() // reset values
				closeMessageModal()
			})
		},
		validationSchema: validationSchema(t)
	})

	return (
		<Modal
			bodyStyle={{ margin: 0, padding: 0 }}
			width={'50%'}
			open={open}
			onCancel={closeMessageModal}
			onOk={() => handleSubmit()}
			cancelText={t('approval.approvalList.messageModal.exitModal')}
			okText={t('approval.approvalList.messageModal.sendMessage')}
			confirmLoading={loading}
			title={
				<HeadSection
					id={contentId}
					title={t('approval.approvalList.messageModal.title', {
						tenantName: tenant.name
					})}
					tags={[]}
					logo={
						tenant?.logo && {
							url: tenant.logo.url,
							alt: t('approval.approvalList.reportCard.logoAlt', {
								tenant: tenant.name
							})
						}
					}
				/>
			}
		>
			<div
				style={{
					borderTop: `3px solid ${getProcessStatusColor(status)}`,
					padding: '10px 16px'
				}}
			>
				<ErrorHandler loading={loading} error={error}>
					<Form layout={'vertical'} form={antdForm}>
						<FormItem
							label={t('approval.approvalList.messageModal.label')}
							name={'userMessage'}
							style={{ margin: 0 }}
							validateStatus={
								touched.userMessage && errors.userMessage ? 'error' : ''
							}
							help={
								touched.userMessage && errors.userMessage
									? errors.userMessage
									: undefined
							}
						>
							<TextArea
								rows={6}
								placeholder={t(
									'approval.approvalList.messageModal.placeholder'
								)}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FormItem>
					</Form>
					<AkaTypography variant={'body2'}>
						{t('approval.approvalList.messageModal.messageCounter', {
							count: values.userMessage.length
						})}
					</AkaTypography>
				</ErrorHandler>
			</div>
		</Modal>
	)
}
