import styled from 'styled-components'
import { Card, Table, Tag, Input } from 'antd'

import { CHANGED_REQUIREMENT_STATUS } from 'modules/core/constants'
import theme from 'components/themeProvider/theme'
import { FilterFilled } from '@ant-design/icons'
import { ContentCheckProofStatus, ReportStatus } from 'generated/types'
import { ProcessStatusType } from './ProcessStatus'
import { Link } from 'react-router-dom'

export const StyledCard = styled(Card)`
	border: 1px solid ${({ theme }) => theme.gray};
	margin-bottom: 64px;
`

export const ButtonRightWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 16px;
`

type ChatBubbleType = {
	direction: 'left' | 'right'
}

export const ChatBubble = styled.div<ChatBubbleType>`
	position: relative;
	background: ${({ theme }) => theme.mainGray};
	border-radius: 0.5rem;
	padding: 0.5rem;
	margin: ${({ direction }) =>
		direction === 'left' ? '0 10px 0 0' : '0 0 0 10px'};
	&:not(:first-of-type) {
		margin-top: 8px;
	}
	&:after {
		content: '';
		position: absolute;
		${({ direction }) => `${direction}:-14px;`}
		top: auto;
		bottom: 0px;
		width: 0;
		height: 0;
		border: 14px solid;
		border-color: transparent transparent ${({ theme }) => theme.mainGray}
			transparent;
	}
`

export const StyledSelectRequirementsTable = styled(Table)`
	col:nth-child(even) {
		background: ${props => props.theme.grayLight};
	}

	col:nth-child(odd) {
		background: white;
	}
	table {
		width: fit-content;
		border-collapse: collapse;
		tr {
			> td,
			th {
				padding: 4px !important;
				transition: none;
				&:nth-child(n + 3) {
					text-align: center;
				}
			}
			&.ant-table-measure-row {
				td {
					padding: 0 !important;
				}
			}
		}
		label.ant-checkbox-wrapper {
			span.ant-checkbox {
				.ant-checkbox-inner {
					transition: none;
					animation: none;
					&:after {
						transition: none;
					}
				}
				&::after {
					content: none;
				}
			}
			&::after {
				content: none;
			}
		}
	}

	.ant-table-thead {
		> tr {
			position: relative;
			&::after {
				content: ' ';
				position: absolute;
				border-bottom: solid 1px ${({ theme }) => theme.grayDark};
				bottom: 0;
				left: 0;
				z-index: 4;
				right: 0;
			}
			> th {
				line-height: 0;
				border: 0;
				&::before {
					content: none !important;
				}
				&:nth-child(n + 3) {
					position: relative;
					height: 170px;
					background-color: transparent;
					text-transform: uppercase;
					div {
						position: absolute;
						top: 165px;
						width: 25px;
						left: 50%;
						white-space: nowrap;
						transform: rotate(-90deg);
						transform-origin: left;
						cursor: default;
					}
				}
				&:nth-child(3),
				:nth-child(4) {
					background-color: ${theme.grayLight};
				}
			}
		}
		.ant-checkbox {
			top: 0;
		}
	}
`
export const ChapterCell = styled.div`
	color: ${({ theme }) => theme.primary};
	font-weight: bold;
`

export const RequirementCell = styled.div`
	display: flex;
	div.requirementId {
		font-weight: bold;
		min-width: 90px;
	}
`

export const CenterCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const getBadgeColor = (status: string) => {
	switch (status) {
		case CHANGED_REQUIREMENT_STATUS.NEW:
			return theme.newItem
		case CHANGED_REQUIREMENT_STATUS.CHANGED:
			return theme.changedItem
		case CHANGED_REQUIREMENT_STATUS.DELETED:
			return theme.deletedItem
		default:
			return theme.gray
	}
}

const ProcessStatus = { ...ReportStatus, ...ContentCheckProofStatus }

export const getProcessStatusColor = (status: ProcessStatusType) => {
	switch (status) {
		case ProcessStatus.OPEN:
		case ProcessStatus.EDITING:
		case ProcessStatus.CORRECTION_REQUESTED:
			return theme.font
		case ProcessStatus.IN_REVIEW:
			return theme.orange
		case ProcessStatus.APPROVED:
			return theme.green
		default:
			return theme.grayDark
	}
}
type BadgeWrapperType = {
	circleSize: number
}
export const BadgeWrapper = styled.div<BadgeWrapperType>`
	span {
		.ant-badge-status-dot {
			height: ${props => props.circleSize}px;
			width: ${props => props.circleSize}px;
		}
		.ant-badge-status-text {
			margin: 0;
		}
	}
`

export const FilterMenuButtonWrapper = styled.div`
	border-top: 1px solid ${theme.grayLight};
	padding: 0px 5px 5px 5px;
`

export const FilterMenuHeader = styled.div`
	background-color: ${theme.primary};
	padding: 5px;
	text-align: center;
	text-transform: uppercase;
`
export const CustomFilterIcon = styled(FilterFilled)`
	position: absolute;
	transform: rotate(90deg);
	font-size: 16px;
`

export const PriorityTag = styled(Tag)`
	margin: 0;
	padding: 0 3px;
	cursor: default;
`
export const RequirementLink = styled(Link)`
	text-decoration: underline;
	color: ${({ theme }) => theme.font};
`

export const NumberInput = styled(Input)`
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
	text-align: right;
`
