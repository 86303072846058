import styled from 'styled-components'
import { Image } from 'antd'

export const CardWrapper = styled.div`
	border: 1px solid ${({ theme }) => theme.gray};
	border-top: none;
	margin-bottom: 64px;
`
interface CardSubSectionWrapperProps {
	gray?: boolean
}

export const CardSubSectionWrapper = styled.div<CardSubSectionWrapperProps>`
	border-bottom: 1px solid ${({ theme }) => theme.gray};
	padding: 16px 16px 0px 16px;
	${({ theme, gray }) => gray && `background-color:  ${theme.grayLight};`}
`

export const TagWrapper = styled.div`
	padding-top: 8px;
	display: flex;
	justify-content: flex-start;
`

export const Logo = styled(Image)`
	object-fit: contain;
	max-height: 50px;
	max-width: 220px;
`

export const HeadSectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 16px;
`
