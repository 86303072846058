import React from 'react'
import { ErrorHandler, LoadingSpinner, AkaTypography } from 'components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { SOFTWARE_SYSTEMS } from 'modules/core/queries'
import { SystemCard } from 'modules/account/components/SystemCard'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { Layout } from 'antd'
import { UPDATE_SOFTWARE_SYSTEM } from 'modules/core/mutations'
import { useTranslation } from 'react-i18next'
import { SoftwareSystemType } from 'generated/types'

const { Content } = Layout

const styles = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	}
}

export const SoftwareSystemList = (): React.ReactElement => {
	const [t] = useTranslation()

	const { loading, data: systemsData, error } = useQuery(SOFTWARE_SYSTEMS)

	const [
		updateSoftwareSystem,
		{ loading: isUpdatingSystem, error: updateError }
	] = useMutation(UPDATE_SOFTWARE_SYSTEM, {
		refetchQueries: [{ query: SOFTWARE_SYSTEMS }]
	})

	const handleSubmit = (systemId: string, slogan: string, website: string) =>
		updateSoftwareSystem({ variables: { systemId, slogan, website } })

	if (loading && !systemsData) return <LoadingSpinner fullPage />

	const systemList = systemsData?.softwareSystems

	return (
		<Content style={styles.contentWrapper}>
			<ErrorHandler loading={loading} error={error}>
				{systemList.length > 0 && !loading ? (
					systemList.map((item: SoftwareSystemType) => (
						<SystemCard
							key={item.id}
							item={item}
							onSubmit={handleSubmit}
							loading={isUpdatingSystem}
							error={updateError}
						/>
					))
				) : (
					<AkaTypography variant="body">
						{t('account.systemList.noResults')}
					</AkaTypography>
				)}
			</ErrorHandler>
		</Content>
	)
}
