import React, { memo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Input, Form, Card, Select, DatePicker, Checkbox, Space } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { AkaButton, AkaTypography } from 'components'
import { StylesDictionary } from 'components/typography/styles'
import { DRUG_MODULE_NAME_OPTIONS } from 'modules/core/constants'
import {
	SoftwareSystemType,
	SoftwareSystemReportType,
	ReportInputType,
	ReportSystemInputType,
	ContactPersonInputType
} from 'generated/types'

const FormItem = Form.Item

const styles: StylesDictionary = {
	card: {
		flex: '1 1 48%',
		minWidth: 300
	},
	flexWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8
	},
	flexItem: {
		flex: 1
	},
	submitButton: {
		marginTop: 16,
		float: 'right'
	}
}

const validationSchema = (t: TFunction) =>
	Yup.object().shape({
		manufacturerName: Yup.string(),
		streetAndNumber: Yup.string()
			.trim()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.streetAndNumber')
				})
			),
		postalCode: Yup.string()
			.trim()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.postalCode')
				})
			),
		city: Yup.string()
			.trim()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.city')
				})
			),
		systemVersion: Yup.string()
			.trim()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.systemVersion')
				})
			),
		rolloutDate: Yup.string()
			.nullable()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.rolloutDate')
				})
			),
		website: Yup.string().url(
			t('approval.report.systemInfo.websiteValidation')
		),
		mainContactPerson: Yup.object({
			firstname: Yup.string()
				.trim()
				.required(
					t('approval.report.systemInfo.requiredFieldText', {
						name: t('approval.report.systemInfo.contactPersonFirstname')
					})
				),
			lastname: Yup.string()
				.trim()
				.required(
					t('approval.report.systemInfo.requiredFieldText', {
						name: t('approval.report.systemInfo.contactPersonLastname')
					})
				),
			phoneNumber: Yup.string()
				.trim()
				.required(
					t('approval.report.systemInfo.requiredFieldText', {
						name: t('approval.report.systemInfo.contactPersonPhoneNumber')
					})
				),
			email: Yup.string()
				.email(t('approval.report.systemInfo.contactPersonEmailValidation'))
				.required(
					t('approval.report.systemInfo.requiredFieldText', {
						name: t('approval.report.systemInfo.contactPersonEmail')
					})
				)
		}),
		additionalContactPerson: Yup.object({
			isEnabled: Yup.boolean(),
			firstname: Yup.string().when('isEnabled', {
				is: true,
				then: Yup.string()
					.trim()
					.required(
						t('approval.report.systemInfo.requiredFieldText', {
							name: t('approval.report.systemInfo.contactPersonFirstname')
						})
					)
			}),
			lastname: Yup.string().when('isEnabled', {
				is: true,
				then: Yup.string()
					.trim()
					.required(
						t('approval.report.systemInfo.requiredFieldText', {
							name: t('approval.report.systemInfo.contactPersonLastname')
						})
					)
			}),
			phoneNumber: Yup.string().when('isEnabled', {
				is: true,
				then: Yup.string()
					.trim()
					.required(
						t('approval.report.systemInfo.requiredFieldText', {
							name: t('approval.report.systemInfo.contactPersonPhoneNumber')
						})
					)
			}),
			email: Yup.string().when('isEnabled', {
				is: true,
				then: Yup.string()
					.email(t('approval.report.systemInfo.contactPersonEmailValidation'))
					.required(
						t('approval.report.systemInfo.requiredFieldText', {
							name: t('approval.report.systemInfo.contactPersonEmail')
						})
					)
			})
		}),
		drugModuleName: Yup.string().required(
			t('approval.report.systemInfo.requiredFieldText', {
				name: t('approval.report.systemInfo.drugModuleName')
			})
		),
		drugModuleVersion: Yup.string()
			.trim()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.drugModuleVersion')
				})
			),
		drugModuleDate: Yup.string()
			.nullable()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.drugModuleDate')
				})
			),
		orderAddress: Yup.string()
			.trim()
			.required(
				t('approval.report.systemInfo.requiredFieldText', {
					name: t('approval.report.systemInfo.orderAddressLabel')
				})
			)
	})

type SystemInfoType = Pick<
	SoftwareSystemReportType,
	'rolloutDate' | 'systemVersion'
> &
	Omit<
		SoftwareSystemType,
		'isParticipating' | 'slogan' | 'systemCosts' | 'approvals'
	>

type SystemInfoForm = SystemInfoType & {
	isReadOnly: boolean
	reportId: string
	isSubmitting: boolean
	onSubmit: (
		reportData: ReportInputType,
		systemData: ReportSystemInputType,
		mainContact: ContactPersonInputType,
		additionalContact?: ContactPersonInputType
	) => Promise<void>
}

const SystemInfoForm = ({
	isReadOnly,
	reportId,
	isSubmitting,
	onSubmit,
	...systemData
}: SystemInfoForm) => {
	const [t] = useTranslation()
	return (
		<Formik
			validationSchema={validationSchema(t)}
			initialValues={{
				systemId: systemData.id,
				streetAndNumber: systemData.streetAndNumber,
				city: systemData.city || '',
				postalCode: systemData.postalCode || '',
				systemVersion: systemData.systemVersion || '',
				rolloutDate: systemData.rolloutDate || '',
				website: systemData.website || '',
				mainContactPerson: {
					title: systemData.mainContactPerson?.title || '',
					firstname: systemData.mainContactPerson?.firstname || '',
					lastname: systemData.mainContactPerson?.lastname || '',
					phoneNumber: systemData.mainContactPerson?.phoneNumber || '',
					email: systemData.mainContactPerson?.email || ''
				},
				additionalContactPerson: {
					isEnabled: !!systemData.additionalContactPerson,
					title: systemData.additionalContactPerson?.title || '',
					firstname: systemData.additionalContactPerson?.firstname || '',
					lastname: systemData.additionalContactPerson?.lastname || '',
					phoneNumber: systemData.additionalContactPerson?.phoneNumber || '',
					email: systemData.additionalContactPerson?.email || ''
				},
				drugModuleName: systemData.drugModule?.name || '',
				drugModuleVersion: systemData.drugModule?.version || '',
				drugModuleDate: systemData.drugModule?.date || '',
				orderAddress: systemData.orderAddress
			}}
			onSubmit={values => {
				const {
					mainContactPerson: mainContact,
					additionalContactPerson,
					rolloutDate,
					systemVersion,
					...system
				} = values

				const { isEnabled, ...additionalContact } = additionalContactPerson

				onSubmit(
					{ reportId, rolloutDate, systemVersion },
					system,
					mainContact,
					isEnabled ? additionalContact : undefined
				)
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue
			}) => (
				<Form onFinish={handleSubmit} layout="vertical" labelAlign="left">
					<div style={styles.flexWrapper}>
						<Card
							size="small"
							style={styles.card}
							title={
								<AkaTypography gutterbottom={0} variant="body" color="gray">
									{t('approval.report.systemInfo.manufacturerLabel')}
								</AkaTypography>
							}
						>
							<FormItem
								style={styles.flexItem}
								label={t('approval.report.systemInfo.manufacturerName')}
							>
								<Input
									name="manufacturerName"
									disabled={true}
									defaultValue={systemData.manufacturerName}
								/>
							</FormItem>
							<FormItem
								style={styles.flexItem}
								label={t('approval.report.systemInfo.streetAndNumber')}
								validateStatus={
									touched.streetAndNumber && errors.streetAndNumber
										? 'error'
										: ''
								}
								help={touched.streetAndNumber && errors.streetAndNumber}
								required={true}
							>
								<Input
									name="streetAndNumber"
									value={values.streetAndNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={255}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<div style={styles.flexWrapper}>
								<FormItem
									style={styles.flexItem}
									label={t('approval.report.systemInfo.postalCode')}
									validateStatus={
										touched.postalCode && errors.postalCode ? 'error' : ''
									}
									help={touched.postalCode && errors.postalCode}
									required={true}
								>
									<Input
										name="postalCode"
										value={values.postalCode}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={5}
										readOnly={isReadOnly}
									/>
								</FormItem>
								<FormItem
									label={t('approval.report.systemInfo.city')}
									style={styles.flexItem}
									validateStatus={touched.city && errors.city ? 'error' : ''}
									help={touched.city && errors.city}
									required={true}
								>
									<Input
										name="city"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={255}
										readOnly={isReadOnly}
									/>
								</FormItem>
							</div>
						</Card>
						<Card
							size="small"
							style={styles.card}
							title={
								<AkaTypography gutterbottom={0} variant="body" color="gray">
									{t('approval.report.systemInfo.systemLabel')}
								</AkaTypography>
							}
						>
							<FormItem
								label={t('approval.report.systemInfo.systemName')}
								style={styles.flexItem}
							>
								<Input
									name="systemName"
									disabled={true}
									defaultValue={systemData.systemName}
								/>
							</FormItem>
							<div style={styles.flexWrapper}>
								<FormItem
									style={styles.flexItem}
									label={t('approval.report.systemInfo.systemVersion')}
									validateStatus={
										touched.systemVersion && errors.systemVersion ? 'error' : ''
									}
									help={touched.systemVersion && errors.systemVersion}
									required={true}
								>
									<Input
										name="systemVersion"
										value={values.systemVersion}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={255}
										readOnly={isReadOnly}
									/>
								</FormItem>
								<FormItem
									label={t('approval.report.systemInfo.rolloutDate')}
									style={{
										...styles.flexItem,
										...{ pointerEvents: isReadOnly ? 'none' : 'auto' }
									}}
									validateStatus={
										touched.rolloutDate && errors.rolloutDate ? 'error' : ''
									}
									help={touched.rolloutDate && errors.rolloutDate}
									required={true}
								>
									<DatePicker
										style={{ width: '100%' }}
										name="rolloutDate"
										value={
											values.rolloutDate ? moment(values.rolloutDate) : null
										}
										format={'DD.MM.YYYY'}
										onChange={date => {
											setFieldValue(
												'rolloutDate',
												date
													? moment(date).format('YYYY-MM-DD').toString()
													: null,
												true
											)
										}}
										onBlur={handleBlur}
									/>
								</FormItem>
							</div>
							<FormItem
								label={t('approval.report.systemInfo.website')}
								style={styles.flexItem}
								validateStatus={
									touched.website && errors.website ? 'error' : ''
								}
								help={touched.website && errors.website}
							>
								<Input
									name="website"
									value={values.website}
									onChange={handleChange}
									onBlur={handleBlur}
									type="url"
									readOnly={isReadOnly}
								/>
							</FormItem>
						</Card>
						<Card
							size="small"
							style={styles.card}
							title={
								<AkaTypography gutterbottom={0} variant="body" color="gray">
									{t('approval.report.systemInfo.mainContactPersonLabel')}
								</AkaTypography>
							}
						>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonTitle')}
								style={styles.flexItem}
							>
								<Input
									name="mainContactPerson.title"
									value={values.mainContactPerson.title}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={255}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonFirstname')}
								style={styles.flexItem}
								validateStatus={
									touched.mainContactPerson?.firstname &&
									errors.mainContactPerson?.firstname
										? 'error'
										: ''
								}
								help={
									touched.mainContactPerson?.firstname &&
									errors.mainContactPerson?.firstname
								}
								required={true}
							>
								<Input
									name="mainContactPerson.firstname"
									value={values.mainContactPerson.firstname}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={255}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonLastname')}
								style={styles.flexItem}
								validateStatus={
									touched.mainContactPerson?.lastname &&
									errors.mainContactPerson?.lastname
										? 'error'
										: ''
								}
								help={
									touched.mainContactPerson?.lastname &&
									errors.mainContactPerson?.lastname
								}
								required={true}
							>
								<Input
									name="mainContactPerson.lastname"
									value={values.mainContactPerson.lastname}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={255}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonPhoneNumber')}
								style={styles.flexItem}
								validateStatus={
									touched.mainContactPerson?.phoneNumber &&
									errors.mainContactPerson?.phoneNumber
										? 'error'
										: ''
								}
								help={
									touched.mainContactPerson?.phoneNumber &&
									errors.mainContactPerson?.phoneNumber
								}
								required={true}
							>
								<Input
									name="mainContactPerson.phoneNumber"
									value={values.mainContactPerson.phoneNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									type="tel"
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonEmail')}
								style={styles.flexItem}
								validateStatus={
									touched.mainContactPerson?.email &&
									errors.mainContactPerson?.email
										? 'error'
										: ''
								}
								help={
									touched.mainContactPerson?.email &&
									errors.mainContactPerson?.email
								}
								required={true}
							>
								<Input
									name="mainContactPerson.email"
									value={values.mainContactPerson.email}
									onChange={handleChange}
									onBlur={handleBlur}
									type="email"
									readOnly={isReadOnly}
								/>
							</FormItem>
						</Card>
						<Card
							size="small"
							style={styles.card}
							title={
								<Space size={8}>
									<AkaTypography gutterbottom={0} variant="body" color="gray">
										{t(
											'approval.report.systemInfo.additionalContactPersonLabel'
										)}
									</AkaTypography>
									<Checkbox
										name="additionalContactPerson.isEnabled"
										checked={values.additionalContactPerson.isEnabled}
										onChange={handleChange}
										disabled={isReadOnly}
									/>
								</Space>
							}
						>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonTitle')}
								style={styles.flexItem}
							>
								<Input
									name="additionalContactPerson.title"
									value={values.additionalContactPerson.title}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={!values.additionalContactPerson.isEnabled}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonFirstname')}
								style={styles.flexItem}
								validateStatus={
									touched.additionalContactPerson?.firstname &&
									errors.additionalContactPerson?.firstname
										? 'error'
										: ''
								}
								help={
									touched.additionalContactPerson?.firstname &&
									errors.additionalContactPerson?.firstname
								}
								required={true}
							>
								<Input
									name="additionalContactPerson.firstname"
									value={values.additionalContactPerson.firstname}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={!values.additionalContactPerson.isEnabled}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonLastname')}
								style={styles.flexItem}
								validateStatus={
									touched.additionalContactPerson?.lastname &&
									errors.additionalContactPerson?.lastname
										? 'error'
										: ''
								}
								help={
									touched.additionalContactPerson?.lastname &&
									errors.additionalContactPerson?.lastname
								}
								required={true}
							>
								<Input
									name="additionalContactPerson.lastname"
									value={values.additionalContactPerson.lastname}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={!values.additionalContactPerson.isEnabled}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonPhoneNumber')}
								style={styles.flexItem}
								validateStatus={
									touched.additionalContactPerson?.phoneNumber &&
									errors.additionalContactPerson?.phoneNumber
										? 'error'
										: ''
								}
								help={
									touched.additionalContactPerson?.phoneNumber &&
									errors.additionalContactPerson?.phoneNumber
								}
								required={true}
							>
								<Input
									name="additionalContactPerson.phoneNumber"
									value={values.additionalContactPerson.phoneNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={!values.additionalContactPerson.isEnabled}
									readOnly={isReadOnly}
								/>
							</FormItem>
							<FormItem
								label={t('approval.report.systemInfo.contactPersonEmail')}
								style={styles.flexItem}
								validateStatus={
									touched.additionalContactPerson?.email &&
									errors.additionalContactPerson?.email
										? 'error'
										: ''
								}
								help={
									touched.additionalContactPerson?.email &&
									errors.additionalContactPerson?.email
								}
								required={true}
							>
								<Input
									name="additionalContactPerson.email"
									value={values.additionalContactPerson.email}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={!values.additionalContactPerson.isEnabled}
									readOnly={isReadOnly}
								/>
							</FormItem>
						</Card>
						<Card
							size="small"
							style={styles.card}
							title={
								<AkaTypography gutterbottom={0} variant="body" color="gray">
									{t('approval.report.systemInfo.drugModuleLabel')}
								</AkaTypography>
							}
						>
							<FormItem
								label={t('approval.report.systemInfo.drugModuleName')}
								name="drugModuleName"
								initialValue={values.drugModuleName}
								style={{
									...styles.flexItem,
									...{ pointerEvents: isReadOnly ? 'none' : 'auto' }
								}}
								validateStatus={
									touched.drugModuleName && errors.drugModuleName ? 'error' : ''
								}
								help={touched.drugModuleName && errors.drugModuleName}
								required={true}
							>
								<Select
									options={DRUG_MODULE_NAME_OPTIONS}
									onChange={value => {
										setFieldValue('drugModuleName', value)
									}}
									onBlur={handleBlur}
								/>
							</FormItem>
							<div style={styles.flexWrapper}>
								<FormItem
									label={t('approval.report.systemInfo.drugModuleVersion')}
									style={styles.flexItem}
									validateStatus={
										touched.drugModuleVersion && errors.drugModuleVersion
											? 'error'
											: ''
									}
									help={touched.drugModuleVersion && errors.drugModuleVersion}
									required={true}
								>
									<Input
										name="drugModuleVersion"
										value={values.drugModuleVersion}
										onChange={handleChange}
										onBlur={handleBlur}
										readOnly={isReadOnly}
									/>
								</FormItem>
								<FormItem
									label={t('approval.report.systemInfo.drugModuleDate')}
									style={{
										...styles.flexItem,
										...{ pointerEvents: isReadOnly ? 'none' : 'auto' }
									}}
									validateStatus={
										touched.drugModuleDate && errors.drugModuleDate
											? 'error'
											: ''
									}
									help={touched.drugModuleDate && errors.drugModuleDate}
									required={true}
								>
									<DatePicker
										style={{ width: '100%' }}
										name="drugModuleDate"
										value={
											values.drugModuleDate
												? moment(values.drugModuleDate)
												: null
										}
										format={'DD.MM.YYYY'}
										onChange={date => {
											setFieldValue(
												'drugModuleDate',
												date
													? moment(date).format('YYYY-MM-DD').toString()
													: null,
												true
											)
										}}
										onBlur={handleBlur}
									/>
								</FormItem>
							</div>
						</Card>
						<Card
							size="small"
							style={styles.card}
							title={
								<AkaTypography gutterbottom={0} variant="body" color="gray">
									{t('approval.report.systemInfo.orderAddressLabel')}
								</AkaTypography>
							}
						>
							<FormItem
								validateStatus={
									touched.orderAddress && errors.orderAddress ? 'error' : ''
								}
								help={touched.orderAddress && errors.orderAddress}
							>
								<Input.TextArea
									name="orderAddress"
									maxLength={1000}
									autoSize={{ minRows: 6, maxRows: 6 }}
									value={values.orderAddress}
									onChange={handleChange}
									onBlur={handleBlur}
									readOnly={isReadOnly}
								/>
							</FormItem>
						</Card>
					</div>
					{!isReadOnly && (
						<AkaButton
							style={styles.submitButton}
							type="primary"
							htmlType="submit"
							loading={isSubmitting}
						>
							{t('approval.report.systemInfo.submitButton')}
						</AkaButton>
					)}
				</Form>
			)}
		</Formik>
	)
}

export default memo(SystemInfoForm)
