import styled from 'styled-components'

import theme from 'components/themeProvider/theme'

export const SideWrapper = styled.div`
	display: flex;
	align-items: left;
	flex-direction: column;
`

export const InfoBackground = styled.div`
	background-color: ${theme.mainGray};
	padding: 20px 16px;
	border-bottom: 1px solid lightgray;
`

export const ContentCheckInstructionHintWrapper = styled.div`
	background-color: ${theme.grayLight};
	padding: 20px 16px;
	display: flex;
	align-items: flex-start;
	padding: 12px;
	border-radius: 6px;
`

export const StepperWrapper = styled.div`
	padding: 20px 16px;
`
