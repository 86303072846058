import React, { useCallback, useState } from 'react'
import moment from 'moment'
import 'moment/locale/de'
import { TFunction, useTranslation } from 'react-i18next'
import theme from 'components/themeProvider/theme'
import {
	AkaButton,
	AkaTypography,
	Card,
	CardSubSection,
	HeadSection
} from 'components'
import { StylesDictionary } from 'components/typography/styles'
import { ProcessStatusSteps } from './ProcessStatus'
import { ContentCheckProofStatus, ContentCheckType } from 'generated/types'
import { ROUTE_FUNCTIONS } from '../../navigation'
import { useParams, Link } from 'react-router-dom'
import { getProcessStatusColor } from './styles'
import { ContentCheckUserMessageModal } from './UserMessageModal'

moment.locale('de')

const styles: StylesDictionary = {
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 16
	},
	infoRow: {
		display: 'flex',
		justifyContent: 'start'
	},
	infoItem: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 100,
		marginLeft: 8
	},
	topBorder: {
		borderTop: `1px solid ${theme.gray}`
	},
	title: {
		fontWeight: 'bold'
	}
}
type ColumnType = {
	title: string
	dataIndex: string
	key: string
}

const columns = (t: TFunction): ColumnType[] => [
	{
		title: t('approval.approvalList.contentCheckCard.columns.appointment'),
		dataIndex: 'submissionDeadline',
		key: 'submissionDeadline'
	},
	{
		title: t('approval.approvalList.contentCheckCard.columns.contract'),
		dataIndex: 'contractId',
		key: 'contractId'
	},
	{
		title: t('approval.approvalList.contentCheckCard.columns.status'),
		dataIndex: 'status',
		key: 'status'
	}
]

const displayContentCheckInfo = (
	header: ColumnType[],
	contents: React.ReactElement[]
) => {
	return (
		<div style={styles.infoRow}>
			{contents.map((content, i) => {
				return (
					<div style={styles.infoItem} key={i}>
						<AkaTypography style={styles.title} variant="body2">
							{header[i].title}
						</AkaTypography>
						{content}
					</div>
				)
			})}
		</div>
	)
}

export type ContentCheckCard = Pick<
	ContentCheckType,
	'id' | 'title' | 'contract' | 'approvalRun' | 'instructionFileUrl'
> & { selectedSystemId: string; status: ContentCheckProofStatus }

const ContentCheckCard = ({
	id,
	title,
	contract,
	approvalRun,
	status,
	selectedSystemId
}: ContentCheckCard) => {
	const [t] = useTranslation()
	const { quarter } = useParams<{ quarter?: string }>()

	const [open, setOpen] = useState(false)
	const openMessageModal = useCallback(() => setOpen(true), [])
	const closeMessageModal = useCallback(() => setOpen(false), [])

	return (
		<Card style={{ borderTop: `3px solid ${getProcessStatusColor(status)}` }}>
			<CardSubSection>
				<HeadSection
					id={id}
					title={`${t(
						'approval.approvalList.contentCheckCard.title'
					)}: ${title}`}
					tags={[]}
					logo={
						approvalRun?.tenant?.logo && {
							url: approvalRun.tenant.logo.url,
							alt: t('approval.approvalList.contentCheckCard.logoAlt', {
								tenant: approvalRun.tenant.name
							})
						}
					}
				/>
			</CardSubSection>
			<CardSubSection gray={true}>
				{displayContentCheckInfo(columns(t), [
					<AkaTypography variant="body2" key={1}>
						{moment(approvalRun.submissionDeadline)
							.format('DD.MM.YYYY')
							.toString()}
					</AkaTypography>,
					<AkaTypography key={2} variant="body2">
						{contract.contractId}
					</AkaTypography>,
					<ProcessStatusSteps key={3} status={status} />
				])}
			</CardSubSection>
			<CardSubSection gray={true}>
				<div style={styles.buttonWrapper}>
					<AkaButton style={{ marginRight: 16 }} onClick={openMessageModal}>
						{t('approval.approvalList.reportCard.writeMessage')}
					</AkaButton>
					<ContentCheckUserMessageModal
						contentCheckId={id}
						systemId={selectedSystemId}
						tenant={approvalRun.tenant}
						open={open}
						closeMessageModal={closeMessageModal}
						status={status}
					/>
					<Link
						to={ROUTE_FUNCTIONS.getContentCheckDetailsPath(
							quarter || '',
							id,
							selectedSystemId
						)}
					>
						<AkaButton
							key="submit"
							type="primary"
							data-testid={`link-to-catalog-${id}`}
						>
							{status === ContentCheckProofStatus.APPROVED ||
							status === ContentCheckProofStatus.IN_REVIEW
								? t('approval.approvalList.contentCheckCard.showContentCheck')
								: t('approval.approvalList.contentCheckCard.editContentCheck')}
						</AkaButton>
					</Link>
				</div>
			</CardSubSection>
		</Card>
	)
}
export default ContentCheckCard
