import { Layout, Select, Spin } from 'antd'
import ChaptersIndex from './ChaptersIndex'
import { useHistory } from 'react-router-dom'
import React, { useContext } from 'react'
import { ErrorHandler } from 'components'
import { debounce } from 'hooks/helpers'
import { useLazyQuery } from '@apollo/react-hooks'
import { SEARCH_RELEASE_REQUIREMENTS } from 'modules/core/queries'
import { ContractsFilterContext } from './CatalogPage'
import { useTranslation } from 'react-i18next'
import { SideWrapper } from './styles'

const { Option } = Select

const styles = {
	width: { width: '100%' },
	optionWidth: {
		width: 'max-content',
		padding: 4
	},
	font: { fontFamily: 'SegoeUI, sans-serif' },
	layout: { display: 'flex' },
	wrapper: {
		padding: 'unset',
		overflow: 'auto'
	}
}

const CatalogSider = ({ releaseId, ...catalog }) => {
	const {
		loadingRelease: loading,
		error,
		rootChapter,
		handleSelectChapter,
		selectedChapterId
	} = catalog

	const { selectedContractIdsList: selectedContracts } = useContext(
		ContractsFilterContext
	)

	const [
		search,
		{ loading: loadingResults, data: { releaseRequirementsSearch = [] } = {} }
	] = useLazyQuery(SEARCH_RELEASE_REQUIREMENTS)

	const handleSearch = debounce(
		value =>
			search({
				variables: {
					releaseId: releaseId,
					query: value,
					selectedContracts
				}
			}),
		500
	)

	const [t] = useTranslation()
	const history = useHistory()

	return (
		<ErrorHandler loading={loading} error={error}>
			<Layout style={styles.layout}>
				<SideWrapper gray>
					<Select
						showSearch
						placeholder={t('core.search')}
						defaultActiveFirstOption={false}
						style={{ ...styles.width, ...styles.font }}
						showArrow={false}
						filterOption={false}
						onSearch={handleSearch}
						onChange={value => {
							const [chapterId, prefixedCaliberId] = value.split('-')
							const searchParams = new URLSearchParams({
								chapterId: chapterId,
								requirementId: prefixedCaliberId
							})
							history.replace({
								search: `?${searchParams.toString()}`
							})
						}}
						notFoundContent={loadingResults ? <Spin size="small" /> : null}
						dropdownMatchSelectWidth={false}
					>
						{releaseRequirementsSearch.map(result => (
							<Option
								key={`${result.id}-${result.chapter.id}`}
								value={`${result.chapter.id}-${result.prefixedCaliberId}`}
								style={{ ...styles.optionWidth, ...styles.font }}
							>
								{`${result.prefixedCaliberId} — ${result.title}`}
							</Option>
						))}
					</Select>
				</SideWrapper>
				<SideWrapper style={styles.wrapper}>
					<ChaptersIndex
						loading={loading}
						rootChapter={rootChapter}
						onSelectChapter={handleSelectChapter}
						selectedChapterId={selectedChapterId}
					/>
				</SideWrapper>
			</Layout>
		</ErrorHandler>
	)
}

export default CatalogSider
