import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Select, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { AkaTypography, LoadingSpinner, AkaButton } from 'components'
import { ButtonRightWrapper } from './styles'
import { RegionType } from '../../../generated/types'
import { ContractRegionGroupType } from 'generated/types'

const { Option } = Select

function usePrevious(value: string[]) {
	const ref = useRef<string[]>()
	useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}

const compareStrings = (a: string, b: string) => (a < b ? -1 : a === b ? 0 : 1)

/**
 * Compares two lists of group IDs regardless of their order
 * */
const groupIdsAreEqual = (idList1: string[], idList2: string[]) => {
	if (idList1.length !== idList2.length) {
		return false
	}
	for (const value of idList1) {
		if (idList2.indexOf(value) === -1) {
			return false
		}
	}
	return true
}

export interface ContractRegionsTable {
	contractRegionGroups: ContractRegionGroupType[]
	defaultSelectedContractRegionGroupsIds?: string[]
	loadingTable: boolean
	isUploading: boolean
	onSubmit: (selectedKeys: string[]) => Promise<void>
	isSubmitting: boolean
	isSubmitted: boolean
	isReadOnly: boolean
}

const ContractRegionsTable = ({
	contractRegionGroups,
	defaultSelectedContractRegionGroupsIds = [],
	loadingTable = true,
	isUploading,
	onSubmit,
	isSubmitting,
	isReadOnly,
	isSubmitted
}: ContractRegionsTable) => {
	const previousSelectedContractRegionGroupsIds = usePrevious(
		defaultSelectedContractRegionGroupsIds
	)
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
		defaultSelectedContractRegionGroupsIds
	)

	const [filterRegionOkvList, setFilterRegionOkvList] = useState<string[]>([])

	const regions = useMemo(() => {
		const regions: RegionType[] = []
		if (!contractRegionGroups) {
			return regions
		}
		for (const group of contractRegionGroups) {
			for (const region of group.regions) {
				if (regions.find(r => r.okv === region.okv)) {
					continue
				}
				regions.push(region)
			}
		}
		regions.sort((a, b) => compareStrings(a.name, b.name))
		return regions
	}, [contractRegionGroups])

	useEffect(() => {
		if (
			!groupIdsAreEqual(
				defaultSelectedContractRegionGroupsIds,
				previousSelectedContractRegionGroupsIds || []
			)
		) {
			setSelectedKeys(defaultSelectedContractRegionGroupsIds)
		}
	}, [
		defaultSelectedContractRegionGroupsIds,
		previousSelectedContractRegionGroupsIds
	])

	const [t] = useTranslation()
	if (loadingTable) return <LoadingSpinner fullPage />
	if (contractRegionGroups.length === 0 && !loadingTable)
		return (
			<AkaTypography data-testid="no-results" variant="body">
				{t('approval.report.contractRegionsTable.noResults')}
			</AkaTypography>
		)

	const columns = [
		{
			title: t('approval.report.contractRegionsTable.columnTitles.contract'),
			dataIndex: 'contract'
		},
		{
			title: t('approval.report.contractRegionsTable.columnTitles.regions'),
			dataIndex: 'regions'
		}
	]

	const filteredContractRegionGroups = contractRegionGroups.filter(group => {
		if (filterRegionOkvList.length === 0) {
			// show all groups if no filter is selected
			return true
		}
		for (const region of group.regions) {
			if (filterRegionOkvList.indexOf(region.name) !== -1) {
				// show group if any of its regions is selected
				return true
			}
		}
		return false
	})
	// sort regions table so that contracts with multiple regions are placed lower in the list.
	filteredContractRegionGroups.sort((a, b) => {
		if (a.regions.length !== b.regions.length)
			return a.regions.length - b.regions.length
		//sort entries alphabetically based on their first region.
		return compareStrings(a.regions[0].name, b.regions[0].name)
	})

	const tableData = filteredContractRegionGroups.map(contractRegionGroup => {
		const regions = contractRegionGroup.regions
		return {
			key: contractRegionGroup.id,
			contract: contractRegionGroup.contract.contractId,
			regions:
				regions.length === 1
					? regions[0].name
					: contractRegionGroup.regions.map((region, index, original) => (
							<React.Fragment key={`${region.okv} ${index}`}>
								<Tooltip title={region.name}>
									<span>{region.shortName}</span>
								</Tooltip>
								{index + 1 < original.length && ', '}
							</React.Fragment>
					  ))
		}
	})

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setSelectedKeys(selectedRowKeys)
		},
		getCheckboxProps: () => {
			return {
				disabled: isReadOnly
			}
		}
	}

	const handleSubmit = () => {
		onSubmit(selectedKeys as string[])
	}
	return (
		<>
			<div style={{ margin: '0 0 30px 0 ' }}>
				<AkaTypography variant="body3" style={{ margin: '0 0 5px 0' }}>
					{t('approval.report.contractRegionsTable.filterByRegions')}:
				</AkaTypography>
				<Select
					style={{ width: '100%' }}
					mode="multiple"
					size={'large'}
					disabled={regions.length === 0}
					onChange={value => {
						setFilterRegionOkvList(value)
					}}
				>
					{regions.map(region => {
						return (
							<Option key={region.okv} value={region.name}>
								{region.name}
							</Option>
						)
					})}
				</Select>
			</div>
			<Table
				rowSelection={{
					type: 'checkbox',
					selectedRowKeys: selectedKeys,
					...rowSelection
				}}
				columns={columns}
				dataSource={tableData}
				pagination={false}
			/>
			<ButtonRightWrapper>
				{!isReadOnly && (
					<AkaButton
						key="submit"
						type="primary"
						loading={isUploading}
						onClick={handleSubmit}
						disabled={isSubmitting}
					>
						{t('approval.report.contractRegionsTable.submitButton')}
					</AkaButton>
				)}
				{isSubmitted && (
					<AkaTypography variant={'tiny'}>
						{t('approval.report.reportAlreadySubmitted')}
					</AkaTypography>
				)}
			</ButtonRightWrapper>
		</>
	)
}

export default React.memo(ContractRegionsTable)
