import React, { useLayoutEffect } from 'react'
import * as PropTypes from 'prop-types'
import { HEADER_HEIGHT } from 'modules/core/constants'

const ScrollInView = ({ id, highlight, children, dependencies }) => {
	useLayoutEffect(() => {
		const element = document.getElementById(id)
		if (element) {
			if (highlight) {
				element.style.boxShadow = '0 0 10px #003b76'
			}
			setTimeout(() => {
				const topWithHeaderOffset =
					element.getBoundingClientRect().top -
					document.body.getBoundingClientRect().top -
					HEADER_HEIGHT +
					1
				window.scrollTo({
					behavior: 'smooth',
					top: topWithHeaderOffset
				})
				// This delay is necessary because of a css transition in RequirementsItems (Collapse component) to get the correct scroll position.
			}, 550)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies])
	return <>{children}</>
}

ScrollInView.propTypes = {
	id: PropTypes.string.isRequired,
	highlight: PropTypes.bool,
	children: PropTypes.node.isRequired,
	dependencies: PropTypes.array
}

ScrollInView.defaultProps = {
	highlight: true,
	dependencies: []
}

export default ScrollInView
