import React, { useEffect, memo } from 'react'
import * as PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import { CHAPTER_DIFF } from 'modules/core/queries'
import Chapter from '../components/Chapter'

const ChapterDiffDetails = memo(
	({
		releaseLoading,
		id,
		setSelectedChapter,
		comparedReleaseId,
		isFirstChapter
	}) => {
		const [getChapterDiff, { loading, data, error }] = useLazyQuery(
			CHAPTER_DIFF,
			{
				variables: { id, comparedReleaseId },
				fetchPolicy: 'network-only',
				onCompleted: data => {
					setSelectedChapter(data.chapterDiff)
				}
			}
		)

		useEffect(() => {
			if (id) {
				getChapterDiff()
			}
		}, [id, getChapterDiff, comparedReleaseId])

		return (
			<Chapter
				isFirstChapter={isFirstChapter}
				loading={loading}
				chapter={data && data.chapterDiff}
				error={error}
				comparedReleaseId={comparedReleaseId}
				releaseLoading={releaseLoading}
			/>
		)
	}
)

ChapterDiffDetails.propTypes = {
	releaseLoading: PropTypes.bool,
	id: PropTypes.string,
	setSelectedChapter: PropTypes.func,
	comparedReleaseId: PropTypes.string
}

ChapterDiffDetails.defaultProps = {
	releaseLoading: true,
	id: undefined,
	setSelectedChapter: () => {},
	comparedReleaseId: undefined
}

export default ChapterDiffDetails
