import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import { AkaTypography } from 'components'
import ReactHtmlParser from 'react-html-parser'
import Requirements from './Requirements'
import { ChapterWrapper } from './styles'
import RequirementsDiff from '../containers/RequirementsDiff'

const ChapterDetails = memo(
	({ chapter, comparedReleaseId, isFirstChapter }) => {
		return (
			<ChapterWrapper>
				{!isFirstChapter && (
					<AkaTypography
						data-testid="chapter-title"
						gutterbottom={50}
						variant="h2"
					>
						{chapter.fullTitle}
					</AkaTypography>
				)}
				<AkaTypography data-testid="chapter-description" variant="body2">
					{ReactHtmlParser(chapter.description)}
				</AkaTypography>
				{!comparedReleaseId &&
					chapter.requirements &&
					chapter.requirements.length > 0 && (
						<Requirements
							list={chapter.requirements}
							loading={false}
							isDiff={false}
						/>
					)}
				{comparedReleaseId && (
					<RequirementsDiff
						chapterId={chapter.id}
						comparedReleaseId={comparedReleaseId}
						loading={false}
					/>
				)}
				{chapter.chapters.length > 0 && (
					<div style={{ paddingLeft: 16 }}>
						{chapter.chapters.map(ch => (
							<AkaTypography
								variant="body2"
								data-testid={`subchapter-${ch.id}`}
								key={ch.id}
							>
								{ch.fullTitle}
							</AkaTypography>
						))}
					</div>
				)}
			</ChapterWrapper>
		)
	}
)

ChapterDetails.propTypes = {
	chapter: PropTypes.shape({}),
	comparedReleaseId: PropTypes.string
}

ChapterDetails.defaultProps = { chapter: {}, comparedReleaseId: undefined }

export default ChapterDetails
