import { AkaTypography } from 'components'
import styled from 'styled-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ContentCheckProofStatus, ReportStatus } from 'generated/types'
import { getProcessStatusColor } from './styles'

const StepsWrapper = styled.div`
	display: flex;
	align-items: center;
`

type DotPropsType = {
	step: number
	index: number
	color: string
}

const Dot = styled.div<DotPropsType>`
	height: 8px;
	width: 8px;
	border-radius: 100%;
	background: ${({ theme, step, index, color }) =>
		step >= index ? color : theme.gray};
`

type LinePropsType = {
	step: number
	index: number
	stepsCount: number
	color: string
}

const Line = styled.div<LinePropsType>`
	width: 18px;
	margin: 2px;
	border-top: ${({ theme, step, index, color }) => `2px dashed
		${step > index ? color : theme.gray}`};
`

const ProcessStatus = { ...ReportStatus, ...ContentCheckProofStatus }
export type ProcessStatusType = keyof typeof ProcessStatus

const STATUS_INDEX_MAP: Record<ProcessStatusType, number> = {
	[ProcessStatus.OPEN]: 0,
	[ProcessStatus.EDITING]: 0,
	[ProcessStatus.IN_REVIEW]: 1,
	[ProcessStatus.CORRECTION_REQUESTED]: 0,
	[ProcessStatus.APPROVED]: 2
}

const stepsCount = 3

export const ProcessStatusSteps = ({
	status = ProcessStatus.IN_REVIEW
}: {
	status: ProcessStatusType
}) => {
	const [t] = useTranslation()
	const currentStep = STATUS_INDEX_MAP[status]

	return (
		<StepsWrapper>
			{new Array(stepsCount).fill(null).map((_, index) => {
				return (
					<StepsWrapper key={index}>
						<Dot
							step={currentStep}
							index={index}
							color={getProcessStatusColor(status)}
						/>
						{index !== stepsCount - 1 && (
							<Line
								step={currentStep}
								index={index}
								stepsCount={stepsCount}
								color={getProcessStatusColor(status)}
							/>
						)}
					</StepsWrapper>
				)
			})}
			{
				<AkaTypography
					style={{ marginBottom: 0, marginLeft: 8 }}
					variant="body2"
				>
					<span style={{ color: getProcessStatusColor(status) }}>
						{t(`approval.processStatus.${status}`)}
					</span>
				</AkaTypography>
			}
		</StepsWrapper>
	)
}
