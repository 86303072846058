import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import NotificationsComponent from '../components/Notifications'
import { ErrorHandler } from 'components'
import { ALL_CATEGORIES_KEY } from '../../core/constants'

const styles = { padding: { padding: '0 20px' } }

const NotificationsList = ({
	list,
	loadingList,
	errorLoadingList,
	selectedCategory
}) => {
	const filteredNotifications = useMemo(
		() =>
			selectedCategory !== ALL_CATEGORIES_KEY
				? list.filter(
						notification => notification.category === selectedCategory
				  )
				: list,
		[list, selectedCategory]
	)

	return (
		<div style={styles.padding}>
			<ErrorHandler loading={loadingList} error={errorLoadingList}>
				<NotificationsComponent
					list={filteredNotifications}
					loading={loadingList}
				/>
			</ErrorHandler>
		</div>
	)
}
NotificationsList.propTypes = {
	list: PropTypes.array,
	loadingList: PropTypes.bool,
	errorLoadingList: PropTypes.object,
	selectedCategory: PropTypes.string
}

NotificationsList.defaultProps = {
	list: [],
	loadingList: true,
	errorLoadingList: undefined,
	selectedCategory: ''
}

export default NotificationsList
