import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { StylesDictionary } from 'components/typography/styles'
import PageLayout from 'modules/layout/containers/PageLayout'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { Layout } from 'antd'
import { ROUTES, ROUTE_FUNCTIONS } from 'modules/navigation'
import ReportSider, { STEPS_CHOICE } from './ReportSider'
import { SOFTWARE_SYSTEM_REPORT_SYSTEM_INFO } from 'modules/core/queries'
import { UPDATE_REPORT_SOFTWARE_SYSTEM_INFO } from 'modules/core/mutations'
import { ErrorHandler, LoadingSpinner, AkaTypography } from 'components'
import SystemInfoForm from 'modules/approval/components/SystemInfoForm'
import {
	SoftwareSystemReportSystemInfoQuery,
	SoftwareSystemReportSystemInfoQueryVariables,
	ReportInputType,
	ReportSystemInputType,
	ContactPersonInputType
} from 'generated/types'
import { useUser, useAntdMessageApi } from 'providers'
import { isReportSubmitted } from 'utils/helper.utils'

const { Content } = Layout

const styles: StylesDictionary = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	},
	alignRight: {
		marginTop: 16,
		float: 'right'
	}
}

const SystemInfoPage = () => {
	const { id: currentReportId, quarter } = useParams<{
		quarter: string
		id: string
	}>()
	const history = useHistory()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const {
		user: { isVswUser }
	} = useUser()

	const {
		data: systemInfoData,
		loading: systemInfoLoading,
		error: systemInfoError
	} = useQuery<
		SoftwareSystemReportSystemInfoQuery,
		SoftwareSystemReportSystemInfoQueryVariables
	>(SOFTWARE_SYSTEM_REPORT_SYSTEM_INFO, {
		variables: { id: currentReportId }
	})

	const [
		updateReportSoftwareSystemInfo,
		{ loading: updatingSystemInfo, error: updateSystemInfoError }
	] = useMutation(UPDATE_REPORT_SOFTWARE_SYSTEM_INFO, {
		refetchQueries: [
			{
				query: SOFTWARE_SYSTEM_REPORT_SYSTEM_INFO,
				variables: { id: currentReportId }
			}
		]
	})

	const currentReport = systemInfoData?.softwareSystemReport

	const isSubmitted = isReportSubmitted(currentReport?.status)
	const isReadOnly = !isVswUser || isSubmitted

	const approvalRun = currentReport?.approvalRun
	const system = currentReport?.system

	const headerNavigations = [
		{
			name: t('navigation.mainHeaderMenu.approvalList'),
			key: ROUTES.approval.report.systemInfoSlug
		}
	]

	const sidebarNavigationHandler = (
		routeFunc: (quarter: string, id: string) => string
	) => {
		history.push(routeFunc(quarter, currentReportId))
	}

	const nextStepRoute = ROUTE_FUNCTIONS.getSystemCostsPath(
		quarter,
		currentReportId
	)
	const handleSubmit = useCallback(
		async (
			reportData: ReportInputType,
			systemData: ReportSystemInputType,
			mainContact: ContactPersonInputType,
			additionalContact?: ContactPersonInputType
		) => {
			const successful = await updateReportSoftwareSystemInfo({
				variables: {
					reportInput: reportData,
					reportSystemInput: systemData,
					mainContactInput: mainContact,
					additionalContactInput: additionalContact
				}
			})
			if (successful) {
				messageApi?.open({
					type: 'success',
					content: t('approval.report.systemInfo.submitSuccessMessage')
				})
				history.push(nextStepRoute)
			}
		},
		[t, updateReportSoftwareSystemInfo, nextStepRoute, history, messageApi]
	)

	const sideBarContent = approvalRun && system?.systemName && (
		<ReportSider
			currentStep={STEPS_CHOICE.STEP1}
			onChange={sidebarNavigationHandler}
			quarter={approvalRun.quarter}
			year={approvalRun.year}
			tenant={approvalRun.tenant.name}
			system={system.systemName}
		/>
	)
	const mainContent = (
		<Content style={styles.contentWrapper}>
			<ErrorHandler
				loading={systemInfoLoading}
				error={systemInfoError || updateSystemInfoError}
			>
				{systemInfoLoading ? (
					<LoadingSpinner fullPage />
				) : (
					currentReport && (
						<>
							<SystemInfoForm
								reportId={currentReport.id}
								isReadOnly={isReadOnly}
								onSubmit={handleSubmit}
								systemVersion={currentReport.systemVersion}
								rolloutDate={currentReport.rolloutDate}
								{...currentReport.system}
								isSubmitting={updatingSystemInfo}
							/>
							{isSubmitted && (
								<AkaTypography style={styles.alignRight} variant={'tiny'}>
									{t('approval.report.reportAlreadySubmitted')}
								</AkaTypography>
							)}
						</>
					)
				)}
			</ErrorHandler>
		</Content>
	)

	return (
		<PageLayout
			navigationItems={headerNavigations}
			navigationDefaultKey={ROUTES.approval.report.systemInfoSlug}
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default SystemInfoPage
