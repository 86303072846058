import React, { memo, useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { LoadingSpinner } from 'components'
import RequirementItem from './RequirementItem'
import { ScrollInView } from 'components'
import { useLocation } from 'react-router-dom'

const hashRegex = /([a-zA-Z0-9-_]+)?/g

const getRequirementIdFromURL = search => {
	const selectedRequirementIdFromURL = new URLSearchParams(search).get(
		'requirementId'
	)
	const matches =
		selectedRequirementIdFromURL &&
		selectedRequirementIdFromURL.match(hashRegex)
	return matches ? matches[0] : ''
}
const Requirements = memo(({ list, loading, isDiff }) => {
	let { search } = useLocation()
	const requirementId = getRequirementIdFromURL(search)

	const renderRequirementList = useMemo(
		() =>
			list.map(item => (
				<RequirementItem key={item.id} item={item} isDiff={isDiff} />
			)),
		[list, isDiff]
	)

	if (loading)
		return <LoadingSpinner fullPage testId="requirements-list-loading" />

	return (
		<ScrollInView id={requirementId} dependencies={[list, requirementId]}>
			{renderRequirementList}
		</ScrollInView>
	)
})

Requirements.propTypes = {
	list: PropTypes.array,
	loading: PropTypes.bool
}

Requirements.defaultProps = {
	list: [],
	loading: true
}

export default Requirements
