export const compareByAlph = (a, b) => {
	if (!a) {
		return 1
	} else if (!b) {
		return -1
		// eslint-disable-next-line no-dupe-else-if
	} else if (!a && !b) {
		return 0
	} else if (a < b) {
		return 1
	} else if (a > b) {
		return -1
	} else {
		return 0
	}
}
