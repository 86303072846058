import React from 'react'
import * as PropTypes from 'prop-types'
import { SIDER_WIDTH } from 'modules/core/constants'
import { Col, Divider, Drawer, Row, Typography } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { RELEASE_ATTACHMENT_OBJECT_CONTRACTS } from 'modules/core/queries'
import { LoadingSpinner } from 'components'

const { Text } = Typography

const AttachmentInfo = ({
	releaseId,
	visible,
	attachmentObjectContracts,
	onClose,
	renderContent,
	getFilePath
}) => {
	const { loading, data: { releaseAttachmentObjectContracts = [] } = {} } =
		useQuery(RELEASE_ATTACHMENT_OBJECT_CONTRACTS, {
			skip: !releaseId || !attachmentObjectContracts,
			fetchPolicy: 'network-only',
			variables: {
				releaseId: releaseId,
				attachmentSpuID:
					attachmentObjectContracts &&
					attachmentObjectContracts.attachmentSpuId,
				attachmentObjectSpuID:
					attachmentObjectContracts &&
					attachmentObjectContracts.attachmentObjectSpuId
			}
		})

	return (
		<Drawer
			width={SIDER_WIDTH}
			placement={'left'}
			closable={false}
			onClose={onClose}
			open={visible}
			key={'left'}
		>
			{attachmentObjectContracts && (
				<>
					<Divider>Anlage</Divider>
					<div>
						<Row>
							<Col>
								<Text type="secondary">Anlage-ID</Text>
							</Col>
							<Col>
								<Text strong>{attachmentObjectContracts.attachment.spuId}</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Title</Text>
							</Col>
							<Col>
								<Text strong>{attachmentObjectContracts.attachment.title}</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Druckarten</Text>
							</Col>
							<Col>
								<Text strong>
									{attachmentObjectContracts.attachment.printTypes
										? attachmentObjectContracts.attachment.printTypes
												.split('|')
												.join(',')
										: '-'}
								</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">AnlageObjekt</Text>
							</Col>
							<Col>
								<Text strong>
									{attachmentObjectContracts.attachmentObject.title}
								</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Typ</Text>
							</Col>
							<Col>
								<Text strong>
									{attachmentObjectContracts.attachmentObject.type ?? '-'}
								</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Inhalt</Text>
							</Col>
							<Col>
								<Text strong>
									{renderContent(
										attachmentObjectContracts.attachmentObject.id,
										getFilePath,
										attachmentObjectContracts.attachmentObject.file,
										attachmentObjectContracts.attachmentObject.text,
										attachmentObjectContracts.attachmentObject.title,
										attachmentObjectContracts.attachmentObject.link
									)}
								</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Freigabe</Text>
							</Col>
							<Col>
								<Text strong>
									{attachmentObjectContracts.attachmentObject.releaseType ??
										'-'}
								</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Version</Text>
							</Col>
							<Col>
								<Text strong>
									{attachmentObjectContracts.attachmentObject.fileVersion ??
										'-'}
								</Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Text type="secondary">Seite</Text>
							</Col>
							<Col>
								<Text strong>
									{attachmentObjectContracts.attachmentObject.page ?? '-'}
								</Text>
							</Col>
						</Row>
					</div>
					<Divider>Verträge</Divider>
					<div>
						<Row justify="start" gutter={16}>
							{loading ? (
								<LoadingSpinner />
							) : (
								releaseAttachmentObjectContracts.map(contract => (
									<Col key={contract}>{contract}</Col>
								))
							)}
						</Row>
					</div>
				</>
			)}
		</Drawer>
	)
}

AttachmentInfo.propTypes = {
	releaseId: PropTypes.string.isRequired,
	visible: PropTypes.bool,
	attachmentObjectContracts: PropTypes.object,
	onClose: PropTypes.func,
	renderContent: PropTypes.func,
	getFilePath: PropTypes.func
}

AttachmentInfo.defaultProps = {
	visible: false,
	attachmentObjectContracts: undefined,
	onClose: () => {},
	renderContent: () => {},
	getFilePath: () => {}
}

export default AttachmentInfo
