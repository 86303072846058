import React from 'react'
import { Layout } from 'antd'

import { ErrorHandler } from 'components'
import ChapterDetails from './ChapterDetails'
import ChapterDiffDetails from './ChapterDiffDetails'
import { LAYOUT_MAX_WIDTH } from 'modules/core/constants'
const { Content } = Layout
const styles = {
	layout: { maxWidth: LAYOUT_MAX_WIDTH },
	contentPadding: {
		paddingBottom: 24
	}
}

const Requirements = ({ selectedReleaseToCompareId, ...catalog }) => {
	const {
		loadingRelease: loading,
		error,
		selectedChapterId,
		setSelectedChapter,
		isFirstChapter
	} = catalog

	return (
		<ErrorHandler loading={loading} error={error}>
			<Layout style={styles.layout}>
				<Content style={styles.contentPadding} id="chapter-details-content">
					{selectedReleaseToCompareId ? (
						<ChapterDiffDetails
							isFirstChapter={isFirstChapter}
							comparedReleaseId={selectedReleaseToCompareId}
							releaseLoading={loading}
							id={selectedChapterId}
							setSelectedChapter={setSelectedChapter}
						/>
					) : (
						<ChapterDetails
							isFirstChapter={isFirstChapter}
							releaseLoading={loading}
							id={selectedChapterId}
							setSelectedChapter={setSelectedChapter}
						/>
					)}
				</Content>
			</Layout>
		</ErrorHandler>
	)
}

export default Requirements
