import React, { useCallback, memo, useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { LoadingSpinner } from 'components'
import { StyledTree } from './styles'

const ChaptersIndex = memo(
	({
		loading,
		rootChapter,
		onSelectChapter,
		selectedChapterId,
		expandParent
	}) => {
		const handleSelectChapter = useCallback(
			selectedKeys => {
				if (selectedKeys.length === 0) {
					onSelectChapter(undefined)
				} else {
					onSelectChapter(selectedKeys[0])
				}
			},
			[onSelectChapter]
		)
		const [keys, setkeys] = useState([])
		const getParentsKeys = useCallback(
			() => rootChapter && rootChapter.chapters.map(chapter => chapter.id),
			[rootChapter]
		)

		useEffect(() => {
			if (rootChapter && expandParent) setkeys([...keys, ...getParentsKeys()])
		}, [rootChapter, setkeys]) // eslint-disable-line

		const renderTreeNode = (chapter, pos) => {
			return {
				'data-testid': 'node-' + chapter.id,
				title: chapter.fullTitle,
				key: chapter.id,
				children:
					chapter?.chapters?.length > 0 &&
					chapter.chapters.map(subChapter =>
						renderTreeNode(subChapter, (pos += `.${subChapter.pos}`))
					)
			}
		}

		if (loading) return <LoadingSpinner fullPage />
		return (
			<StyledTree
				selectedKeys={[selectedChapterId]}
				expandedKeys={keys}
				defaultExpandedKeys={keys}
				onExpand={expanded => setkeys([...expanded])}
				onSelect={handleSelectChapter}
				treeData={rootChapter.chapters.map(chapter =>
					renderTreeNode(chapter, `${chapter.pos}`)
				)}
			/>
		)
	}
)

ChaptersIndex.propTypes = {
	loading: PropTypes.bool,
	rootChapter: PropTypes.shape({ chapters: PropTypes.array }),
	onSelectChapter: PropTypes.func,
	fixed: PropTypes.bool,
	expandParent: PropTypes.bool
}

ChaptersIndex.defaultProps = {
	loading: false,
	rootChapter: { chapters: [] },
	onSelectChapter: () => {},
	fixed: false,
	expandParent: false
}

export default ChaptersIndex
