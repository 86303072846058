declare global {
	interface Window {
		env: never
	}
}

function readEnv(name: string) {
	if (window.env && window.env[name]) {
		return window.env[name]
	} else {
		return process.env[name]
	}
}

export const BACKEND_URL = readEnv('REACT_APP_API_URL')
export const ENVIRONMENT = readEnv('REACT_APP_ENV')
export const SENTRY_DSN = readEnv('REACT_APP_SENTRY_DSN')
export const SENTRY_ENV = readEnv('REACT_APP_SENTRY_ENVIRONMENT')
