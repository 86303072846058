import React, { useMemo, useCallback } from 'react'
import { Layout } from 'antd'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import PageLayout from 'modules/layout/containers/PageLayout'
import { ErrorHandler } from 'components'
import { ROUTES, ROUTE_FUNCTIONS } from 'modules/navigation'
import {
	SOFTWARE_SYSTEM_REPORT_REQUIREMENTS,
	SOFTWARE_SYSTEM_REPORT_UNSATISFIED_REQUIREMENTS
} from '../../core/queries'
import { SELECT_CONTRACT_REGION_GROUP_REQUIREMENTS } from 'modules/core/mutations'
import SelectRequirementsTable from '../components/SelectRequirementsTable'
import { groupIdsAreEqual } from 'utils/helper.utils'
import ReportSider, { STEPS_CHOICE } from './ReportSider'
import { LoadingSpinner } from 'components'
import { useUser, useAntdMessageApi } from 'providers'
import { PrefilledInfoBanner } from '../components/PrefilledInfoBanner'
import { isReportSubmitted } from 'utils/helper.utils'

const { Content } = Layout

const styles = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		overflow: 'hidden',
		flex: 'initial'
	}
}

const getSelectedContractRegionGroupRequirements = (
	chapters,
	selectedContractRegionGroupRequirements = []
) => {
	if (chapters.length) {
		chapters.forEach(chapter => {
			if (chapter.matrixRequirements.length) {
				chapter.matrixRequirements.forEach(requirement => {
					if (requirement.selectedContractRegionGroupIds.length) {
						selectedContractRegionGroupRequirements.push({
							id: requirement.id,
							contractRegionGroupIds:
								requirement.selectedContractRegionGroupIds,
							allSelected: groupIdsAreEqual(
								requirement.contractRegionGroupIds,
								requirement.selectedContractRegionGroupIds
							)
						})
					}
				})
			}

			if (chapter.chapters.length) {
				getSelectedContractRegionGroupRequirements(
					chapter.chapters,
					selectedContractRegionGroupRequirements
				)
			}
		})
	}

	return selectedContractRegionGroupRequirements
}

const SelectRequirementsPage = () => {
	const { id: currentReportId, quarter } = useParams()
	const history = useHistory()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const {
		user: { isVswUser }
	} = useUser()

	const {
		data,
		loading: loadingRequirements,
		error: error
	} = useQuery(SOFTWARE_SYSTEM_REPORT_REQUIREMENTS, {
		variables: { id: currentReportId }
	})

	const [
		selectContractRegionGroups,
		{
			loading: isUpdatingContractRegionGroupRequirements,
			error: updateContractRegionGroupRequirementsError
		}
	] = useMutation(SELECT_CONTRACT_REGION_GROUP_REQUIREMENTS, {
		refetchQueries: [
			{
				query: SOFTWARE_SYSTEM_REPORT_REQUIREMENTS,
				variables: { id: currentReportId }
			},
			{
				query: SOFTWARE_SYSTEM_REPORT_UNSATISFIED_REQUIREMENTS,
				variables: { id: currentReportId }
			}
		],
		awaitRefetchQueries: true
	})

	const currentReport = data?.softwareSystemReport
	const isSubmitted = isReportSubmitted(currentReport?.status)
	const isReadOnly = !isVswUser || isSubmitted

	const isPrefilled = currentReport?.isPrefilled
	const showPrefilledBanner = isPrefilled && !isReadOnly

	const approvalRun = currentReport?.approvalRun

	const selectedContractRegionGroupRequirements = useMemo(() => {
		if (approvalRun?.release?.rootChapter?.chapters)
			return getSelectedContractRegionGroupRequirements(
				approvalRun?.release?.rootChapter?.chapters
			)
		return []
	}, [approvalRun?.release?.rootChapter?.chapters])

	const headerNavigations = [
		{
			name: t('navigation.mainHeaderMenu.approvalList'),
			key: ROUTES.approval.report.selectRequirementsSlug
		}
	]
	const nextStepRoute = ROUTE_FUNCTIONS.getJustificationsPath(
		quarter,
		currentReportId
	)

	const handleSubmit = useCallback(
		async selectedContractRegionGroupRequirements => {
			const result = await selectContractRegionGroups({
				variables: {
					reportId: currentReportId,
					selection: selectedContractRegionGroupRequirements
				}
			})
			if (result) {
				messageApi?.open({
					type: 'success',
					content: t('approval.report.requirementsTable.submitSuccessMessage')
				})
				history.push(nextStepRoute)
			}
		},
		[
			currentReportId,
			selectContractRegionGroups,
			t,
			nextStepRoute,
			history,
			messageApi
		]
	)

	const sidebarNavigationHandler = routeFunc => {
		history.push(routeFunc(quarter, currentReportId))
	}

	const sideBarContent = approvalRun && (
		<ReportSider
			currentStep={STEPS_CHOICE.STEP4}
			onChange={sidebarNavigationHandler}
			quarter={approvalRun.quarter}
			year={approvalRun.year}
			tenant={approvalRun.tenant.name}
			system={currentReport.system.systemName}
		/>
	)

	const mainContent = (
		<Content style={styles.contentWrapper}>
			{showPrefilledBanner && (
				<PrefilledInfoBanner style={{ marginBottom: '20px' }} />
			)}
			<ErrorHandler
				loading={loadingRequirements}
				error={error || updateContractRegionGroupRequirementsError}
			>
				{!loadingRequirements ? (
					<SelectRequirementsTable
						releaseId={approvalRun?.release.id}
						selectedContractRegionGroups={
							currentReport?.selectedContractRegionGroups
						}
						changes={currentReport?.changes}
						chapters={approvalRun?.release?.rootChapter?.chapters}
						selectedContractRegionGroupRequirements={
							selectedContractRegionGroupRequirements
						}
						onSubmit={handleSubmit}
						isSubmitting={isUpdatingContractRegionGroupRequirements}
						isReadOnly={isReadOnly}
						isSubmitted={isSubmitted}
					/>
				) : (
					<LoadingSpinner fullPage={true} />
				)}
			</ErrorHandler>
		</Content>
	)

	return (
		<PageLayout
			navigationItems={headerNavigations}
			navigationDefaultKey={ROUTES.approval.report.selectRequirementsSlug}
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default SelectRequirementsPage
