import { Steps } from 'antd'
import { useTranslation } from 'react-i18next'
import { SideWrapper, InfoBackground, StepperWrapper } from './styles'
import { ROUTE_FUNCTIONS } from 'modules/navigation'
import { AkaTypography } from 'components'

export enum STEPS_CHOICE {
	STEP1 = 0,
	STEP2 = 1,
	STEP3 = 2,
	STEP4 = 3,
	STEP5 = 4
}

const STEP_ROUTE_FUNCTIONS_DICT = {
	[STEPS_CHOICE.STEP1]: ROUTE_FUNCTIONS.getSystemInfoPath,
	[STEPS_CHOICE.STEP2]: ROUTE_FUNCTIONS.getSystemCostsPath,
	[STEPS_CHOICE.STEP3]: ROUTE_FUNCTIONS.getSelectContractRegionsPath,
	[STEPS_CHOICE.STEP4]: ROUTE_FUNCTIONS.getSelectRequirementsPath,
	[STEPS_CHOICE.STEP5]: ROUTE_FUNCTIONS.getJustificationsPath
}

export interface ReportSider {
	currentStep: STEPS_CHOICE
	onChange: (routeFunction: (quarter: string, id: string) => string) => void
	year: number
	quarter: number
	tenant: string
	system: string
}

const ReportSider = ({
	currentStep,
	onChange,
	year,
	quarter,
	tenant,
	system
}: ReportSider) => {
	const [t] = useTranslation()

	const handleChange = (value: STEPS_CHOICE) =>
		onChange(STEP_ROUTE_FUNCTIONS_DICT[value])
	return (
		<SideWrapper>
			<InfoBackground>
				<AkaTypography gutterbottom={0} variant={'bodySegoe'}>
					{`${t('approval.sidebar.info.approval')}`}
					{year && <strong>{` ${year}-Q${quarter}`}</strong>}
				</AkaTypography>
				<AkaTypography gutterbottom={0} variant={'bodySegoe'}>
					{`${t('approval.sidebar.info.report')}`}
					<strong>{` ${tenant}`}</strong>
				</AkaTypography>
				<AkaTypography gutterbottom={0} variant={'bodySegoe'}>
					{`${t('approval.sidebar.info.system')}`}
					<strong>{` ${system}`}</strong>
				</AkaTypography>
			</InfoBackground>
			<StepperWrapper>
				<Steps
					current={currentStep}
					onChange={handleChange}
					direction="vertical"
					items={[
						{
							title: t('approval.sidebar.stepper.step1'),
							style: { paddingBottom: 15 }
						},
						{
							title: t('approval.sidebar.stepper.step2'),
							style: { paddingBottom: 15 }
						},
						{
							title: t('approval.sidebar.stepper.step3'),
							style: { paddingBottom: 15 }
						},
						{
							title: t('approval.sidebar.stepper.step4'),
							style: { paddingBottom: 15 }
						},
						{
							title: t('approval.sidebar.stepper.step5'),
							style: { paddingBottom: 15 }
						}
					]}
				/>
			</StepperWrapper>
		</SideWrapper>
	)
}

export default ReportSider
