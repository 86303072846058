import { Alert } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from 'react'

export const PrefilledInfoBanner = (props: {
	style?: CSSProperties
}): React.ReactElement => {
	const [t] = useTranslation()
	return (
		<Alert
			message={t('approval.report.prefilledAlert')}
			style={props.style}
			type="warning"
			showIcon
			closable
		/>
	)
}
