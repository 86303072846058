import React from 'react'
import { useTranslation } from 'react-i18next'
import { Headline, SubHeadline, Wrapper } from './styles'

export default () => {
	const [t] = useTranslation()
	return (
		<Wrapper>
			<Headline>404</Headline>
			<SubHeadline>{t('core.pageNotFound')}</SubHeadline>
		</Wrapper>
	)
}
