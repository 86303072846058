import ChaptersIndex from './ChaptersIndex'
import React from 'react'
import { SideWrapper } from './styles'

const styles = {
	wrapper: {
		padding: 'unset',
		overflow: 'auto'
	}
}
const ChangesSider = ({
	rootChapter,
	loadingChapters,
	handleSelectChapter,
	selectedChapterId
}) => {
	return (
		<SideWrapper style={styles.wrapper}>
			<ChaptersIndex
				loading={loadingChapters}
				rootChapter={rootChapter}
				onSelectChapter={handleSelectChapter}
				selectedChapterId={selectedChapterId}
				expandParent={true}
			/>
		</SideWrapper>
	)
}

export default ChangesSider
