import React from 'react'
import * as PropTypes from 'prop-types'
import { Circle as CircleWrapper } from './styles'

const Circle = ({ width, color }) => (
	<CircleWrapper width={width} color={color} />
)

Circle.propTypes = {
	width: PropTypes.number,
	color: PropTypes.oneOf(['success', 'red', 'orange'])
}

Circle.defaultProps = {
	width: 12,
	color: 'success'
}

export default Circle
