import styled from 'styled-components'

export const ButtonWrapper = styled.div`
	button {
		height: 30px;
		padding: 0 32px;
		font-family: SegoeUI Bold, sans-serif;
	}
	a {
		font-family: SegoeUI Bold, sans-serif;
	}
`
