import styled from 'styled-components'
import { Layout } from 'antd'
import { HEADER_HEIGHT, LAYOUT_MAX_WIDTH } from 'modules/core/constants'

export const StyledSiteLayout = styled(Layout)`
	margin-top: ${HEADER_HEIGHT}px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: ${LAYOUT_MAX_WIDTH}px;
`

export const Sidebar = styled(Layout.Sider)`
	transition: all 0.3s ease-in-out;
	height: 100vh;
	top: 0;
	left: 0;
	padding-top: ${HEADER_HEIGHT}px;
	border-right: 1px solid lightgray;
	overflow: hidden;
	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
	}
	.ant-layout-sider-trigger {
		background: ${({ theme }) => theme.primary};
		display: flex;
		justify-content: center;
		transition: all 0.3s ease-in-out;
		align-items: center;
		svg {
			width: 25px;
			height: 25px;
		}
	}

	&.ant-layout-sider-collapsed {
		.ant-layout-sider-trigger {
			height: 100%;
		}
	}
`

export const RightSide = styled.div`
	min-width: 125px;
	display: flex;
	justify-content: center;
`
