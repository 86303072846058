import { ApprovalLogItemType } from 'generated/types'
import { ChatBubble } from './styles'
import { Tag } from 'antd'
import { AkaTypography } from '../../../components'
import { StylesDictionary } from 'components/typography/styles'

const styles: StylesDictionary = {
	chatMessage: {
		lineHeight: '1.2em',
		marginTop: '1em',
		whiteSpace: 'pre-line'
	}
}

export type ApprovalFeedItem = Pick<
	ApprovalLogItemType,
	| 'isUserMessage'
	| 'actionMessage'
	| 'userMessage'
	| 'createdAt'
	| 'approvalRun'
	| 'createdByText'
>

export const ApprovalFeedItem = ({
	createdByText,
	createdAt,
	approvalRun,
	actionMessage,
	userMessage,
	isUserMessage
}: ApprovalFeedItem) => {
	return (
		<ChatBubble direction={isUserMessage ? 'right' : 'left'}>
			<Tag
				color={approvalRun.tenant.hexColor}
				style={{ borderRadius: '0.5rem' }}
			>
				{approvalRun.tenant.catalogName}
			</Tag>
			<AkaTypography variant={'bodySegoe'} style={styles.chatMessage}>
				{actionMessage}
			</AkaTypography>
			{userMessage && (
				<AkaTypography variant={'tiny'} style={styles.chatMessage}>
					{userMessage}
				</AkaTypography>
			)}
			<AkaTypography variant={'tiny'} gutterbottom={0} color={'secondary'}>
				<>
					{createdByText},{' '}
					{new Date(createdAt).toLocaleString('de', {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit'
					})}
				</>
			</AkaTypography>
		</ChatBubble>
	)
}
