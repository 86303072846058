export default {
	approvalList: {
		noResults: 'Keine Selbstauskünfte vorhanden',
		reportCard: {
			title: 'Selbstauskunft',
			editReport: 'Bearbeiten',
			showReport: 'Anzeigen',
			writeMessage: 'Nachricht schreiben',
			logoAlt: 'Firmenlogo von {{tenant}}',
			columns: {
				appointment: 'EINREICHUNGSFRIST',
				status: 'STATUS'
			}
		},
		messageModal: {
			title: `Nachricht an {{tenantName}}`,
			label: 'Nachricht',
			placeholder: 'Sehr geehrte Operatoren, ich schreibe Ihnen, da...',
			sendMessage: 'Nachricht senden',
			exitModal: 'Abbrechen',
			success: 'Nachricht erfolgreich gesendet.',
			messageCounter: '{{count}}/500 Buchstaben',
			errorNoMessage: 'Bitte geben Sie eine Nachricht ein',
			errorMaxMessage:
				'Ihre Nachricht darf nicht mehr als 500 Zeichen beinhalten',
			errorBackendMessage:
				'Ihre Nachricht konnte leider nicht versendet werden.'
		},
		contentCheckCard: {
			title: 'Ergebnisprüfung',
			logoAlt: 'Firmenlogo von {{tenant}}',
			editContentCheck: 'Bearbeiten',
			showContentCheck: 'Anzeigen',
			columns: {
				appointment: 'EINREICHUNGSFRIST',
				contract: 'VERTRAG',
				status: 'STATUS'
			}
		},
		sidebarContent: { title: 'Zulassung für Quartal' },
		mainContent: {
			title:
				'Folgezulassung für <strong>{{selectedSystemLabel}}</strong> in <strong>{{quarter}}</strong>',
			userHasNoSystems: 'Ihrem Benutzer wurde kein VSW-System zugeordnet'
		}
	},
	report: {
		submitSuccessMessage:
			'Die Selbstauskunft für das System {{system}} wurde erfolgreich für das Quartal {{quarter}} eingereicht.',
		prefilledAlert:
			'Die Selbstauskunft wurde anhand Ihrer Daten aus dem Vorquartal automatisch vorausgefüllt. Bitte prüfen Sie die Aktualität dieser Daten.',
		contractRegionsTable: {
			filterByRegions: 'Nach Regionen filtern',
			noResults: 'Keine Verträge gefunden',
			columnTitles: {
				contract: 'Vertrag',
				regions: 'KV-Regionen'
			},
			submitButton: 'Speichern und weiter',
			submitSuccessMessage: 'Die Verträge wurden erfolgreich gespeichert'
		},
		requirementsTable: {
			selectAllRequirements: 'Alle Funkionen auswählen',
			submitButton: 'Speichern und weiter',
			submitSuccessMessage: 'Die Funtkionsmatrix wurde erfolgreich gespeichert',
			changedStatus: {
				title: 'Änderungsstatus',
				new: 'Neu',
				changed: 'Geändert',
				deleted: 'Gelöscht',
				reset: 'Zurücksetzen',
				ok: 'OK',
				filter: 'Filterung'
			},
			priority: {
				title: 'Priorität',
				required: 'Pflicht',
				optional: 'Optional',
				conditional: 'Konditional',
				filter: 'Filterung'
			}
		},
		justifications: {
			requirementLabel: 'Anforderungen',
			requirementPlaceHolder: 'Anforderungen auswählen...',
			requirementError: 'Wählen Sie mindestens eine Anforderung aus',
			justifcationLabel: 'Begründung',
			justifcationPlaceholder: 'Begründung eingeben...',
			justifcationError: 'Bitte geben Sie eine Begründung an',
			add: 'Begründung hinzufügen',
			remove: 'Entfernen',
			submit: 'Einreichen',
			notAllRequirementsAssignedError:
				'Sie haben noch nicht alle Anforderungen begründet',
			noRequirementsFound: 'Keine Anforderungen vorhanden',
			justifyingUnneededRequirements:
				'Einige der Anforderungen wurden mittlerweile erfüllt und benötigen keine Begründung mehr. Bitte passen Sie die obere Auswahl entsprechend an.',
			noJustificationNecessary:
				'Es ist keine Begründung notwendig, da Ihr System alle Pflichtanforderungen erfüllt.',
			submitSuccessMessage: 'Die Begründungen wurden erfolgreich eingereicht'
		},
		systemInfo: {
			requiredFieldText: '{{name}} ist erforderlich',
			manufacturerLabel: 'Hersteller',
			manufacturerName: 'Firma',
			streetAndNumber: 'Strasse/Nr.',
			postalCode: 'Postleitzahl',
			postalCodeValidation: 'Bitte eine gültige Postleitzahl angeben',
			city: 'Ort',
			systemLabel: 'Software',
			systemName: 'Name der Software',
			systemVersion: 'Versionsnummer',
			rolloutDate: 'Rollouttermin',
			website: 'Produkt-Website',
			websiteValidation:
				'Bitte eine gültige URL mit http:// oder https:// angeben',
			mainContactPersonLabel: 'Softwareverantwortlicher',
			contactPersonTitle: 'Titel',
			contactPersonFirstname: 'Vorname',
			contactPersonLastname: 'Nachname',
			contactPersonPhoneNumber: 'Telefon',
			contactPersonEmail: 'E-Mail-Adresse',
			contactPersonEmailValidation: 'Bitte eine gültige E-Mail-Adresse angeben',
			additionalContactPersonLabel: 'Ansprechpartner - Bereich Zulassung',
			drugModuleLabel: 'Datenbasis Arzeimittelmodul',
			drugModuleName: 'Name',
			drugModuleVersion: 'Version',
			drugModuleDate: 'Datum Datenstand',
			orderAddressLabel: 'Software-Bestelladresse',
			submitButton: 'Speichern und weiter',
			submitSuccessMessage:
				'Die Angaben zur Vertragssoftware wurden wurden erfolgreich gespeichert'
		},
		systemCosts: {
			description:
				'Sie haben die Möglichkeit Ihre Softwarekosten zu erfassen, so dass diese Informationen den interessierten Ärzten zeitnah jeweils zu Quartalsbeginn bereitgestellt werden können.',
			choiceDisagree:
				'Der Erfassung und Veröffentlichung von Softwarekosten wird nicht zugestimmt.',
			choiceAgree:
				'Es wird bestätigt, dass die erfassten Softwarekosten für die kommenden Quartale auf der Internetseite des Hausärzteverbandes veröffentlich werden dürfen.',
			table: {
				setupAndMaintenance: 'Setup/ Pflege',
				module: 'Modul',
				amount: 'Betrag € netto',
				accounting: 'Abrechnung',
				note: 'Bemerkung',
				lanr: 'LANR',
				bsnr: 'BSNR',
				legend: {
					row1: {
						label: 'Setup:',
						text: 'Initiale Gebühr für die Bereitstellung einer HZV/FAV-Software'
					},
					row2: {
						label: 'Pflege:',
						text: 'Monatliche Gebühren für die HZV/FAV-Verträge'
					},
					row3: {
						label: 'Abrechnung:',
						text: 'Erfolgt nach LANR oder BSNR ggf. abhängig von KV-Regionen'
					}
				}
			},
			submitButton: 'Speichern und weiter',
			submitSuccessMessage:
				'Die Angaben zu den Softwarekosten wurden erfolgreich gespeichert'
		},
		reportAlreadySubmitted: 'Sie haben bereits Ihre Selbstauskunft eingereicht.'
	},
	sidebar: {
		info: {
			approval: 'Zulassung für Quartal',
			report: 'Selbstauskunft:',
			system: 'System:',
			contentCheck: 'Ergebnisprüfung'
		},
		stepper: {
			step1: 'Angaben zur Vertragssoftware',
			step2: 'Softwarekosten',
			step3: 'Verträge auswählen',
			step4: 'Anforderungen auswählen',
			step5: 'Begründung für nicht umgesetzte Anforderungen'
		}
	},
	contentCheck: {
		heading:
			'Ergebnisprüfung für den Vertrag  <2>{{contractName}}</2> für das System <2>{{systemName}}</2>',
		instructionHint:
			'In diesem Quartal findet für den Vertrag <1>{{contractName}}<1> eine Ergebnisprüfung statt.',
		pleaseUploadProof:
			'Bitte laden Sie die Ergebnisprüfung herunter und folgen Sie den Anweisungen im Dokument.',
		noInstructionFile: 'keine Ergebnisprüfung Dokument!',
		uploadSuccessMessage:
			'Danke für die Einreichung der Ergebnisprüfung. Das Zulassungsteam wird die Einreichung sichten und Ihnen eine Rückmeldung geben.',
		alreadyProofSubmitted:
			'Sie haben bereits Ihre Ergebnisprüfung eingereicht.',
		uploadText: 'Klicken oder rüber ziehen, um Ergebnisprüfung hochzuladen.',
		uploadHint:
			'Einzelfile- und Multifile Upload. Achten Sie bitte darauf, keinerlei echte personenbezogene Inhalte hochzuladen!',
		proofSubmit: 'Ergebnisprüfung einreichen',
		alreadyUploadedFiles: 'Bereits hochgeladene Dateien'
	},
	processStatus: {
		EDITING: 'In Bearbeitung',
		IN_REVIEW: 'Eingereicht',
		OPEN: 'Ausstehend',
		CORRECTION_REQUESTED: 'Ausstehend (Korrektur)',
		APPROVED: 'Abgenommen'
	},
	systemApprovalStatus: {
		REJECTED: 'Nicht zugelassen',
		APPROVED: 'Zugelassen',
		APPROVED_WITH_RESERVATION: 'Zugelassen u.V.'
	}
}
