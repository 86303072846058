import React, { useCallback, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import theme from '../themeProvider/theme'

const SHOW_LESS_TEXT = 'Zeige weniger'
const SHOW_MORE_TEXT = '... Mehr'
const styles = {
	readMoreButton: {
		display: 'inline',
		backgroundColor: 'transparent',
		outline: 'none',
		border: 0,
		padding: 0,
		margin: 0,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.primary
	}
}

const getReadMoreParts = (text, characterLimit, showMore) => {
	let result = text
	if (showMore) return result
	else if (text) {
		const teaserWordsArray = text.split(' ')

		while (teaserWordsArray.join(' ').length > characterLimit) {
			teaserWordsArray.pop()
		}

		result = teaserWordsArray.join(' ')
	}
	return result
}
const getText = (text, characterLimit, showMore) => {
	return getReadMoreParts(text, characterLimit, showMore)
}

const ReadMore = ({ text, characterLimit, onReadMoreClick }) => {
	const [showMore, setShowMore] = useState(false)

	const [displayedText, setDisplayedText] = useState(
		getText(text, characterLimit, showMore)
	)

	const toggleState = useCallback(() => setShowMore(showMore => !showMore), [])

	useEffect(() => {
		setDisplayedText(getText(text, characterLimit, showMore))
	}, [showMore, characterLimit, text])

	return (
		<>
			<span data-testid="displayed-text">{displayedText}</span>
			{!!text && (
				<button
					data-testid="read-more-less-button"
					onClick={onReadMoreClick ? onReadMoreClick : toggleState}
					style={styles.readMoreButton}
				>
					{showMore ? SHOW_LESS_TEXT : SHOW_MORE_TEXT}
				</button>
			)}
		</>
	)
}

ReadMore.propTypes = {
	text: PropTypes.string,
	characterLimit: PropTypes.number,
	onReadMoreClick: PropTypes.func
}

ReadMore.defaultProps = {
	text: '',
	characterLimit: 100,
	onReadMoreClick: undefined
}

export default ReadMore
