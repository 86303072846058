import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode'

export const JWT = (() => {
	const cookies = new Cookies()
	const cookieName = 'JWT_TOKEN'
	/* Return parsed jwt */
	const decode = jwt => {
		try {
			return jwtDecode(jwt)
		} catch (e) {
			return false
		}
	}

	/* Get the jwt from cookies */
	const get = () => cookies.get(cookieName)

	/* Get the parsed jwt from cookies */
	const getDecoded = () => {
		return decode(get())
	}

	const getPayload = jwt => {
		const payload = decode(jwt)
		const { exp } = payload
		return exp
	}

	const expiresIn = jwt => {
		try {
			const exp = getPayload(jwt)
			if (!exp) return true
			const actualTimeInSeconds = new Date().getTime() / 1000
			return (exp - actualTimeInSeconds) / 60
		} catch (e) {
			return false
		}
	}

	const validate = jwt => {
		if (!jwt) return false
		try {
			const exp = getPayload(jwt)
			if (!exp) return true

			const expires = expiresIn(jwt)
			return expires && expires > 0
		} catch (e) {
			return false
		}
	}

	return {
		get: () => {
			return get()
		},
		/* Saves the jwt in cookies */
		keep: jwt => {
			if (!jwt) return
			cookies.set(cookieName, jwt, { path: '/' })
		},

		/* Return number of minutes the jwt expires in (if valid) */
		expiresIn: () => {
			return expiresIn(get())
		},
		/* Validate jwt by correctly decoding and checking expiry */
		validate: () => {
			return validate(get())
		},
		isAuthenticated: () => {
			const token = get()
			if (!token) {
				return false
			}
			return validate(token) && expiresIn(token) > 0
		},
		/* Remove the jwt from cookies */
		forget: () => {
			cookies.remove(cookieName, { path: '/' })
		},

		matchesEmail: email => {
			return getDecoded().email === email
		}
	}
})()
