import * as Sentry from '@sentry/react'

import { ENVIRONMENT, SENTRY_DSN, SENTRY_ENV } from '../constants'

export const initSentry = () => {
	if (ENVIRONMENT === 'development' || !SENTRY_DSN) {
		console.info('Sentry reporting is disabled in development environment')
		return
	}
	if (!SENTRY_DSN) {
		console.info(
			'Sentry reporting disabled; set REACT_APP_SENTRY_DSN to enable'
		)
		return
	}
	Sentry.init({
		dsn: SENTRY_DSN,
		environment: SENTRY_ENV,
		integrations: [new Sentry.BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// we capture 5% because the app is B2B, so it has not much traffic
		tracesSampleRate: 0.05
	})
}
