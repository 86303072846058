import styled from 'styled-components'

export const Wrapper = styled.div`
	img {
		font-size: 15px;
		animation: spin-animation 5s infinite;
		animation-timing-function: linear;
		@keyframes spin-animation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
	${({ fullPage }) =>
		fullPage &&
		`
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999;
    position: fixed;
    text-align: center;
    justify-content: center;
    padding: 32px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;`}
	text-align: center;
	padding: 32px;
	.anticon {
		margin: auto;
		font-size: 32px;
		color: ${({ theme }) => theme.primary};
	}
`
