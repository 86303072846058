import React from 'react'
import PropTypes from 'prop-types'
import { useUser } from 'providers'
import { Route, Redirect } from 'react-router-dom'
import { LoadingSpinner } from 'components'

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { loadingVerify, loadingAuth, hasToken, isAuthenticated } = useUser()

	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated && !loadingAuth && !loadingVerify ? (
					<Component {...props} />
				) : loadingVerify && hasToken ? (
					<LoadingSpinner fullPage />
				) : (
					<Redirect
						to={{
							pathname: `/login`
						}}
					/>
				)
			}
		/>
	)
}

PrivateRoute.propTypes = {
	component: PropTypes.func
}

export default PrivateRoute
