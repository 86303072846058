import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PageLayout from 'modules/layout/containers/PageLayout'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { Layout } from 'antd'
import { ROUTES } from 'modules/navigation'
import ReportSider, { STEPS_CHOICE } from './ReportSider'
import { SUBMIT_JUSTIFY_UNSATISFIED_REQUIREMENTS } from 'modules/core/mutations'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ROUTE_FUNCTIONS } from 'modules/navigation'
import {
	ErrorHandler,
	LoadingSpinner,
	AkaTypography,
	AkaButton
} from 'components'

import { useUser, useAntdMessageApi } from 'providers'

import {
	JustificationInputType,
	SoftwareSystemReportJustificationsQuery,
	SoftwareSystemReportJustificationsQueryVariables,
	SoftwareSystemReportUnsatiesfiedRequirementsQuery,
	SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
} from 'generated/types'
import JustificationsForm from 'modules/approval/components/JustificationsForm'
import {
	SOFTWARE_SYSTEM_REPORT_JUSTIFICATIONS,
	SOFTWARE_SYSTEM_REPORT_UNSATISFIED_REQUIREMENTS
} from 'modules/core/queries'
import { isReportSubmitted } from 'utils/helper.utils'

const { Content } = Layout

const styles = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	}
}

const JustificationsPage = () => {
	const { id: currentReportId, quarter } = useParams<{
		quarter: string
		id: string
	}>()
	const history = useHistory()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const {
		user: { isVswUser }
	} = useUser()

	const {
		data: justificationsData,
		loading: loadingJustifications,
		error: loadingJustificationsError
	} = useQuery<
		SoftwareSystemReportJustificationsQuery,
		SoftwareSystemReportJustificationsQueryVariables
	>(SOFTWARE_SYSTEM_REPORT_JUSTIFICATIONS, {
		variables: { id: currentReportId }
	})

	const currentReport = justificationsData?.softwareSystemReport
	const isSubmitted = isReportSubmitted(currentReport?.status)
	const isReadOnly = !isVswUser || isSubmitted

	const {
		data: unsatisfiedRequirementsData,
		loading: loadingUnsatisfiedRequirements,
		error: loadingUnsatisfiedRequirementsError
	} = useQuery<
		SoftwareSystemReportUnsatiesfiedRequirementsQuery,
		SoftwareSystemReportUnsatiesfiedRequirementsQueryVariables
	>(SOFTWARE_SYSTEM_REPORT_UNSATISFIED_REQUIREMENTS, {
		skip: !currentReport || isReadOnly,
		variables: { id: currentReportId }
	})

	const [
		justifyUnsatisfiedRequirements,
		{ loading: isSubmittingJustifications, error: justificationsSubmitError }
	] = useMutation(SUBMIT_JUSTIFY_UNSATISFIED_REQUIREMENTS, {
		refetchQueries: [
			{
				query: SOFTWARE_SYSTEM_REPORT_JUSTIFICATIONS,
				variables: { id: currentReportId }
			}
		]
	})
	const unsatisfiedRequirements =
		unsatisfiedRequirementsData?.softwareSystemReport
			?.unsatisfiedRequirements || []

	const approvalRun = currentReport?.approvalRun
	const systemName = currentReport?.system?.systemName
	const handleSubmit = useCallback(
		async (justifications: JustificationInputType[]) => {
			await justifyUnsatisfiedRequirements({
				variables: {
					items: justifications,
					reportId: currentReportId
				}
			})
				.then(() => {
					messageApi?.open({
						type: 'success',
						content: t('approval.report.submitSuccessMessage', {
							system: systemName,
							quarter: quarter
						})
					})

					history.push(ROUTE_FUNCTIONS.getApprovalPath(quarter))
				})
				// we do nothing here as we only want to display the backend error message handled by the ErrorHandler
				.catch(() => {})
		},
		[
			currentReportId,
			justifyUnsatisfiedRequirements,
			history,
			quarter,
			systemName,
			t,
			messageApi
		]
	)

	const headerNavigations = [
		{
			name: t('navigation.mainHeaderMenu.approvalList'),
			key: ROUTES.approval.report.justificationsSlug
		}
	]

	const sidebarNavigationHandler = (
		routeFunc: (quarter: string, id: string) => string
	) => {
		history.push(routeFunc(quarter, currentReportId))
	}

	const sideBarContent = approvalRun && systemName && (
		<ReportSider
			currentStep={STEPS_CHOICE.STEP5}
			onChange={sidebarNavigationHandler}
			quarter={approvalRun.quarter}
			year={approvalRun.year}
			tenant={approvalRun.tenant.name}
			system={systemName}
		/>
	)

	const mainContent = (
		<Content style={styles.contentWrapper}>
			<ErrorHandler
				loading={loadingJustifications}
				error={
					loadingJustificationsError ||
					loadingUnsatisfiedRequirementsError ||
					justificationsSubmitError
				}
			>
				{loadingJustifications || loadingUnsatisfiedRequirements ? (
					<LoadingSpinner fullPage />
				) : currentReport?.justifications.length ||
				  !!unsatisfiedRequirements.length ? (
					<JustificationsForm
						onSubmit={handleSubmit}
						unsatisfiedRequirements={unsatisfiedRequirements}
						isSubmitting={isSubmittingJustifications}
						submittedJustifications={currentReport?.justifications || []}
						isReadOnly={isReadOnly}
						isSubmitted={isSubmitted}
					/>
				) : (
					<>
						<AkaTypography data-testid="no-results" variant="body">
							{t('approval.report.justifications.noJustificationNecessary')}
						</AkaTypography>

						{!isReadOnly && (
							<AkaButton
								type="primary"
								htmlType="submit"
								onClick={() => handleSubmit([])}
								loading={isSubmittingJustifications}
							>
								{t('approval.report.justifications.submit')}
							</AkaButton>
						)}
						{isSubmitted && (
							<AkaTypography variant={'tiny'}>
								{t('approval.report.reportAlreadySubmitted')}
							</AkaTypography>
						)}
					</>
				)}
			</ErrorHandler>
		</Content>
	)

	return (
		<PageLayout
			navigationItems={headerNavigations}
			navigationDefaultKey={ROUTES.approval.report.justificationsSlug}
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default JustificationsPage
