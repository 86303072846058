import React, { CSSProperties } from 'react'
import { StyledTitle, StyledParagraph } from './styles'

export interface CustomTypography {
	gutterbottom?: number | null
	children: React.ReactNode
	variant?:
		| 'tiny'
		| 'body'
		| 'body2'
		| 'body3'
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'bodySegoe'
		| 'bodySegoe2'
		| 'tinySegoe'
	color?:
		| 'success'
		| 'primary'
		| 'error'
		| 'red'
		| 'orange'
		| 'inherit'
		| 'secondary'
		| 'default'
		| 'gray'
		| 'white'
		| 'delContent'
		| 'insContent'
	style?: CSSProperties
}

const CustomTypography = ({
	children,
	variant = 'h3',
	gutterbottom = null,
	color = 'default',
	...other
}: CustomTypography) => {
	const level: { [key: string]: number } = {
		h1: 1,
		h2: 2,
		h3: 3,
		h4: 4
	}

	if (['h1', 'h2', 'h3', 'h4'].find(item => item === variant))
		return (
			<StyledTitle
				gutterbottom={gutterbottom}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				level={level[variant] as any}
				variant={variant}
				color={color}
				{...other}
			>
				{children}
			</StyledTitle>
		)

	return (
		<StyledParagraph
			gutterbottom={gutterbottom}
			variant={variant}
			color={color}
			{...other}
		>
			{children}
		</StyledParagraph>
	)
}

export default CustomTypography
