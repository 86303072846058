import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/react-hooks'
import PageLayout from 'modules/layout/containers/PageLayout'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import { ErrorHandler } from 'components'
import {
	ContractRegionGroupsQuery,
	ContractRegionGroupsQueryVariables,
	SelectContractRegionGroupsMutation,
	SelectContractRegionGroupsMutationVariables
} from 'generated/types'
import { Layout } from 'antd'

import { ROUTES, ROUTE_FUNCTIONS } from 'modules/navigation'
import {
	CONTRACT_REGION_GROUPS,
	SOFTWARE_SYSTEM_REPORT_REQUIREMENTS
} from '../../core/queries'
import ContractRegionsTable from '../components/ContractRegionsTable'
import { SELECT_CONTRACT_REGION_GROUPS } from 'modules/core/mutations'
import ReportSider, { STEPS_CHOICE } from './ReportSider'
import { ContractRegionGroupType } from '../../../generated/types'
import { useUser, useAntdMessageApi } from 'providers'
import { PrefilledInfoBanner } from 'modules/approval/components/PrefilledInfoBanner'
import { isReportSubmitted } from 'utils/helper.utils'

const { Content } = Layout

const styles = {
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	}
}
const SelectContractRegionsPage = () => {
	const { id: currentReportId, quarter } = useParams<{
		quarter: string
		id: string
	}>()
	const history = useHistory()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const {
		user: { isVswUser }
	} = useUser()

	const {
		data,
		loading: loadingContractRegionGroups,
		error: fetchContractRegionGroupsError
	} = useQuery<ContractRegionGroupsQuery, ContractRegionGroupsQueryVariables>(
		CONTRACT_REGION_GROUPS,
		{
			variables: { id: currentReportId }
		}
	)

	const [
		selectContractRegionGroups,
		{ loading: isUpdatingContracts, error: updateContractsError }
	] = useMutation<
		SelectContractRegionGroupsMutation,
		SelectContractRegionGroupsMutationVariables
	>(SELECT_CONTRACT_REGION_GROUPS, {
		refetchQueries: [
			{ query: CONTRACT_REGION_GROUPS, variables: { id: currentReportId } },
			{
				query: SOFTWARE_SYSTEM_REPORT_REQUIREMENTS,
				variables: { id: currentReportId }
			}
		],
		awaitRefetchQueries: true
	})

	const currentReport = data?.softwareSystemReport
	const isSubmitted = isReportSubmitted(currentReport?.status)
	const isReadOnly = !isVswUser || isSubmitted

	const isPrefilled = currentReport?.isPrefilled
	const showPrefilledBanner = isPrefilled && !isReadOnly

	const contractRegionGroups = data?.contractRegionGroupList as
		| Array<ContractRegionGroupType>
		| undefined
	const approvalRun = currentReport?.approvalRun

	const nextStepRoute = ROUTE_FUNCTIONS.getSelectRequirementsPath(
		quarter,
		currentReportId
	)
	const handleSubmit = useCallback(
		async (selectedGroupIds: Array<string>) => {
			if (currentReport?.id) {
				const successful = await selectContractRegionGroups({
					variables: {
						reportId: currentReport.id,
						selectedGroupIds: selectedGroupIds
					}
				})
				if (successful) {
					messageApi?.open({
						type: 'success',
						content: t(
							'approval.report.contractRegionsTable.submitSuccessMessage'
						)
					})
					history.push(nextStepRoute)
				}
			}
		},
		[
			selectContractRegionGroups,
			currentReport,
			t,
			nextStepRoute,
			history,
			messageApi
		]
	)

	const headerNavigations = [
		{
			name: t('navigation.mainHeaderMenu.approvalList'),
			key: ROUTES.approval.report.selectContractRegionsSlug
		}
	]

	const sidebarNavigationHandler = (
		routeFunc: (quarter: string, id: string) => string
	) => {
		history.push(routeFunc(quarter, currentReportId))
	}

	const sideBarContent = approvalRun && (
		<ReportSider
			currentStep={STEPS_CHOICE.STEP3}
			onChange={sidebarNavigationHandler}
			quarter={approvalRun.quarter}
			year={approvalRun.year}
			tenant={approvalRun.tenant.name}
			system={currentReport.system.systemName}
		/>
	)
	const defaultSelectedContractRegionGroupsIds =
		currentReport?.selectedContractRegionGroups.map(group => group.id)

	const mainContent = (
		<Content style={styles.contentWrapper}>
			{showPrefilledBanner && (
				<PrefilledInfoBanner style={{ marginBottom: '20px' }} />
			)}
			<ErrorHandler
				loading={loadingContractRegionGroups || isUpdatingContracts}
				error={fetchContractRegionGroupsError || updateContractsError}
			>
				<ContractRegionsTable
					loadingTable={loadingContractRegionGroups}
					isReadOnly={isReadOnly}
					onSubmit={handleSubmit}
					contractRegionGroups={contractRegionGroups || []}
					defaultSelectedContractRegionGroupsIds={
						defaultSelectedContractRegionGroupsIds || []
					}
					isUploading={isUpdatingContracts}
					isSubmitting={isUpdatingContracts}
					isSubmitted={isSubmitted}
				/>
			</ErrorHandler>
		</Content>
	)

	return (
		<PageLayout
			navigationItems={headerNavigations}
			navigationDefaultKey={ROUTES.approval.report.selectContractRegionsSlug}
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default SelectContractRegionsPage
