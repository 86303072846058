const breakpoints = {
	xsmall: 0,
	small: 620,
	medium: 768,
	large: 1000,
	xlarge: 1200
}

const buildVariant = ({
	fontSize,
	fontFamily,
	fontWeight,
	letterSpacing,
	lineHeight
}) => `
  font-size: ${fontSize}px !important;
  font-family: ${fontFamily} !important;
  font-weight:${fontWeight} !important;
  letter-spacing: ${letterSpacing}px !important;
  line-height: ${lineHeight}px;
`

const typography = {
	h1: buildVariant({
		fontSize: 30,
		fontFamily: 'PT Sans, Arial, sans-serif',
		fontWeight: 400,
		lineHeight: 36
	}),
	h2: buildVariant({
		fontSize: 26,
		fontFamily: 'PT Sans, Arial, sans-serif',
		fontWeight: 400,
		lineHeight: 30
	}),
	h3: buildVariant({
		fontSize: 24,
		fontFamily: 'PT Sans, Arial, sans-serif',
		fontWeight: 400,
		lineHeight: 28
	}),
	h4: buildVariant({
		fontSize: 16,
		fontFamily: 'PT Sans, Arial, sans-serif',
		fontWeight: 400,
		lineHeight: 26
	}),
	tiny: buildVariant({
		fontSize: 12,
		fontFamily: 'PT Sans, Arial, sans-serif',
		letterSpacing: 0.25
	}),
	body: buildVariant({
		fontSize: 14,
		fontFamily: 'PT Sans, Arial, sans-serif',
		fontWeight: 'bold'
	}),
	body2: buildVariant({
		fontSize: 16,
		fontFamily: 'PT Sans, Arial, sans-serif',
		letterSpacing: 0.25,
		lineHeight: 26
	}),
	body3: buildVariant({
		fontSize: 14,
		fontFamily: 'PT Sans, Arial, sans-serif',
		letterSpacing: 0.25,
		lineHeight: 22
	}),
	bodySegoe: buildVariant({
		fontSize: 14,
		fontFamily: 'SegoeUI, sans-serif',
		letterSpacing: 0.25,
		lineHeight: 26
	}),
	bodySegoe2: buildVariant({
		fontSize: 14,
		fontFamily: 'SegoeUI Bold, sans-serif',
		lineHeight: 26
	}),
	tinySegoe: buildVariant({
		fontSize: 10,
		fontFamily: 'SegoeUI, sans-serif',
		letterSpacing: 0.25,
		lineHeight: 16
	})
}

export default {
	primary: '#227dc0',
	error: '#f12e49',
	success: '#26c17f',
	font: '#414141',
	black: '#000',
	white: '#fff',
	gray: '#bdbdbd',
	grayLight: '#fafafa',
	grayLighter: 'rgba(214,214,214,0.2)',
	grayDark: '#828282',
	grayDarker: '#c6c6c6',
	warning: '#EAE570',
	yellow: '#f1c02e',
	green: '#52c41a',
	red: '#f12e4b',
	orange: '#f5a623',
	pink: '#f6cdd3',
	changedItem: '#fa9917',
	newItem: '#4ec452',
	deletedItem: '#ff3366',
	delContent: '#f12e4b',
	insContent: '#00b470',
	mainGray: '#e8e8e8',
	...breakpoints,
	typography
}
