import { ApolloClient } from 'apollo-client'

import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client'
import introspectionQueryResultData from 'generated/fragmentTypes.json'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { JWT } from './JWT'
import { handleError } from '../utils/error.utils'
import { createUploadLink } from 'apollo-upload-client'
import { BACKEND_URL } from '../constants'

const httpUploadLink = createUploadLink({
	uri: BACKEND_URL
})

const cache = new InMemoryCache({
	dataIdFromObject: object => {
		switch (object.__typename) {
			case 'ChapterType':
				return object.comparedReleaseId
					? `${object.id}-${object.comparedReleaseId}`
					: defaultDataIdFromObject(object)
			case 'RequirementType':
				return object.comparedReleaseId
					? `${object.id}-${object.comparedReleaseId}`
					: defaultDataIdFromObject(object)
			case 'RegionType':
				return object.comparedReleaseId
					? `${object.okv}-${object.comparedReleaseId}-${object.comparedRequirementId}`
					: `${object.okv}`
			case 'ReleaseRequirementsChangesDirectoryType':
				return object.comparedReleaseId
					? `${object.currentReleaseId}-${object.comparedReleaseId}`
					: `${object.currentReleaseId}`
			case 'ReleaseAttachmentContractsChangesDirectoryType':
				return object.comparedReleaseId
					? `${object.currentReleaseId}-${object.comparedReleaseId}`
					: `${object.currentReleaseId}`
			case 'ReleaseContractsChangesDirectoryType':
				return object.comparedReleaseId
					? `${object.currentReleaseId}-${object.comparedReleaseId}`
					: `${object.currentReleaseId}`
			default:
				return defaultDataIdFromObject(object) // fall back to default handling
		}
	},
	possibleTypes: introspectionQueryResultData.possibleTypes
})

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			authorization: JWT.get() ? `jwt ${JWT.get()}` : ''
		}
	}
})

const errorTracker = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(error => handleError(error))
	}
	if (networkError) {
		console.error(`[Network error]: ${networkError}`)
	}
})

export const apolloClient = new ApolloClient({
	link: ApolloLink.from([errorTracker, authLink, httpUploadLink]),
	cache
})
