import styled from 'styled-components'
import { LAYOUT_MAX_WIDTH, SUB_HEADER_HEIGHT } from 'modules/core/constants'
import { Layout } from 'antd'

const { Header } = Layout

export const CustomSubHeader = styled(Header)`
	position: fixed;
	display: flex;
	padding: 0 75px !important;
	align-items: center;
	z-index: ${({ zindex }) => zindex};
	width: 100%;
	max-width: ${LAYOUT_MAX_WIDTH}px;
	height: ${SUB_HEADER_HEIGHT}px !important;
	line-height: 45px;
`
