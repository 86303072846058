/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReportStatus, ContentCheckProofStatus } from 'generated/types'

/**
 * Compares two lists of group IDs regardless of their order
 * */
export const groupIdsAreEqual = (idList1: any, idList2: any) => {
	if (idList1.length !== idList2.length) {
		return false
	}
	for (const value of idList1) {
		if (idList2.indexOf(value) === -1) {
			return false
		}
	}
	return true
}

export const isReportSubmitted = (status: ReportStatus | undefined) =>
	status === ReportStatus.IN_REVIEW || status === ReportStatus.APPROVED

export const isContentCheckProofSubmitted = (
	status: ContentCheckProofStatus | undefined
) =>
	status === ContentCheckProofStatus.IN_REVIEW ||
	status === ContentCheckProofStatus.APPROVED
