import styled from 'styled-components'
import { COLORS } from '../constants'

const getColor = (color, theme) => {
	switch (color) {
		case COLORS.SUCCESS:
			return theme.green
		case COLORS.RED:
			return theme.red
		default:
			return theme.orange
	}
}

export const Circle = styled.div`
	width: ${props => props.width}px;
	border-radius: 50%;
	height: ${props => props.width}px;
	background: ${props => getColor(props.color, props.theme)};
`
