import { useTranslation } from 'react-i18next'
import { SideWrapper, InfoBackground } from './styles'
import { AkaTypography } from 'components'

export interface ContentCheckSider {
	year: number
	quarter: number
	tenant: string
	system: string
	contract: string
}

const ContentCheckSider = ({
	year,
	quarter,
	tenant,
	system,
	contract
}: ContentCheckSider) => {
	const [t] = useTranslation()

	return (
		<SideWrapper>
			<InfoBackground>
				<AkaTypography gutterbottom={0} variant={'bodySegoe'}>
					{`${t('approval.sidebar.info.approval')}`}
					{year && <strong>{` ${year}-Q${quarter}`}</strong>}
				</AkaTypography>
				<AkaTypography gutterbottom={0} variant={'bodySegoe'}>
					{`${t('approval.sidebar.info.contentCheck')}`}
					<strong>{` ${contract} ${tenant}`}</strong>
				</AkaTypography>
				<AkaTypography gutterbottom={0} variant={'bodySegoe'}>
					{`${t('approval.sidebar.info.system')}`}
					<strong>{` ${system}`}</strong>
				</AkaTypography>
			</InfoBackground>
		</SideWrapper>
	)
}

export default ContentCheckSider
