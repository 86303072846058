import React from 'react'
import * as PropTypes from 'prop-types'
import LoadingIcon from '../../img/load-icon.svg'
import { Wrapper } from './styles'

const LoadingSpinner = ({ style, fullPage, testId }) => (
	<Wrapper data-testid={testId} style={style} fullPage={fullPage}>
		<img src={LoadingIcon} width={50} alt="logo" />
	</Wrapper>
)

LoadingSpinner.propTypes = {
	style: PropTypes.shape({}),
	fullPage: PropTypes.bool,
	testId: PropTypes.string
}

LoadingSpinner.defaultProps = {
	style: {},
	fullPage: false,
	testId: 'loading-spinner'
}

export default LoadingSpinner
