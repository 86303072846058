import React from 'react'
import { useHistory } from 'react-router-dom'
import { CustomCloseOutlined } from './styles'
import { ROUTES } from 'modules/navigation'

const CloseButton = () => {
	const history = useHistory()

	function handleClick() {
		history.push(ROUTES.home)
	}

	return (
		<CustomCloseOutlined data-testid="close-button" onClick={handleClick} />
	)
}

export default CloseButton
