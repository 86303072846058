import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import { Divider, Layout, Menu } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import PageLayout from 'modules/layout/containers/PageLayout'
import { ROUTES } from 'modules/navigation'
import { AkaTypography, ErrorHandler } from 'components'
import { CONTENT_MAX_WIDTH } from 'modules/core/constants'
import {
	CONTENT_CHECK_LIST,
	SOFTWARE_SYSTEM_APPROVALS,
	SOFTWARE_SYSTEM_REPORT_LIST,
	SOFTWARE_SYSTEMS
} from 'modules/core/queries'
import { useState } from 'react'
import theme from 'components/themeProvider/theme'
import CardList from '../components/CardList'
import { ApprovalFeedList } from './ApprovalFeedList'

const { Content } = Layout

const styles = {
	menuWrapperStyle: {
		overflow: 'auto',
		maxHeight: '50%',
		flexShrink: 0
	},
	menuStyle: {
		borderRight: 0,
		paddingTop: 4,
		fontFamily: 'SegoeUI, Sans',
		fontWeight: 'bold',
		overflow: 'hidden'
	},
	contentWrapper: {
		padding: '24px 24px 24px 48px',
		maxWidth: CONTENT_MAX_WIDTH
	},
	cardList: {
		marginTop: 32
	},
	sidebarContent: {
		padding: '20px 15px',
		backgroundColor: theme.mainGray,
		borderBottom: '1px solid lightgray'
	},
	sidebarContentTitle: {
		display: 'flex',
		flexDirection: 'row'
	},
	systemApprovalBadge: {
		color: 'white',
		padding: '2px 8px',
		borderRadius: '4px',
		marginRight: '20px'
	}
}

const SYSTEM_APPROVAL_COLOR = {
	APPROVED: '#128020',
	REJECTED: '#FF3466',
	APPROVED_WITH_RESERVATION: '#F0D75D'
}

const SystemApprovalStatus = ({ systemId, quarter, year }) => {
	const [t] = useTranslation()
	const { data, error } = useQuery(SOFTWARE_SYSTEM_APPROVALS, {
		variables: {
			systemId: systemId,
			quarter: quarter,
			year: year
		},
		skip: !systemId
	})

	const approvals = data?.softwareSystem?.approvals

	return (
		<ErrorHandler error={error}>
			{approvals && (
				<AkaTypography variant={'h3'} as={'div'}>
					{approvals.map(systemApproval => {
						const tenant = systemApproval.tenant
						const statusKey = systemApproval.status
						return (
							<span key={tenant.id}>
								<span
									style={{
										color: SYSTEM_APPROVAL_COLOR[statusKey],
										fontWeight: 'bold'
									}}
								>
									{t(`approval.systemApprovalStatus.${statusKey}`)}{' '}
								</span>
								<span
									style={{
										background: tenant.hexColor,
										marginLeft: 8,
										...styles.systemApprovalBadge
									}}
								>
									{tenant.name}
								</span>
							</span>
						)
					})}
				</AkaTypography>
			)}
		</ErrorHandler>
	)
}

const ApprovalListPage = () => {
	const { quarter } = useParams()
	const history = useHistory()
	const [t] = useTranslation()
	const [selectedMenu, setSelectedMenu] = useState(null)

	const [year, quarterNum] = quarter.split('-Q')

	const {
		data: systems,
		loading: loadingSystems,
		error: errorSystems
	} = useQuery(SOFTWARE_SYSTEMS)

	const { data, loading, error } = useQuery(SOFTWARE_SYSTEM_REPORT_LIST, {
		variables: {
			systemId: selectedMenu && selectedMenu.key,
			quarter: quarterNum,
			year: year
		},
		skip: !selectedMenu
	})

	const {
		data: contentChecks,
		loading: loadingContentChecks,
		error: errorContentChecks
	} = useQuery(CONTENT_CHECK_LIST, {
		variables: {
			systemId: selectedMenu && selectedMenu.key,
			quarter: quarterNum,
			year: year
		},
		skip: !selectedMenu
	})

	const softwareSystems = systems?.softwareSystems
	const contentCheckList = contentChecks?.contentCheckList
	const reportList = data?.softwareSystemReportList

	const menuItems = useMemo(
		() =>
			softwareSystems && softwareSystems.length > 0
				? softwareSystems.map(value => ({
						key: value.id,
						label: value.systemName
				  }))
				: [],
		[softwareSystems]
	)

	const getMenuItem = itemKey => menuItems.find(item => item.key === itemKey)

	const navigations = [
		{
			name: t('navigation.mainHeaderMenu.approvalList'),
			key: ROUTES.approval.base
		}
	]

	const navigationChangeHandler = e => {
		if (e.key !== ROUTES.approval.base) {
			history.push(`${ROUTES.approval.base}/${quarter}${e.key}`)
		}
	}

	const sideBarContent = (
		<ErrorHandler loading={loadingSystems} error={errorSystems}>
			<div style={styles.sidebarContent}>
				<div style={styles.sidebarContentTitle}>
					<AkaTypography variant={'bodySegoe'} gutterbottom={0}>
						{`${t('approval.approvalList.sidebarContent.title')}`}{' '}
						<strong>{quarter}</strong>
					</AkaTypography>
				</div>
			</div>
			{menuItems.length > 0 && (
				<>
					<div style={styles.menuWrapperStyle}>
						<Menu
							mode="inline"
							style={styles.menuStyle}
							defaultSelectedKeys={[menuItems[0].key]}
							onSelect={e => {
								setSelectedMenu(getMenuItem(e.key))
							}}
							items={menuItems}
						/>
					</div>
					<Divider />
					<ApprovalFeedList
						systemId={selectedMenu && selectedMenu.key}
						year={year}
						quarter={quarterNum}
					/>
				</>
			)}
		</ErrorHandler>
	)

	const mainContent = (selectedSystemLabel, selectedSystemId) => (
		// we need to pass the label as a string here as only text nodes allowed by trans component
		<Content style={styles.contentWrapper}>
			<ErrorHandler
				loading={loading || loadingContentChecks}
				error={error || errorContentChecks}
			>
				{!loading && !loadingContentChecks && (
					<>
						<AkaTypography variant={'h1'}>
							{menuItems && menuItems.length > 0 ? (
								<Trans i18nKey={'approval.approvalList.mainContent.title'}>
									Folgezulassung für <strong>{{ selectedSystemLabel }}</strong>{' '}
									in <strong>{{ quarter }}</strong>
								</Trans>
							) : (
								t('approval.approvalList.mainContent.userHasNoSystems')
							)}
						</AkaTypography>
						<SystemApprovalStatus
							systemId={selectedMenu && selectedMenu.key}
							quarter={quarterNum}
							year={year}
						/>
						<div style={styles.cardList}>
							<CardList
								reportsLoading={loading}
								reportList={reportList}
								contentCheckList={contentCheckList}
								contentChecksLoading={loadingContentChecks}
								selectedSystemId={selectedSystemId}
							/>
						</div>
					</>
				)}
			</ErrorHandler>
		</Content>
	)

	useEffect(() => {
		if (menuItems.length > 0 && !selectedMenu) {
			setSelectedMenu(menuItems[0])
		}
	}, [softwareSystems, menuItems, setSelectedMenu, selectedMenu])

	return (
		<PageLayout
			navigationItems={navigations}
			navigationDefaultKey={ROUTES.approval.base}
			onNavigationChange={navigationChangeHandler}
			renderMainContent={
				selectedMenu && mainContent(selectedMenu.label, selectedMenu.key)
			}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default ApprovalListPage
