import gql from 'graphql-tag'

export const fragmentUser = gql`
	fragment User on UserType {
		id
		email
		firstName
		lastName
		isSuperuser
		hasSelectedContracts
		isKasseUser
		isVswUser
		helpFile
		contractsFilter {
			id
			contractId
		}
	}
`
