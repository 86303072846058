import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
	RELEASE_REQUIREMENTS_CHANGES,
	RELEASE_REVISION_HISTORY_CHAPTERS
} from 'modules/core/queries'
import { CHANGES_CHAPTER_IDS } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { useAntdMessageApi } from 'providers'

const requirements_chapters = index => [
	{
		id: CHANGES_CHAPTER_IDS.REQUIREMENT,
		title: 'Anforderungen',
		pos: index + 1,
		fullTitle: `${index}. Anforderungen`,
		chapters: [
			{
				id: CHANGES_CHAPTER_IDS.CHANGED_REQUIREMENT,
				title: 'Änderungen',
				pos: 1,
				fullTitle: `${index}.1. Änderungen`
			},
			{
				id: CHANGES_CHAPTER_IDS.NEW_REQUIREMENT,
				title: 'Neue Anforderungen',
				pos: 2,
				fullTitle: `${index}.2. Neue Anforderungen`
			},
			{
				id: CHANGES_CHAPTER_IDS.DELETED_REQUIREMENT,
				title: 'Gelöschte Anforderungen',
				pos: 3,
				fullTitle: `${index}.3. Gelöschte Anforderungen`
			}
		]
	},
	{
		id: CHANGES_CHAPTER_IDS.CHANGED_ATTACHMENTS,
		title: 'Anlagen',
		pos: index + 2,
		fullTitle: `${index + 1}. Anlagen`,
		chapters: []
	},
	{
		id: CHANGES_CHAPTER_IDS.SELECTIVE_CONTRACT_DEFINITIONS,
		title: 'Selektivvertragsdefinitionen',
		pos: index + 3,
		fullTitle: `${index + 2}. Selektivvertragsdefinitionen`,
		chapters: []
	}
]

const useRevisionHistory = (
	release,
	comparedRelease,
	selectedContractIdsList,
	selected
) => {
	const [t] = useTranslation()
	const [rootChapter, setRootChapter] = useState(undefined)
	const [selectedChapter, setSelectedChapter] = useState(undefined)
	const [selectedChapterId, setSelectedChapterId] = useState(undefined)
	const [rootChapterId, setRootChapterId] = useState(undefined)
	const [dynamicChapterIds, setDynamicChapterIds] = useState([])
	const messageApi = useAntdMessageApi()

	const {
		loading: loadingChapters,
		data: { release: { revisionHistoryRootChapter } = {} } = {}
	} = useQuery(RELEASE_REVISION_HISTORY_CHAPTERS, {
		variables: {
			id: release && release.id
		},
		skip: !selected
	})

	const {
		data: {
			releaseRequirementsChanges: {
				deletedRequirements = [],
				newRequirements = [],
				changedRequirements = [],
				changedAssignmentRequirements = []
			} = {}
		} = {},
		loading
	} = useQuery(RELEASE_REQUIREMENTS_CHANGES, {
		skip: !comparedRelease || !release || !selected,
		variables: {
			currentReleaseId: release && release.id,
			comparedReleaseId: comparedRelease && comparedRelease.id,
			selectedContracts: selectedContractIdsList
		}
	})

	const handleSelectChapter = chapterId => {
		setSelectedChapterId(chapterId)
		setSelectedChapter(
			rootChapter.chapters.find(chapter => chapter.id === chapterId)
		)
	}

	useEffect(() => {
		if (
			loading &&
			Object.keys(CHANGES_CHAPTER_IDS).includes(selectedChapterId) &&
			selectedChapterId !== CHANGES_CHAPTER_IDS.CHANGED_ATTACHMENTS
		) {
			messageApi?.open({
				type: 'success',
				content: t('core.downloadReleaseRequirementChanges')
			})
		}
	}, [loading, selectedChapterId, t, messageApi])

	useEffect(() => {
		if (revisionHistoryRootChapter) {
			const { chapters = [] } = revisionHistoryRootChapter
			let chapter_list = []
			let dynamic_chapter_ids = []
			let index = 0

			for (let tempChapter of chapters) {
				dynamic_chapter_ids.push(tempChapter.id)
				chapter_list.push({ ...tempChapter })
				index += 1
			}
			const temp_chapter_list = [
				...chapter_list,
				...requirements_chapters(index)
			]
			setRootChapter({ chapters: temp_chapter_list })
			setDynamicChapterIds(dynamic_chapter_ids.slice(1))
			setRootChapterId(dynamic_chapter_ids[0])
		}
	}, [revisionHistoryRootChapter, setRootChapter])

	useEffect(() => {
		if (rootChapter) {
			handleSelectChapter(rootChapter.chapters[0].id)
		}
	}, [rootChapter]) // eslint-disable-line

	return {
		loading,
		loadingChapters,
		newRequirements,
		changedRequirements,
		deletedRequirements,
		changedAssignmentRequirements,
		dynamicChapterIds,
		selectedChapter,
		selectedChapterId,
		rootChapterId,
		rootChapter,
		handleSelectChapter
	}
}

export default useRevisionHistory
