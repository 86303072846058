export default {
	login: 'Anmelden',
	loginHeadline:
		'Willkommen im AKA-Portal der Hausärztlichen Vertragsgemeinschaft AG',
	passwordForget: 'Passwort vergessen',
	requestAccount: 'Benutzerregistrierung anfordern',
	email: 'E-Mail-Adresse',
	password: 'Passwort',
	emailRequired: 'Geben Sie bitte eine E-Mail-Adresse an.',
	passwordRequired: 'Passwort ist erforderlich.',
	invalidCredentials: 'Ungültige Anmeldeinformationen',
	validations: {
		email: 'Eine gültige E-Mail-Adresse ist erforderlich.'
	},
	footer: {
		company: 'HÄVG Rechenzentrum GmbH',
		impressum: 'Impressum'
	}
}
