/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, KeyboardEvent, useState, useCallback } from 'react'
import { Table, Radio, Input, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { NumberInput } from './styles'
import { AkaButton, AkaTypography } from 'components'
import { SystemCostType } from 'generated/types'

function checkIfNumber(event: KeyboardEvent<HTMLInputElement>) {
	const regex = new RegExp(
		/(^\d*,?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
	)

	return !event.key.match(regex) && event.preventDefault()
}

const styles = {
	bottomContainer: {
		padding: '16px 8px',
		display: 'flex'
	},
	tableLegend: {
		flex: 1
	}
}

export type SystemCostsTable = {
	isReadOnly: boolean
	initialData: Array<SystemCostType>
	onSubmit: (systemCosts: Array<SystemCostType>) => void
	isSubmitting: boolean
}

const SystemCostsTable = ({
	isReadOnly,
	initialData,
	onSubmit,
	isSubmitting
}: SystemCostsTable) => {
	const [t] = useTranslation()

	const [tableData, setTableData] = useState(initialData)
	const handleInputChange = useCallback(
		(dataIndex: string, key: number, value: string) => {
			setTableData((previousTableData: any) => {
				const updatedTableData = [...previousTableData]
				updatedTableData[key] = { ...updatedTableData[key], [dataIndex]: value }
				return updatedTableData
			})
		},
		[]
	)

	const columns = [
		{
			title: t('approval.report.systemCosts.table.setupAndMaintenance'),
			dataIndex: 'type',
			key: 'type',
			render: (text: string) => <div>{text}</div>
		},
		{
			title: t('approval.report.systemCosts.table.module'),
			dataIndex: 'module',
			key: 'module'
		},
		{
			title: t('approval.report.systemCosts.table.amount'),
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: number, record: any) => (
				<NumberInput
					type="number"
					value={amount}
					readOnly={isReadOnly}
					maxLength={100}
					name="amount"
					onKeyDown={event => checkIfNumber(event)}
					onChange={e =>
						handleInputChange('amount', record.key, e.target.value)
					}
				/>
			)
		},
		{
			title: t('approval.report.systemCosts.table.accounting'),
			dataIndex: 'accounting',
			key: 'accounting',
			render: (text: string, record: any) => (
				<Radio.Group
					disabled={isReadOnly}
					value={text}
					style={{ width: '100%', textAlign: 'center' }}
					onChange={e =>
						handleInputChange('accounting', record.key, e.target.value)
					}
				>
					<Radio
						style={{
							visibility: record.key === 5 ? 'hidden' : 'visible'
						}}
						value="LANR"
					>
						{t('approval.report.systemCosts.table.lanr')}
					</Radio>
					<Radio value="BSNR">
						{t('approval.report.systemCosts.table.bsnr')}
					</Radio>
				</Radio.Group>
			)
		},
		{
			title: t('approval.report.systemCosts.table.note'),
			dataIndex: 'note',
			key: 'note',
			render: (text: string, record: any) => (
				<Input
					readOnly={isReadOnly}
					name="note"
					maxLength={500}
					value={text}
					onChange={e => handleInputChange('note', record.key, e.target.value)}
				/>
			)
		}
	]

	return (
		<>
			<Table
				size="small"
				columns={columns}
				dataSource={tableData}
				pagination={false}
			/>

			<div style={styles.bottomContainer}>
				<div style={styles.tableLegend}>
					<Row>
						<Col span={3}>
							<AkaTypography gutterbottom={0} variant="tiny">
								{t('approval.report.systemCosts.table.legend.row1.label')}
							</AkaTypography>
						</Col>
						<Col>
							<AkaTypography gutterbottom={0} variant="tiny">
								{t('approval.report.systemCosts.table.legend.row1.text')}
							</AkaTypography>
						</Col>
					</Row>
					<Row>
						<Col span={3}>
							<AkaTypography gutterbottom={0} variant="tiny">
								{t('approval.report.systemCosts.table.legend.row2.label')}
							</AkaTypography>
						</Col>
						<Col>
							<AkaTypography gutterbottom={0} variant="tiny">
								{t('approval.report.systemCosts.table.legend.row2.text')}
							</AkaTypography>
						</Col>
					</Row>
					<Row>
						<Col span={3}>
							<AkaTypography gutterbottom={0} variant="tiny">
								{t('approval.report.systemCosts.table.legend.row3.label')}
							</AkaTypography>
						</Col>
						<Col>
							<AkaTypography gutterbottom={0} variant="tiny">
								{t('approval.report.systemCosts.table.legend.row3.text')}
							</AkaTypography>
						</Col>
					</Row>
				</div>
				{!isReadOnly && (
					<AkaButton
						loading={isSubmitting}
						type="primary"
						onClick={() => onSubmit(tableData)}
					>
						{t('approval.report.systemCosts.submitButton')}
					</AkaButton>
				)}
			</div>
		</>
	)
}
export default memo(SystemCostsTable)
