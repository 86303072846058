import React, { useContext, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import { CHAPTER } from 'modules/core/queries'
import Chapter from '../components/Chapter'
import { ContractsFilterContext } from './CatalogPage'

const ChapterDetails = ({
	releaseLoading,
	id,
	setSelectedChapter,
	isFirstChapter
}) => {
	const { selectedContractIdsList } = useContext(ContractsFilterContext)
	const [getChapter, { loading, data, error }] = useLazyQuery(CHAPTER, {
		fetchPolicy: 'network-only',
		onCompleted: data => {
			setSelectedChapter(data.chapter)
		}
	})
	useEffect(() => {
		if (id) {
			getChapter({
				variables: { id, selectedContracts: selectedContractIdsList }
			})
		}
	}, [id, getChapter, selectedContractIdsList])

	return (
		<Chapter
			isFirstChapter={isFirstChapter}
			loading={loading}
			chapter={data && data.chapter}
			error={error}
			releaseLoading={releaseLoading}
		/>
	)
}

ChapterDetails.propTypes = {
	releaseLoading: PropTypes.bool,
	id: PropTypes.string,
	setSelectedChapter: PropTypes.func
}

ChapterDetails.defaultProps = {
	releaseLoading: true,
	id: null,
	setSelectedChapter: () => {}
}

export default ChapterDetails
