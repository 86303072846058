export default {
	contractsFilterDescription:
		'Mit dieser Liste können Sie den Vertragsfilter im AKA-Portal automatisch vorbefüllen',
	activeContactsFilter: 'Meine Verträge als Filter anwenden',
	profileInfo: {
		email: 'E-Mail-Adresse',
		firstname: 'Vorname',
		lastname: 'Nachname'
	},
	profile: 'Profil',
	myContracts: 'Meine Verträge',
	systems: 'Meine Systeme',
	systemList: {
		editForm: {
			validation: {
				maxLength: 'Es sind maximal 255 Zeichen erlaubt',
				website: 'Bitte eine gültige URL mit http:// oder https:// angeben'
			},
			placeholders: {
				website: 'Website',
				slogan: 'Slogan'
			},
			edit: 'Bearbeiten',
			save: 'Speichern'
		},
		labels: {
			website: 'WEBSITE'
		},
		noResults: 'Keine Systeme zugewiesen'
	}
}
