import React, { useEffect, useMemo, useState } from 'react'
import { Table } from 'antd'
import { AkaTypography, ErrorHandler } from 'components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { USER_CONTRACTS } from 'modules/core/queries'
import { CONTRACT_TYPS } from 'modules/core/constants'
import { SET_USER_CONTRACT_FILTER } from 'modules/core/mutations'
import { debounce } from 'hooks/helpers'
import { useUser } from 'providers'
import { useTranslation } from 'react-i18next'
import { compareByAlph } from 'modules/core/helpers'

const UserContracts = () => {
	const { user, refetchMe } = useUser()
	const [t] = useTranslation()
	const { loading, data, error } = useQuery(USER_CONTRACTS, {
		fetchPolicy: 'network-only'
	})
	const [setContractFilter, { loading: updating }] = useMutation(
		SET_USER_CONTRACT_FILTER
	)

	const [selectedContractKeys, setSelctedContractKeys] = useState(
		user.contractsFilter.map(({ id }) => id)
	)
	const updateUserContractFilter = debounce(
		contractList => setContractFilter({ variables: { contractList } }),
		1000
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const userContracts = data ? data.userContracts : []

	const filters = useMemo(
		() =>
			userContracts.reduce(
				(acc, curr) => {
					return {
						identificationList: [
							{ text: curr.identification, value: curr.identification },
							...acc.identificationList
						],
						contractIdList: [
							{ text: curr.contractId, value: curr.contractId },
							...acc.contractIdList
						]
					}
				},
				{
					identificationList: [],
					contractIdList: []
				}
			),
		[userContracts]
	)

	const columns = [
		{
			title: '#',
			dataIndex: 'identification',
			filters: filters.identificationList,
			defaultSortOrder: 'descend',
			sorter: (a, b) => compareByAlph(a.identification, b.attachmentObject),
			onFilter: (value, record) => {
				return record.identification === value
			},
			filterMultiple: true
		},
		{
			title: 'Vertrag',
			dataIndex: 'contractId',
			filters: filters.contractIdList,
			defaultSortOrder: 'ascend',
			sorter: (a, b) => compareByAlph(a.contractId, b.contractId),
			onFilter: (value, record) => {
				return record.contractId === value
			}
		},
		{
			title: 'Vertragstyp',
			dataIndex: 'typ',
			render: typ => CONTRACT_TYPS[typ],
			filters: Object.keys(CONTRACT_TYPS).map(key => ({
				text: CONTRACT_TYPS[key],
				value: key
			})),
			defaultSortOrder: 'ascend',
			sorter: (a, b) =>
				compareByAlph(CONTRACT_TYPS[a.typ], CONTRACT_TYPS[b.typ]),
			onFilter: (value, record) => {
				return record.typ === value.toString()
			}
		}
	]

	useEffect(() => {
		return () => refetchMe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// rowSelection object indicates the need for row selection
	const rowSelection = {
		onChange: selectedRowKeys => {
			setSelctedContractKeys(selectedRowKeys)
			updateUserContractFilter(selectedRowKeys)
		},
		getCheckboxProps: record => ({
			name: record.name
		})
	}

	return (
		<ErrorHandler loading={loading || updating} error={error || error}>
			<AkaTypography variant="body2">
				{t('account.contractsFilterDescription')}
			</AkaTypography>
			<Table
				rowSelection={{
					type: 'checkbox',
					columnWidth: 'auto',
					selectedRowKeys: selectedContractKeys,
					...rowSelection
				}}
				columns={columns}
				dataSource={userContracts}
				pagination={false}
				loading={loading || updating}
			/>
		</ErrorHandler>
	)
}

export default UserContracts
