/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useMemo, useEffect } from 'react'
import { Input, Form, Select, Card, Space } from 'antd'
import { AkaButton, AkaTypography } from 'components'
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
	JustificationInputType,
	JustificationType,
	RequirementType
} from 'generated/types'

const { TextArea } = Input
const FormItem = Form.Item
const FormList = Form.List

type PartialRequirementType = Pick<
	RequirementType,
	'id' | 'prefixedCaliberId' | 'title'
>

type RequirementOptionType = {
	label: string
	value: string
	prefixedCaliberId: RequirementType['prefixedCaliberId']
}

type PartialJustification = Pick<JustificationType, 'id' | 'text'> & {
	requirements: PartialRequirementType[]
}

export interface JustificationsForm {
	unsatisfiedRequirements: PartialRequirementType[]
	submittedJustifications: PartialJustification[]
	onSubmit: (justifications: JustificationInputType[]) => Promise<void>
	isSubmitting: boolean
	isSubmitted: boolean
	isReadOnly: boolean
}

const getRequirementsOptions = (
	requirements: PartialRequirementType[]
): RequirementOptionType[] =>
	requirements
		.map(({ id, prefixedCaliberId, title }) => ({
			label: `${prefixedCaliberId} - ${title}`,
			value: id,
			prefixedCaliberId: prefixedCaliberId
		}))
		.sort(
			(a: RequirementOptionType, b: RequirementOptionType) =>
				parseInt(a.value) - parseInt(b.value)
		)

const requirementsHaveChanged = (changedValues: any): boolean => {
	for (const justification of changedValues.justifications) {
		if (justification?.requirements) return true
	}
	return false
}
const JustificationsForm = ({
	unsatisfiedRequirements,
	submittedJustifications,
	onSubmit,
	isSubmitting,
	isSubmitted,
	isReadOnly
}: JustificationsForm) => {
	const [t] = useTranslation()
	const initialRequirementOptions = useMemo(
		() => getRequirementsOptions(unsatisfiedRequirements),
		[unsatisfiedRequirements]
	)

	const initiallyAssignedRequirementIds = submittedJustifications.reduce<
		string[]
	>((acc, next) => {
		return [...acc, ...next.requirements.map(req => req.id)]
	}, [])

	const [assignedRequirementIds, setAssignedRequirementIds] = useState<
		string[]
	>(initiallyAssignedRequirementIds)

	const unassignedRequirementOptions = initialRequirementOptions.filter(
		option => !assignedRequirementIds.includes(option.value)
	)

	const [notAllRequirementsAssignedError, setNotAllRequirementsAssignedError] =
		useState(false)
	const [
		justifyingUnneededRequirementsError,
		setJustifyingUnneededRequirementsError
	] = useState(false)

	const [form] = Form.useForm()

	const handleSubmit = ({
		justifications
	}: {
		justifications: any[]
	}): void => {
		if (!unassignedRequirementOptions.length) {
			const submitData = justifications.map(justification => ({
				text: justification.text,
				requirementIds: justification.requirements.map(
					(requirement: any) => requirement.value
				)
			}))
			onSubmit(submitData)
		} else {
			setNotAllRequirementsAssignedError(true)
		}
	}

	useEffect(() => {
		if (
			!unassignedRequirementOptions.length &&
			notAllRequirementsAssignedError
		) {
			setNotAllRequirementsAssignedError(false)
		}
	}, [unassignedRequirementOptions, notAllRequirementsAssignedError])

	useEffect(() => {
		const unneededRequirements = assignedRequirementIds.length
			? assignedRequirementIds.filter(
					reqId => unsatisfiedRequirements.findIndex(r => r.id === reqId) === -1
			  )
			: []
		setJustifyingUnneededRequirementsError(!!unneededRequirements.length)
	}, [assignedRequirementIds, unsatisfiedRequirements])

	const initialValues = useMemo(
		() =>
			submittedJustifications.length
				? submittedJustifications.map(justification => ({
						text: justification.text,
						requirements: justification.requirements.map(requirement => ({
							value: requirement.id,
							label: requirement.prefixedCaliberId
						}))
				  }))
				: [
						{
							requirements: [],
							text: ''
						}
				  ],
		[submittedJustifications]
	)

	const errorMessage =
		(notAllRequirementsAssignedError &&
			t('approval.report.justifications.notAllRequirementsAssignedError')) ||
		(justifyingUnneededRequirementsError &&
			t('approval.report.justifications.justifyingUnneededRequirements'))

	return (
		<Form
			onFinish={handleSubmit}
			form={form}
			layout="vertical"
			name="justificationForm"
			onValuesChange={(changedValues, allValues: any) => {
				if (requirementsHaveChanged(changedValues)) {
					const { justifications } = allValues
					const assignedRequirementIds = justifications
						.filter(
							(field: any) =>
								field?.requirements && field.requirements.length > 0
						)
						.reduce(
							(acc: any, currentField: any) => [
								...acc,
								...currentField.requirements.reduce(
									(acc: any, requirement: any) => [...acc, requirement.value],
									[]
								)
							],
							[]
						)
					setAssignedRequirementIds(assignedRequirementIds)
				}
				// reset options to initial state
				if (
					changedValues.justifications.length === 0 &&
					!changedValues.justifications[0]
				) {
					setAssignedRequirementIds([])
				}
			}}
		>
			<FormList name="justifications" initialValue={initialValues}>
				{(fields, { add, remove }) => {
					return (
						<Space
							direction="vertical"
							size="large"
							style={{ display: 'flex' }}
						>
							{fields.map(field => {
								return (
									<div key={field.key}>
										<Card
											actions={
												fields.length > 0 && !isReadOnly
													? [
															<AkaButton
																key={'remove'}
																danger
																onClick={() => {
																	remove(field.name)
																}}
																icon={<MinusCircleOutlined />}
															>
																{t('approval.report.justifications.remove')}
															</AkaButton>
													  ]
													: undefined
											}
										>
											<FormItem
												label={t(
													'approval.report.justifications.requirementLabel'
												)}
												name={[field.name, 'requirements']}
												rules={[
													{
														required: true,
														message: t(
															'approval.report.justifications.requirementError'
														)
													}
												]}
												style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }}
											>
												<Select
													labelInValue
													allowClear
													mode="multiple"
													optionLabelProp="prefixedCaliberId"
													placeholder={t(
														'approval.report.justifications.requirementPlaceHolder'
													)}
													options={unassignedRequirementOptions}
													notFoundContent={t(
														'approval.report.justifications.noRequirementsFound'
													)}
												/>
											</FormItem>
											<FormItem
												label={t(
													'approval.report.justifications.justifcationLabel'
												)}
												name={[field.name, 'text']}
												rules={[
													{
														required: true,
														message: t(
															'approval.report.justifications.justifcationError'
														)
													},
													{
														whitespace: true, // validate for whitespace only
														message: t(
															'approval.report.justifications.justifcationError'
														)
													}
												]}
											>
												<TextArea
													readOnly={isReadOnly}
													placeholder={t(
														'approval.report.justifications.justifcationPlaceholder'
													)}
													rows={2}
												/>
											</FormItem>
										</Card>
									</div>
								)
							})}
							{!isReadOnly && (
								<Form.Item
									style={{
										display: 'flex',
										justifyContent: 'center',
										opacity: unassignedRequirementOptions.length > 0 ? 1 : 0,
										pointerEvents:
											unassignedRequirementOptions.length > 0 ? 'auto' : 'none'
									}}
								>
									<AkaButton type="primary" onClick={() => add()}>
										<PlusCircleOutlined />{' '}
										{t('approval.report.justifications.add')}
									</AkaButton>
								</Form.Item>
							)}
						</Space>
					)
				}}
			</FormList>
			<FormItem
				style={{
					float: 'right',
					textAlign: 'right'
				}}
				help={errorMessage}
				validateStatus="error"
			>
				{!isReadOnly && (
					<AkaButton
						type="primary"
						htmlType="submit"
						disabled={isSubmitting}
						loading={isSubmitting}
					>
						{t('approval.report.justifications.submit')}
					</AkaButton>
				)}
				{isSubmitted && (
					<AkaTypography variant={'tiny'}>
						{t('approval.report.reportAlreadySubmitted')}
					</AkaTypography>
				)}
			</FormItem>
		</Form>
	)
}

export default memo(JustificationsForm)
